import { Long } from 'cosmjs-types/helpers';
import { calculateFee } from 'cosmjs/packages/stargate';
import { MsgTransferEncodeObject } from 'cosmjs/packages/stargate/build/modules/ibc/messages';
import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useAsset } from '../../../asset/asset-context';
import { ALL_ITEMS_PAGINATION, DEFAULT_GAS_ADJUSTMENT } from '../../../client/client-types';
import { SigningStationClient } from '../../../client/station-clients/signing-station-client';
import { StationClient } from '../../../client/station-clients/station-client';
import { convertToCoinsAmount, getFixedDenom } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';
import { useWallet } from '../../../wallet/wallet-context';
import { isWalletSupported } from '../../../wallet/wallet-service';
import { getSourceChannel } from '../../ibc-transfer-service';
import { TemporarySessionWallet } from '../../../wallet/wallets/temporary-session-wallet';

export const useRefund = (): {
    canRefund: boolean,
    refundChecking: boolean,
    refundingNetwork?: Network,
    refunding: boolean,
    balanceToRefund?: CoinsAmount | null,
    checkRefund: () => Promise<void>,
    refund: () => Promise<void>
} => {
    const { showErrorMessage, showSuccessMessage } = useSnackbar();
    const hubNetworkState = useHubNetworkState();
    const { networkDenoms, hubChannelNetworkMap, networks, hubNetwork } = useNetwork();
    const { hubWallet } = useWallet();
    const { vsAsset } = useAsset();
    const [ refundChecking, setRefundChecking ] = useState(false);
    const [ refunding, setRefunding ] = useState(false);
    const [ client, setClient ] = useState<StationClient>();
    const [ balanceToRefund, setBalanceToRefund ] = useState<CoinsAmount | null>();

    const [ refundingNetworkWallet, setRefundingNetworkWallet ] = useState<TemporarySessionWallet>();

    const refundingNetwork = useMemo(() => vsAsset?.network, [ vsAsset?.network ]);

    const canRefund = useMemo(
        () => Boolean(refundingNetwork && hubWallet && !isWalletSupported(refundingNetwork, hubWallet.getWalletType())),
        [ hubWallet, refundingNetwork ],
    );

    const checkRefund = useCallback(async (): Promise<void> => {
        if (!canRefund || !hubWallet || !refundingNetwork || !hubNetwork || !hubNetworkState.address || !vsAsset) {
            return;
        }
        setRefundChecking(true);
        try {
            const wallet = new TemporarySessionWallet();
            await wallet.init(hubWallet, hubNetworkState.address, hubNetwork, refundingNetwork);
            setRefundingNetworkWallet(wallet);

            const client = await StationClient.connectForNetwork(
                refundingNetwork, hubNetwork, networks, hubChannelNetworkMap, networkDenoms);
            setClient(client);

            const address = await wallet.getAddress(refundingNetwork).then(({ address }) => address);
            if (!address) {
                showErrorMessage(`Can't fetch refunding address, please try again later`);
                return;
            }
            const { balances } = await client.getBankQueryClient().AllBalances({ address, pagination: ALL_ITEMS_PAGINATION });
            const refundingBalance = balances.find((balance) => balance.denom === vsAsset.currency.baseDenom);
            const coins = !refundingBalance ? { ...vsAsset, amount: 0 } : await convertToCoinsAmount(refundingBalance, client);
            setBalanceToRefund(coins);
        } catch (error) {
            console.error(error);
            showErrorMessage('Something went wrong, please try again later');
        } finally {
            setRefundChecking(false);
        }
    }, [
        canRefund,
        setClient,
        hubChannelNetworkMap,
        hubNetwork,
        hubNetworkState.address,
        hubWallet,
        networkDenoms,
        networks,
        vsAsset,
        refundingNetwork,
        showErrorMessage,
    ]);

    const refund = useCallback(async () => {
        if (!canRefund || !client || !refundingNetworkWallet || !refundingNetwork || !balanceToRefund) {
            return;
        }
        try {
            setRefunding(true);
            const address = await refundingNetworkWallet.getAddress(refundingNetwork).then(({ address }) => address);
            if (!address) {
                showErrorMessage(`Can't fetch refunding address, please try again later`);
                return;
            }
            const signingClient = await SigningStationClient
                .connectForQueryClient(client, refundingNetworkWallet, await refundingNetworkWallet.getOfflineSigner());

            const sourceChannel = getSourceChannel(refundingNetwork, hubNetwork);
            const timeoutTimestamp = Long.fromNumber(new Date().getTime() + (refundingNetwork?.ibc?.timeout || 0)).multiply(1_000_000);
            const ibcTransferMessage: MsgTransferEncodeObject = {
                typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
                value: {
                    sourceChannel,
                    sender: address,
                    receiver: hubNetworkState.address,
                    sourcePort: 'transfer',
                    token: { denom: getFixedDenom(balanceToRefund), amount: balanceToRefund?.baseAmount?.toString() || '' },
                    timeoutHeight: { revisionNumber: Long.fromNumber(9999), revisionHeight: Long.ONE },
                    timeoutTimestamp,
                },
            };
            const gasValue = await signingClient.simulate(address, [ ibcTransferMessage ], undefined);
            const gas = Math.round(Number(gasValue) * DEFAULT_GAS_ADJUSTMENT);
            const fee = calculateFee(gas, signingClient.getGasPrice());
            if (ibcTransferMessage.value.token) {
                ibcTransferMessage.value.token.amount =
                    (BigInt(ibcTransferMessage.value.token.amount) - BigInt(fee.amount[0].amount)).toString();
            }
            const response = await signingClient.signAndBroadcast(address, [ ibcTransferMessage ], fee);
            if (response.code === 0) {
                setBalanceToRefund(undefined);
                showSuccessMessage(`Your transaction successfully submitted!`);
            } else {
                console.error(response);
                showErrorMessage('Transaction delivery failed');
            }
        } catch (error) {
            console.error(error);
            showErrorMessage('Something went wrong, please try again later');
        } finally {
            setRefunding(false);
        }
    }, [
        balanceToRefund,
        canRefund,
        client,
        hubNetwork,
        hubNetworkState.address,
        refundingNetwork,
        refundingNetworkWallet,
        showErrorMessage,
        showSuccessMessage,
    ]);

    return { canRefund, refunding, balanceToRefund, refundingNetwork, checkRefund, refundChecking, refund };
};
