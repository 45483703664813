export const TOKENOMICS_DATABASE_PATH = 'rollapp-tokenomics';

export const ROLLAPP_TAGS = [ 'Meme', 'AI', 'Gaming', 'NFT', 'DeFi', 'Betting', 'Community', 'Social', 'DePIN', 'Launchpad' ] as const;
export type RollAppTag = typeof ROLLAPP_TAGS[number];

export const ROLLAPP_LIST_COLUM_TYPES = [
    'name',
    'pool-apr',
    'staking-apr',
    'price',
    'market-cap',
    'volume',
    'liquidity',
    'total-endorsed',
    'tvl',
    'dym-raised',
    'iro-progress',
    'iro-time',
] as const;
export type RollappListColumnType = typeof ROLLAPP_LIST_COLUM_TYPES[number];

export const RollappColumnTypeNameMap: { [type in RollappListColumnType]: string } = {
    name: 'Name',
    'pool-apr': 'Pool APR',
    'staking-apr': 'Staking APR',
    price: 'Price',
    'market-cap': 'Market Cap',
    volume: 'Volume',
    liquidity: 'Liquidity',
    'total-endorsed': 'Total Endorsed',
    tvl: 'Total Value Bridged (TVB)',
    'dym-raised': 'DYM Raised',
    'iro-progress': 'Launchpad Progress',
    'iro-time': 'Launchpad Time',
};

export type LaunchType = 'Fair Launch' | 'DYM Native';

export const ROLLAPP_BASE_STATUSES = [ 'Live', 'IRO', 'Pre-Launch' ] as const;
export type RollappBaseStatus = typeof ROLLAPP_BASE_STATUSES[number];

export type ViewMode = 'List' | 'Grid';

export interface RollAppTokenomics {
    iro: number;
    communityPool: number;
    vesting: number;
    accounts: number;
    vestingGroups: { start: number, end: number, amount: number }[];
    distribution: { sequencer: number; communityPool: number, stakers: number };
}
