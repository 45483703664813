import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import './iro-time-badge.scss';
import Badge, { BadgeProps } from '../../../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../../../shared/utils/color-utils';
import { getTimeLeftText, getTimeOffset } from '../../../../../shared/utils/date-utils';
import { Asset } from '../../../../asset/asset-types';
import { useIRO } from '../../../../iro/iro-context';
import { ROLLAPP_STATUS_INFO_MAP } from '../../../../network/network-types';

interface IroTimeBadgeProps extends Omit<BadgeProps, 'label'> {
    asset: Asset;
}

const IroTimeBadge: React.FC<IroTimeBadgeProps> = ({ asset, ...badgeProps }) => {
    const { getIroPlan } = useIRO();
    const [ today, setToday ] = useState(new Date());

    const iroPlan = useMemo(
        () => asset.iroDenom ? getIroPlan(asset.networkId) : undefined,
        [ asset.iroDenom, asset.networkId, getIroPlan ],
    );

    const startTime = useMemo(() => iroPlan?.startTime ? new Date(iroPlan.startTime) : today, [ iroPlan?.startTime, today ]);

    const endTime = useMemo(() => iroPlan?.preLaunchTime ? new Date(iroPlan.preLaunchTime) : today, [ iroPlan?.preLaunchTime, today ]);

    const color = useMemo(() => getCssVariableValue(ROLLAPP_STATUS_INFO_MAP['IRO'].variableColor).split(',').map(Number), []);

    const badgeLabel = useMemo(() => {
        if (endTime <= today) {
            return '';
        }
        const time = getTimeOffset(startTime > today ? startTime : endTime);
        return `${time.days.toString().padStart(2, '0')}:${time.hours.toString().padStart(2, '0')}:` +
            `${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`;
    }, [ endTime, startTime, today ]);

    const info = useMemo(() => {
        const timeLeft = getTimeLeftText(getTimeOffset(startTime > today ? startTime : endTime), undefined, true);
        if (startTime > today) {
            return `Launchpad start in: ${timeLeft}`;
        }
        return `Launchpad available for at least: ${timeLeft}`;
    }, [ endTime, startTime, today ]);

    useEffect(() => {
        if (endTime > today) {
            const timeout = setTimeout(() => setToday(new Date()), 1000);
            return () => clearTimeout(timeout);
        }
    }, [ endTime, today ]);

    if (!iroPlan || !badgeLabel) {
        return <></>;
    }
    return (
        <Badge
            className='iro-time-badge'
            label={<>
                {startTime < today ? 'Ends' : 'Starts'}:&nbsp;
                <span className={classNames('badge-label', { clock: startTime < today && endTime > today })}>{badgeLabel}</span>
            </>}
            rounded
            color={color}
            info={info}
            {...badgeProps} />
    );
};

export default IroTimeBadge;

