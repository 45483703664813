import { readStream } from '../../../shared/utils/file-utils';
import { getFixedDenom } from '../../currency/currency-service';
import { Asset } from '../asset-types';
import { Holder } from './holder-types';

export const getHolders = async (asset: Asset, allocation: number, signal?: AbortSignal): Promise<Holder[]> => {
    const response = await fetch(
        `${process.env.REACT_APP_FYI_API}?module=dymension&action=top-holders-by-denom&denom=${getFixedDenom(asset)}`,
        { signal },
    ).catch((error) => {
        if (!signal?.aborted) {
            console.error('Failed to fetch holders', { error });
        }
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    const result = JSON.parse(responseText || '{}').result as
        { top: { a: string, h: number, l: number }[], holders: number };
    const totalAmount = allocation || result.top.reduce((current, { a, h, l }) => current + h + l / (10 ** 18), 0);
    return result.top.map<Holder>(({ a, h, l }) => {
        const amount = h + l / (10 ** 18);
        return { hexAddress: a, amount, weight: amount / totalAmount };
    });
};


export const getHoldersCount = async (asset: Asset, signal?: AbortSignal): Promise<number> => {
    const response = await fetch(
        `${process.env.REACT_APP_FYI_API}?module=dymension&action=count-holders-by-denom&denom=${getFixedDenom(asset)}`,
        { signal },
    ).catch((error) => {
        if (!signal?.aborted) {
            console.error('Failed to fetch holders count', { error });
        }
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    const result = JSON.parse(responseText || '{}').result as { count: number };
    return result.count;
};

