import { filterNonEmptyValues } from '../../shared/utils/object-utils';
import { createEmptyCoinsAmount, isCoinsEquals } from '../currency/currency-service';
import { IbcTransferDetails } from '../ibc-transfer/ibc-status/ibc-status-types';
import { Network } from '../network/network-types';
import { CoinsAmount } from '../currency/currency-types';

export interface AccountNetworkState {
    network?: Network;
    address?: string;
    hexAddress?: string;
    bankBalances?: CoinsAmount[];
    erc20Balances?: CoinsAmount[];
    balances?: CoinsAmount[];
    pendingTransfers?: IbcTransferDetails[];
    claimableTransfers?: IbcTransferDetails[];
    addressLoading?: boolean;
    bankBalancesLoading?: boolean;
    erc20BalancesLoading?: boolean;
    balancesLoading?: boolean;
    pendingTransfersLoading?: boolean;
    error?: any;
    erc20Error?: any;
}

export type AccountNetworkAction =
    { type: 'set-network', payload?: Network | undefined } |
    { type: 'set-address-loading', payload?: boolean } |
    { type: 'set-address', payload: { address?: string, hexAddress?: string } | undefined } |
    { type: 'set-bank-balances-loading', payload?: boolean } |
    { type: 'set-erc20-balances-loading', payload?: boolean } |
    { type: 'set-balances-loading', payload?: boolean } |
    { type: 'set-pending-transfers-loading', payload?: boolean } |
    { type: 'set-bank-balances', payload: CoinsAmount[] | undefined } |
    { type: 'set-erc20-balances', payload: CoinsAmount[] | undefined } |
    { type: 'set-balances', payload: CoinsAmount[] | undefined } |
    { type: 'set-pending-transfers', payload: IbcTransferDetails[] | undefined } |
    { type: 'set-erc20-error', payload: any } |
    { type: 'set-error', payload: any } |
    { type: 'clear-data' }

export const ACCOUNT_NETWORK_DEFAULTS: AccountNetworkState = {
    address: undefined,
    hexAddress: undefined,
    bankBalances: undefined,
    erc20Balances: undefined,
    balances: undefined,
    pendingTransfers: undefined,
    claimableTransfers: undefined,
    bankBalancesLoading: false,
    erc20BalancesLoading: false,
    balancesLoading: false,
    addressLoading: false,
    pendingTransfersLoading: false,
};

export const accountNetworkReducer = (state: AccountNetworkState, action: AccountNetworkAction): AccountNetworkState => {
    switch (action.type) {
        case 'set-network':
            if (action.payload?.chainId === state.network?.chainId) {
                return state;
            }
            return { ...state, network: action.payload, ...(state.network ? ACCOUNT_NETWORK_DEFAULTS : undefined) };
        case 'set-address-loading':
            return { ...state, addressLoading: action.payload ?? true };
        case 'set-address':
            return { ...state, ...action.payload, addressLoading: false };
        case 'set-bank-balances-loading':
            const bankBalancesLoading = action.payload ?? true;
            return { ...state, bankBalancesLoading, balancesLoading: bankBalancesLoading || state.erc20BalancesLoading };
        case 'set-erc20-balances-loading':
            const erc20BalancesLoading = action.payload ?? true;
            return { ...state, erc20BalancesLoading, balancesLoading: erc20BalancesLoading || state.bankBalancesLoading };
        case 'set-balances-loading':
            return { ...state, balancesLoading: action.payload ?? true };
        case 'set-pending-transfers-loading':
            return { ...state, pendingTransfersLoading: action.payload ?? true };
        case 'set-bank-balances':
            return getCombinedBalancesState({ ...state, bankBalances: action.payload, bankBalancesLoading: false });
        case 'set-erc20-balances':
            return getCombinedBalancesState({ ...state, erc20Balances: action.payload, erc20BalancesLoading: false });
        case 'set-balances':
            return {
                ...state,
                balances: action.payload,
                balancesLoading: false,
                bankBalances: undefined,
                erc20Balances: undefined,
                bankBalancesLoading: false,
                erc20BalancesLoading: false,
            };
        case 'set-pending-transfers':
            return {
                ...state,
                pendingTransfers: action.payload,
                claimableTransfers: action.payload?.filter((transfer) => transfer.claimable),
                pendingTransfersLoading: false,
            };
        case 'set-error':
            return { ...state, error: action.payload };
        case 'set-erc20-error':
            return { ...state, erc20Error: action.payload, error: action.payload };
        case 'clear-data':
            return { ...state, ...ACCOUNT_NETWORK_DEFAULTS };
        default:
            return state;
    }
};

export const getCombinedBalancesState = (state: AccountNetworkState): AccountNetworkState => {
    const { network, bankBalances, erc20Balances, bankBalancesLoading, erc20BalancesLoading } = state;
    const balances = [ ...(bankBalances || []), ...(erc20Balances || []) ];
    if (network) {
        const otherNetworkBalances: CoinsAmount[] = network.currencies
            .map((currency) => createEmptyCoinsAmount(network, currency))
            .filter((emptyCoins) => balances.every((coins) => !isCoinsEquals(coins, emptyCoins)));
        balances.push(...otherNetworkBalances);
    }
    return {
        ...state,
        balances: filterNonEmptyValues(balances).sort((balance1, balance2) =>
            ((balance2?.amount ? 1 : 0) - (balance1?.amount ? 1 : 0)) || ((balance1?.ibc ? 1 : 0) - (balance2?.ibc ? 1 : 0))),
        balancesLoading: bankBalancesLoading || erc20BalancesLoading,
    };
};

