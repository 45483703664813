import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretUpIcon } from '../../../../assets/icons/caret-up.svg';
import { formatNumber, roundNumber } from '../../../utils/number-utils';
import Icon from '../../icon/icon';
import { AnalyticsChangePeriod } from './statistics-change-types';
import { getPeriodLabel } from './statistics-change-service';
import './statistics-change.scss';

interface StatisticsChangeProps {
    currentValue: number;
    previousValue: number;
    period: AnalyticsChangePeriod;
    className?: string;
    align?: 'left' | 'right';
    showPeriod?: boolean;
    size?: 'small' | 'medium';
    children?: ReactNode;
    containerClassName?: string;
    onClick?: () => void;
    showChangeAffect?: boolean;
}

const StatisticsChange: React.FC<StatisticsChangeProps> = ({
    currentValue,
    previousValue,
    period,
    align = 'left',
    showPeriod = true,
    onClick,
    size = 'medium',
    containerClassName,
    className,
    children,
    showChangeAffect,
}) => {
    const [ value, setValue ] = useState(currentValue);
    const [ valueColor, setValueColor ] = useState<string>();

    const changePercentage = useMemo(() => {
        const diff = currentValue - previousValue;
        const percentage = previousValue === 0 ? 0 : (diff / previousValue) * 100;
        if (percentage && percentage !== Infinity) {
            return roundNumber(percentage, 2);
        }
        return percentage;
    }, [ currentValue, previousValue ]);

    useEffect(() => {
        setValue(currentValue);
        if (showChangeAffect && value !== currentValue) {
            setValueColor(currentValue > value ? 'green' : 'red');
            setTimeout(() => setValueColor(undefined), 1000);
        }
    }, [ currentValue, showChangeAffect, value ]);

    if (currentValue === Infinity || previousValue === Infinity || !previousValue) {
        return <>{children}</>;
    }
    return (
        <div className={classNames('statistics-change-container', containerClassName, align, valueColor)} onClick={onClick}>
            {children}
            {Boolean(children) ? <span className='left-space' /> : undefined}
            <div
                className={
                    classNames('statistics-change', className, size, { positive: changePercentage >= 0 })
                }
            >
                <Icon className='caret-icon' iconColorMode='original'><CaretUpIcon /></Icon>
                {formatNumber(Math.abs(changePercentage), { minimumFractionDigits: 2 })}%{showPeriod ? ` (${getPeriodLabel(period)})` : ''}
            </div>
        </div>
    );
};

export default StatisticsChange;
