import React, { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Menu, { MenuAction, MenuProps, MenuRefProps } from '../../menu/menu';
import './options-modal.scss';

export interface OptionProps {
    children: ReactNode;
    disabled?: boolean;
    value: string | number;
    iconColorMode?: 'fill' | 'stroke' | 'original';
    className?: string;
    group?: string;
    trackEvent?: string;
}

export interface OptionsModalProps extends Omit<MenuProps, 'children'> {
    onOptionSelect?: (option: OptionProps) => void;
    selectedValue?: string | number | string[];
    multiselect?: boolean;
    children: ReactNode;
}

export const Option: React.FC<OptionProps> = ({ children }) => <>{children}</>;

const OptionsModal: React.ForwardRefRenderFunction<MenuRefProps, OptionsModalProps> = ({
    children,
    selectedValue,
    onOptionSelect,
    multiselect,
    ...menuProps
}, menuRef) => {
    const options = validateAndGetChildrenAsArray(children, Option);

    const onSelectOption = (event: React.MouseEvent, option: OptionProps): void => {
        event.stopPropagation();
        onOptionSelect?.(option);
    };

    return (
        <Menu {...menuProps} className={classNames('options-modal form-control', menuProps.className)} ref={menuRef}>
            {options.map((option) => {
                const active = !multiselect ?
                    option.props.value === selectedValue : (selectedValue as string[])?.includes(option.props.value.toString());
                return (
                    <MenuAction
                        disabled={!multiselect && option.props.disabled}
                        className={classNames('option', option.props.className, { active })}
                        iconColorMode={option.props.iconColorMode}
                        key={option.props.value}
                        onClick={(event) => onSelectOption(event, option.props)}
                        group={option.props.group}
                        notCloseAfterClick={multiselect}
                        trackEvent={option.props.trackEvent}
                    >
                        {multiselect &&
                            <input
                                type='checkbox'
                                disabled={option.props.disabled}
                                className='multiselect-checkbox'
                                onClick={(event) => onSelectOption(event, option.props)}
                                checked={active}
                                readOnly
                            />}
                        {option}
                    </MenuAction>
                );
            })}
        </Menu>
    );
};

export default forwardRef(OptionsModal);

