import { orderBy, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import useWindowSize from '../../../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../../../shared/utils/color-utils';
import { getShortDateString } from '../../../../../shared/utils/date-utils';
import { convertDecimalToInt, formatNumber } from '../../../../../shared/utils/number-utils';
import { ResponsiveContainer, XAxis, YAxis, AreaChart, Area, ReferenceLine } from 'recharts';
import './vesting-schedule-chart.scss';
import { filterNonEmptyValues } from '../../../../../shared/utils/object-utils';
import { Network } from '../../../network-types';

export interface VestingDataValue {
    name: string;
    value: number;
    rgbColor: string;
    start?: number;
    end?: number;
}

interface VestingScheduleChartProp {
    chartData: VestingDataValue[];
    network: Network;
}

const MAX_CHART_HEIGHT = 250;
const MIN_CHART_HEIGHT = 200;

export const VestingScheduleChart: React.FC<VestingScheduleChartProp> = ({ chartData, network }) => {
    const [ chartWidth, setChartWidth ] = useState(0);
    const { isMobile } = useWindowSize();

    const chartHeight = useMemo(() => Math.max(MIN_CHART_HEIGHT, Math.min(MAX_CHART_HEIGHT, chartWidth * 0.4)), [ chartWidth ]);

    const fixedChartData = useMemo(() => {
        const reversedChartData = [ ...chartData ].reverse();
        return reversedChartData.map((dataItem, dataItemIndex) => ({
            ...dataItem,
            name: `${dataItem.name}-${dataItemIndex}`,
            showBorder: dataItemIndex === reversedChartData.length - 1 || reversedChartData[dataItemIndex + 1].name !== dataItem.name,
        }));
    }, [ chartData ]);

    const dates = orderBy(uniq(filterNonEmptyValues(chartData.reduce((current, dataItem) => {
        if (dataItem.start) {
            current.push(dataItem.start);
        }
        if (dataItem.end) {
            current.push(dataItem.end);
        }
        return current;
    }, [ Date.now(), network.creationDate ]))));

    const points = dates.map((date) => ({
        date,
        ...fixedChartData.reduce<{ [name: string]: number }>((current, dataItem) => {
            const value = !dataItem.start || !dataItem.end ? dataItem.value :
                date <= dataItem.start ? 0 : date >= dataItem.end ? dataItem.value :
                    dataItem.value * (date - dataItem.start) / (dataItem.end - dataItem.start);
            return { ...current, [dataItem.name]: convertDecimalToInt(value) };
        }, {}),
    }));

    return (
        <ResponsiveContainer height={chartHeight} onResize={(width) => setChartWidth(width)}>
            <AreaChart data={points} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <XAxis
                    type='number'
                    domain={[ dates[0], dates[dates.length - 1] ]}
                    dataKey='date'
                    tickFormatter={(value) => getShortDateString(value)}
                />

                {fixedChartData.map((dataItem) => (
                    <Area
                        key={dataItem.name}
                        type='linear'
                        dataKey={dataItem.name}
                        stackId='1'
                        strokeWidth={dataItem.showBorder ? 1 : 0}
                        stroke={getCssVariableValue('--black')}
                        fill={`rgb(${dataItem.rgbColor})`}
                    />
                ))}

                <ReferenceLine
                    x={Date.now()}
                    stroke={getCssVariableValue('--cream-dark')}
                    strokeDasharray='3 3'
                />

                <YAxis
                    mirror={isMobile}
                    tick={!isMobile ? undefined : { fill: getCssVariableValue('--cream-dark') }}
                    tickFormatter={(value) => !value ? '' : formatNumber(value, { notation: 'compact' })}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default VestingScheduleChart;
