import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/button/button';
import ControlsComposer from '../../shared/components/form-controls/controls-composer/controls-composer';
import { Option } from '../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../shared/components/form-controls/select/select';
import Swiper from '../../shared/components/swiper/swiper';
import useScrollPosition from '../../shared/hooks/use-scroll-position';
import EarningList from './earning-list/earning-list';
import { EARNING_TYPE_DESCRIPTION_MAP, EARNING_TYPES, EarningType } from './earnings-types';
import { useEarnings } from './use-earnings';
import './earnings.scss';

const Earnings: React.FC = () => {
    const { loading, records, typeFilter, setTypeFilter, loadMore } = useEarnings();
    const { canLoadMore } = useScrollPosition();
    const navigate = useNavigate();
    const [ preventLoadMore, setPreventLoadMore ] = useState(false);

    useEffect(() => {
        if (!preventLoadMore && canLoadMore) {
            setPreventLoadMore(true);
            loadMore();
            setTimeout(() => setPreventLoadMore(false), 50);
        }
    }, [ loadMore, canLoadMore, preventLoadMore ]);

    const renderEarningTypeCard = (type: EarningType): ReactElement => {
        const colorVariable = EARNING_TYPE_DESCRIPTION_MAP[type].colorVariable;
        return (
            <div key={type} className='section earning-type-card'>
                <h5 className='card-label'>
                    <span className='card-type-indicator' style={{ backgroundColor: `rgb(var(${colorVariable}))` }} />{type}
                </h5>
                <p className='card-description'>{EARNING_TYPE_DESCRIPTION_MAP[type].description}</p>
                <span className='space' />
                <Button className='card-action' onClick={() => navigate(EARNING_TYPE_DESCRIPTION_MAP[type].url)}>Start ⟶</Button>
            </div>
        );
    };

    return (
        <div className='earnings-page page'>
            <div className='header-section'>
                <Swiper showOneInMobile>
                    {EARNING_TYPES.filter((type) => type !== 'Royalty').map((type) => renderEarningTypeCard(type))}
                </Swiper>
            </div>

            <h5 className='earning-list-header'>
                My Earnings

                <span className='space' />

                <ControlsComposer className='earning-list-filters'>
                    <Select
                        value={typeFilter || ''}
                        className='earning-type-select'
                        optionsOverlayAlign='right'
                        onSelect={(value) => setTypeFilter(value ? value as EarningType : undefined)}
                        controlSize='medium'
                    >
                        {EARNING_TYPES.map((type) => (
                            <Option key={type} value={type}>{type}</Option>
                        ))}
                        <Option key='all' value=''>All Earnings</Option>
                    </Select>
                </ControlsComposer>
            </h5>

            <EarningList records={records} loading={loading} />
        </div>
    );
};

export default Earnings;
