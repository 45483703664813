import { EncodeObject } from '@cosmjs/proto-signing';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { OverlayAlign } from '../../shared/components/overlay/overlay';
import { useSnackbar } from '../../shared/components/snackbar/snackbar-context';
import ConnectQuickAuthWalletDialog from '../account/account-menu/connect-quick-auth-wallet-dialog/connect-quick-auth-wallet-dialog';
import { useAccountConfiguration } from '../account/account-menu/use-account-configuration';
import { useAccountNetwork } from '../account/use-account-network';
import { useClient } from '../client/client-context';
import { convertToCoin } from '../currency/currency-service';
import { useNetwork } from '../network/network-context';
import { useQuickAuth } from '../quick-auth/quick-auth-context';
import { ContractMessage } from '../tx/tx-types';
import { useTx } from '../tx/use-tx';
import { useWallet } from '../wallet/wallet-context';
import AccountMenu from '../account/account-menu/account-menu';
import { getTextFromReactNode } from './connect-service';
import { ConnectOutMessage, ConnectInMessage } from './connect-types';

const NETWORK_IDS_KEY = 'networkIds';

function Connect(): JSX.Element {
    const [ params ] = useSearchParams();
    const { hubNetwork, getNetwork } = useNetwork();
    const { messages } = useSnackbar();
    const { signingClientStateMap, connectSigningClient } = useClient();
    const { connectWallet, disconnectWallet, walletError, networkWalletTypeMap, networkWalletConnectingMap } = useWallet();
    const [ triggerBoundingRect, setTriggerBoundingRect ] = useState<DOMRect | null>(null);
    const [ menuAlign, setMenuAlign ] = useState<OverlayAlign>();
    const networkIds = useMemo(() => (params.get(NETWORK_IDS_KEY)?.split(',') || []).filter(Boolean), [ params ]);
    const firstNetwork = useMemo(() => getNetwork(networkIds[0]), [ getNetwork, networkIds ]);
    const [ networkState, selectNetwork ] = useAccountNetwork(firstNetwork || hubNetwork);
    const [ messagesToExecute, setMessagesToExecute ] = useState<EncodeObject[]>();
    const { setNetwork } = useQuickAuth();
    const { account } = useParams();
    const navigate = useNavigate();
    const [ evmContract, setEvmContract ] = useState<ContractMessage>();
    const { setVisibleNetworksForSession } = useAccountConfiguration();

    const urlEncryptedAccount = useMemo(
        () => !account ? undefined : account.replace(`${window.location.origin}`, '').replace(/\/(\/connect\/)?account\//, ''),
        [ account ],
    );

    const { txState, txStateDispatch, broadcast } = useTx({
        networkState,
        txMessagesCreator: (fee, params) => params || [],
        evmContractCreator: !evmContract ? undefined : () => evmContract,
        useEvm: Boolean(evmContract),
    });

    const networkWalletType = networkState.network ? networkWalletTypeMap[networkState.network.chainId] : undefined;
    const connectingWallet = networkState.network ? networkWalletConnectingMap[networkState.network.chainId] : undefined;

    const sendMessage = useCallback((message: ConnectOutMessage) => window.parent.postMessage(message, '*'), []);

    useEffect(() => {
        if (hubNetwork && firstNetwork) {
            setTimeout(() => {
                setNetwork(firstNetwork);
                connectSigningClient(firstNetwork);
            });
        }
    }, [ connectSigningClient, firstNetwork, hubNetwork, setNetwork ]);

    useEffect(() => setVisibleNetworksForSession(networkIds), [ networkIds, setVisibleNetworksForSession ]);

    useEffect(() => {
        if (networkState.network && messagesToExecute?.length && signingClientStateMap[networkState.network.chainId]) {
            broadcast(undefined, messagesToExecute, true).then(() => setMessagesToExecute(undefined));
        }
    }, [ broadcast, messagesToExecute, networkState.network, signingClientStateMap ]);

    useEffect(() => {
        if (networkState.network && evmContract && signingClientStateMap[networkState.network.chainId]) {
            broadcast(undefined, undefined, true).then(() => setEvmContract(undefined));
        }
    }, [ broadcast, evmContract, networkState.network, signingClientStateMap ]);

    const onMessage = useCallback((event: MessageEvent<ConnectInMessage>) => {
        if (event.data.type === 'setTriggerBoundingRect') {
            setTriggerBoundingRect(event.data.rect);
        }
        if (event.data.type === 'selectNetwork') {
            selectNetwork(getNetwork(event.data.networkId));
        }
        if (event.data.type === 'setMenuAlign') {
            setMenuAlign(event.data.align);
        }
        if (event.data.type === 'executeTx') {
            if (txState.broadcasting) {
                sendMessage({ type: 'tx-response', error: new Error('A transaction is already in progress.') });
                return;
            }
            setMessagesToExecute(event.data.messages);
        }
        if (event.data.type === 'executeEthTx') {
            if (txState.broadcasting) {
                sendMessage({ type: 'tx-response', error: new Error('A transaction is already in progress.') });
                return;
            }
            setEvmContract(event.data.contract);
        }
        if (event.data.type === 'connect') {
            const { networkId, walletType } = event.data;
            if (!walletType) {
                disconnectWallet(networkId);
            } else if (walletType !== 'Keplr' && walletType !== 'MetaMask' && walletType !== 'Leap') {
                throw new Error('Unsupported wallet');
            } else {
                connectWallet(networkId, walletType);
            }
        }
    }, [ connectWallet, disconnectWallet, getNetwork, selectNetwork, sendMessage, txState.broadcasting ]);

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }, [ onMessage ]);

    useEffect(() => {
        if (networkState.network && !connectingWallet && !networkState.addressLoading) {
            sendMessage({ type: 'ready' });
        }
    }, [ connectingWallet, networkState.addressLoading, networkState.network, sendMessage ]);

    useEffect(() => sendMessage({
        type: 'notification',
        messages: messages.map((message) => ({
            ...message,
            content: getTextFromReactNode(message.content).trim(),
            prefix: undefined,
            action: undefined,
        })),
    }), [ messages, sendMessage ]);

    useEffect(() => {
        if (networkState.address || networkState.hexAddress) {
            sendMessage({ type: 'connect', address: networkState.address, hexAddress: networkState.hexAddress });
        }
    }, [ networkState.address, networkState.hexAddress, sendMessage ]);

    useEffect(() => {
        if (networkState.balances && !networkState.balancesLoading) {
            sendMessage({ type: 'balances', balances: networkState.balances.map((balance) => convertToCoin(balance)) });
        }
    }, [ networkState.balances, networkState.balancesLoading, sendMessage ]);

    useEffect(() => {
        if (networkState.network && !networkWalletType) {
            sendMessage({ type: 'disconnect' });
        }
    }, [ networkState.network, networkWalletType, sendMessage ]);

    useEffect(() => {
        if (walletError) {
            sendMessage({ type: 'wallet-error', error: JSON.stringify(walletError) });
        }
    }, [ sendMessage, walletError ]);

    useEffect(() => {
        if (!txState.broadcasting && (txState.response || txState.error)) {
            sendMessage({ type: 'tx-response', response: JSON.stringify(txState.response), error: JSON.stringify(txState.error) });
            txStateDispatch({ type: 'set-response', payload: undefined });
        }
    }, [ sendMessage, txState.broadcasting, txState.error, txState.response, txStateDispatch ]);

    const triggerElement = triggerBoundingRect ? <span style={{ position: 'absolute', ...triggerBoundingRect.toJSON() }} /> : null;

    return (
        <div className='connect-container'>
            {triggerElement && (
                <AccountMenu
                    visibleAlways
                    menuAlign={menuAlign}
                    trigger={triggerElement}
                    optionalNetworks={[ ...networkIds, hubNetwork?.chainId ].filter(Boolean) as string[]}
                    forceHubConnect={false}
                    footer={undefined}
                    onClickOutside={() => sendMessage({ type: 'menu-visible', value: false })}
                />
            )}
            {networkState.network && urlEncryptedAccount &&
                <ConnectQuickAuthWalletDialog
                    urlEncryptedAccount={urlEncryptedAccount}
                    onRequestClose={() => navigate(`/connect?networkIds=${networkIds.join(',')}`)}
                />}
        </div>
    );
}

export default Connect;

