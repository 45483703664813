import React from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import useVersion from '../../shared/hooks/use-version';
import AssetDashboardPage from '../asset/asset-dashboard/asset-dashboard-page/asset-dashboard-page';
import AssetsPage from '../asset/assets-page/assets-page';
import PoolDashboardPage from '../amm/pool-dashboard/pool-dashboard-page/pool-dashboard-page';
import PoolsPage from '../amm/pools/pools-page';
import TokensSwapPage from '../amm/tokens-swap/tokens-swap-page/tokens-swap-page';
import Connect from '../connect/connect';
import Earnings from '../earnings/earnings';
import HubCreateProposalPage from '../hub/hub-create-proposal';
import HubProposalPage from '../hub/hub-proposal-page';
import EibcClientPage from '../ibc-transfer/eibc-client-page/eibc-client-page';
import IbcStatusListPage from '../ibc-transfer/ibc-status/ibc-status-list-page';
import IbcTransferStatusPage from '../ibc-transfer/ibc-status/ibc-transfer-status/ibc-transfer-status-page/ibc-transfer-status-page';
import CreateRollappPage from '../rollapp/manage-rollapps-page/create-rollapp-page/create-rollapp-page';
import ManageRollappsPage from '../rollapp/manage-rollapps-page/manage-rollapps-page';
import RollappCreateProposalPage from '../rollapp/rollapp-create-proposal-page/rollapp-create-proposal-page';
import RollappProposalPage from '../rollapp/rollapp-proposal-page/rollapp-proposal-page';
import SponsorshipPage from '../sponsorship/sponsorship-page/sponsorship-page';
import AppLayout from './layout/app-layout';
import IbcTransferPage from '../ibc-transfer/ibc-transfer-page/ibc-transfer-page';
import RollappsPage from '../rollapp/rollapps-page/rollapps-page';
import HubDashboardPage from '../hub/hub-dashboard-page';
import RollappDashboardPage from '../rollapp/rollapp-dashboard-page/rollapp-dashboard-page';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout />,
        children: [
            { path: 'account/:account', element: <RollappsPage /> },
            { path: 'dymension/:pageKey', element: <HubDashboardPage /> },
            { path: 'dymension/proposal/:proposalId', element: <HubProposalPage /> },
            { path: 'dymension/create-proposal', element: <HubCreateProposalPage /> },
            { path: 'rollapps', element: <RollappsPage /> },
            { path: 'rollapps/manage/create/:id', element: <CreateRollappPage /> },
            { path: 'rollapps/manage/create', element: <CreateRollappPage /> },
            { path: 'endorsement', element: <SponsorshipPage /> },
            { path: 'rollapps/manage', element: <ManageRollappsPage /> },
            { path: 'rollapps/:id', element: <RollappDashboardPage /> },
            { path: 'rollapps/:id/:pageKey', element: <RollappDashboardPage /> },
            { path: 'rollapps/:id/proposal/:proposalId', element: <RollappProposalPage /> },
            { path: 'rollapps/:id/create-proposal', element: <RollappCreateProposalPage /> },
            { path: 'amm/swap', element: <TokensSwapPage /> },
            { path: 'amm/pools', element: <PoolsPage /> },
            { path: 'amm/pool/:id', element: <PoolDashboardPage /> },
            { path: 'amm/assets', element: <AssetsPage /> },
            { path: 'amm/asset/:key', element: <AssetDashboardPage /> },
            { path: 'earnings', element: <Earnings /> },
            { path: 'ibc/transfer', element: <IbcTransferPage /> },
            { path: 'ibc/eibc-client', element: <EibcClientPage /> },
            { path: 'ibc/status', element: <IbcStatusListPage /> },
            { path: 'ibc/status/:id', element: <IbcTransferStatusPage /> },
            { path: '', element: <Navigate to='/rollapps' /> },
            { path: '*', element: <Navigate to='/rollapps' /> },
        ],
    },
    { path: 'connect', element: <Connect /> },
    { path: 'connect/account/:account', element: <Connect /> },
]);

function App(): JSX.Element {
    useVersion();
    return <RouterProvider router={router} />;
}

export default App;
