import React, { forwardRef, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useWindowSize from '../../../hooks/use-window-size';
import { MenuRefProps } from '../../menu/menu';
import { OverlayAlign } from '../../overlay/overlay';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Button, { ButtonProps } from '../../button/button';
import Icon from '../../icon/icon';
import Spinner from '../../spinner/spinner';
import Input from '../input/input';
import OptionsModal, { Option, OptionProps } from '../options-modal/options-modal';
import './select.scss';

export interface SelectProps {
    controlSize?: 'medium' | 'large';
    className?: string;
    optionsModalClassName?: string;
    visibleAlways?: boolean;
    disabled?: boolean;
    multiselect?: boolean;
    maxValues?: number;
    footer?: ReactNode;
    placeholder?: ReactElement | string;
    loading?: boolean;
    optionsMenuOpenDisabled?: boolean;
    iconColorMode?: 'fill' | 'stroke' | 'original';
    header?: ReactNode;
    buttonType?: ButtonProps['buttonType'];
    moreOptionsLoading?: boolean;
    value?: string | number | string[];
    onSelect?: (value: string | number | string[]) => void;
    onOptionsModalOpen?: (value: boolean) => void;
    searchFilterPredicate?: (searchText: string, value: string | number) => boolean;
    searchPlaceholder?: string;
    emptySearchResultsLabel?: string;
    error?: ReactNode;
    optionsOverlayAlign?: OverlayAlign;
    fitToTriggerWidth?: boolean;
    children: ReactNode;
    renderCustomTrigger?: (value?: string | number | string[]) => ReactElement;
    renderTriggerSelectedOption?: (value?: string | number | string[]) => ReactNode;
    showOptionsOnly?: boolean;
}

export const MAX_MULTISELECT_TRIGGER_VALUES = 3;

const Select: React.ForwardRefRenderFunction<MenuRefProps, SelectProps> = ({
    children,
    disabled,
    value,
    onSelect,
    multiselect,
    searchFilterPredicate,
    searchPlaceholder,
    emptySearchResultsLabel,
    footer,
    visibleAlways,
    optionsModalClassName,
    error,
    loading,
    moreOptionsLoading,
    optionsOverlayAlign,
    optionsMenuOpenDisabled,
    className,
    renderCustomTrigger,
    renderTriggerSelectedOption,
    header,
    maxValues,
    fitToTriggerWidth,
    onOptionsModalOpen,
    buttonType,
    showOptionsOnly,
    iconColorMode = 'fill',
    placeholder = 'Choose an option',
    controlSize = 'medium',
}, selectRef) => {
    const { isMobile } = useWindowSize();
    const [ open, setOpen ] = useState(visibleAlways ?? false);
    const [ searchText, setSearchText ] = useState('');
    const [ currentValue, setCurrentValue ] = useState(value);
    const options = validateAndGetChildrenAsArray(children, Option);

    controlSize = isMobile ? 'medium' : controlSize;

    const filteredOptions = useMemo(
        () => !searchFilterPredicate || !searchText ? options :
            options.filter((option) => searchFilterPredicate(searchText, option.props.value)),
        [ options, searchFilterPredicate, searchText ],
    );

    useEffect(() => setCurrentValue(value), [ value ]);

    useEffect(() => {
        onOptionsModalOpen?.(open);
        if (!open) {
            setSearchText('');
        }
    }, [ onOptionsModalOpen, open, value ]);

    const onSelectOption = useCallback((option: OptionProps): void => {
        if (option.disabled) {
            return;
        }
        if (!multiselect) {
            setCurrentValue(option.value);
            setOpen(false);
            onSelect?.(option.value);
            return;
        }
        setCurrentValue((currentValue) => {
            const values = currentValue ? [ ...currentValue as string[] ] : [];
            const valueIndex = values.indexOf(option.value.toString());
            if (valueIndex >= 0) {
                values.splice(valueIndex, 1);
            } else if (!maxValues || values.length < maxValues) {
                values.push(option.value.toString());
            }
            onSelect?.(values);
            return values;
        });
    }, [ maxValues, multiselect, onSelect ]);

    const renderTrigger = (): ReactElement => {
        if (showOptionsOnly) {
            return <></>;
        }
        if (renderCustomTrigger) {
            return renderCustomTrigger(currentValue);
        }
        const selectedOptions = options.filter((option) => !multiselect ?
            option.props.value === currentValue : (currentValue as string[])?.includes(option.props.value.toString()));
        const triggerClassName = classNames(
            'select-trigger',
            buttonType !== 'icon' ? 'form-control interactive' : undefined,
            controlSize,
            className,
            { open, selected: Boolean(selectedOptions.length), 'horizontally-baseline': multiselect },
        );
        return (
            <Button
                disabled={disabled || loading}
                loading={loading}
                iconColorMode={iconColorMode}
                className={triggerClassName}
                buttonType={buttonType || 'secondary'}
            >
                {!selectedOptions.length ? placeholder : (renderTriggerSelectedOption?.(currentValue) || <>
                    {selectedOptions.slice(0, MAX_MULTISELECT_TRIGGER_VALUES)
                        .reduce<ReactNode[]>((current, option, optionIndex) => [ ...current, optionIndex > 0 ? ', ' : '', option ], [])}
                    {selectedOptions.length > MAX_MULTISELECT_TRIGGER_VALUES &&
                        <small className='more-label'>&nbsp;(+{selectedOptions.length - MAX_MULTISELECT_TRIGGER_VALUES} more)</small>}
                </>)}
                {buttonType !== 'icon' && <>
                    <span className='space' />
                    {!optionsMenuOpenDisabled && <Icon className='trigger-arrow'><ArrowDownIcon /></Icon>}
                </>}
            </Button>
        );
    };

    const renderHeader = (): ReactElement | null => {
        return !searchFilterPredicate ? null : <>
            <Input
                type='search'
                className='search-input'
                controlSize='large'
                value={searchText}
                placeholder={searchPlaceholder}
                onValueChange={setSearchText}
            />
            {header}
            {emptySearchResultsLabel && filteredOptions.length === 0 && (
                <span className='empty-search-results-label'>
                    {emptySearchResultsLabel}
                </span>
            )}
        </>;
    };

    const renderFooter = (): ReactElement | null => {
        return !footer && !moreOptionsLoading ? null : <>
            {footer}
            {moreOptionsLoading && <footer className='options-loader'><Spinner size='small' /></footer>}
        </>;
    };

    return <>
        <OptionsModal
            ref={selectRef}
            trigger={renderTrigger()}
            disabled={optionsMenuOpenDisabled}
            className={classNames('options-modal', optionsModalClassName, controlSize)}
            header={renderHeader()}
            footer={renderFooter()}
            onOpenChange={setOpen}
            onOptionSelect={onSelectOption}
            multiselect={multiselect}
            selectedValue={currentValue}
            overlayAlign={optionsOverlayAlign}
            fitToTriggerWidth={fitToTriggerWidth}
            visibleAlways={visibleAlways}
            relativePosition={showOptionsOnly}
        >
            {filteredOptions}
        </OptionsModal>
        <span className={classNames('error-label', { visible: error })}>{error}</span>
    </>;

};

export default forwardRef(Select);

