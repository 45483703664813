import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { IChartingLibraryWidget } from '../../../../../trading-view';
import { isCoinsEquals } from '../../../../currency/currency-service';
import { useIRO } from '../../../../iro/iro-context';
import { convertToBondingCurve, getCurveType } from '../../../../iro/iro-service';
import { useNetwork } from '../../../../network/network-context';
import { useAsset } from '../../../asset-context';
import { Asset } from '../../../asset-types';
import { createPriceChartWidget } from './price-chart-service';
import './price-chart.scss';

interface PriceChartProps {
    asset: Asset;
    className?: string;
}

const CHART_CONTAINER_ID = 'price-chart-container';

export const PriceChart: React.FC<PriceChartProps> = ({ asset, className }) => {
    const { hubNetwork } = useNetwork();
    const { vsAsset, hubAsset } = useAsset();
    const { getIroPlan } = useIRO();
    const [ chartWidget, setChartWidget ] = useState<IChartingLibraryWidget | undefined>();

    useEffect(() => setChartWidget(undefined), [ asset.key ]);

    useEffect(() => {
        if (chartWidget || !hubNetwork || !hubAsset || !vsAsset) {
            return;
        }
        const iroPlan = asset.iroDenom ? getIroPlan(asset.networkId) : undefined;
        const curveType = iroPlan?.bondingCurve ? getCurveType(convertToBondingCurve(iroPlan.bondingCurve)) : undefined;
        setChartWidget(createPriceChartWidget(
            asset,
            isCoinsEquals(asset, hubAsset) ? vsAsset : hubAsset,
            vsAsset,
            CHART_CONTAINER_ID,
            hubNetwork.chainId,
            curveType === 'Fixed',
        ));
    }, [ asset, hubNetwork, hubAsset, vsAsset, chartWidget, getIroPlan ]);

    useEffect(() => () => chartWidget?.remove(), [ chartWidget ]);

    return <div id={CHART_CONTAINER_ID} className={classNames('section small', className)}></div>;
};

export default PriceChart;
