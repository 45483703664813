import classNames from 'classnames';
import React, { forwardRef, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import Addresses from '../../../shared/components/addresses/addresses';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../shared/components/form-controls/input/input';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select, { SelectProps } from '../../../shared/components/form-controls/select/select';
import { MenuRefProps } from '../../../shared/components/menu/menu';
import HubWalletSelectorDialog from '../../account/account-menu/hub-wallet-selector-dialog/hub-wallet-selector-dialog';
import { AccountNetworkState } from '../../account/account-network-state';
import CommonNetworkBadge from '../../ibc-transfer/common-network-badge/common-network-badge';
import { useWallet } from '../../wallet/wallet-context';
import WalletLogo from '../../wallet/wallet-logo/wallet-logo';
import { isWalletSupported } from '../../wallet/wallet-service';
import { WALLET_TYPES, WalletType } from '../../wallet/wallet-types';
import { PortalWallet } from '../../wallet/wallets/portal-wallet/portal-wallet';
import PortalWalletSourceLogo from '../../wallet/wallets/portal-wallet/portal-wallet-source-logo';
import { useNetwork } from '../network-context';
import { getNetworkLogoPath } from '../network-service';
import { Network } from '../network-types';
import './network-selector.scss';

interface NetworkSelectorProps extends Partial<SelectProps> {
    networkData?: AccountNetworkState;
    switchNetworkAfterConnect?: boolean;
    optionsMenuOpenDisabled?: boolean;
    onNetworkSelect?: (networkId: string, commonNetwork?: boolean) => void,
    className?: string;
    walletSelectClassName?: string;
    networkSelectClassName?: string;
    walletSelectorLast?: boolean;
    hideWalletSelector?: boolean;
    hideAddress?: boolean;
    showCommonNetworks?: boolean;
    walletSelectorDisabled?: boolean;
    showAddressInput?: boolean;
    address?: string;
    setAddress?: (value: string) => void;
    isNetworkVisible?: (network: Network, commonNetwork?: boolean) => boolean;
    networks?: Network[];
    size?: 'small' | 'medium';
}

const NetworkSelector: React.ForwardRefRenderFunction<MenuRefProps, NetworkSelectorProps> = ({
    networkData,
    onNetworkSelect,
    isNetworkVisible,
    networks,
    optionsMenuOpenDisabled,
    switchNetworkAfterConnect,
    walletSelectorLast,
    hideWalletSelector,
    hideAddress,
    className,
    walletSelectorDisabled,
    walletSelectClassName,
    networkSelectClassName,
    showAddressInput,
    address,
    setAddress,
    showCommonNetworks,
    size = 'medium',
    ...otherSelectProps
}, selectRef) => {
    const { networks: allNetworks, commonNetworks, getNetwork } = useNetwork();
    const { networkWalletMap, networkWalletTypeMap, connectWallet } = useWallet();
    const [ hubWalletSelectorDialogOpen, setHubWalletSelectorDialogOpen ] = useState(false);

    const connectedWallet = networkData?.network ? networkWalletMap[networkData.network.chainId] : undefined;

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const searchRegExp = new RegExp(searchText, 'i');
        const network = getNetwork(value.toString());
        return Boolean(network && (searchRegExp.test(network.chainName) || searchRegExp.test(network.chainId)));
    }, [ getNetwork ]);

    const renderNetworkOption = (network?: Network, addresses?: string[]): ReactNode => {
        if (!network) {
            return undefined;
        }
        return <>
            <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />
            <span className='network-name'>{network.chainName}</span>
            {!hideAddress && addresses?.length ?
                <Addresses size='small' addresses={addresses} canCopy className='network-address' /> : undefined}
        </>;
    };

    const optionalAddressPrefixes = useMemo(
        () => networkData?.network ? [ networkData.network.bech32Prefix, '0x' ].filter(Boolean) as string[] : [],
        [ networkData?.network ],
    );

    const renderNetworksSelectHeader = (): ReactElement | null => {
        if (!showCommonNetworks || !commonNetworks?.length) {
            return null;
        }
        return (
            <div className='common-networks'>
                {commonNetworks.map((network, networkIndex) => {
                    const showNetworkDomain = commonNetworks?.some((otherNetwork, otherNetworkIndex) =>
                        networkIndex !== otherNetworkIndex && network.chainName.toLowerCase() === otherNetwork.chainName.toLowerCase());

                    return (
                        <CommonNetworkBadge
                            key={networkIndex}
                            network={network}
                            className='nowrap'
                            disabled={!isNetworkVisible?.(network, true)}
                            showNetworkDomain={showNetworkDomain}
                            selected={networkData?.network?.chainId === network.chainId}
                            onClick={() => onNetworkSelect?.(network.chainId.toString(), true)}
                        />
                    );
                })}
            </div>
        );
    };

    const renderNetworkWalletSelect = (): ReactElement => {
        const network = networkData?.network;
        return (
            <Select
                controlSize={size === 'small' ? 'medium' : 'large'}
                className={classNames('network-wallet-select', walletSelectClassName, size)}
                optionsModalClassName={network?.type === 'Hub' ? 'hub-options-modal' : ''}
                placeholder={<WalletIcon />}
                disabled={!network?.chainId || walletSelectorDisabled}
                value={network && networkWalletTypeMap[network.chainId]}
                optionsOverlayAlign={walletSelectorLast ? 'right' : undefined}
                onSelect={(type) => network && connectWallet(network.chainId, type as WalletType, true, switchNetworkAfterConnect)}
                onOptionsModalOpen={(isOpen) => isOpen && network?.type === 'Hub' ? setHubWalletSelectorDialogOpen(isOpen) : undefined}
                renderTriggerSelectedOption={(type) => type === 'PortalWallet' ?
                    <PortalWalletSourceLogo type={(connectedWallet as PortalWallet)?.getSourceType()} /> :
                    <WalletLogo type={type as WalletType} />}
            >
                {WALLET_TYPES.filter((type) => network?.type === 'Hub' || (type !== 'PortalWallet' &&
                    (type !== 'Quick Auth' || (network && networkWalletTypeMap[network.chainId]) === 'Quick Auth'))).map((walletType) => (
                    <Option
                        key={walletType}
                        value={walletType}
                        iconColorMode='original'
                        disabled={!network || !isWalletSupported(network, walletType) || walletType === 'Quick Auth'}
                    >
                        <WalletLogo type={walletType} />&nbsp;&nbsp;{walletType}
                    </Option>
                ))}
            </Select>
        );
    };

    const renderNetworkSelect = (): ReactElement => {
        const addresses = networkData?.address ? [ networkData.address ] : [];
        if (networkData?.hexAddress &&
            (networkData.network?.type === 'EVM' || networkData.network?.type === 'RollApp' || networkData.network?.evm)) {
            try {
                addresses.unshift(networkData.hexAddress);
            } catch {}
        }
        return (
            <Select
                ref={selectRef}
                controlSize={size === 'small' ? 'medium' : 'large'}
                placeholder='Select network'
                className={classNames('network-select', networkSelectClassName)}
                searchFilterPredicate={searchFilterPredicate}
                searchPlaceholder='Search...'
                fitToTriggerWidth={!showAddressInput}
                header={renderNetworksSelectHeader()}
                emptySearchResultsLabel='No Networks found'
                optionsMenuOpenDisabled={optionsMenuOpenDisabled}
                value={networkData?.network?.chainId}
                onSelect={(chainId) => onNetworkSelect?.(chainId?.toString())}
                renderTriggerSelectedOption={() => renderNetworkOption(networkData?.network, addresses)}
                {...otherSelectProps}
            >
                {(networks || allNetworks)
                    .filter((network) => !isNetworkVisible || isNetworkVisible(network))
                    .map((network) => (
                        <Option className='network-option' value={network.chainId} key={network.chainId} disabled={network.disabled}>
                            {renderNetworkOption(network)}
                        </Option>
                    ))}
            </Select>
        );
    };

    return <>
        <ControlsComposer
            className={classNames('network-selector', size, className, { 'have-address-input': showAddressInput })}
            reverse={walletSelectorLast}
        >
            {!hideWalletSelector && renderNetworkWalletSelect()}
            {renderNetworkSelect()}
            {showAddressInput && (
                <Input
                    controlSize={size === 'small' ? 'medium' : 'large'}
                    placeholder={optionalAddressPrefixes.map((prefix) => `${prefix}...`).join(' / ')}
                    onValueChange={setAddress}
                    value={address}
                />
            )}
        </ControlsComposer>

        {networkData && hubWalletSelectorDialogOpen &&
            <HubWalletSelectorDialog networkState={networkData} onRequestClose={() => setHubWalletSelectorDialogOpen(false)} />}
    </>;
};

export default forwardRef(NetworkSelector);
