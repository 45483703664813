import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAsset } from '../../../asset/asset-context';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';

const TotalLiquidityProperty: React.FC = () => {
    const { network } = useNetworkDashboard();
    const { mainAssetMap, assets, loading } = useAsset();

    const networkAssets = useMemo(() => assets?.filter((asset) => asset.networkId === network.chainId), [ assets, network.chainId ]);

    const { currentValue, previousValue } = useMemo(() => {
        const currentValue = networkAssets?.reduce((current, asset) => current + asset.liquidity, 0) || 0;
        const previousValue = networkAssets?.reduce((current, asset) => current + asset.previousDayLiquidity, 0) || 0;
        return { currentValue, previousValue };
    }, [ networkAssets ]);

    return (
        <Property
            label='Liquidity (24h):'
            loading={!networkAssets && loading}
            na={!networkAssets?.length || mainAssetMap?.[network.chainId]?.futureIRO}
        >
            <StatisticsChange
                period='day'
                size='small'
                currentValue={currentValue}
                previousValue={previousValue}
            >
                {formatPrice(currentValue, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </StatisticsChange>
        </Property>
    );
};

export default TotalLiquidityProperty;
