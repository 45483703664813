import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Alert from '../../../../shared/components/alert/alert';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Input from '../../../../shared/components/form-controls/input/input';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import ToggleSwitch from '../../../../shared/components/toggle-switch/toggle-switch';
import { DEFAULT_PIN_CODE_LENGTH, PIN_CODE_CHARACTERS } from '../../../../shared/utils/text-utils';
import { Network } from '../../../network/network-types';
import { useWallet } from '../../../wallet/wallet-context';
import { PortalWalletSourceType } from '../../../wallet/wallets/portal-wallet/types';
import PortalWalletSourceLogo from '../../../wallet/wallets/portal-wallet/portal-wallet-source-logo';
import './init-portal-wallet-dialog.scss';

interface InitPortalWalletDialogProps extends DialogProps {
    recover?: boolean;
    network: Network;
    sourceType: PortalWalletSourceType;
    onConnect?: () => void;
}

const EMPTY_CHARACTER = '-';

const InitPortalWalletDialog: React.FC<InitPortalWalletDialogProps> = ({
    recover,
    network,
    sourceType,
    className,
    onConnect,
    ...otherDialogProps
}) => {
    const { connectWallet } = useWallet();
    const [ rememberKey, setRememberKey ] = useState(false);
    const [ pinCode, setPinCode ] = useState<string>();
    const pinCodeCharInputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const displayedPinCode = useMemo(() => pinCode?.replaceAll(EMPTY_CHARACTER, '') || '', [ pinCode ]);

    const pinCodeRequired = useMemo(() => sourceType !== 'MetaMask', [ sourceType ]);

    const rememberMeWarning = <>
        Only activate the "remember me" option when using a device that is secure and belongs to you.<br />
        Enabling this feature on a public or non-secured device could potentially expose your keys and
        sensitive information to unauthorized individuals.
    </>;

    const onConfirmClick = useCallback((): void => {
        connectWallet(network.chainId, 'PortalWallet', true, false, { saveToCache: rememberKey, pinCode, sourceType });
        onConnect?.();
        otherDialogProps.onRequestClose?.();
    }, [ connectWallet, network.chainId, onConnect, otherDialogProps, pinCode, rememberKey, sourceType ]);

    const onPinCharacterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>, charIndex: number) => {
        event.preventDefault();
        event.stopPropagation();

        const charInput = pinCodeCharInputRefs.current[charIndex] as HTMLInputElement;
        if (event.key === 'Unidentified') {
            charInput.value = '';
        }
        setTimeout(() => {
            const key = event.key === 'Unidentified' ? charInput.value : event.key;
            if (key && PIN_CODE_CHARACTERS.includes(key)) {
                charInput.value = key;
                charIndex++;
            } else if (key === 'Enter' || key === 'ArrowRight') {
                charIndex++;
            } else if (/\s/.test(key) || key === 'Space') {
                charInput.value = '';
                charIndex++;
            } else if (key === 'Tab') {
                charIndex += (event.shiftKey ? -1 : 1);
            } else if (key === 'Backspace' || key === '') {
                charInput.value = '';
                charIndex--;
            } else if (key === 'ArrowLeft') {
                charIndex--;
            } else {
                return;
            }
            setPinCode(pinCodeCharInputRefs.current.map((charInput) => charInput?.value || EMPTY_CHARACTER).join(''));
            charIndex = Math.max(0, Math.min(pinCodeCharInputRefs.current.length - 1, charIndex));
            const nextCharInput = pinCodeCharInputRefs.current[charIndex];
            nextCharInput?.focus();
            nextCharInput?.select();
        });
    }, []);

    return (
        <Dialog className={classNames('init-portal-wallet-dialog', className)} closable {...otherDialogProps}>
            <DialogTitle className='dialog-title'>
                <PortalWalletSourceLogo className='wallet-logo' type={sourceType} />
                {recover ? 'Recover' : 'Generate'} Portal Wallet
            </DialogTitle>
            <DialogContent className='dialog-content'>
                <p className='signature-description'>
                    Sign with your {sourceType} account to {recover ? 'Recover your' : 'Generate a'} Portal Wallet.
                </p>
                <p className='signature-description'>
                    We use signatures to verify your ownership and to confirm wallet compatibility.
                </p>

                <div className='remember-me-section'>
                    <ToggleSwitch isChecked={rememberKey} onCheck={setRememberKey}>
                        Remember me
                        <InfoIndicator>{rememberMeWarning}</InfoIndicator>
                    </ToggleSwitch>

                    {rememberKey && <Alert type='warning' className='remember-me-warning'>{rememberMeWarning}</Alert>}
                </div>

                {pinCodeRequired && (
                    <div className='pin-code-section'>
                        <p>{!recover && `Already have a ${sourceType} wallet? `}Enter your PIN:</p>
                        <div className='pin-code-characters'>
                            {Array.from({ length: DEFAULT_PIN_CODE_LENGTH }).map((_, charIndex) => (
                                <Input
                                    ref={(ref) => pinCodeCharInputRefs.current[charIndex] = ref}
                                    key={charIndex}
                                    className='pin-code-character-input'
                                    controlSize='large'
                                    maxLength={1}
                                    max={1}
                                    minLength={1}
                                    spellCheck={false}
                                    value={pinCode?.charAt(charIndex) === EMPTY_CHARACTER ? '' : pinCode?.charAt(charIndex)}
                                    onKeyDown={(event) => onPinCharacterKeyDown(event, charIndex)}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogAction
                primary
                className='confirm-button'
                onClick={onConfirmClick}
                trackEvent={recover ? 'init_portal_wallet_recover_click' :
                    displayedPinCode ? 'init_portal_wallet_login_click' : 'init_portal_wallet_generate_click'}
                disabled={pinCodeRequired && (displayedPinCode ? displayedPinCode.length !== DEFAULT_PIN_CODE_LENGTH : recover)}
            >
                {recover && 'Recover'}
                {!recover && (displayedPinCode ? 'Log In' : 'Generate')}
            </DialogAction>
        </Dialog>
    );
};

export default InitPortalWalletDialog;
