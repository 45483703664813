import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import { DA_INFO_MAP, DATA_AVAILABILITY_CHAINS } from '../../../network/network-types';
import DaSelectorDialog from '../../manage-rollapps-page/create-rollapp-page/da-selector-dialog/da-selector-dialog';
import RollappsHighlights from '../rollapp-highlights/rollapp-highlights';
import './rollapps-hero.scss';

const RollappsHero: React.FC = () => {
    const [ currentDaIndex, setCurrentDaIndex ] = useState(0);
    const [ previousDaIndex, setPreviousDaIndex ] = useState(0);
    const [ currentDaImageWidth, setCurrentDaImageWidth ] = useState(0);
    const [ daSelectorDialogVisible, setDaSelectorDialogVisible ] = useState(false);

    const currentDa = useMemo(() => DATA_AVAILABILITY_CHAINS[currentDaIndex], [ currentDaIndex ]);

    const previousDa = useMemo(() => DATA_AVAILABILITY_CHAINS[previousDaIndex], [ previousDaIndex ]);

    useEffect(() => {
        const interval = setInterval(() => {
            setPreviousDaIndex(currentDaIndex);
            setCurrentDaIndex((currentDaIndex) => (currentDaIndex + 1) % DATA_AVAILABILITY_CHAINS.length);
        }, 2500);
        return () => clearInterval(interval);
    }, [ currentDaIndex ]);

    return <>
        <div className='hero-section'>
            <img src={require('../../../../assets/images/header-background.jpg')} alt='hero background' className='hero-background' />

            <div className='hero-content'>
                <h2 className='nowrap'>
                    Launch a RollApp on
                    <div className='da-logo-container' style={{ width: currentDaImageWidth }}>
                        {(currentDaIndex !== previousDaIndex) && (
                            <img
                                key={`previous-${previousDaIndex}`}
                                className={classNames('da-logo-full fade-out', previousDa.toLowerCase())}
                                src={DA_INFO_MAP[previousDa].fullLogo}
                                alt='previous da logo'
                            />
                        )}
                        <img
                            key={`current-${currentDaIndex}`}
                            className={classNames('da-logo-full slide-in', currentDa.toLowerCase())}
                            src={DA_INFO_MAP[currentDa].fullLogo}
                            alt='da logo'
                            onLoad={(event) => setCurrentDaImageWidth(event.currentTarget.width)} // Update width dynamically
                        />
                    </div>
                </h2>
                <p className='description'>Launch Anywhere, Connect Everywhere.</p>
                <Button
                    className='start-button'
                    onClick={() => setDaSelectorDialogVisible(true)}
                    trackEvent='launch_rollapp_click'
                >
                    Start ⟶
                </Button>
            </div>

            <span className='space' />

            <div className='rollapps-highlights-container'>
                <RollappsHighlights className='rollapps-highlights' />
            </div>
        </div>

        {daSelectorDialogVisible && <DaSelectorDialog onRequestClose={() => setDaSelectorDialogVisible(false)} />}
    </>;
};

export default RollappsHero;
