import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Badge from '../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { CoinsAmount } from '../currency-types';
import './display-name-with-iro.scss';

interface DisplayNameWithFutureProps {
    coins: CoinsAmount;
    className?: string;
    suffix?: ReactNode;
}

const DisplayNameWithIro: React.FC<DisplayNameWithFutureProps> = ({ coins, className, suffix }) => {
    if (!coins.iroDenom) {
        return <span className={classNames('horizontally-centered', className)}>{coins.currency.displayDenom}{suffix}</span>;
    }
    return (
        <span className={classNames('horizontally-centered', className)}>
            {coins.currency.displayDenom}
            {suffix}
            <Badge
                className='iro-badge'
                rounded
                color={getCssVariableValue('--gold-rgb').split(',').map(Number)}
                size='small'
                info='Launchpad'
                label='LPD'
            />
        </span>
    );
};

export default DisplayNameWithIro;
