export interface AnalyticsMapState<T extends object> {
    analyticsMap?: T;
    loadingMap?: { [id: string]: boolean };
    error?: any;
}

type AnalyticsMapAction<T> =
    { type: 'set-analytics', payload: T | undefined } |
    { type: 'set-loading', payload: { ids: string[], value?: boolean } } |
    { type: 'set-error', payload?: any };

export const analyticsMapReducer = (
    state: AnalyticsMapState<any>,
    action: AnalyticsMapAction<any>,
): AnalyticsMapState<any> => {
    switch (action.type) {
        case 'set-analytics':
            return {
                ...state,
                analyticsMap: { ...state.analyticsMap, ...action.payload },
                loadingMap: { ...state.loadingMap, ...createLoadingMap(Object.keys(action.payload || {}), false) },
                error: undefined,
            };
        case 'set-loading':
            return { ...state, loadingMap: { ...state.loadingMap, ...createLoadingMap(action.payload.ids, action.payload.value ?? true) } };
        case 'set-error':
            return {
                ...state,
                error: action.payload,
                loadingMap: state.analyticsMap && createLoadingMap(Object.keys(state.analyticsMap), false),
            };
        default:
            return state;
    }
};

const createLoadingMap = (ids: string[], value: boolean): { [id: string]: boolean } => {
    return ids.reduce((current, id) => ({ ...current, [id]: value }), {});
};
