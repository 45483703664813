import { useCallback, useEffect, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import useVisibility from '../../shared/hooks/use-visibility';
import { useHubNetworkState } from '../account/hub-network-state-context';
import { useAmm } from '../amm/amm-context';
import { useClient } from '../client/client-context';
import { useIncentives } from '../incentives/incentives-context';
import { useWallet } from '../wallet/wallet-context';
import { loadEarnings } from './earnings-service';
import { EarningRecord, EarningType } from './earnings-types';

interface UseEarningsValue {
    records?: EarningRecord[];
    loading: boolean;
    typeFilter?: EarningType;
    setTypeFilter: (type?: EarningType) => void;
    loadMore: () => void;
}

const PAGE_SIZE = 100;

export const useEarnings = (): UseEarningsValue => {
    const hubNetworkState = useHubNetworkState();
    const { clientStateMap, handleClientError } = useClient();
    const { ammState } = useAmm();
    const { hubWallet } = useWallet();
    const { incentivesState } = useIncentives();
    const [ loading, setLoading ] = useState(true);
    const [ records, setRecords ] = useState<EarningRecord[]>();
    const [ typeFilter, setTypeFilter ] = useState<EarningType>();
    const [ page, setPage ] = useState(1);
    const visibility = useVisibility();
    const [ canLoadMore, setCanLoadMore ] = useState(true);
    const cancelAndSetEarningsPromise = useCancelablePromise<EarningRecord[]>();

    const clientState = hubNetworkState.network && clientStateMap[hubNetworkState.network.chainId];

    useEffect(() => {
        if (visibility && hubWallet) {
            setPage(1);
            setCanLoadMore(true);
            setLoading(true);
        }
    }, [ visibility, typeFilter, hubWallet, clientState, hubNetworkState.address ]);

    useEffect(() => {
        setTypeFilter(undefined);
    }, [ hubWallet, hubNetworkState.address ]);

    useEffect(() => {
        if (!hubWallet) {
            setLoading(false);
            setCanLoadMore(false);
            setRecords(undefined);
        }
    }, [ hubWallet ]);

    const loadMore = useCallback(() => {
        if (!loading && canLoadMore) {
            setLoading(true);
            setPage((page) => page + 1);
        }
    }, [ canLoadMore, loading ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setLoading(false);
            return;
        }
        if (!hubNetworkState.address ||
            !clientState?.client ||
            clientState?.connecting ||
            incentivesState.loading ||
            ammState.loading ||
            !canLoadMore ||
            !loading) {
            return;
        }
        cancelAndSetEarningsPromise((signal) => loadEarnings(
            clientState.client!,
            hubNetworkState.address!,
            ammState.pools || [],
            incentivesState.gauges || [],
            page,
            PAGE_SIZE,
            typeFilter,
            signal,
        ))
            .then((currentRecords) => {
                if (currentRecords.length < PAGE_SIZE) {
                    setCanLoadMore(false);
                }
                setRecords(page === 1 ? currentRecords : [ ...(records || []), ...currentRecords ]);
            })
            .catch(handleClientError)
            .finally(() => setLoading(false));
    }, [
        ammState.loading,
        ammState.pools,
        canLoadMore,
        cancelAndSetEarningsPromise,
        clientState,
        handleClientError,
        hubNetworkState.address,
        incentivesState.gauges,
        incentivesState.loading,
        loading,
        page,
        records,
        typeFilter,
    ]);

    return { records, loading, typeFilter, loadMore, setTypeFilter };
};
