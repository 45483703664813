import { Decimal } from 'cosmjs/packages/math';
import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../../../../shared/components/alert/alert';
import InfoIndicator from '../../../../../../shared/components/info-indicator/info-indicator';
import Link from '../../../../../../shared/components/link/link';
import ToggleSwitch from '../../../../../../shared/components/toggle-switch/toggle-switch';
import useWindowSize from '../../../../../../shared/hooks/use-window-size';
import { useNetwork } from '../../../../../network/network-context';
import { useCreateRollapp } from '../../create-rollapp-context';
import Input from '../../../../../../shared/components/form-controls/input/input';
import { DEFAULT_DENOM_EXPONENT, DISCORD_OPERATORS_LINK, OPERATE_DOCS_URL } from '../../types';
import './sequencer-section.scss';

const SequencerSection: React.FC = () => {
    const navigate = useNavigate();
    const { rollAppParams } = useNetwork();
    const { rollapp, isMinSequencerBondValid, updateRollapp, showErrors, isComplete, setVisibleStep } = useCreateRollapp();
    const { width, height } = useWindowSize();
    const [ infoBoxContainerHeight, setInfoBoxContainerHeight ] = useState<CSSProperties['height']>('initial');
    const infoBoxRef = useRef<HTMLDivElement>(null);

    const permissionless = useMemo(() => rollapp.initialSequencer === '*', [ rollapp.initialSequencer ]);

    useEffect(() => {
        if (!infoBoxRef.current || !width) {
            return;
        }
        setTimeout(() => setInfoBoxContainerHeight(infoBoxRef.current?.clientHeight));
    }, [ isComplete, width ]);

    const goToAppsSection = useCallback(() => {
        setVisibleStep('Register');
        setTimeout(() => window.scrollTo({ top: height }));
    }, [ height, setVisibleStep ]);

    const updateMinSequencerBond = useCallback((value: string, previousValue: string) => {
        const amountPattern = new RegExp('^([0-9]+\\.?)?([0-9]{0,9})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        if (value === '') {
            rollapp.minSequencerBond = undefined;
            return;
        }
        try {
            const minSequencerBond = [ { denom: 'adym', amount: Decimal.fromUserInput(value, DEFAULT_DENOM_EXPONENT).atomics } ];
            updateRollapp('minSequencerBond', minSequencerBond);
        } catch (ignore) {}
    }, [ rollapp, updateRollapp ]);

    const renderError = (): ReactNode => {
        if (!showErrors) {
            return '';
        }
        if (!rollapp.minSequencerBond) {
            return 'Minimum sequencer bond is required';
        }
        if (!isMinSequencerBondValid) {
            return `The minimum sequencer bond is ${
                Decimal.fromAtomics(rollAppParams?.minSequencerBondGlobal?.amount || '0', DEFAULT_DENOM_EXPONENT).toString()}`;
        }
    };

    return (
        <div className='sequencer-section section'>
            <h5 className='section-header'>Set Initial Operator</h5>
            <div className='control-container'>
                <div className='control-label-container'>
                    <label>Access Mode</label>
                    <InfoIndicator indicatorSize='small'>
                        Choose between a whitelisted operator, where only a specified address can start and produce blocks for your RollApp, or a permissionless setup, allowing anyone to become your RollApp's sequencer.
                    </InfoIndicator>
                </div>
                <ToggleSwitch
                    isChecked={permissionless}
                    disabled={rollapp.launched}
                    onCheck={(value) => updateRollapp('initialSequencer', value ? '*' : '')}
                    containerClassName='permissionless-switch'
                >
                    Permissionless
                </ToggleSwitch>
            </div>

            <div className='control-container'>
                <div className='control-label-container'>
                    <label className={permissionless ? '' : 'required'}>Reserve the initial operator address</label>
                    <InfoIndicator indicatorSize='small'>
                        Operator address for whitelisting
                    </InfoIndicator>
                </div>
                <Input
                    value={permissionless ? '' : rollapp.initialSequencer}
                    onValueChange={(value) => updateRollapp('initialSequencer', value)}
                    placeholder='e.g., dym1...'
                    disabled={rollapp.launched || permissionless}
                    typeFinishDelay={1000}
                    error={showErrors && !rollapp.initialSequencer && 'Operator address is required'}
                />
            </div>

            <div className='control-container'>
                <div className='control-label-container'>
                    <label className='required'>Min sequencer bond</label>
                    <InfoIndicator indicatorSize='small'>
                        Choose the minimum amount of DYM your operator will be required to bond in order to operate your RollApp.
                    </InfoIndicator>
                </div>
                <Input
                    value={rollapp.minSequencerBond === undefined ? '' :
                        Decimal.fromAtomics(rollapp.minSequencerBond?.[0]?.amount || '0', DEFAULT_DENOM_EXPONENT).toString()}
                    onValueChange={(value, previousValue) => updateMinSequencerBond(value, previousValue)}
                    suffix='DYM'
                    disabled={rollapp.launched}
                    error={renderError()}
                />
            </div>

            <div className='info-box-container' style={{ height: infoBoxContainerHeight }}>
                <Alert
                    type={isComplete ? 'success' : 'info'}
                    title={isComplete ? 'RollApp Setup Complete!' : ''}
                    ref={infoBoxRef}
                >
                    {isComplete ? <>
                        All necessary details have been submitted. You may still adjust the details, including the operator,
                        until the selected operator initiates the RollApp.<br />
                        Next steps:
                        <ul className='list'>
                            <li>
                                Start running your RollApp using the <Link inline url={OPERATE_DOCS_URL} external>docs</Link>, or
                                connect with an operator on <Link inline url={DISCORD_OPERATORS_LINK} external>Discord</Link> to
                                handle the task.
                            </li>
                            <li>Register an App on your RollApp <Link inline onClick={goToAppsSection}>here</Link>.</li>
                            <li>Promote your RollApp to attract
                                more <Link inline onClick={() => navigate('/dymension/endorsement')}>endorsements</Link> and
                                boost your incentive earnings!
                            </li>
                        </ul>
                    </> : <>
                        Before your RollApp can run, you need to set a operator. You have several options:
                        <ul className='list'>
                            <li>
                                <b>Run it Yourself</b>: Use the <Link inline url={OPERATE_DOCS_URL} external>docs</Link> to
                                set up and run the RollApp on your own.
                            </li>
                            <li>
                                <b>Find a Community Operator</b>: Connect with a community operator
                                on <Link inline url={DISCORD_OPERATORS_LINK} external>Discord</Link> to handle the process.
                                After coordinating, proceed to whitelist their operator address.
                            </li>
                            <li>
                                <b>Permissionless Mode</b>: Allow anyone in the network to run your RollApp, though without coordination, there’s no guarantee it will be run.
                            </li>
                        </ul>
                    </>}
                </Alert>
            </div>
        </div>
    );
};

export default SequencerSection;
