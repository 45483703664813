import classNames from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ThunderIcon } from '../../../assets/icons/thunder.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear.svg';
import Button from '../../../shared/components/button/button';
import Dialog, { DialogAction, DialogContent, DialogTitle } from '../../../shared/components/dialog/dialog';
import Icon from '../../../shared/components/icon/icon';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getTimeLeftText, getTimeOffset } from '../../../shared/utils/date-utils';
import ConnectQuickAuthWalletDialog from '../../account/account-menu/connect-quick-auth-wallet-dialog/connect-quick-auth-wallet-dialog';
import { useLayout } from '../../app/layout/layout-context';
import { useWallet } from '../../wallet/wallet-context';
import { useQuickAuth } from '../quick-auth-context';
import QuickAuthDialog from '../quick-dialog/quick-auth-dialog';
import './quick-auth-action.scss';

interface QuickAuthActionProps {
    accountMenuTriggerWidth?: number;
}

const QuickAuthAction: FC<QuickAuthActionProps> = ({ accountMenuTriggerWidth }) => {
    const { ctaClassName } = useLayout();
    const { hubWallet } = useWallet();
    const [ quickAuthDialogOpen, setQuickAuthDialogOpen ] = useState(false);
    const [ connectQuickAuthWalletOpen, setConnectQuickAuthWalletOpen ] = useState(false);
    const [ quickAuthTooltipDialogVisible, setQuickAuthTooltipDialogVisible ] = useState(false);
    const [ hideQuickAuthActionIfNotConnected, setHideQuickAuthActionIfNotConnected ] =
        usePersistedState('show-quick-auth-action-if-not-connected', false);
    const { networkState, expiration, isConnected } = useQuickAuth();
    const { account } = useParams();
    const { isTablet } = useWindowSize();
    const navigate = useNavigate();

    const urlEncryptedAccount = useMemo(
        () => !account ? undefined : account.replace(`${window.location.origin}/account/`, ''),
        [ account ],
    );

    if ((!isConnected && hideQuickAuthActionIfNotConnected) || (!isTablet && !hubWallet)) {
        return <></>;
    }


    return <>
        <Tooltip
            placement={isConnected ? 'top-end' : 'bottom-end'}
            title='Quick Auth is a secure session with Dymension, allowing users to interact without signing each transaction, accessible via browser or mobile.'
        >
            <div
                className={classNames('quick-auth-action', ctaClassName, { connected: isConnected })}
                style={{ width: accountMenuTriggerWidth }}
                onClick={() => isTablet ? setConnectQuickAuthWalletOpen(true) : setQuickAuthDialogOpen(true)}
                data-ga-event='quick_auth_click'
            >
                <Icon className='thunder-icon'><ThunderIcon /></Icon>{isConnected ? '' : 'Quick Auth'}
                {!isConnected && (
                    <Button
                        buttonType='icon'
                        size='xs'
                        className='close-button'
                        onClick={(event) => {
                            event.stopPropagation();
                            setQuickAuthTooltipDialogVisible(true);
                        }}
                    >
                        <ClearIcon />
                    </Button>
                )}
                {isConnected && <span className='time'>{getTimeLeftText(getTimeOffset(expiration), 'double')}</span>}
            </div>
        </Tooltip>

        {quickAuthDialogOpen && networkState.network &&
            <QuickAuthDialog networkState={networkState} onRequestClose={() => setQuickAuthDialogOpen(false)} />}

        {networkState.network && (connectQuickAuthWalletOpen || urlEncryptedAccount) &&
            <ConnectQuickAuthWalletDialog
                urlEncryptedAccount={urlEncryptedAccount}
                onRequestClose={() => urlEncryptedAccount ? navigate('/') : setConnectQuickAuthWalletOpen(false)}
            />}

        {quickAuthTooltipDialogVisible && (
            <Dialog className='quick-auth-tooltip-dialog' closable onRequestClose={() => setQuickAuthTooltipDialogVisible(false)}>
                <DialogTitle>Hide Quick-Auth Tooltip</DialogTitle>
                <DialogContent className='dialog-content'>
                    Are you sure you want to hide the Quick-Auth tooltip when your session ends?<br />If you proceed, you can still connect through the account menu.
                </DialogContent>
                <DialogAction close primary>Cancel</DialogAction>
                <DialogAction trackEvent='hide_quick_auth_click' secondary close onClick={() => setHideQuickAuthActionIfNotConnected(true)}>
                    OK
                </DialogAction>
            </Dialog>
        )}
    </>;
};

export default QuickAuthAction;
