import classNames from 'classnames';
import React, { useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { useAsset } from '../../../../asset/asset-context';
import { CoinsAmount } from '../../../../currency/currency-types';
import { isCoinsEquals } from '../../../../currency/currency-service';
import './account-total-value.scss';

interface AccountTotalValueProps {
    className?: string;
    balances?: CoinsAmount[];
    loading: boolean;
}

const AccountTotalValue: React.FC<AccountTotalValueProps> = ({ className, balances, loading }) => {
    const { loading: assetsLoading, assets, getTokenPrice } = useAsset();

    const currentValue = useMemo(() => {
        if ((!balances && loading) || (!assets && assetsLoading)) {
            return undefined;
        }
        return balances?.reduce((current, balance) => current + (getTokenPrice(balance) || 0), 0);
    }, [ assets, assetsLoading, balances, getTokenPrice, loading ]);

    const previousValue = useMemo(
        () => balances?.reduce((current, balance) => {
            const asset = assets?.find((asset) => isCoinsEquals(asset, balance));
            return asset ? current + (balance.amount * asset.previousDayPrice) : current;
        }, 0) || 0,
        [ assets, balances ],
    );

    return (
        <Property
            label='Total Available'
            className={classNames('account-total-value', className)}
            valueClassName='property-value'
            labelClassName='property-label'
        >
            {currentValue === undefined && loading ? <Spinner /> :
                <StatisticsChange
                    period='day'
                    currentValue={currentValue || 0}
                    previousValue={(previousValue || 0)}
                >
                    {formatPrice(currentValue || 0, undefined, undefined, 10)}
                </StatisticsChange>}
        </Property>
    );
};

export default AccountTotalValue;
