import classNames from 'classnames';
import React, { forwardRef, ReactNode, useCallback } from 'react';
import Addresses from '../../../shared/components/addresses/addresses';
import Button from '../../../shared/components/button/button';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select, { SelectProps } from '../../../shared/components/form-controls/select/select';
import Icon from '../../../shared/components/icon/icon';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import Link from '../../../shared/components/link/link';
import { MenuRefProps } from '../../../shared/components/menu/menu';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useWallet } from '../../wallet/wallet-context';
import { convertToBech32Address } from '../../wallet/wallet-service';
import { useNetwork } from '../network-context';
import { getNetworkLogoPath } from '../network-service';
import { Network } from '../network-types';
import './multiple-network-selector.scss';

interface MultipleNetworkSelectorProps extends Omit<SelectProps, 'children'> {
    optionsMenuOpenDisabled?: boolean;
    onNetworkSelect?: (networkId: string) => void,
    isNetworkVisible?: (network: Network) => boolean;
    isNetworkSelectable?: (network: Network) => boolean;
    networks?: Network[];
}

const MultipleNetworkSelector: React.ForwardRefRenderFunction<MenuRefProps, MultipleNetworkSelectorProps> = ({
    onNetworkSelect,
    isNetworkVisible,
    isNetworkSelectable,
    networks,
    optionsMenuOpenDisabled,
    className,
    optionsModalClassName,
    ...otherDialogProps
}, selectRef) => {
    const hubNetworkState = useHubNetworkState();
    const { networks: allNetworks, getNetwork } = useNetwork();
    const { hubWallet } = useWallet();

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const searchRegExp = new RegExp(searchText, 'i');
        const network = getNetwork(value.toString());
        return Boolean(network && (searchRegExp.test(network.chainName) || searchRegExp.test(network.chainId)));
    }, [ getNetwork ]);

    const renderNetworkOption = (network?: Network, addresses?: string[]): ReactNode => {
        if (!network) {
            return undefined;
        }
        if (hubNetworkState.hexAddress && (network.type === 'Hub' || network.type === 'RollApp' || network.type === 'EVM')) {
            addresses = filterNonEmptyValues([
                hubNetworkState.hexAddress, convertToBech32Address(hubNetworkState.hexAddress, network.bech32Prefix || ''),
            ]);
        }
        return <>
            <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />
            <span className='network-name'>{network.chainName}</span>
            {addresses?.length ? (
                <Addresses
                    trigger={<><Icon color='secondary'><CopyIcon /></Icon>&nbsp;Address</>}
                    size='small'
                    addresses={addresses}
                    canCopy
                    className='network-address'
                />
            ) : undefined}
            <span className='space' />
            <Button
                buttonType='secondary'
                size='small'
                className='import-to-wallet-button'
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    hubWallet?.switchNetwork?.(network);
                }}
            >
                Import To Wallet
            </Button>
        </>;
    };

    return (
        <Select
            ref={selectRef}
            placeholder='Select network'
            className={classNames('multiple-network-selector', className)}
            optionsModalClassName={classNames('multiple-network-selector-options', optionsModalClassName)}
            searchFilterPredicate={searchFilterPredicate}
            searchPlaceholder='Search...'
            fitToTriggerWidth
            multiselect
            emptySearchResultsLabel='No Networks found'
            optionsMenuOpenDisabled={optionsMenuOpenDisabled}
            {...otherDialogProps}
        >
            {(networks || allNetworks)
                .filter((network) => !isNetworkVisible || isNetworkVisible(network))
                .map((network) => (
                    <Option value={network.chainId} key={network.chainId} disabled={network.disabled || isNetworkSelectable?.(network)}>
                        {renderNetworkOption(network)}
                    </Option>
                ))}
        </Select>
    );
};

export default forwardRef(MultipleNetworkSelector);
