import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { CommunityPoolSpendProposal } from 'cosmjs-types/cosmos/distribution/v1beta1/distribution';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import Button from '../../../../shared/components/button/button';
import Input from '../../../../shared/components/form-controls/input/input';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import './proposal-type.scss';

interface CreateStreamProps {
    content?: CommunityPoolSpendProposal;
    onInit?: (data: CommunityPoolSpendProposal) => void;
    onChange?: (data: Partial<CommunityPoolSpendProposal>, isValid: boolean) => void;
}

const MAX_COINS = 10;

const CommunityPoolSpend: React.FC<CreateStreamProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(
        () => Boolean(content?.recipient && content?.amount?.length && content.amount.every(({ denom, amount }) => denom && amount)),
        [ content ],
    );

    useEffect(() => onInit?.({
        title: '',
        description: '',
        amount: [ { denom: '', amount: '' } ],
        recipient: '',
    }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    const updateCoin = useCallback((record: Coin, key: keyof Coin, value: string) => {
        record[key] = value;
        if (content) {
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const addCoin = useCallback(() => {
        if (content) {
            content.amount.push({ denom: '', amount: '' });
            onChange?.({ ...content }, false);
        }
    }, [ content, onChange ]);

    const onRemoveCoin = useCallback((index: number) => {
        if (content) {
            content.amount.splice(index, 1);
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const renderCoinsSection = (): ReactElement => {
        return (
            <div className='proposal-content-section'>
                <label className='proposal-control-label'>Amount</label>
                {content?.amount?.map((coin, coinIndex) => (
                    <div className='proposal-change-item' key={coinIndex}>
                        <label className='proposal-change-label'>Denom</label>
                        <Input
                            value={coin.denom}
                            onTypeFinish={(value) => updateCoin(coin, 'denom', value?.toString() || '')}
                        />
                        <label className='proposal-change-label'>Amount</label>
                        <Input
                            value={coin.amount}
                            type='number'
                            onTypeFinish={(value) => updateCoin(coin, 'amount', value?.toString() || '')}
                        />
                        <Button
                            disabled={content?.amount.length <= 1}
                            className='proposal-remove-item-button'
                            buttonType='icon'
                            onClick={() => onRemoveCoin(coinIndex)}
                        >
                            <ClearIcon />
                        </Button>
                    </div>
                ))}
                <Button
                    className='proposal-add-item-button'
                    buttonType='secondary'
                    disabled={(content?.amount?.length || 0) >= MAX_COINS}
                    onClick={addCoin}
                >
                    Add Coin
                </Button>
            </div>
        );
    };

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Recipient</label>
            <Input
                value={content?.recipient}
                onValueChange={(value) => onChange?.({ recipient: value.toString() || '' }, isValid)}
            />
            {renderCoinsSection()}
        </div>
    );
};

export default CommunityPoolSpend;
