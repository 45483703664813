import { Decimal } from 'cosmjs/packages/math';
import { GasPrice } from 'cosmjs/packages/stargate';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { roundNumber } from '../../../shared/utils/number-utils';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import { useAsset } from '../../asset/asset-context';
import { DEFAULT_GAS_PRICE_STEPS } from '../../client/client-types';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { ContractMessage, SignMethod } from '../tx-types';
import { TxValue, useTx } from '../use-tx';
import { AmountTxState, amountTxStateReducer } from './amount-tx-state';
import { AccountNetworkState } from '../../account/account-network-state';
import { createEmptyCoinsAmount, getFixedDenom, isCoinsEquals } from '../../currency/currency-service';

export type AmountTxMessagesCreator = (fee?: CoinsAmount, coins?: CoinsAmount, params?: any, broadcasting?: boolean) => EncodeObject[];

export type AmountEvmContractCreator = (messages: EncodeObject[], coins?: CoinsAmount) => undefined | ContractMessage;

export interface AmountTxValue extends Omit<TxValue, 'setGasPrice'> {
    amountTxState: AmountTxState;
    setCoins: (coins: CoinsAmount) => void;
    setAmount: (amount: number) => void;
}

interface AmountTxParams {
    networkState: AccountNetworkState;
    availableBalances?: CoinsAmount[];
    disabledBalances?: CoinsAmount[];
    reduceFeeFromBalances?: boolean;
    selectInitialCurrency?: boolean;
    clearAmountAfterResponse?: boolean;
    initialAsset?: CoinsAmount;
    amountTxMessagesCreator?: AmountTxMessagesCreator;
    extraFees?: CoinsAmount[];
    signMethod?: SignMethod;
    amountEvmContractCreator?: AmountEvmContractCreator;
    useEvm?: boolean;
}

export const useAmountTx = ({
    networkState,
    availableBalances,
    amountTxMessagesCreator,
    disabledBalances,
    reduceFeeFromBalances = true,
    selectInitialCurrency = true,
    clearAmountAfterResponse = true,
    initialAsset,
    extraFees,
    signMethod,
    useEvm,
    amountEvmContractCreator,
}: AmountTxParams): AmountTxValue => {
    const { networkDenoms } = useNetwork();
    const { getTokenPrice } = useAsset();
    const [ amountTxState, amountTxStateDispatch ] = useReducer(amountTxStateReducer, { availableAmount: 0 });
    const [ shouldBroadcast, setShouldBroadcast ] = useState<{ memo?: string, params?: any, ignoreFeeLoading?: boolean }>();

    const txMessagesCreator = useCallback(
        (fee?: CoinsAmount, params?: any, broadcasting?: boolean) =>
            amountTxMessagesCreator?.(fee, amountTxState.coins, params, broadcasting) || [],
        [ amountTxMessagesCreator, amountTxState.coins ],
    );

    const evmContractCreator = useCallback(
        (messages: EncodeObject[]) => amountEvmContractCreator?.(messages, amountTxState.coins),
        [ amountEvmContractCreator, amountTxState.coins ],
    );

    const { txState, setGasPrice, calculateFee, broadcast: txBroadcast, ...otherTxValueProps } = useTx({
        networkState,
        signMethod,
        useEvm,
        evmContractCreator,
        txMessagesCreator,
    });

    const setCoins = useCallback((coins: CoinsAmount) => amountTxStateDispatch({ type: 'set-coins', payload: coins }), []);

    const setAmount = useCallback((amount: number) => amountTxStateDispatch({ type: 'set-coins-amount', payload: amount }), []);

    const broadcast = useCallback(async (memo?: string, params?: any, ignoreFeeLoading?: boolean) => {
        if (!txState.fee?.coins.amount) {
            setShouldBroadcast({ memo, params, ignoreFeeLoading });
            calculateFee(undefined, true);
            return;
        }
        return txBroadcast(memo, params, ignoreFeeLoading);
    }, [ calculateFee, txBroadcast, txState.fee?.coins.amount ]);

    useEffect(() => {
        if (!networkState.network || !amountTxState.coins || (!networkState.balances && !availableBalances)) {
            amountTxStateDispatch({ type: 'set-available-amount', payload: 0 });
            return;
        }
        const amountTxCoins = amountTxState.coins;
        const availableBalance =
            amountTxCoins && (availableBalances || networkState.balances)?.find((balance) => isCoinsEquals(balance, amountTxCoins));

        let availableAmount = availableBalance?.amount || 0;
        if (reduceFeeFromBalances) {
            availableAmount = [ txState.fee?.coins, ...(extraFees || []) ]
                .filter((fee) => fee && isCoinsEquals(amountTxCoins, fee))
                .reduce((current, fee) => current - (fee?.amount || 0), availableAmount);
        }
        amountTxStateDispatch({
            type: 'set-available-amount',
            payload: roundNumber(Math.max(0, availableAmount), amountTxCoins.currency.decimals),
        });
    }, [
        amountTxState.coins,
        txState.fee?.coins,
        availableBalances,
        extraFees,
        reduceFeeFromBalances,
        networkState.balances,
        networkState.network,
    ]);

    useEffect(() => {
        if (networkState.network) {
            amountTxStateDispatch({ type: 'set-coins', payload: undefined });
        }
    }, [ networkState.network ]);

    useEffect(() => {
        if (!networkState.balancesLoading && networkState.network && selectInitialCurrency) {
            const mainCoin = createEmptyCoinsAmount(networkState.network, undefined, networkDenoms);
            const initialCoins = !initialAsset || isCoinsEquals(mainCoin, initialAsset) ? [ mainCoin ] : [ initialAsset, mainCoin ];
            const fixedAvailableBalances = (availableBalances || networkState.balances || initialCoins)
                .filter((balance) => !disabledBalances ||
                    disabledBalances?.every((disabledBalance) => !isCoinsEquals(balance, disabledBalance)));
            if (initialAsset && networkState.network.type === 'Hub' &&
                fixedAvailableBalances.every((balance) => !isCoinsEquals(balance, initialAsset))
            ) {
                fixedAvailableBalances.push(initialAsset);
            }
            if (!fixedAvailableBalances.length) {
                return;
            }
            const coins = filterNonEmptyValues([ amountTxState.coins, ...initialCoins ].map((coins) => {
                const token = coins && fixedAvailableBalances.find((balance) => isCoinsEquals(balance, coins));
                return !token ? undefined : { ...token, amount: coins.amount };
            }))[0] || { ...fixedAvailableBalances[0], amount: 0 };

            if (amountTxState.coins && isCoinsEquals(coins, amountTxState.coins) && (amountTxState.coins.ibc || !coins.ibc)) {
                return;
            }
            amountTxStateDispatch({ type: 'set-coins', payload: coins });
        }
    }, [
        initialAsset,
        amountTxState.coins,
        availableBalances,
        disabledBalances,
        networkState.balances,
        networkState.network,
        networkState.balancesLoading,
        networkDenoms,
        selectInitialCurrency,
    ]);

    useEffect(() => {
        if (txState.response && clearAmountAfterResponse) {
            setAmount(0);
        }
    }, [ clearAmountAfterResponse, setAmount, txState.response ]);

    const calculateGasPrice = useCallback(() => {
        const feeCoins = txState.fee?.coins;
        const txCoins = amountTxState.coins;
        const feeGas = txState.fee?.gas;
        if (networkState.network?.type !== 'Hub' ||
            !networkState.balances?.length ||
            !feeGas ||
            !feeCoins ||
            !txCoins ||
            isCoinsEquals(feeCoins, txCoins)) {
            return;
        }
        setGasPrice(undefined);
        const feeBalance = networkState.balances.find((balance) => isCoinsEquals(balance, feeCoins));
        if (feeBalance && feeBalance.amount >= feeCoins.amount) {
            return;
        }
        const txCoinsBalance = networkState.balances.find((balance) => isCoinsEquals(balance, txCoins));
        const txCoinsFeeAmount = getTokenPrice(feeCoins, txCoins);
        if (!txCoinsBalance || !txCoinsFeeAmount || txCoinsBalance.amount < txCoinsFeeAmount) {
            return;
        }
        const updatedFeeCoins = { ...feeCoins, amount: networkState.network.gasPriceSteps?.average ?? DEFAULT_GAS_PRICE_STEPS.average };
        updatedFeeCoins.amount *= Math.pow(10, txCoins.currency.decimals - feeCoins.currency.decimals);
        const txCoinsGasPriceAmount = getTokenPrice(updatedFeeCoins, txCoins);
        if (!txCoinsGasPriceAmount) {
            return;
        }
        const gasPrice = new GasPrice(
            Decimal.fromUserInput(roundNumber(txCoinsGasPriceAmount, txCoins.currency.decimals).toString(), txCoins.currency.decimals),
            getFixedDenom(txCoins),
        );
        setGasPrice(gasPrice);
        return gasPrice;
    }, [
        amountTxState.coins,
        getTokenPrice,
        networkState.balances,
        networkState.network?.gasPriceSteps?.average,
        networkState.network?.type,
        setGasPrice,
        txState.fee?.coins,
        txState.fee?.gas,
    ]);

    useEffect(() => {
        if (!shouldBroadcast || txState.feeLoading) {
            return;
        }
        if (txState.fee?.coins.amount) {
            const calculatedGasPrice = calculateGasPrice();
            if (calculatedGasPrice && (!txState.fee.gasPrice || txState.fee.gasPrice.denom !== calculatedGasPrice.denom)) {
                return;
            }
        }
        setShouldBroadcast(undefined);
        txBroadcast(shouldBroadcast.memo, shouldBroadcast.params, shouldBroadcast.ignoreFeeLoading).then();
    }, [ calculateGasPrice, shouldBroadcast, txBroadcast, txState.fee, txState.feeLoading ]);

    useEffect(() => {
        calculateGasPrice();
    }, [ calculateGasPrice ]);

    return { amountTxState, setCoins, setAmount, txState, calculateFee, broadcast, ...otherTxValueProps };
};
