import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './da-selector-dialog.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../shared/components/button/button';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import Spinner from '../../../../../shared/components/spinner/spinner';
import Swiper from '../../../../../shared/components/swiper/swiper';
import useWindowSize from '../../../../../shared/hooks/use-window-size';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { DA_INFO_MAP, DATA_AVAILABILITY_CHAINS, DataAvailability } from '../../../../network/network-types';

const DaSelectorDialog: React.FC<DialogProps> = (props) => {
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const [ selectedDa, setSelectedDa ] = useState<DataAvailability>();
    const [ daCostMap, setDaCostMap ] = useState<{ [da in DataAvailability]?: string }>({});

    useEffect(() => {
        DATA_AVAILABILITY_CHAINS.map((da) =>
            fetch(`${process.env.REACT_APP_DA_COST_BASE_URL}/v1/${DA_INFO_MAP[da].costApiId}/1000000`)
                .then((response) => response.json())
                .then((result) => {
                    const cost = Number(result['cost_in_usd']);
                    setDaCostMap((daCostMap) => ({
                        ...daCostMap, [da]: `${formatPrice(cost, undefined, { maximumSignificantDigits: 2 })} per MB`,
                    }));
                })
                .catch((error) => {
                    console.error(`Can't fetch da cost`, error);
                    setDaCostMap((daCostMap) => ({ ...daCostMap, [da]: '-' }));
                }));
    }, []);

    const itemWidth = useMemo(
        () => width <= 508 ? 90 : width <= 720 ? 60 : width <= 860 ? 45 : width <= 1024 ? 40 : undefined,
        [ width ],
    );

    const onContinue = useCallback(() => {
        if (selectedDa) {
            localStorage.setItem('da-', JSON.stringify(selectedDa));
            navigate('/rollapps/manage/create');
        }
    }, [ navigate, selectedDa ]);

    return (
        <Dialog className='da-selector-dialog' {...props}>
            <DialogTitle className='dialog-title'>Launch On</DialogTitle>
            <DialogContent className='dialog-content'>
                <p className='dialog-subtitle'>The chosen layer will serve as the source of truth for verifiability and validity of your chain</p>

                <Swiper containerClassName='das-swiper' showOneInMobile itemWidth={itemWidth}>
                    {DATA_AVAILABILITY_CHAINS.map((da) => (
                        <Button
                            buttonType='secondary'
                            className='da-button'
                            key={da}
                            onClick={() => setSelectedDa(da === selectedDa ? undefined : da)}
                            active={da === selectedDa}
                        >
                            <img alt='da logo' className='da-logo' src={DA_INFO_MAP[da].logo} />
                            <h3>{da}</h3>

                            <div className='da-properties'>
                                <div className='da-property'>
                                    <span className='property-name'>Cost</span>
                                    <span className='property-value'>{daCostMap[da] ?? <Spinner size='small' />}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Throughput</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].throughput}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Block Size</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].blockSize}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Block Time</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].blockTime}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Data Retention</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].dataRetention}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Finality Time</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].finalityTime}</span>
                                </div>
                            </div>
                        </Button>
                    ))}
                </Swiper>

            </DialogContent>

            <DialogAction secondary close>Cancel</DialogAction>
            <DialogAction primary disabled={!selectedDa} onClick={onContinue} trackEvent='da_confirm_click'>Continue</DialogAction>
        </Dialog>
    );
};

export default DaSelectorDialog;
