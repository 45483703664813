import classNames from 'classnames';
import React, { CSSProperties, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../../shared/components/icon/icon';
import Property from '../../../../shared/components/property/property';
import Spinner from '../../../../shared/components/spinner/spinner';
import { getHistoryValuesInPeriod } from '../../../../shared/components/statistics/statistics-change/statistics-change-service';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import { ReactComponent as DymensionLogo } from '../../../../assets/logos/dymension-logo.svg';
import PoolApr from '../../../amm/pools/pool-apr/pool-apr';
import { useAsset } from '../../../asset/asset-context';
import { useIRO } from '../../../iro/iro-context';
import IroProgress from '../../../iro/iro-progress/iro-progress';
import { useNetwork } from '../../../network/network-context';
import { Network, ROLLAPP_STATUS_INFO_MAP } from '../../../network/network-types';
import SimpleLineChart from '../../../network/statistics/charts/simple-line-chart/simple-line-chart';
import MarketCapProperty from '../../../network/statistics/properties/market-cap-property/market-cap-property';
import TotalTvlProperty from '../../../network/statistics/properties/total-tvl-property';
import VmProperty from '../../../network/statistics/properties/vm-property/vm-property';
import { useSponsorship } from '../../../sponsorship/sponsorship-context';
import RollappStatusBadge from '../../rollapp-status/badge/rollapp-status-badge';
import RollappSummary from '../../rollapp-summary/rollapp-summary';
import { useRollapps } from '../rollapps-context';
import IroTimeBadge from './iro-time-badge/iro-time-badge';
import './rollapp-cards.scss';

const RollappCards: React.FC = () => {
    const navigate = useNavigate();
    const { loading, hubCurrency } = useNetwork();
    const { filteredRollapps, rollappsAnalyticsState } = useRollapps();
    const { mainAssetMap } = useAsset();
    const { loading: iroLoading } = useIRO();
    const { isMobile, width } = useWindowSize();
    const { distribution, loading: distributionLoading } = useSponsorship();

    const renderSimpleChangeChart = (rollapp: Network, type: 'FDV' | 'TVB'): ReactElement => {
        const data = rollappsAnalyticsState.analyticsMap?.[rollapp.chainId]?.totalSupply;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');
        const isLoading = rollappsAnalyticsState.loadingMap?.[rollapp.chainId] !== false;

        return <>
            {!data && isLoading ? <Spinner size='small' /> : weekData && (
                <SimpleLineChart
                    size={isMobile ? 'small' : 'medium'}
                    historyList={weekData}
                    fetchComparableValues={({ marketCap, tvl }) => type === 'FDV' ? marketCap : tvl}
                />
            )}
            <span className='simple-chart-title'>{type} (7d)</span>
        </>;
    };

    const renderRollappCard = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];

        const sponsored = distribution?.find((record) => record.rollapp?.chainId === rollapp.chainId);
        const sponsoredValue = !distribution && distributionLoading ? <Spinner size='small' /> :
            formatPrice(sponsored?.power || 0, hubCurrency?.displayDenom, undefined, undefined, undefined, 1000);

        const dymRaisedValue = formatPrice(asset?.iroDymRaised || 0, hubCurrency?.displayDenom, undefined, undefined, undefined, 1000);

        const pool = mainAssetMap?.[rollapp.chainId]?.pools?.[0];

        const onAprValueClick = (event: React.MouseEvent) => {
            event.stopPropagation();
            navigate(`/rollapps/${rollapp.chainId}/staking`);
        };

        return (
            <div
                className={classNames('rollapp-card', { glow: rollapp.status === 'IRO' && !asset?.endedIRO })}
                style={!rollapp.status ? {} :
                    { '--glow-color': `var(${ROLLAPP_STATUS_INFO_MAP[rollapp.status].variableColor})` } as CSSProperties}
                key={rollapp.chainId}
                onClick={() => navigate(`/rollapps/${rollapp.chainId}`)}
            >
                <div className='card-header'>
                    <RollappSummary daVisible rollapp={rollapp} showFairLaunch size='big' showStatusIndicator={false} />
                    <div className='rollapp-badges'>
                        <RollappStatusBadge status={rollapp.status} size='small' endedIro={asset?.endedIRO} />
                        {asset && <IroTimeBadge asset={asset} size='small' />}
                    </div>
                    {renderSimpleChangeChart(rollapp, rollapp.tokenless ? 'TVB' : 'FDV')}
                </div>
                <p className='card-tagline ellipsis'>{rollapp.shortDescription || rollapp.description || <>&nbsp;</>}</p>
                <div className='card-content'>
                    <div className='card-properties'>
                        {rollapp.tokenless ? <>
                            <TotalTvlProperty network={rollapp} size='small' showPeriod={false} />
                            <VmProperty network={rollapp} size='small' label={width < 1680 ? 'VM' : undefined} labelClassName='nowrap' />
                            <Property size='small' label={<Icon className='dym-native-icon'><DymensionLogo /></Icon>}>
                                DYM Native
                            </Property>
                        </> : <>
                            <MarketCapProperty
                                showPeriod={false}
                                fullyDiluted
                                showChangeAffect
                                network={rollapp}
                                size='small'
                                label={isMobile ? 'FDV' : undefined}
                            />
                            {rollapp.status === 'IRO' ? <>
                                <Property label='DYM Raised' labelClassName='nowrap' size='small'>
                                    {!asset?.iroProgress && iroLoading ? <Spinner size='small' /> : !asset?.iroDymRaised ?
                                        <span className='secondary-text'>N/A</span> : dymRaisedValue}
                                </Property>
                                <Property label='Launchpad Progress' labelClassName='nowrap' size='small'>
                                    {!asset?.iroProgress && iroLoading ? <Spinner size='small' /> : !asset?.iroDymRaised ?
                                        <span className='secondary-text'>N/A</span> :
                                        <IroProgress className='iro-bonding-curv-progress' asset={asset} />}
                                </Property>
                            </> : <>
                                <Property labelClassName='nowrap' label='Stake APR' size='small'>
                                    {!asset?.stakingApr ? <span className='secondary-text'>N/A</span> : (
                                        <span className='staking-apr-value' onClick={onAprValueClick}>
                                            {roundNumber(asset.stakingApr, 2)}%
                                        </span>
                                    )}
                                </Property>
                                <Property size='small' label='Pool APR' labelClassName='nowrap'>
                                    {!pool ? <span className='secondary-text'>N/A</span> : <PoolApr pool={pool} />}
                                </Property>
                            </>}
                        </>}
                        <Property label='Endorsed' size='small'>{sponsoredValue}</Property>
                    </div>
                </div>
            </div>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!filteredRollapps.length) {
            return <div className='no-data'>No RollApps</div>;
        }
    };

    return (
        <div className='rollapp-cards-container'>
            {filteredRollapps.map(renderRollappCard)}
            {renderBottomBar()}
        </div>

    );
};

export default RollappCards;
