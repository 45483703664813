import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetwork } from '../../../../network/network-context';
import { getBaseAmountWithoutFee } from '../../../../tx/tx-service';
import { ContractMessage } from '../../../../tx/tx-types';
import { AccountNetworkState } from '../../../account-network-state';
import { useClient } from '../../../../client/client-context';
import { ClientError } from '../../../../client/client-error';
import { getFixedDenom, isCoinsEquals } from '../../../../currency/currency-service';
import { CoinsAmount } from '../../../../currency/currency-types';
import { AmountTxValue, useAmountTx } from '../../../../tx/amount-tx/use-amount-tx';
import { useWallet } from '../../../../wallet/wallet-context';
import { WalletError } from '../../../../wallet/wallet-error';
import { convertToBech32Address } from '../../../../wallet/wallet-service';
import { createSendMessage, getSendERC20DataField } from './send-funds-service';

interface SendFundsValue extends Omit<AmountTxValue, 'calculateFee' | 'clearFee' | 'txStateDispatch'> {
    destinationAddress: string,
    setDestinationAddress: (value: string) => void;
    haveEvmContract: boolean;
}

export const useSendFunds = (networkState: AccountNetworkState, initialAsset?: CoinsAmount): SendFundsValue => {
    const { vfcMap } = useNetwork();
    const { handleClientError } = useClient();
    const { handleWalletError } = useWallet();
    const [ destinationAddress, setDestinationAddress ] = useState('');

    const getEvmContractAddress = useCallback((coins?: CoinsAmount): string | undefined => {
        if (coins?.erc20Address) {
            return coins?.erc20Address;
        }
        const denom = coins && getFixedDenom(coins, false);
        if (denom) {
            return vfcMap?.[denom]?.contractAddress;
        }
    }, [ vfcMap ]);

    const amountEvmContractCreator = useCallback((messages: EncodeObject[], coins?: CoinsAmount): undefined | ContractMessage => {
        const address = getEvmContractAddress(coins);
        return !address || !messages.length ? undefined : { address, data: getSendERC20DataField(messages[0].value) };
    }, [ getEvmContractAddress ]);

    const sendMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = networkState.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!coins ||
            !networkState.network ||
            !networkState.address ||
            !networkState.network.bech32Prefix ||
            !balance) {
            return [];
        }
        const baseAmountWithoutFee = getBaseAmountWithoutFee(coins, networkState.balances, fee);
        const toAddress = !destinationAddress.startsWith('0x') ? destinationAddress :
            convertToBech32Address(destinationAddress, networkState.network.bech32Prefix);
        const message = createSendMessage(networkState.address, toAddress || networkState.address, coins, balance, baseAmountWithoutFee);
        return [ message ];
    }, [ destinationAddress, networkState.address, networkState.balances, networkState.network ]);

    const { txState, amountTxState, setCoins, setAmount, calculateFee, clearFee, broadcast } = useAmountTx({
        networkState,
        amountTxMessagesCreator: sendMessagesCreator,
        initialAsset,
        useEvm: Boolean(networkState.network?.type !== 'Hub' && networkState.network?.evm),
        amountEvmContractCreator,
    });

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => {
        if (txState.response) {
            setDestinationAddress('');
        }
    }, [ txState.response ]);

    useEffect(() => {
        if (networkState.network && amountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ amountTxState.coins?.currency, calculateFee, clearFee, networkState.network ]);

    const haveEvmContract = useMemo(
        () => Boolean(getEvmContractAddress(amountTxState.coins)),
        [ amountTxState.coins, getEvmContractAddress ],
    );

    return { destinationAddress, txState, amountTxState, haveEvmContract, setDestinationAddress, setCoins, broadcast, setAmount };
};
