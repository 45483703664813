import React from 'react';
import Icon from '../../../../../shared/components/icon/icon';
import Property, { PropertyProps } from '../../../../../shared/components/property/property';
import { ReactComponent as EthereumLogo } from '../../../../../assets/logos/ethereum-logo2.svg';
import { ReactComponent as CosmWasmLogo } from '../../../../../assets/logos/cosm-wasm-logo.svg';
import { Network } from '../../../network-types';
import './vm-property.scss';

interface VmPropertyProps extends Omit<PropertyProps, 'children' | 'label'> {
    network: Network;
    label?: string;
}

const VmProperty: React.FC<VmPropertyProps> = ({ network, label = 'Virtual Machine', ...otherPropertyProps }) => {
    return (
        <Property label={label} className='vm-property' {...otherPropertyProps}>
            <Icon className='vm-logo' iconColorMode='original'>{network.evmType === 'EVM' ? <EthereumLogo /> : <CosmWasmLogo />}</Icon>
            {network.evmType}
        </Property>
    );
};

export default VmProperty;
