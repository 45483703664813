import classNames from 'classnames';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import Spinner from '../../../../shared/components/spinner/spinner';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import TokenomicsChart, { TokenomicsDataValue } from '../../../network/statistics/charts/tokenomics-chart/tokenomics-chart';
import VestingScheduleChart, { VestingDataValue } from '../../../network/statistics/charts/vesting-schedule-chart/vesting-schedule-chart';
import { useNetworkDashboard } from '../../network-dashboard-context';
import useNetworkTokenomics from './use-network-tokenomics';
import './network-tokenomics.scss';

const TOKENOMICS_CHARTS_CONTAINER_VERTICALLY_WIDTH = 600;

const NetworkTokenomics: React.FC = () => {
    const { network } = useNetworkDashboard();
    const { tokenomics, loading } = useNetworkTokenomics(network);
    const [ showTokenomicsChartsVertically, setShowTokenomicsChartsVertically ] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }
        const { width } = containerRef.current.getBoundingClientRect();
        setShowTokenomicsChartsVertically(width <= TOKENOMICS_CHARTS_CONTAINER_VERTICALLY_WIDTH);
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                setShowTokenomicsChartsVertically(entry.contentRect.width <= TOKENOMICS_CHARTS_CONTAINER_VERTICALLY_WIDTH);
            }
        });
        observer.observe(containerRef.current);
        return () => observer.disconnect();
    }, []);

    const allocationData = useMemo((): TokenomicsDataValue[] => {
        if (!tokenomics) {
            return [];
        }
        return [
            { name: 'Vesting', value: tokenomics.vesting, rgbColor: getCssVariableValue('--red-rgb') },
            { name: 'Community', value: tokenomics.communityPool, rgbColor: getCssVariableValue('--light-blue-rgb') },
            { name: 'Launchpad', value: tokenomics.iro, rgbColor: getCssVariableValue('--orange-rgb') },
            { name: 'Unlocked', value: tokenomics.accounts, rgbColor: getCssVariableValue('--light-green-rgb') },
        ];
    }, [ tokenomics ]);

    const mintDistributionData = useMemo((): TokenomicsDataValue[] => {
        if (!tokenomics) {
            return [];
        }
        return [
            { name: 'Stakers', value: tokenomics.distribution.stakers, rgbColor: getCssVariableValue('--light-blue-rgb') },
            { name: 'Community', value: tokenomics.distribution.communityPool, rgbColor: getCssVariableValue('--dark-blue-rgb') },
            { name: 'Sequencer', value: tokenomics.distribution.sequencer, rgbColor: getCssVariableValue('--light-green-rgb') },
        ];
    }, [ tokenomics ]);

    const vestingScheduleData = useMemo((): VestingDataValue[] => {
        if (!tokenomics) {
            return [];
        }
        return [
            ...(tokenomics.vestingGroups.map((group) => ({
                name: 'Vesting',
                value: group.amount,
                start: Number(group.start.toString().slice(0, 13)),
                end: Number(group.end.toString().slice(0, 13)),
                rgbColor: getCssVariableValue('--red-rgb'),
            }))),
            { name: 'Community Pool', value: tokenomics.communityPool, rgbColor: getCssVariableValue('--light-blue-rgb') },
            { name: 'Launchpad', value: tokenomics.iro, rgbColor: getCssVariableValue('--orange-rgb') },
            { name: 'Unlocked', value: tokenomics.accounts, rgbColor: getCssVariableValue('--light-green-rgb') },
        ];
    }, [ tokenomics ]);

    return (
        <div className={classNames('section network-tokenomics', { 'no-data': !tokenomics })} ref={containerRef}>
            {loading ? <Spinner /> : !tokenomics ? 'No Tokenomics Data' : <>
                <div
                    className={classNames('tokenomics-charts-container', { vertically: showTokenomicsChartsVertically })}
                >
                    <div className='section-part'>
                        <h5 className='part-title secondary-text'>Genesis Allocation</h5>
                        <TokenomicsChart chartData={allocationData} />
                    </div>
                    <div className='section-part'>
                        <h5 className='part-title secondary-text'>Token Mint Distribution</h5>
                        <TokenomicsChart chartData={mintDistributionData} />
                    </div>
                </div>

                {tokenomics.vestingGroups.length ? (
                    <div className='vesting-schedule-chart-container'>
                        <h5 className='part-title secondary-text'>Unlock Schedule</h5>
                        <VestingScheduleChart chartData={vestingScheduleData} network={network} />
                    </div>
                ) : undefined}
            </>}
        </div>
    );
};

export default NetworkTokenomics;
