import { DirectSecp256k1HdWallet, OfflineSigner } from 'cosmjs/packages/proto-signing';
import { onboarding } from '@dydxprotocol/v4-client-js';
import { Network } from '../../network/network-types';
import { WalletError } from '../wallet-error';
import { convertToHexAddress } from '../wallet-service';
import { Wallet, WalletInfoMap, WalletType } from '../wallet-types';
import { CosmosWallet } from './cosmos-wallet';

import { EthereumWallet } from './ethereum-wallet';

export class TemporarySessionWallet implements Wallet {
    private signer?: DirectSecp256k1HdWallet;

    public getWalletType(): WalletType {
        return 'PortalWallet';
    }

    public async getAddress(network: Network): Promise<{ address?: string, hexAddress?: string }> {
        const address = (await this.signer?.getAccounts())?.[0].address;
        if (!address) {
            throw new WalletError('KEY_NOT_FOUND', this.getWalletType(), network);
        }
        const hexAddress = convertToHexAddress(address);
        return { address, hexAddress };
    }

    public async getOfflineSigner(): Promise<OfflineSigner> {
        if (!this.signer) {
            throw new WalletError('NO_OFFLINE_SIGNER', this.getWalletType());
        }
        return this.signer;
    }

    public async init(sourceWallet: Wallet, sourceAddress: string, sourceNetwork: Network, network: Network): Promise<void> {
        const message = `Generate a ${network.chainId} key from ${sourceAddress}`;
        let signature = '';
        const walletType = sourceWallet.getWalletType();
        if (WalletInfoMap[walletType].type === 'evm') {
            const provider = await (sourceWallet as EthereumWallet).getProvider();
            signature = await provider.request({ method: 'personal_sign', params: [ message, convertToHexAddress(sourceAddress) ] });
        } else if (WalletInfoMap[walletType].type === 'cosmos') {
            const provider = await (sourceWallet as CosmosWallet).getProvider();
            signature = (await provider.signArbitrary(sourceNetwork.chainId, sourceAddress, message)).signature;
        }
        const { mnemonic } = onboarding.deriveHDKeyFromEthereumSignature(signature);
        this.signer = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic, { prefix: network.bech32Prefix });
    }

    public clear(): void {}

    public setAccountChangesListener(listener: () => void): void {}

    public async validateWalletInstalled(): Promise<void> {}
}

