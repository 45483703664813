import React, { Children, CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import useWindowSize from '../../hooks/use-window-size';
import './swiper.scss';


const DESKTOP_CARD_WIDTH = 25;
const DESKTOP_SLIDING_CARD_WIDTH = 24;
const TABLET_CARD_WIDTH = 50;
const TABLET_SLIDING_CARD_WIDTH = 45;
const MOBILE_CARD_WIDTH = 100;
const MOBILE_SLIDING_CARD_WIDTH = 80;
const TABLET_WIDTH = 1024;
const MOBILE_WIDTH = 720;

export interface SwiperProps {
    children: ReactNode;
    containerClassName?: string;
    showOneInMobile?: boolean;
    hidePageDotsIfCantSlide?: boolean;
    itemWidth?: number;
    showOtherCardsEdges?: boolean;
    pageIndex?: number;
    onSwipe?: (pageIndex: number, mouse: boolean) => void;
    footer?: ReactNode;
}

const Swiper: React.FC<SwiperProps> = ({
    children,
    itemWidth,
    containerClassName,
    showOneInMobile,
    hidePageDotsIfCantSlide = true,
    showOtherCardsEdges = true,
    pageIndex = 0,
    onSwipe,
    footer
}) => {
    const [ currentPage, setCurrentPage ] = useState(0);
    const { width } = useWindowSize();
    const swiperContainerRef = useRef<HTMLDivElement>(null);

    const childrenCount = useMemo(() => Children.count(children), [ children ]);

    const scrollToPage = useCallback((page: number) => {
        setCurrentPage((currentPage) => {
            if (currentPage !== page) {
                const pageBounds = swiperContainerRef.current?.children[page].getBoundingClientRect();
                const scrollOffset = (pageBounds?.width || 0) * (page - currentPage);
                swiperContainerRef.current?.scrollBy({ left: scrollOffset, behavior: 'smooth' });
            }
            return currentPage;
        });
    }, []);

    useEffect(() => scrollToPage(pageIndex), [ pageIndex, scrollToPage ]);

    const canSlide = useMemo(() => {
        if (itemWidth) {
            return childrenCount * itemWidth > 100;
        }
        if (showOneInMobile && width <= MOBILE_WIDTH) {
            return childrenCount > 100 / MOBILE_CARD_WIDTH;
        }
        if (width <= TABLET_WIDTH) {
            return childrenCount > 100 / TABLET_CARD_WIDTH;
        }
        return childrenCount > 100 / DESKTOP_CARD_WIDTH;
    }, [ childrenCount, itemWidth, showOneInMobile, width ]);

    const fixedItemWidth = useMemo(() => {
        if (itemWidth) {
            return itemWidth;
        }
        if (showOneInMobile && width <= MOBILE_WIDTH) {
            return canSlide ? MOBILE_SLIDING_CARD_WIDTH : (100 / childrenCount);
        }
        if (width <= TABLET_WIDTH) {
            return canSlide ? TABLET_SLIDING_CARD_WIDTH : (100 / childrenCount);
        }
        return canSlide ? DESKTOP_SLIDING_CARD_WIDTH : (100 / childrenCount);
    }, [ canSlide, childrenCount, itemWidth, showOneInMobile, width ]);

    const onSwiperScroll = useCallback((scrollEvent: React.UIEvent): void => {
        const fixedItemWidth = (scrollEvent.target as HTMLDivElement).scrollWidth / childrenCount;
        const page = Math.round((scrollEvent.target as HTMLDivElement).scrollLeft / fixedItemWidth);
        setCurrentPage(page);
        if (currentPage !== page) {
            onSwipe?.(page, page !== pageIndex);
        }
    }, [ childrenCount, currentPage, onSwipe, pageIndex ]);

    return (
        <div
            className={classNames('swiper-container', containerClassName, { 'show-edges': showOtherCardsEdges })}
            style={{ '--item-width': `${fixedItemWidth}%` } as CSSProperties}
        >
            <div className='swiper' onScroll={onSwiperScroll} ref={swiperContainerRef}>{children}</div>
            <ul className={classNames('page-dots', { hide: hidePageDotsIfCantSlide && !canSlide })}>
                {Array.from({ length: childrenCount }).map((_, dotIndex) => (
                    <span
                        onClick={() => scrollToPage(dotIndex)}
                        key={dotIndex}
                        className={classNames('page-dot', { active: currentPage === dotIndex })}
                    />
                ))}
            </ul>
            {footer}
        </div>
    );
};

export default Swiper;
