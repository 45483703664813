import classNames from 'classnames';
import React, { useState } from 'react';
import Alert from '../../../../shared/components/alert/alert';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Link from '../../../../shared/components/link/link';
import Spinner from '../../../../shared/components/spinner/spinner';
import { useAsset } from '../../../asset/asset-context';
import { CoinsAmount } from '../../../currency/currency-types';
import { ReactComponent as DollarIcon } from '../../../../assets/icons/dollar-symbol.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/send.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import IbcTransferDialog from '../../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import BalancesList from '../../balances-list/balances-list';
import { useHubNetworkState } from '../../hub-network-state-context';
import NetworksSelectorDialog from '../networks-selector-dialog/networks-selector-dialog';
import { useAccountConfiguration } from '../use-account-configuration';
import ClaimableTokensDialog from '../../claimable-tokens/claimable-tokens-dialog';
import AccountTotalValue from './account-total-value/account-total-value';
import SendFundsDialog from './send-funds-dialog/send-funds-dialog';
import { UseAccountBalancesValue } from './use-account-balances';
import './account-balances.scss';

interface AccountBalancesProps extends UseAccountBalancesValue {
    className?: string;
    onBalanceClick?: (balance: CoinsAmount) => void;
    optionalNetworks: string[];
}

const AccountBalances: React.FC<AccountBalancesProps> = ({
    className,
    optionalNetworks,
    balances,
    loading,
    unavailableEndpointRollapps,
    onBalanceClick,
}) => {
    const { hubAsset } = useAsset();
    const hubNetworkState = useHubNetworkState();
    const [ sendFundsDialogOpen, setSendFundsDialogOpen ] = useState(false);
    const [ transferDialogOpen, setTransferDialogOpen ] = useState(false);
    const [ claimableTokensDialogOpen, setClaimableTokensDialogOpen ] = useState(false);
    const [ networksSelectorDialogVisible, setNetworksSelectorDialogVisible ] = useState(false);
    const [ depositCreditPaymentDialogOpen, setDepositCreditPaymentDialogOpen ] = useState(false);
    const { configuration, setVisibleNetworks } = useAccountConfiguration();

    return <>
        <AccountTotalValue balances={balances} loading={loading} />
        <div className='account-menu-actions'>
            <Button
                className='account-menu-action'
                size='small'
                disabled={!process.env.REACT_APP_KADO_URL}
                onClick={() => setDepositCreditPaymentDialogOpen(true)}
                trackEvent='account_menu_buy_action_click'
            >
                <DollarIcon />&nbsp;Buy
            </Button>
            <Button
                className='account-menu-action'
                size='small'
                onClick={() => setTransferDialogOpen(true)}
                trackEvent='account_menu_transfer_action_click'
            >
                <DownloadIcon />&nbsp;Transfer
            </Button>
            <Button
                className='account-menu-action'
                size='small'
                onClick={() => setSendFundsDialogOpen(true)}
                trackEvent='account_menu_send_action_click'
            >
                <SendIcon />&nbsp;Send
            </Button>
        </div>
        <BalancesList
            balances={balances?.filter((balance) => balance.amount) || []}
            loading={loading}
            onBalanceClick={onBalanceClick}
            className={classNames(className, 'balances-list-container')}
            sortByValue
            header={hubNetworkState.pendingTransfers?.length ? (
                <Link className='claimable-transfers-link' onClick={() => setClaimableTokensDialogOpen(true)}>
                    {hubNetworkState.pendingTransfers.length} pending transfer(s)
                </Link>
            ) : undefined}
            suffix={unavailableEndpointRollapps.length ? (
                <Alert className='endpoints-warning' type='warning'>
                    The following networks might not represent all tokens due to endpoint limitations:
                    <ul className='list'>
                        {unavailableEndpointRollapps.map((network) => <li key={network.chainId}>{network.chainName}</li>)}
                    </ul>
                </Alert>
            ) : undefined}
        />

        <Link
            className='visible-networks-action'
            onClick={() => setNetworksSelectorDialogVisible(true)}
            trackEvent='account_menu_visible_rollapps_click'
        >
            Visible RollApps
        </Link>

        {sendFundsDialogOpen && <SendFundsDialog initialCoins={hubAsset} onRequestClose={() => setSendFundsDialogOpen(false)} />}

        {transferDialogOpen &&
            <IbcTransferDialog className='network-deposit-withdraw-dialog' onRequestClose={() => setTransferDialogOpen(false)} />}

        {claimableTokensDialogOpen && (
            <ClaimableTokensDialog
                transfers={hubNetworkState.pendingTransfers}
                loading={hubNetworkState.pendingTransfersLoading}
                onRequestClose={() => setClaimableTokensDialogOpen(false)}
            />
        )}

        {networksSelectorDialogVisible && (
            <NetworksSelectorDialog
                initialNetworks={configuration?.visibleNetworks}
                onConfirm={setVisibleNetworks}
                optionalNetworks={optionalNetworks}
                onRequestClose={() => setNetworksSelectorDialogVisible(false)}
            />
        )}

        {depositCreditPaymentDialogOpen && (
            <Dialog className='credit-payment-dialog' closable onRequestClose={() => setDepositCreditPaymentDialogOpen(false)}>
                <DialogTitle>Buy DYM</DialogTitle>
                <DialogContent className='dialog-content'>
                    <Spinner className='dialog-spinner' />

                    <iframe
                        title='credit payment widget'
                        src={`${process.env.REACT_APP_KADO_URL}${hubNetworkState.address}`}
                        className='credit-payment-widget'
                        allow='clipboard-write; payment; accelerometer; gyroscope; camera; geolocation; autoplay; fullscreen;'
                    />
                </DialogContent>
            </Dialog>
        )}
    </>;
};

export default AccountBalances;
