import React from 'react';
import Property, { PropertyProps } from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAsset } from '../../../asset/asset-context';
import { getMaxDenomAmount } from '../../../currency/currency-service';
import { Network } from '../../network-types';

interface TotalTvlPropertyProps {
    network: Network;
    size?: PropertyProps['size'];
    showPeriod?: boolean;
}

const TotalTvlProperty: React.FC<TotalTvlPropertyProps> = ({ network, size,showPeriod }) => {
    const { vsAsset } = useAsset();

    return (
        <Property
            label={network.type === 'Hub' ? 'TVL' : 'TVB'}
            na={network.status === 'IRO' || network.totalSupply?.value.tvl === undefined}
            size={size}
            info={network.type === 'Hub' ? `IBC tokens + DYM in liquidity pools` : 'Total amount of IBC tokens bridged to the RollApp'}
        >
            <StatisticsChange
                period='day'
                size={size}
                showPeriod={showPeriod}
                currentValue={network.totalSupply?.value.tvl || 0}
                previousValue={network.totalSupply?.previousDayValue?.tvl || 0}
            >
                {formatPrice(getMaxDenomAmount(network.totalSupply?.value.tvl || 0, vsAsset?.currency))}
            </StatisticsChange>
        </Property>
    );
};

export default TotalTvlProperty;
