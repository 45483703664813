import React, { ReactElement, useState } from 'react';
import Addresses from '../../../../shared/components/addresses/addresses';
import Button from '../../../../shared/components/button/button';
import Icon from '../../../../shared/components/icon/icon';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../assets/icons/external-link.svg';
import Link from '../../../../shared/components/link/link';
import useCopyToClipboard from '../../../../shared/hooks/use-copy-to-clipboard';
import { getTimeLeftText, getTimeOffset } from '../../../../shared/utils/date-utils';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useAsset } from '../../../asset/asset-context';
import { fetchPathAndDenom, getCurrencyLogoPath, isCoinsEquals } from '../../../currency/currency-service';
import DisplayNameWithIro from '../../../currency/display-name-with-iro/display-name-with-iro';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { convertToHexAddress } from '../../../wallet/wallet-service';
import EibcFeeEditModal from '../eibc-fee-edit-modal/eibc-fee-edit-modal';
import IbcStatusBadge from '../ibc-status-badge/ibc-status-badge';
import { getStatusTooltipInfo } from '../ibc-status-service';
import { IbcTransferDetails } from '../ibc-status-types';
import './ibc-transfer-status.scss';

interface IbcTransferStatusProps {
    transfer: IbcTransferDetails;
}

const IbcTransferStatus: React.FC<IbcTransferStatusProps> = ({ transfer }) => {
    const { hubNetwork, getNetwork } = useNetwork();
    const hubNetworkState = useHubNetworkState();
    const { assets, mainAssetMap, getAssetLink } = useAsset();
    const copyToClipboard = useCopyToClipboard();
    const [ eibcFeeEditModalVisible, setEibcFeeEditModalVisible ] = useState(false);

    const renderHashProperty = (): ReactElement => {
        const baseExploreTxUrl = transfer.status === 'Initiated' ? transfer.sourceNetwork?.exploreTxUrl : hubNetwork?.exploreTxUrl;
        const exploreTxUrl = baseExploreTxUrl ? baseExploreTxUrl + transfer.hash : undefined;

        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    Transaction Hash

                    <InfoIndicator indicatorSize='small'>
                        A transaction hash for the transfer status <b>initiated</b> is associated with source chain.<br />
                        ֿOnce the transfer reaches the transaction has will be associated with <b>Dymension Hub.</b>
                    </InfoIndicator>
                </span>
                <span className='property-value external-link-property' onClick={() => window.open(exploreTxUrl, '_blank')}>
                    <span className='ellipsis'>{transfer.hash}</span>
                    {exploreTxUrl && (
                        <Icon className='external-link-icon'>
                            <ExternalLinkIcon />
                        </Icon>
                    )}
                    <Button
                        size='small'
                        buttonType='icon'
                        onClick={(event) => {
                            event.stopPropagation();
                            copyToClipboard(transfer.hash, 'Transaction Hash');
                        }}
                    >
                        <CopyIcon />
                    </Button>
                </span>
            </div>
        );
    };

    const renderStatusProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>Status</span>
                <span className='property-value'>
                    <IbcStatusBadge
                        transfer={transfer}
                        showBaseStatus
                        getTooltipInfo={() =>
                            transfer.status === 'EibcPending' && transfer.claimable ? 'Can be claimed from the account menu' : ''}
                    />
                </span>
            </div>
        );
    };

    const renderClaimableProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>Claimable</span>
                <span className='property-value'>{transfer.claimable ? 'Yes' : 'No'}</span>
            </div>
        );
    };

    const renderBlockProperty = (): ReactElement => {
        const exploreBlockUrl = `${hubNetwork?.explorerUrl}block/${transfer.blockHeight}`;

        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    Block
                    <InfoIndicator indicatorSize='small'>The number of the block in which the transaction is recorded in <b>Dymension Hub</b></InfoIndicator>
                </span>
                {transfer.status === 'Initiated' ? (
                    <span className='secondary-text'>The transaction has not yet arrived to Dymension</span>
                ) : (
                    <span className='property-value external-link-property' onClick={() => window.open(exploreBlockUrl, '_blank')}>
                        {transfer.blockHeight}
                        {exploreBlockUrl && (
                            <Icon className='external-link-icon'>
                                <ExternalLinkIcon />
                            </Icon>
                        )}
                    </span>
                )}
            </div>
        );
    };

    const renderTimeProperty = (): ReactElement => {
        const timeOffset = getTimeOffset(transfer.time);
        return (
            <div className='transfer-property date-property'>
                <span className='property-label'>
                    Time<InfoIndicator indicatorSize='small'>The transaction's creation timestamp</InfoIndicator>
                </span>
                <span className='property-value'>
                    {new Date(transfer.time).toUTCString().replace('GMT', 'UTC')}
                    <span className='secondary-text time-left'>
                        ({getTimeLeftText(timeOffset, 'single', true)} ago)
                    </span>
                </span>
            </div>
        );
    };

    const renderNetworkAddressProperty = (direction: 'Source' | 'Destination'): ReactElement => {
        const network = direction === 'Source' ? transfer.sourceNetwork : transfer.destinationNetwork;
        const address = direction === 'Source' ? transfer.sender : transfer.receiver;
        const hexAddress = direction === 'Source' ? transfer.hexSender : transfer.hexReceiver;
        const channel = (direction === 'Source' && transfer.type === 'In') ? transfer.destinationChannel :
            (direction === 'Destination' && transfer.type === 'Out') ? transfer.sourceChannel : '';
        const channelLabel = channel.charAt(0).toUpperCase() + channel.slice(1).replace('-', ' ');
        const addresses = [ address ];
        const networkAsset = network && mainAssetMap?.[network.chainId];
        const networkUrl = network?.type === 'Hub' ? '/dymension/dashboard' :
            network?.type === 'RollApp' ? `/rollapps/${network?.chainId}/dashboard` : networkAsset ? getAssetLink(networkAsset) : '';
        if (network?.type === 'EVM' || network?.type === 'RollApp' || network?.evm) {
            try {
                addresses.unshift(hexAddress || convertToHexAddress(address));
            } catch {}
        }
        return (
            <div className='transfer-property network-address-property'>
                <span className='property-label'>{direction}</span>
                <span className='property-value'>
                    {network?.chainName && <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />}
                    <span className='name-address'>
                        {!network?.chainName ? channelLabel :
                            <Link className='internal-link' inline url={networkUrl}>{network?.chainName}</Link>}
                        <span className='address'>(<Addresses canCopy addresses={addresses} />)</span>
                    </span>
                </span>
            </div>
        );
    };

    const renderAmountProperty = (): ReactElement => {
        const assetNetwork = transfer.coins ? getNetwork(transfer.coins.networkId) : transfer.sourceNetwork;
        const asset = assets?.find((asset) => transfer.coins && isCoinsEquals(asset, transfer.coins));
        const assetUrl = !asset ? '' : getAssetLink(asset);

        return (
            <div className='transfer-property'>
                <span className='property-label'>Amount</span>
                <span className='property-value'>
                    {assetNetwork && transfer.coins &&
                        <img className='asset-logo' src={getCurrencyLogoPath(transfer.coins.currency, assetNetwork)} alt='asset logo' />}
                    {!transfer.coins ?
                        formatPrice(transfer.amount, fetchPathAndDenom(transfer.denom).baseDenom, { notation: 'compact' }) : (
                            <Link inline url={assetUrl} className='internal-link'>
                                {formatPrice(transfer.coins.amount, '')}&nbsp;<DisplayNameWithIro coins={transfer.coins} />
                            </Link>
                        )}
                </span>
            </div>
        );
    };

    const renderEibcOrderProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    eIBC Order ID
                    <InfoIndicator indicatorSize='small'>The onchain ID associated with the eIBC order</InfoIndicator>
                </span>
                <span className='property-value'>
                    {!transfer.eibcOrderId ? <span className='secondary-text'>No eIBC involved</span> : <>
                        <span className='ellipsis'>{transfer.eibcOrderId}</span>
                        <Button
                            size='small'
                            buttonType='icon'
                            onClick={() => copyToClipboard(transfer.eibcOrderId || '', 'eIBC order ID')}
                        >
                            <CopyIcon />
                        </Button>
                    </>}
                </span>
            </div>
        );
    };

    const renderEibcFulFillerAddressProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    eIBC FulFiller Address
                    <InfoIndicator indicatorSize='small'>
                        The address which fulfilled this eIBC order i.e provided the liquidity facilitating the withdrawal of funds from the RollApp
                    </InfoIndicator>
                </span>
                <span className='property-value'>
                    {transfer.fulfillerAddress && (
                        <Addresses
                            canCopy
                            addresses={[ convertToHexAddress(transfer.fulfillerAddress), transfer.fulfillerAddress ]}
                            display='full'
                            className='fulfiller-address-property-value'
                        />
                    )}
                </span>
            </div>
        );
    };


    const renderEibcFeeProperty = (): ReactElement => {
        const assetNetwork = transfer.eibcFeeCoins ? getNetwork(transfer.eibcFeeCoins.networkId) : hubNetwork;

        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    eIBC tip
                    <InfoIndicator indicatorSize='small'>
                        Offering a higher fast withdrawal fee increases the likelihood of fulfillers prioritizing your transfer for faster processing.
                    </InfoIndicator>
                </span>
                <span className='property-value'>
                    {assetNetwork && transfer.eibcFeeCoins && <>
                        <img
                            className='asset-logo'
                            src={getCurrencyLogoPath(transfer.eibcFeeCoins.currency, assetNetwork)}
                            alt='asset logo'
                        />
                        {formatPrice(transfer.eibcFeeCoins.amount, '')}&nbsp;<DisplayNameWithIro coins={transfer.eibcFeeCoins} />
                    </>}
                    {(transfer.status === 'EibcPending' && transfer.hexSender === hubNetworkState.hexAddress) && (
                        <Button onClick={() => setEibcFeeEditModalVisible(true)} buttonType='icon'><EditIcon /></Button>
                    )}
                    {eibcFeeEditModalVisible &&
                        <EibcFeeEditModal transfer={transfer} onRequestClose={() => setEibcFeeEditModalVisible(false)} />}
                </span>
            </div>
        );
    };

    const renderSystemStatusProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    System Status
                    <InfoIndicator indicatorSize='small'>The original system status with precise transport information.</InfoIndicator>
                </span>
                <span className='property-value'>
                    <IbcStatusBadge transfer={transfer} getTooltipInfo={() => getStatusTooltipInfo(transfer)} />
                </span>
            </div>
        );
    };

    return (
        <div className='section ibc-transfer-status'>
            {renderHashProperty()}
            {renderStatusProperty()}
            {(transfer.status === 'EibcPending' || transfer.status === 'Refunding') && renderClaimableProperty()}
            {renderBlockProperty()}
            {renderTimeProperty()}
            <span className='border' />
            {renderNetworkAddressProperty('Source')}
            {renderNetworkAddressProperty('Destination')}
            {renderAmountProperty()}
            <span className='border' />
            {renderEibcOrderProperty()}
            {transfer.fulfillerAddress && renderEibcFulFillerAddressProperty()}
            {transfer.eibcFeeCoins && renderEibcFeeProperty()}
            {renderSystemStatusProperty()}
        </div>
    );
};

export default IbcTransferStatus;
