import { Decimal } from 'cosmjs/packages/math';
import { MsgBuyEncodeObject, MsgSellEncodeObject } from 'cosmjs/packages/stargate';
import { MsgBuyExactSpendEncodeObject } from 'cosmjs/packages/stargate/build/modules';
import { Plan } from '../../client/station-clients/dymension/generated/iro/iro';
import { getClosedAmount } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';

export const createIroBuyMessage = (
    buyer: string,
    plan: Plan,
    coins: CoinsAmount,
    inverseCoins: CoinsAmount,
    maxSpendAmount?: bigint,
): MsgBuyEncodeObject => {
    let amount = BigInt(Decimal.fromUserInput(coins.amount.toFixed(coins.currency.decimals), coins.currency.decimals).atomics);
    const amountToBuy = BigInt(plan.totalAllocation?.amount || '0') - BigInt(plan.soldAmt);
    if (amountToBuy < amount) {
        amount = amountToBuy;
    }
    const maxCostAmount = getClosedAmount(
        Math.round(inverseCoins.amount * (10 ** inverseCoins.currency.decimals)), inverseCoins.currency.decimals, maxSpendAmount);
    return {
        typeUrl: '/dymensionxyz.dymension.iro.MsgBuy',
        value: { buyer, amount: amount.toString(), maxCostAmount: maxCostAmount.toString(), planId: plan.id.toString() },
    };
};

export const createIroBuyExactSpendMessage = (
    buyer: string,
    plan: Plan,
    coins: CoinsAmount,
    inverseCoins: CoinsAmount,
    maxSpendAmount?: bigint,
): MsgBuyExactSpendEncodeObject => {
    let amount = BigInt(Decimal.fromUserInput(coins.amount.toFixed(coins.currency.decimals), coins.currency.decimals).atomics);
    const maxAmountToBuy = BigInt(plan.totalAllocation?.amount || '0') - BigInt(plan.soldAmt);
    if (amount > maxAmountToBuy) {
        amount = maxAmountToBuy;
    }
    if (amount < BigInt(1)) {
        amount = BigInt(1);
    }
    const spend = getClosedAmount(
        Math.round(inverseCoins.amount * (10 ** inverseCoins.currency.decimals)), inverseCoins.currency.decimals, maxSpendAmount);
    return {
        typeUrl: '/dymensionxyz.dymension.iro.MsgBuyExactSpend',
        value: { buyer, planId: plan.id.toString(), spend: spend.toString(), minOutTokensAmount: amount.toString() },
    };
};

export const createIroSellMessage = (
    seller: string,
    plan: Plan,
    coins: CoinsAmount,
    inverseCoins: CoinsAmount,
    maxAmount?: bigint,
): MsgSellEncodeObject => {
    const amount = getClosedAmount(Math.round(coins.amount * (10 ** coins.currency.decimals)), coins.currency.decimals, maxAmount);
    const minIncomeAmount = BigInt(Math.max(1, Math.round(inverseCoins.amount * (10 ** inverseCoins.currency.decimals))));
    return {
        typeUrl: '/dymensionxyz.dymension.iro.MsgSell',
        value: { seller, amount: amount.toString(), minIncomeAmount: minIncomeAmount.toString(), planId: plan.id.toString() },
    };
};


