import { useEffect, useState } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { useClient } from '../../client/client-context';
import { useIRO } from '../../iro/iro-context';
import { Asset } from '../asset-types';
import { Holder } from './holder-types';
import { getHolders } from './holder.service';

interface UseHolderListValue {
    holders?: Holder[];
    loading: boolean;
}

export const useHolderList = (asset: Asset): UseHolderListValue => {
    const { handleClientError } = useClient();
    const { getIroPlan } = useIRO();
    const [ holders, setHolders ] = useState<Holder[]>();
    const [ loading, setLoading ] = useState(true);
    const cancelAndSetHoldersPromise = useCancelablePromise<Holder[]>();

    useEffect(() => {
        if (holders?.length) {
            return;
        }
        const allocation = !asset.iroDenom ?
            (asset.network.totalSupply?.value.amount || 0) / (10 ** 18) :
            (Number(getIroPlan(asset.networkId)?.totalAllocation?.amount) || 0) / (10 ** 18);
        cancelAndSetHoldersPromise((signal) => getHolders(asset, allocation, signal))
            .then((holders) => {
                if (!asset.iroDenom) {
                    const amount = allocation - holders.reduce((current, holder) => current + holder.amount, 0);
                    if (amount > 0) {
                        const weight = amount * holders[0].weight / holders[0].amount;
                        holders.push({ amount, weight, hexAddress: '' });
                    }
                }
                holders.sort((holder1, holder2) => holder2.amount - holder1.amount);
                setHolders(holders);
            })
            .catch((error) => handleClientError(error))
            .finally(() => setLoading(false));
    }, [ asset, cancelAndSetHoldersPromise, getIroPlan, handleClientError, holders?.length ]);

    return { holders, loading };
};
