import React, { useState } from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import MultipleNetworkSelector from '../../../network/multiple-network-selector/multiple-network-selector';
import './networks-selector-dialog.scss';

interface NetworksSelectorDialogProps extends DialogProps {
    initialNetworks?: string[];
    optionalNetworks: string[];
    onConfirm: (networks: string[]) => void;
}

const NetworksSelectorDialog: React.FC<NetworksSelectorDialogProps> = ({
    initialNetworks,
    optionalNetworks,
    onConfirm,
    ...otherDialogProps
}) => {
    const [ selectedNetworks, setSelectedNetworks ] = useState(initialNetworks || []);

    return (
        <Dialog className='networks-selector-dialog' {...otherDialogProps}>
            <DialogTitle>Visible RollApps</DialogTitle>
            <DialogContent className='dialog-content'>
                <MultipleNetworkSelector
                    optionsModalClassName='options-modal'
                    multiselect
                    visibleAlways
                    showOptionsOnly
                    value={selectedNetworks}
                    onSelect={(value) => setSelectedNetworks(value as string[])}
                    isNetworkVisible={(network) => !optionalNetworks || optionalNetworks.includes(network.chainId)}
                    isNetworkSelectable={(network) => network.type === 'Hub'}
                />
            </DialogContent>
            <DialogAction secondary close>Cancel</DialogAction>
            <DialogAction
                primary
                close
                onClick={() => onConfirm(selectedNetworks)}
                trackEvent='visible_rollapps_dialog_confirm_click'
            >
                Confirm
            </DialogAction>
        </Dialog>
    );
};

export default NetworksSelectorDialog;
