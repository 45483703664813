import React, { useMemo } from 'react';
import Alert from '../../../../../../../shared/components/alert/alert';
import Button from '../../../../../../../shared/components/button/button';
import { getTimeLeftText, millisecondsToTime } from '../../../../../../../shared/utils/date-utils';
import { formatNumber, roundNumber } from '../../../../../../../shared/utils/number-utils';
import { useCreateRollapp } from '../../../create-rollapp-context';
import './iro-allocation-info.scss';
import { DEFAULT_DENOM_EXPONENT, MAX_IRO_ALLOCATION, MIN_IRO_ALLOCATION } from '../../../types';

const IroAllocationInfo: React.FC = () => {
    const { iro, haveIRO, rollapp, supplyAllocation, supplyAllocationAmounts, token, setVisibleStep } = useCreateRollapp();

    const isIroValid = useMemo(
        () => (supplyAllocationAmounts.iro || 0) <= MAX_IRO_ALLOCATION * (10 ** DEFAULT_DENOM_EXPONENT) &&
            (supplyAllocationAmounts.iro || 0) > MIN_IRO_ALLOCATION * (10 ** DEFAULT_DENOM_EXPONENT),
        [ supplyAllocationAmounts.iro ],
    );

    return (
        <Alert className='iro-allocation-info' type={!haveIRO || isIroValid ? 'info' : 'error'}>
            {!haveIRO ? <>
                No allocation has been set for the Launchpad. This step will be skipped. To initiate the Launchpad,
                please return to <b>Step 2</b> and update the allocation accordingly
            </> : <>
                The Launchpad allocation is set at <b>{roundNumber(supplyAllocation.iro || 0, 4)}%</b>,
                which equals <b>{supplyAllocationAmounts.displayIro} {token.name}</b>
            </>}
            &nbsp;&nbsp;
            {!rollapp.sealed &&
                <Button size='small' className='update-tokenomics-button' onClick={() => setVisibleStep('Tokenomics')}>Update</Button>}
            {haveIRO && (
                <p className='iro-description'>
                    {!isIroValid ? <>
                        Launchpad allocation must be between <b>{formatNumber(MIN_IRO_ALLOCATION)}</b> and <b>{formatNumber(MAX_IRO_ALLOCATION)} {token.name}</b>.
                    </> : <>
                        Following RollApp launch, any unsold Launchpad tokens will be distributed as incentives to token liquidity providers (LPs),
                        beginning exactly {getTimeLeftText(millisecondsToTime(iro.startTimeAfterSettlement), undefined, true)} post-launch
                        and lasting for {getTimeLeftText(millisecondsToTime(iro.incentivesDistributionTime), undefined, true)}.
                    </>}
                </p>
            )}
        </Alert>
    );
};

export default IroAllocationInfo;
