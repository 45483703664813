export const CURRENCY_TYPES = [ 'main', 'staking', 'fee', 'regular' ] as const;
export type CurrencyType = typeof CURRENCY_TYPES[number];

export const CUSTOM_BASE_DENOM_PREFIX_REGEX = /^(erc20|factory)\//;

export interface Currency {
    displayDenom: string,
    baseDenom: string,
    bridgeDenom?: string;
    decimals: number,
    logo?: string;
    ibcRepresentation?: string;
    rollappIbcRepresentation?: string;
    networkId?: string;
    cctp?: boolean;
    common?: boolean;
    type: CurrencyType;
    solanaMintAccount?: string;
}

export interface CoinsAmount {
    amount: number;
    baseAmount?: bigint;
    currency: Currency;
    erc20Address?: string;
    iroDenom?: string;
    networkId: string;
    containerNetworkId?: string;
    ibc?: { representation: string; path: string };
}

export interface NetworkDenom {
    networkId: string;
    ibcNetworkId: string;
    denom: string;
    baseDenom: string;
    path: string;
}
