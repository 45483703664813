import React, { useMemo } from 'react';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { useNetwork } from '../../../../network/network-context';

const TotalIbcTransfersProperty: React.FC = () => {
    const { hubNetwork } = useNetwork();

    const currentValue = useMemo(() => {
        const ibcTransfers = hubNetwork?.ibcTransfers;
        const currentValue = (ibcTransfers?.value.totalIn || 0) + (ibcTransfers?.value.totalOut || 0);
        const previousValue = (ibcTransfers?.previousDayValue?.totalIn || 0) + (ibcTransfers?.previousDayValue?.totalOut || 0);
        return currentValue - previousValue;
    }, [ hubNetwork?.ibcTransfers ]);

    const previousValue = useMemo(() => {
        const ibcTransfers = hubNetwork?.ibcTransfers;
        const currentValue = (ibcTransfers?.previousDayValue?.totalIn || 0) + (ibcTransfers?.previousDayValue?.totalOut || 0);
        const previousValue = (ibcTransfers?.previousTwoDaysValue?.totalIn || 0) + (ibcTransfers?.previousTwoDaysValue?.totalOut || 0);
        return currentValue - previousValue;
    }, [ hubNetwork?.ibcTransfers ]);

    return (
        <div className='statistics-property'>
            <span className='property-label'>Transfers (24h):</span>
            <StatisticsChange period='day' size='small' currentValue={currentValue} previousValue={previousValue}>
                {formatNumber(currentValue)}
            </StatisticsChange>
        </div>
    );
};

export default TotalIbcTransfersProperty;


