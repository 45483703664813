import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Decimal } from 'cosmjs/packages/math';
import { DirectSecp256k1HdWallet, EncodeObject, OfflineSigner } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useState } from 'react';
import { AccountNetworkState } from '../../modules/account/account-network-state';
import { getMainCurrency } from '../../modules/currency/currency-service';
import { CoinsAmount } from '../../modules/currency/currency-types';
import { createGrantAllowanceMessages, createGrantAuthorizationMessages } from '../../modules/quick-auth/quick-auth-service';
import { DeliveryTxCode } from '../../modules/tx/tx-types';
import { useTx } from '../../modules/tx/use-tx';
import { timeToMilliseconds } from '../utils/date-utils';

interface UseAuthZValue {
    connect: () => void;
    connecting: boolean;
    error?: any;
    signer?: OfflineSigner;
}

interface TxParams {
    address: string;
    expiration: number;
    feeGrant?: boolean;
    signer: OfflineSigner;
}

const DEFAULT_FEE_GRANT_SPEND_LIMIT = '10';
const SESSION_DURATION = timeToMilliseconds({ seconds: 300 });

export const useAuthZ = (networkState: AccountNetworkState, messageTypes: string[]): UseAuthZValue => {
    const [ address, setAddress ] = useState<string | undefined>();
    const [ connecting, setConnecting ] = useState(false);
    const [ signer, setSigner ] = useState<OfflineSigner>();

    useEffect(() => {
        if (networkState.address && networkState.address !== address) {
            setAddress(networkState.address);
        }
    }, [ address, networkState.address, setAddress ]);

    const grantMessagesCreator = useCallback((fee?: CoinsAmount, params?: TxParams): EncodeObject[] => {
        if (!networkState.network || !networkState.address || !params?.address || !params.expiration) {
            return [];
        }
        const currency = getMainCurrency(networkState.network);
        const spendLimit: Coin[] = [
            { amount: Decimal.fromUserInput(DEFAULT_FEE_GRANT_SPEND_LIMIT, currency.decimals).atomics, denom: currency.baseDenom },
        ];
        return params.feeGrant ?
            [ createGrantAllowanceMessages(networkState.address, params.address, params.expiration, spendLimit) ] :
            createGrantAuthorizationMessages(networkState.network, networkState.address, params.address, params.expiration, messageTypes);
    }, [ networkState.network, networkState.address, messageTypes ]);

    const { txState, broadcast } = useTx({
        networkState,
        txMessagesCreator: grantMessagesCreator,
    });

    useEffect(() => {
        if (!txState.response) {
            return;
        }
        if (txState.response.deliveryTxCode !== DeliveryTxCode.SUCCESS) {
            setConnecting(false);
            return;
        }
        const { expiration, signer, address, feeGrant } = txState.response.params as TxParams || {};
        if (!feeGrant && signer) {
            broadcast(undefined, { signer, address, expiration, feeGrant: true }, true).then();
            return;
        }
        setConnecting(false);
        if (signer && expiration) {
            setSigner(signer);
            setTimeout(() => setSigner(undefined), Math.max(0, expiration - Date.now()));
        }
    }, [ broadcast, txState.response ]);

    const connect = useCallback(() => {
        if (!networkState.network) {
            return;
        }
        setConnecting(true);
        const expiration = Date.now() + SESSION_DURATION;
        DirectSecp256k1HdWallet.generate(12, { prefix: networkState.network.bech32Prefix })
            .then(async (signer) => {
                const address = (await signer.getAccounts())?.[0]?.address;
                return broadcast(undefined, { signer, address, expiration }, true);
            })
            .catch(() => setConnecting(false));
    }, [ broadcast, networkState.network ]);

    return { connect, connecting, signer, error: txState.error };
};
