import React, { useCallback, useMemo } from 'react';
import { useSnackbar } from '../../../../../../../shared/components/snackbar/snackbar-context';
import { useCreateRollapp } from '../../../create-rollapp-context';
import Input from '../../../../../../../shared/components/form-controls/input/input';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import { SupplyAllocation } from '../../../types';

const SupplyAllocationSection: React.FC = () => {
    const { showWarningMessage } = useSnackbar();
    const {
        rollapp,
        supplyAllocation,
        currentStep,
        accounts,
        supplyAllocationAmounts,
        setSupplyAllocation,
        setShouldGenerateGenesis,
    } = useCreateRollapp();

    const onSupplyAllocationChange = useCallback((
        type: keyof SupplyAllocation,
        value: string,
        previousValue: string,
    ) => {
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        if (!value) {
            setSupplyAllocation({ ...supplyAllocation, [type]: undefined });
            return '';
        }
        const amountPattern = new RegExp('^[0-9]*(\\.[0-9]{0,4})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        const amount = Number(value);
        const maxValue = 100 - ((type === 'iro' ? supplyAllocation.communityPool : supplyAllocation.iro) || 0);
        setSupplyAllocation({ ...supplyAllocation, [type]: Math.min(maxValue, amount) });
        setShouldGenerateGenesis(true);
        return amount > maxValue ? maxValue.toString() : undefined;
    }, [ supplyAllocation, setShouldGenerateGenesis, setSupplyAllocation ]);

    const onSupplyTypeFinished = useCallback(() => {
        if (currentStep !== 'Tokenomics' || accounts.length) {
            showWarningMessage(`Make sure the declared accounts supply match the amount allocated on your accounts`);
        }
    }, [ accounts.length, currentStep, showWarningMessage ]);

    const accountsAllocation = useMemo(
        () => 100 - (supplyAllocation.iro || 0) - (supplyAllocation.communityPool || 0),
        [ supplyAllocation.communityPool, supplyAllocation.iro ],
    );

    return (
        <div className='section'>
            <h5 className='section-header'>
                Supply Allocation
                <InfoIndicator indicatorSize='small'>
                    Allocate your supply by percentage to genesis accounts, the community pool, or the Launchpad pre-sale (100% towards Launchpad = 'Fair Launch').
                </InfoIndicator>
            </h5>

            <div className='controls-row'>
                <div className='control-container'>
                    <div className='control-label-container'>
                        <label>Launchpad</label>
                        <InfoIndicator indicatorSize='small'>
                            Launchpad is a pre-launch sale of RollApp tokens. DYM raised is used to create a token
                            liquidity pool when the RollApp launches. Select 0 if you do not want to include an Launchpad for your RollApp.
                        </InfoIndicator>
                    </div>
                    <Input
                        onTypeFinish={onSupplyTypeFinished}
                        disabled={rollapp.sealed}
                        value={supplyAllocation.iro}
                        onValueChange={(value, previousValue) => onSupplyAllocationChange('iro', value, previousValue)}
                        suffix='%'
                    />
                </div>

                <div className='control-container'>
                    <div className='control-label-container'>
                        <label>Community Pool</label>
                        <InfoIndicator indicatorSize='small'>
                            Community Pool, your onchain DAO budget, can be accessed only with the vote approval of majority of your RollApp stakers.
                        </InfoIndicator>
                    </div>
                    <Input
                        disabled={rollapp.sealed}
                        value={supplyAllocation.communityPool}
                        onValueChange={(value, previousValue) => onSupplyAllocationChange('communityPool', value, previousValue)}
                        onTypeFinish={onSupplyTypeFinished}
                        suffix='%'
                    />
                </div>

                <div className='control-container'>
                    <div className='control-label-container'><label>Accounts</label></div>
                    <Input disabled value={accountsAllocation} suffix='%' />
                </div>
            </div>

            <p className='secondary-text'>
                Token supply will be distributed:&nbsp;
                <span className='primary-text'><b>{supplyAllocationAmounts.displayIro}</b></span> to the Launchpad pre-sale,&nbsp;
                <span className='primary-text'><b>{supplyAllocationAmounts.displayCommunityPool}</b></span> to the Community Pool, and&nbsp;
                <span className='primary-text'><b>{supplyAllocationAmounts.displayAccounts}</b></span> to accounts.
            </p>
        </div>
    );
};

export default SupplyAllocationSection;
