import { useCallback, useEffect, useReducer } from 'react';
import { getFixedTransfer, loadPendingTransfers } from '../ibc-transfer/ibc-status/ibc-status-service';
import { IbcTransferDetails } from '../ibc-transfer/ibc-status/ibc-status-types';
import { useNetwork } from '../network/network-context';
import { useWallet } from '../wallet/wallet-context';
import { useClient } from '../client/client-context';
import { convertToBech32Address } from '../wallet/wallet-service';
import { useAccountConfiguration } from './account-menu/use-account-configuration';
import { accountNetworkReducer, AccountNetworkState } from './account-network-state';
import { Network } from '../network/network-types';
import { getBalances, getERC20Balances, getEvmBalances, getSolanaBalances } from './account-service';
import { CoinsAmount } from '../currency/currency-types';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';

export const useAccountNetwork = (
    network?: Network,
    loadBalances: boolean = true,
    loadAddress: boolean = true,
    stateDefaults?: AccountNetworkState,
): [ AccountNetworkState, (network?: Network) => void ] => {
    const { hubNetwork, rollAppParams, hubChannelNetworkMap, networks, networkDenoms } = useNetwork();
    const { hubWallet, networkWalletMap, networkWalletTypeMap, handleWalletError } = useWallet();
    const { clientStateMap, connectClient } = useClient();
    const [ networkState, networkStateDispatch ] = useReducer(accountNetworkReducer, { ...stateDefaults, network });
    const cancelAndSetAddressPromise = useCancelablePromise<{ address?: string, hexAddress?: string }>();
    const cancelAndSetBalancesPromise = useCancelablePromise<CoinsAmount[]>();
    const cancelAndSetErc20BalancesPromise = useCancelablePromise<{ balances: CoinsAmount[], erc20Tokens: { denom: string, erc20Address: string }[] }>();
    const cancelAndSetEvmSolanaBalancesPromise = useCancelablePromise<CoinsAmount[]>();
    const cancelAndSetPendingTransfersPromise = useCancelablePromise<IbcTransferDetails[]>();
    const { saveVisibleErc20Tokens } = useAccountConfiguration();
    const networkWallet = networkState.network?.type === 'RollApp' ?
        hubWallet : networkState.network ? networkWalletMap[networkState.network.chainId] : null;
    const clientState = networkState.network ? clientStateMap[networkState.network.chainId] : null;

    const setNetwork = useCallback((network?: Network): void => networkStateDispatch({ type: 'set-network', payload: network }), []);

    useEffect(() => {
        if (networkState.network?.rpc) {
            connectClient(networkState.network);
        }
    }, [ connectClient, networkState.network ]);

    useEffect(() => {
        if (networkState.network && !networkWallet && !networkWalletTypeMap[networkState.network.chainId]) {
            networkStateDispatch({ type: 'clear-data' });
            cancelAndSetBalancesPromise();
            cancelAndSetErc20BalancesPromise();
            cancelAndSetAddressPromise();
            cancelAndSetPendingTransfersPromise();
        }
    }, [
        networkWallet,
        cancelAndSetPendingTransfersPromise,
        cancelAndSetAddressPromise,
        cancelAndSetBalancesPromise,
        cancelAndSetErc20BalancesPromise,
        networkState.network,
        networkWalletTypeMap,
    ]);

    useEffect(() => {
        networkStateDispatch({ type: 'set-pending-transfers', payload: undefined });
    }, [ networkState.address ]);

    useEffect(() => {
        if (network) {
            networkStateDispatch({ type: 'set-network', payload: network });
            cancelAndSetBalancesPromise();
            cancelAndSetErc20BalancesPromise();
            cancelAndSetAddressPromise();
            cancelAndSetPendingTransfersPromise();
        }
    }, [
        cancelAndSetPendingTransfersPromise,
        cancelAndSetAddressPromise,
        cancelAndSetBalancesPromise,
        network,
        cancelAndSetErc20BalancesPromise,
    ]);

    useEffect(() => {
        if (!networkWallet || !networkState.network || !loadAddress) {
            return;
        }
        networkStateDispatch({ type: 'set-address-loading' });
        if (loadBalances) {
            networkStateDispatch({ type: 'set-balances-loading' });
            if (networkState.network.type === 'Hub') {
                networkStateDispatch({ type: 'set-pending-transfers-loading' });
            }
        }
        cancelAndSetAddressPromise(networkWallet.getAddress(networkState.network))
            .then((addresses) => {
                if (networkState.network?.type === 'RollApp') {
                    addresses = {
                        hexAddress: addresses.hexAddress,
                        address: convertToBech32Address(addresses.hexAddress || '', networkState.network.bech32Prefix || ''),
                    };
                }
                networkStateDispatch({ type: 'set-address', payload: addresses });
            })
            .catch((error) => {
                networkStateDispatch({ type: 'set-address', payload: undefined });
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                handleWalletError(error);
            });
    }, [ networkWallet, cancelAndSetAddressPromise, networkState.network, loadBalances, handleWalletError, loadAddress ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            networkStateDispatch({ type: 'set-bank-balances-loading', payload: false });
            return;
        }
        if (!loadBalances ||
            !networkWallet ||
            !networkState.network ||
            networkState.network.type === 'EVM' ||
            networkState.network.type === 'Solana' ||
            !networkState.address ||
            !clientState?.client ||
            clientState?.connecting) {
            return;
        }
        networkStateDispatch({ type: 'set-bank-balances-loading' });
        cancelAndSetBalancesPromise(getBalances(clientState.client, networkState.address))
            .then((balances) => networkStateDispatch({ type: 'set-bank-balances', payload: balances }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-bank-balances', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        networkWallet,
        cancelAndSetBalancesPromise,
        clientState,
        networkState.address,
        networkState.network,
        loadBalances,
    ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            networkStateDispatch({ type: 'set-erc20-balances-loading', payload: false });
            return;
        }
        if (!loadBalances ||
            !networkWallet ||
            !networkState.network ||
            networkState.network.type === 'EVM' ||
            networkState.network.type === 'Solana' ||
            !networkState.hexAddress ||
            !clientState?.client ||
            clientState?.connecting) {
            return;
        }
        networkStateDispatch({ type: 'set-erc20-balances-loading' });
        cancelAndSetErc20BalancesPromise(getERC20Balances(clientState.client, networkState.hexAddress))
            .then(({ balances, erc20Tokens }) => {
                saveVisibleErc20Tokens(networkState.network?.chainId || '', erc20Tokens);
                networkStateDispatch({ type: 'set-erc20-balances', payload: balances });
            })
            .catch((error) => {
                networkStateDispatch({ type: 'set-erc20-balances', payload: undefined });
                networkStateDispatch({ type: 'set-erc20-error', payload: error });
            });
    }, [
        networkWallet,
        cancelAndSetErc20BalancesPromise,
        clientState,
        networkState.hexAddress,
        networkState.network,
        loadBalances,
        saveVisibleErc20Tokens,
    ]);

    useEffect(() => {
        if (!loadBalances ||
            !networkWallet ||
            !networkDenoms?.length ||
            !networkState.hexAddress ||
            !networkState.network ||
            (networkState.network?.type !== 'EVM')) {
            return;
        }
        networkStateDispatch({ type: 'set-balances-loading' });
        cancelAndSetEvmSolanaBalancesPromise(
            getEvmBalances(networkState.network, networkState.hexAddress, networkDenoms))
            .then((balances) => networkStateDispatch({ type: 'set-balances', payload: balances }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetEvmSolanaBalancesPromise,
        loadBalances,
        clientState,
        networkDenoms,
        networkState.hexAddress,
        networkState.network,
        networkWallet,
    ]);

    useEffect(() => {
        if (!loadBalances ||
            !networkWallet ||
            !networkDenoms?.length ||
            !networkState.address ||
            !networkState.network ||
            (networkState.network?.type !== 'Solana')) {
            return;
        }
        networkStateDispatch({ type: 'set-balances-loading' });
        cancelAndSetEvmSolanaBalancesPromise(getSolanaBalances(networkState.network, networkState.address, networkDenoms))
            .then((balances) => networkStateDispatch({ type: 'set-balances', payload: balances }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetEvmSolanaBalancesPromise,
        loadBalances,
        networkDenoms,
        clientState,
        networkState.address,
        networkState.network,
        networkWallet,
    ]);

    useEffect(() => {
        if (!loadBalances ||
            !hubNetwork ||
            !rollAppParams ||
            !networkWallet ||
            !networkState.network ||
            !networkState.address ||
            !clientState ||
            networkState.network.type !== 'Hub') {
            return;
        }
        networkStateDispatch({ type: 'set-pending-transfers-loading' });
        cancelAndSetPendingTransfersPromise((signal) => loadPendingTransfers(networkState.network!.chainId, networkState.address!, signal))
            .then((transfers) => {
                const fixedTransfers = transfers
                    .map((transfer) => getFixedTransfer(transfer, hubChannelNetworkMap, hubNetwork, networks))
                    .filter((transfer) => transfer?.coins) as IbcTransferDetails[];
                const pendingTransfers = fixedTransfers.filter((transfer) => transfer.type === 'In' || transfer.status === 'Refunding');
                networkStateDispatch({ type: 'set-pending-transfers', payload: pendingTransfers });
            })
            .catch((error) => {
                networkStateDispatch({ type: 'set-pending-transfers', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetPendingTransfersPromise,
        loadBalances,
        clientState,
        networkState.address,
        networkState.network,
        networkWallet,
        rollAppParams,
        hubNetwork,
        hubChannelNetworkMap,
        networks,
    ]);

    return [ networkState, setNetwork ];
};


