import React from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../../shared/utils/number-utils';
import useHandleTxResponses from '../../../tx/use-handle-tx-responses';
import { useWallet } from '../../../wallet/wallet-context';
import { IbcTransferDetails } from '../ibc-status-types';
import { useEibcFulfillOrder } from './use-eibc-fulfill-order';
import './eibc-fulfill-order-modal.scss';

interface EibcFulfillOrderModalProps extends DialogProps {
    transfer: IbcTransferDetails;
}

const EibcFulfillOrderModal: React.FC<EibcFulfillOrderModalProps> = ({ transfer, ...otherDialogProps }) => {
    const { hubWallet } = useWallet();
    const { txState, eibcFeeLoading, broadcast } = useEibcFulfillOrder(transfer);
    useHandleTxResponses(txState, hubWallet, otherDialogProps.onRequestClose);

    return (
        <Dialog className='eibc-fulfill-order-modal' closable {...otherDialogProps}>
            <DialogTitle>Fulfill eIBC Order</DialogTitle>
            <DialogContent className='modal-content'>
                You are about to fulfill an eIBC order. By completing this action:
                <ul className='list'>
                    <li>The transfer recipient will receive their funds immediately.</li>
                    <li>
                        <div className='horizontally-centered'>
                            You will earn a fee of&nbsp;
                            <span className='primary-text'>
                                {eibcFeeLoading ? <Spinner size='xs' /> : !transfer.eibcFeeCoins?.amount ? '-' :
                                    <b>{formatPrice(transfer.eibcFeeCoins?.amount || 0, transfer.eibcFeeCoins?.currency.displayDenom)}</b>}
                            </span>
                            &nbsp;for fulfilling the order.
                        </div>
                    </li>
                    <li>
                        The transferred amount&nbsp;
                        <span className='primary-text'>
                            <b>({formatPrice(transfer.coins?.amount || 0, transfer.coins?.currency.displayDenom)})</b>
                        </span>
                        &nbsp;will be locked for ~1 week until finalization.
                    </li>
                    <li>After ~1 week, you will be able to claim your tokens.</li>
                </ul>
            </DialogContent>
            <DialogAction close secondary>Cancel</DialogAction>
            <DialogAction
                primary
                loading={txState.broadcasting}
                disabled={txState.broadcasting || txState.feeLoading || eibcFeeLoading}
                onClick={() => broadcast()}
            >
                Fulfill
            </DialogAction>
        </Dialog>
    );
};

export default EibcFulfillOrderModal;
