import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Addresses from '../../../../shared/components/addresses/addresses';
import Badge from '../../../../shared/components/badge/badge';
import Button from '../../../../shared/components/button/button';
import Input from '../../../../shared/components/form-controls/input/input';
import { ReactComponent as MagicWandIcon } from '../../../../assets/icons/magic-wand.svg';
import Spinner from '../../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { roundNumber } from '../../../../shared/utils/number-utils';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import DisplayNameWithIro from '../../../currency/display-name-with-iro/display-name-with-iro';
import { useNetwork } from '../../../network/network-context';
import RollappSummary from '../../../rollapp/rollapp-summary/rollapp-summary';
import { useEibcClient } from '../eibc-client-context';
import { RollappLiquidity } from '../eibc-client-types';
import RemoveRollappLiquidityDialog from '../remove-rollapp-liquidity/remove-rollapp-liquidity-dialog';
import RollappLiquidityDialog from '../rollapp-liquidity/rollapp-liquidity-dialog';
import './rollapp-liquidity-list.scss';

interface RollappLiquidityListProps {
    className?: string;
    headerClassName?: string;
}

const MAX_VISIBLE_TOKENS = 3;

const RollappLiquidityList: React.FC<RollappLiquidityListProps> = ({ headerClassName, className }) => {
    const { loading, liquidityList, groups } = useEibcClient();
    const { getNetwork } = useNetwork();
    const navigate = useNavigate();
    const [ searchText, setSearchText ] = useState<string>();
    const { isTablet } = useWindowSize();
    const [ editedRollappLiquidity, setEditedRollappLiquidity ] = useState<RollappLiquidity>();
    const [ removedRollappLiquidity, setRemovedRollappLiquidity ] = useState<RollappLiquidity>();
    const [ rollappLiquidityDialogVisible, setRollappLiquidityDialogVisible ] = useState(false);

    const toggleRollappDialog = useCallback((value: boolean, liquidity?: RollappLiquidity): void => {
        setRollappLiquidityDialogVisible(value);
        setEditedRollappLiquidity(value && liquidity ? cloneDeep(liquidity) : undefined);
    }, []);

    const filteredLiquidityList = useMemo(() => {
        if (!searchText || !liquidityList) {
            return liquidityList || [];
        }
        const lowerCaseSearchText = searchText.toLowerCase();
        return liquidityList.filter((liquidity) =>
            liquidity.rollapp.chainId.includes(lowerCaseSearchText) ||
            liquidity.rollapp.chainName.toLowerCase().includes(lowerCaseSearchText) ||
            liquidity.rollapp.description?.toLowerCase().includes(lowerCaseSearchText) ||
            liquidity.rollapp.shortDescription?.toLowerCase().includes(lowerCaseSearchText) ||
            liquidity.rollapp.currencies.some((currency) =>
                currency.displayDenom.toLowerCase().includes(lowerCaseSearchText) ||
                currency.baseDenom.toLowerCase().includes(lowerCaseSearchText)));
    }, [ liquidityList, searchText ]);

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading && !liquidityList) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!filteredLiquidityList?.length) {
            return <div className='no-data'>No RollApps</div>;
        }
    };

    const renderNameColumn = (liquidity: RollappLiquidity): ReactElement => {
        return <TableColumn className='name-column'><RollappSummary navigateOnClick rollapp={liquidity.rollapp} /></TableColumn>;
    };

    const renderOperatorColumn = (liquidity: RollappLiquidity): ReactElement => {
        const group = groups?.find((group) => group.metadata.policy_address === liquidity.operatorGroupAddress);
        return (
            <TableColumn contentClassName='group-address-column'>
                {group ? <b>{group?.metadata.moniker}</b> : undefined}
                <Addresses className='address-container' display='long' addresses={[ liquidity.operatorGroupAddress ]} canCopy />
            </TableColumn>
        );
    };

    const renderMinLpFeeColumn = (liquidity: RollappLiquidity): ReactElement => {
        return <TableColumn>{roundNumber(liquidity.minLpFee * 100, 4)}%</TableColumn>;
    };

    const renderOperatorFeeShareColumn = (liquidity: RollappLiquidity): ReactElement => {
        return <TableColumn>{roundNumber(liquidity.operatorFeeShare * 100, 4)}%</TableColumn>;
    };

    const renderProvidedTokensColumn = (liquidity: RollappLiquidity): ReactElement => {
        return (
            <TableColumn className='tokens-column'>
                {liquidity.tokens.slice(0, MAX_VISIBLE_TOKENS).map((token, tokenIndex) => {
                    const tokenNetwork = getNetwork(token.networkId);
                    return !tokenNetwork ? undefined : (
                        <Badge
                            rounded
                            key={tokenIndex}
                            onClick={() => navigate(tokenNetwork?.type === 'Hub' ?
                                '/dymension/token' : `/rollapps/${tokenNetwork.chainId}/token`)}
                            className='token-badge'
                            label={<>
                                <img className='token-logo' src={getCurrencyLogoPath(token.currency, tokenNetwork)} alt='token logo' />
                                <DisplayNameWithIro coins={token} />
                            </>}
                        />
                    );
                })}
                {liquidity.tokens.length <= MAX_VISIBLE_TOKENS ? undefined :
                    <span className='secondary-text nowrap more-tokens-label'>+{liquidity.tokens.length - MAX_VISIBLE_TOKENS} more</span>}
            </TableColumn>
        );
    };

    const renderActionsColumn = (liquidity: RollappLiquidity): ReactElement => {
        return (
            <TableColumn className='actions-column' align='right'>
                <Button className='remove-action' size='small' onClick={() => setRemovedRollappLiquidity(liquidity)}>Revoke</Button>
                <Button size='small' onClick={() => toggleRollappDialog(true, liquidity)}>Edit</Button>
            </TableColumn>
        );
    };

    const renderRollappLiquidityHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Name</TableColumn>
                <TableColumn>Operator</TableColumn>
                <TableColumn nowrap>Min Fee</TableColumn>
                <TableColumn nowrap>Operator Fee Share</TableColumn>
                <TableColumn>Provided Tokens</TableColumn>
                <TableColumn className='actions-column'></TableColumn>
            </TableRow>
        );
    };

    const renderRollappLiquidityRow = (liquidity: RollappLiquidity): ReactElement => {
        return (
            <TableRow key={`${liquidity.operatorGroupAddress}-${liquidity.rollapp.chainId}`} className='rollapp-liquidity-row'>
                {renderNameColumn(liquidity)}
                {renderOperatorColumn(liquidity)}
                {renderMinLpFeeColumn(liquidity)}
                {renderOperatorFeeShareColumn(liquidity)}
                {renderProvidedTokensColumn(liquidity)}
                {renderActionsColumn(liquidity)}
            </TableRow>
        );
    };

    return (
        <div className={className}>
            <h5 className={classNames('rollapp-liquidity-list-header', headerClassName)}>
                Served RollApps ({filteredLiquidityList?.length || 0})
                <span className='space' />

                <div className='rollapp-liquidity-list-controls'>
                    <Input
                        controlSize='medium'
                        className='rollapp-liquidity-search'
                        value={searchText}
                        type='search'
                        placeholder='Search RollApps...'
                        onValueChange={setSearchText}
                    />
                    <Button
                        buttonType='primary'
                        tooltipPlacement='top'
                        tooltip={isTablet ? 'Provide RollApp Liquidity' : ''}
                        onClick={() => toggleRollappDialog(true)}
                    >
                        <MagicWandIcon />
                        <span className='add-rollapp-liquidity-button-text'>&nbsp;&nbsp;Provide RollApp Liquidity</span>
                    </Button>
                </div>
            </h5>

            <div className={classNames('rollapp-liquidity-list-container', className)}>
                <Table className='rollapp-liquidity-list' indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderRollappLiquidityHeaderRow()}
                    {filteredLiquidityList?.map(renderRollappLiquidityRow)}
                </Table>
            </div>

            {rollappLiquidityDialogVisible &&
                <RollappLiquidityDialog editedLiquidity={editedRollappLiquidity} onRequestClose={() => toggleRollappDialog(false)} />}

            {removedRollappLiquidity &&
                <RemoveRollappLiquidityDialog
                    liquidity={removedRollappLiquidity}
                    onRequestClose={() => setRemovedRollappLiquidity(undefined)}
                />}
        </div>
    );
};

export default RollappLiquidityList;
