import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { useAsset } from '../../../../asset/asset-context';
import { CoinsAmount } from '../../../../currency/currency-types';
import { Validator } from '../../../../staking/validator/validator-types';
import { getStakingCurrency, isCoinsEquals } from '../../../../currency/currency-service';
import './account-total-staked-value.scss';

interface AccountTotalStakedValueProps {
    className?: string;
    delegatedValidators?: Validator[];
    loading: boolean;
}

const AccountTotalStakedValue: React.FC<AccountTotalStakedValueProps> = ({ className, delegatedValidators, loading }) => {
    const { assets, loading: assetsLoading, getTokenPrice } = useAsset();

    const getTotalStakedValue = useCallback((previousDay?: boolean) => {
        if ((!delegatedValidators && loading) || (!assets && assetsLoading)) {
            return undefined;
        }
        return delegatedValidators?.reduce((current, validator) => {
            const amount = current + (validator.amountStaked?.amount || 0);
            const stakeCurrency = validator.network && getStakingCurrency(validator.network);
            const stakeCoins: CoinsAmount | undefined = stakeCurrency &&
                { amount, currency: stakeCurrency, networkId: validator.network?.chainId || '' };
            const asset = stakeCoins && assets?.find((asset) => isCoinsEquals(asset, stakeCoins));
            return current + (!stakeCoins || !asset ? 0 : previousDay ? amount * asset.previousDayPrice : getTokenPrice(stakeCoins) || 0);
        }, 0);
    }, [ assets, assetsLoading, delegatedValidators, getTokenPrice, loading ]);

    const currentValue = useMemo(() => getTotalStakedValue(), [ getTotalStakedValue ]);

    const previousValue = useMemo(() => getTotalStakedValue(true), [ getTotalStakedValue ]);

    return (
        <Property
            label='Total Staked'
            className={classNames('account-total-staked-value', className)}
            valueClassName='property-value'
            labelClassName='property-label'
        >
            {currentValue === undefined && loading ? <Spinner /> :
                <StatisticsChange
                    period='day'
                    currentValue={currentValue || 0}
                    previousValue={(previousValue || 0)}
                >
                    {formatPrice(currentValue || 0, undefined, undefined, 10)}
                </StatisticsChange>}
        </Property>
    );
};

export default AccountTotalStakedValue;
