import classNames from 'classnames';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../shared/components/badge/badge';
import Menu, { MenuAction } from '../../../shared/components/menu/menu';
import Spinner from '../../../shared/components/spinner/spinner';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { getShortDateTimeString } from '../../../shared/utils/date-utils';
import { formatPrice } from '../../../shared/utils/number-utils';
import { getShortenedAddress } from '../../../shared/utils/text-utils';
import { useAsset } from '../../asset/asset-context';
import DisplayNameWithIro from '../../currency/display-name-with-iro/display-name-with-iro';
import { getCurrencyLogoPath, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount, CUSTOM_BASE_DENOM_PREFIX_REGEX } from '../../currency/currency-types';
import { useDymns } from '../../dymns/dymns-context';
import { IbcTransferDetails } from '../../ibc-transfer/ibc-status/ibc-status-types';
import IbcTransferDialog from '../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import { useNetwork } from '../../network/network-context';
import { Network } from '../../network/network-types';
import SendFundsDialog from '../account-menu/account-balances/send-funds-dialog/send-funds-dialog';
import './balances-list.scss';

interface AccountBalancesProps {
    balances: CoinsAmount[] | IbcTransferDetails[];
    onBalanceClick?: (balance: CoinsAmount) => void;
    loading?: boolean;
    className?: string;
    header?: ReactNode;
    suffix?: ReactNode;
    sortByValue?: boolean;
}

const BalancesList: React.FC<AccountBalancesProps> = ({ balances, onBalanceClick, header, suffix, loading, className, sortByValue }) => {
    const navigate = useNavigate();
    const { getAssetLink } = useAsset();
    const { getTokenPrice, assets } = useAsset();
    const { networks, getNetwork, toHubCoins } = useNetwork();
    const { dymnsState } = useDymns();
    const [ sendFundsDialogInitialCoins, setSendFundsDialogInitialCoins ] = useState<CoinsAmount>();
    const [ transferDialogCoins, setTransferDialogCoins ] = useState<CoinsAmount>();

    const optionalTransferNetworks = useMemo(() => networks.map((network) => network.chainId), [ networks ]);

    const onViewAsset = useCallback((balance: CoinsAmount, transfer?: IbcTransferDetails) => {
        const url = transfer ? `/ibc/status/${transfer.id}` : getAssetLink(balance);
        if (window.location.pathname.includes('/connect')) {
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
        onBalanceClick?.(balance);
    }, [ getAssetLink, navigate, onBalanceClick ]);

    const fetchCoinsAndTransfer = useCallback((balance: CoinsAmount | IbcTransferDetails): { coins: CoinsAmount, transfer?: IbcTransferDetails } => {
        const balanceAsTransfer = balance as IbcTransferDetails;
        if (balanceAsTransfer.coins) {
            return { coins: balanceAsTransfer.coins, transfer: balanceAsTransfer };
        }
        return { coins: balance as CoinsAmount };
    }, []);

    const balancesInfo = useMemo(() => {
        const list = balances.map((balance) => {
            const { coins, transfer } = fetchCoinsAndTransfer(balance);
            const network = getNetwork(coins.networkId) as Network;
            const showNetworkDomain = balances?.some((otherBalance) => {
                const { coins: otherCoins } = fetchCoinsAndTransfer(otherBalance);
                return otherCoins.currency.displayDenom.toLowerCase() === coins.currency.displayDenom.toLowerCase() &&
                    (coins.networkId !== otherCoins.networkId);
            });
            const showBaseDenom = !showNetworkDomain && Boolean(balances?.some((otherBalance) => {
                const { coins: otherCoins } = fetchCoinsAndTransfer(otherBalance);
                return otherCoins.currency.displayDenom.toLowerCase() === coins.currency.displayDenom.toLowerCase() &&
                    coins.currency.baseDenom !== otherCoins.currency.baseDenom;
            }));
            const price = getTokenPrice(coins) || 0;
            const asset = assets?.find((asset) => isCoinsEquals(asset, toHubCoins(coins), true));
            const containerNetwork = coins.containerNetworkId ? getNetwork(coins.containerNetworkId) : undefined;
            return { coins, transfer, price, network, asset, showNetworkDomain, showBaseDenom, containerNetwork };
        });
        if (sortByValue) {
            list.sort((balance1, balance2) => balance2.price - balance1.price);
        }
        return list;
    }, [ assets, balances, fetchCoinsAndTransfer, getNetwork, getTokenPrice, sortByValue, toHubCoins ]);

    const emptyLabel = useMemo(() => balancesInfo.some((balance) => balance.transfer) ? 'No Transfers' : 'No Balances', [ balancesInfo ]);

    const getTokenSuffix = useCallback((network: Network, coins: CoinsAmount, showNetworkDomain: boolean, showBaseDenom: boolean) => {
        let suffixValue = '';
        if (showNetworkDomain) {
            const alias = dymnsState.aliasesMap[network.chainId]?.aliases?.[0];
            suffixValue = alias ? `@${alias}` : network.chainName;
        } else if (showBaseDenom) {
            suffixValue = !CUSTOM_BASE_DENOM_PREFIX_REGEX.test(coins.currency.baseDenom) ? coins.currency.baseDenom :
                getShortenedAddress(coins.currency.baseDenom.replace(CUSTOM_BASE_DENOM_PREFIX_REGEX, ''));
        }
        return suffixValue && <span className='token-suffix nowrap ellipsis'>{suffixValue}</span>;
    }, [ dymnsState.aliasesMap ]);

    return <>
        <ul className={classNames('account-balances', className)}>
            {loading && <Spinner className='balances-loader' />}
            {header}
            {!loading && !balancesInfo.length && <span className='no-balances'>{emptyLabel}</span>}
            {balancesInfo.map((
                { asset, coins, transfer, price, showNetworkDomain, showBaseDenom, network, containerNetwork },
                balanceIndex,
            ) => {
                const balanceRow = (
                    <li
                        className={classNames('balance-row', { canClick: Boolean(asset) })}
                        key={balanceIndex}
                        onClick={() => transfer ? onViewAsset(coins, transfer) : undefined}
                    >
                        <img className='currency-logo' src={getCurrencyLogoPath(coins.currency, network)} alt='currency logo' />
                        <span className='currency-name-container'>
                            <DisplayNameWithIro coins={coins} suffix={getTokenSuffix(network, coins, showNetworkDomain, showBaseDenom)} />
                            {containerNetwork && <span className='currency-network nowrap ellipsis'>{containerNetwork?.chainName}</span>}
                            {transfer?.time ?
                                <span className='transfer-time nowrap'>{getShortDateTimeString(transfer.time)}</span> : undefined}
                        </span>
                        {transfer && (
                            <Badge
                                label={transfer.claimable ? 'Claimable' : 'Pending'}
                                size='small'
                                className='finalized-badge'
                                color={getCssVariableValue(
                                    transfer.claimable ? '--light-green-rgb' : '--orange-rgb').split(',').map(Number)}
                            />
                        )}
                        {coins.amount !== undefined && (
                            <span className='currency-option-balance'>
                                {formatPrice(coins.amount, '', undefined, 10)}
                                <span className='balance-value'>{formatPrice(price)}</span>
                            </span>
                        )}
                    </li>
                );
                return transfer ? balanceRow : (
                    <Menu closeWhenScroll trigger={balanceRow} key={balanceIndex}>
                        <MenuAction
                            onClick={() => coins && setSendFundsDialogInitialCoins(coins)}
                            trackEvent='balance_menu_send_click'
                        >
                            Send
                        </MenuAction>
                        <MenuAction onClick={() => coins && setTransferDialogCoins(coins)} trackEvent='balance_menu_transfer_click'>
                            Transfer
                        </MenuAction>
                        <MenuAction
                            disabled={!asset}
                            onClick={() => asset && onViewAsset(asset, transfer)}
                            trackEvent='balance_menu_trade_click'
                        >
                            Trade
                        </MenuAction>
                    </Menu>
                );
            })}
            {suffix}
        </ul>

        {sendFundsDialogInitialCoins &&
            <SendFundsDialog initialCoins={sendFundsDialogInitialCoins} onRequestClose={() => setSendFundsDialogInitialCoins(undefined)} />}

        {transferDialogCoins?.containerNetworkId && (
            <IbcTransferDialog
                className='network-deposit-withdraw-dialog'
                optionalSourceNetworks={[ transferDialogCoins.containerNetworkId ]}
                optionalDestinationNetworks={optionalTransferNetworks}
                initialDestinationNetwork={transferDialogCoins.networkId}
                onRequestClose={() => setTransferDialogCoins(undefined)}
                initialAsset={transferDialogCoins}
            />
        )}
    </>;
};

export default BalancesList;
