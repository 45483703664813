import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import Spinner from '../../../../shared/components/spinner/spinner';
import { useAuthZ } from '../../../../shared/hooks/use-authz';
import { useAccountNetwork } from '../../../account/use-account-network';
import { useClient } from '../../../client/client-context';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import { FORCE_TRANSFER_MESSAGE_TYPES, IbcTransferDetails } from '../ibc-status-types';
import { relayPacket } from './resolve-transfer-service';

export interface UseResolveTransferValue {
    broadcasting: boolean;
    broadcast: () => void;
    resolveAll: boolean;
    setResolveAll: (value: boolean) => void;
}

const RESOLVE_TRANSFER_MESSAGE_KEY = 'resolve-transfer';

export const useResolveTransfer = (transfer?: IbcTransferDetails, onResolve?: () => void): UseResolveTransferValue => {
    const { removeMessage, showMessage, showSuccessMessage, showErrorMessage } = useSnackbar();
    const { signingClientStateMap, clientStateMap, connectClient } = useClient();
    const { handleWalletError, networkWalletMap } = useWallet();
    const [ broadcasting, setBroadcasting ] = useState(false);
    const [ networkState ] = useAccountNetwork(transfer?.sourceNetwork, false, broadcasting);
    const [ relaying, setRelaying ] = useState(false);
    const [ resolveAll, setResolveAll ] = useState(false);

    const singingClientState = networkState.network && signingClientStateMap[networkState.network.chainId];
    const sourceClientState = transfer?.sourceNetwork && clientStateMap[transfer.sourceNetwork.chainId];
    const destinationClientState = transfer?.destinationNetwork && clientStateMap[transfer.destinationNetwork.chainId];
    const networkWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : null;

    const { connect, signer: authZSigner, connecting, error: authZError } = useAuthZ(networkState, FORCE_TRANSFER_MESSAGE_TYPES);

    const toUseAuthZ = useMemo(() => networkState.network?.type !== 'Hub', [ networkState.network?.type ]);

    const signer = useMemo(
        () => authZSigner ? authZSigner : singingClientState?.client?.getActiveSigner(),
        [ authZSigner, singingClientState?.client ],
    );

    useEffect(() => {
        if (!broadcasting) {
            return;
        }
        if (networkState.error || authZError) {
            setBroadcasting(false);
            removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
            showErrorMessage(`Something went wrong, please try again later`);
            return;
        }
        if (singingClientState && !singingClientState.client && !singingClientState.connecting) {
            setBroadcasting(false);
            removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
            showErrorMessage(`Can't connect to ${networkState.network?.chainName || 'the network'}, please try again later`);
            return;
        }
        if (sourceClientState && !sourceClientState.client && !sourceClientState.connecting) {
            setBroadcasting(false);
            removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
            showErrorMessage(`Can't connect to ${transfer?.sourceNetwork?.chainName || 'the network'}, please try again later`);
            return;
        }
        if (destinationClientState && !destinationClientState.client && !destinationClientState.connecting) {
            setBroadcasting(false);
            removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
            showErrorMessage(`Can't connect to ${transfer?.destinationNetwork?.chainName || 'the network'}, please try again later`);
            return;
        }
    }, [
        broadcasting,
        authZError,
        destinationClientState,
        networkState.error,
        networkState.network?.chainName,
        removeMessage,
        showErrorMessage,
        singingClientState,
        sourceClientState,
        transfer?.destinationNetwork?.chainName,
        transfer?.sourceNetwork?.chainName,
    ]);

    useEffect(() => {
        if (toUseAuthZ && broadcasting && networkState.address && !relaying && !connecting && !signer) {
            connect();
        }
    }, [ broadcasting, connect, connecting, networkState.address, relaying, signer, toUseAuthZ ]);


    useEffect(() => {
        if (!relaying &&
            broadcasting &&
            transfer &&
            networkState.address &&
            signer &&
            singingClientState?.client &&
            sourceClientState?.client &&
            destinationClientState?.client
        ) {
            setRelaying(true);
            removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
            showMessage({
                content: <div className='horizontally-centered'><Spinner size='small' />&nbsp;&nbsp;Resolving transfer...</div>,
                key: RESOLVE_TRANSFER_MESSAGE_KEY,
                duration: 600000,
            });
            relayPacket(
                transfer,
                networkState.address,
                signer,
                singingClientState.client.getGasPrice(),
                sourceClientState.client!,
                destinationClientState.client!,
                resolveAll,
                () => {
                    removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
                    showMessage({
                        content: 'Please confirm and sign the transactions to proceed. (Your wallet may take a few seconds to open)',
                        key: RESOLVE_TRANSFER_MESSAGE_KEY,
                        duration: 600000,
                    });
                },
            )
                .then(() => {
                    removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
                    showSuccessMessage({
                        content: 'Resolving transfer succeed! - The status will be updated in the next few seconds',
                        key: RESOLVE_TRANSFER_MESSAGE_KEY,
                    });
                    onResolve?.();
                    setTimeout(() => onResolve?.(), 1000);
                    setTimeout(() => onResolve?.(), 3000);
                })
                .catch((error) => {
                    console.error(error);
                    removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
                    showErrorMessage(`Failed to resolve transfer, please try again later`);
                })
                .finally(() => {
                    setRelaying(false);
                    setBroadcasting(false);
                });
        }
    }, [
        destinationClientState?.client,
        signer,
        sourceClientState?.client,
        networkState.address,
        onResolve,
        transfer,
        relaying,
        broadcasting,
        singingClientState?.client,
        showMessage,
        showErrorMessage,
        removeMessage,
        resolveAll,
        showSuccessMessage,
    ]);

    const broadcast = useCallback(() => {
        if (!transfer) {
            showErrorMessage(`Something went wrong, please try again later`);
            return;
        }
        if (transfer.sourceNetwork?.status === 'Unavailable' || !transfer.sourceNetwork?.rpc) {
            showErrorMessage(`${transfer?.sourceNetwork?.chainName || 'Network'} unavailable, please try again later`);
            return;
        }
        if (transfer.destinationNetwork?.status === 'Unavailable' || !transfer.destinationNetwork?.rpc) {
            showErrorMessage(`${transfer?.destinationNetwork?.chainName || 'Network'} unavailable, please try again later`);
            return;
        }
        if (!networkWallet) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, networkState.network));
            return;
        }
        removeMessage(RESOLVE_TRANSFER_MESSAGE_KEY);
        showMessage({
            content: <div className='horizontally-centered'><Spinner size='small' />&nbsp;&nbsp;Fetching transfer data...</div>,
            key: RESOLVE_TRANSFER_MESSAGE_KEY,
            duration: 600000,
        });
        connectClient(transfer.sourceNetwork);
        connectClient(transfer.destinationNetwork);
        setBroadcasting(true);
    }, [ connectClient, handleWalletError, networkState.network, networkWallet, removeMessage, showErrorMessage, showMessage, transfer ]);

    return { resolveAll, broadcast, broadcasting, setResolveAll };
};
