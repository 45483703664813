import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import BigNumber from 'bignumber.js';
import { convertIroToCoinsAmount, createIroDenom, isIroDenom } from '../iro/iro-service';
import { DEFAULT_DENOM_EXPONENT } from '../rollapp/manage-rollapps-page/create-rollapp-page/types';
import { CoinsAmount, Currency, CurrencyType, NetworkDenom } from './currency-types';
import { ChannelNetworkMap, Network } from '../network/network-types';
import { StationClient } from '../client/station-clients/station-client';
import { getNetworkLogoPath } from '../network/network-service';

export const getCurrencyLogoPath = (currency: Currency, network: Network, fallback = true) => {
    if (!currency.logo) {
        return getNetworkLogoPath(network, fallback);
    }
    if (/^https?:\/\//i.test(currency.logo)) {
        return currency.logo;
    }
    return `${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}${currency.logo}`;
};

export const convertToCoinsAmount = async (coin: Coin, client: StationClient, waitToNetworkDenoms = true): Promise<CoinsAmount | null> => {
    if (isIroDenom(coin.denom)) {
        return convertIroToCoinsAmount(coin, client.getAllNetworks());
    }
    const network = client.getNetwork();
    if (waitToNetworkDenoms && !client.getHubNetworkDenoms()) {
        return null;
    }
    let currency = getCurrency(network, coin.denom);
    let sourceNetwork = network;
    let ibc: CoinsAmount['ibc'];
    let iroDenom: string | undefined;
    if (!currency) {
        let networkDenom = client.getHubNetworkDenoms()?.find((networkDenom) => networkDenom.denom === coin.denom);
        if (!networkDenom) {
            const response = await client.getIbcTransferQueryClient()?.DenomTrace({ hash: coin.denom }).catch((error) => {
                console.error(`Can't fetch denom trace for: ${coin.denom}`, error);
                return null;
            });
            networkDenom = (response?.denomTrace || {}) as NetworkDenom;
        }
        const { path, baseDenom } = networkDenom;
        if (!path || !baseDenom) {
            return null;
        }
        if (isIroDenom(baseDenom)) {
            const result = convertIroToCoinsAmount({ denom: baseDenom, amount: coin.amount }, client.getAllNetworks());
            const ibcSourceNetwork = result?.networkId && client.getAllNetworks().find((network) => network.chainId === result.networkId);
            if (!ibcSourceNetwork) {
                return null;
            }
            sourceNetwork = ibcSourceNetwork;
            currency = result?.currency;
            iroDenom = result?.iroDenom;
        } else {
            currency = network.currencies.find((currency) => currency.bridgeDenom === coin.denom && currency.baseDenom === baseDenom);
            if (currency) {
                const networkDenom =
                    client.getHubNetworkDenoms()?.find((networkDenom) => networkDenom.denom === currency?.ibcRepresentation);
                const ibcSourceNetwork = client.getAllNetworks().find((network) => network.chainId === networkDenom?.ibcNetworkId);
                if (!ibcSourceNetwork) {
                    return null;
                }
                sourceNetwork = ibcSourceNetwork;
            } else {
                const ibcSourceNetwork = getIbcSourceNetwork(network, client.getHubNetwork(), client.getHubChannelNetworkMap(), path);
                if (!ibcSourceNetwork) {
                    return null;
                }
                sourceNetwork = ibcSourceNetwork;
                currency = getCurrency(ibcSourceNetwork, baseDenom);
            }
        }
        if (!currency) {
            return null;
        }
        ibc = { representation: coin.denom, path };
    }
    const amount = getMaxDenomAmount(Number(coin.amount) || 0, currency);
    const coins = {
        amount,
        containerNetworkId: network.chainId,
        currency,
        ibc,
        networkId: sourceNetwork.chainId,
        iroDenom,
    };
    try {
        return { ...coins, baseAmount: BigInt(coin.amount) };
    } catch {
        return { ...coins, baseAmount: BigInt(Math.round(Number(coin.amount))) };
    }
};


export const getIbcSourceNetwork = (
    network: Network,
    hubNetwork: Network,
    hubChannelNetworkMap: ChannelNetworkMap,
    path: string,
): Network | undefined => {
    const pathParts = path.split(/\/?transfer\//).filter(Boolean);
    if (!pathParts.length || pathParts.length > 2 ||
        (network.type === 'Hub' && pathParts.length === 2) ||
        (network.type !== 'Hub' && pathParts[0] !== network.ibc?.channel)) {
        return;
    }
    if (network.type === 'Hub') {
        return hubChannelNetworkMap[pathParts[0]];
    } else if (pathParts.length === 1) {
        return hubNetwork;
    } else {
        return hubChannelNetworkMap[pathParts[1]];
    }
};

export const fetchPathAndDenom = (denom: string): { path: string, baseDenom: string } => {
    const baseDenom = denom.replace(/^(transfer\/channel-\d+\/)*/, '');
    return { baseDenom, path: denom === baseDenom ? '' : denom.replace(new RegExp(`/${baseDenom}$`), '') };
};

export const getFixedDenom = (coins: CoinsAmount, alsoCheckBaseDenom = true): string => {
    return coins.ibc?.representation || coins.iroDenom || (alsoCheckBaseDenom ? coins.currency.baseDenom : '');
};

export const convertToCoin = (coins: CoinsAmount, minCoinsAmount: number = 1): Coin => {
    const amount = (getMinDenomAmount(coins.amount, coins.currency) || minCoinsAmount).toLocaleString(undefined, { useGrouping: false });
    const denom = getFixedDenom(coins);
    return { amount, denom };
};

export const getMaxDenomAmount = (amount: number, currency?: Currency, decimals: number = DEFAULT_DENOM_EXPONENT): number => {
    return Math.round(amount + Number.EPSILON) / Math.pow(10, currency?.decimals ?? decimals);
};

export const getMinDenomAmount = (amount: number, currency?: Currency, decimals: number = DEFAULT_DENOM_EXPONENT): number => {
    return Math.round(new BigNumber(10).pow(currency?.decimals ?? decimals).times(amount).toNumber());
};

export const getCurrency = (network: Network, denom: string): Currency | undefined => {
    return network.currencies.find((currency) => currency.baseDenom === denom);
};

export const getMainCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'main') || getCurrencyByType(network, 'regular') || network.currencies[0];
};

export const getStakingCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'staking') || getMainCurrency(network);
};

export const getFeeCurrency = (network: Network): Currency => {
    return getCurrencyByType(network, 'fee') || getMainCurrency(network);
};

export const isCoinsEquals = (coins1: CoinsAmount, coins2: CoinsAmount, ignoreIro?: boolean): boolean => {
    return Boolean((coins1.currency.baseDenom === coins2.currency.baseDenom &&
            (coins1.networkId === coins2.networkId ||
                coins1.currency.networkId === coins2.networkId ||
                coins2.currency.networkId === coins1.networkId ||
                (coins1.currency.networkId && coins1.currency.networkId === coins2.currency.networkId)) &&
            (ignoreIro || coins1.iroDenom === coins2.iroDenom)) ||
        (coins1.currency.ibcRepresentation && coins1.currency.ibcRepresentation === coins2.currency.ibcRepresentation) ||
        (coins2.currency.ibcRepresentation && coins1.ibc?.representation === coins2.currency.ibcRepresentation) ||
        (coins1.currency.ibcRepresentation && coins1.currency.ibcRepresentation === coins2.ibc?.representation));
};

export const isDenomsEquals = (coins1: CoinsAmount, baseDenom: string, alsoCheckBaseDenom = true, alsoCheckIro?: boolean): boolean => {
    return coins1.ibc?.representation === baseDenom || coins1.iroDenom === baseDenom ||
        (alsoCheckIro && createIroDenom(coins1.networkId) === baseDenom) ||
        (alsoCheckBaseDenom && coins1.currency.baseDenom === baseDenom);
};

export const createEmptyCoinsAmount = (
    containerNetwork: Network,
    currency?: Currency,
    networkDenoms?: NetworkDenom[],
    amount = 0,
): CoinsAmount => {
    currency = currency || getMainCurrency(containerNetwork);
    const networkId = currency.networkId || (currency.ibcRepresentation && networkDenoms?.find((networkDenom) =>
            networkDenom.denom === currency?.ibcRepresentation && networkDenom.baseDenom === currency?.baseDenom)?.ibcNetworkId)
        || containerNetwork.chainId;
    return {
        currency,
        amount,
        networkId,
        containerNetworkId: containerNetwork.chainId,
        ibc: currency.rollappIbcRepresentation ? { representation: currency.rollappIbcRepresentation, path: '' } : undefined,
    };
};

export const getCurrencyByType = (network: Network, type: CurrencyType): Currency | undefined => {
    return network.currencies.find((currency) => currency.type === type);
};

export const parseCoins = (input: string): Coin[] => {
    return input
        .replace(/\s/g, '')
        .split(',')
        .filter(Boolean)
        .map((part) => {
            const match = part.match(/^([0-9]+)((future_|IRO\/)[a-z]+_[0-9]+-[0-9]+|[a-zA-Z][a-zA-Z0-9/]{2,127})$/);
            if (!match) {
                throw new Error('Got an invalid coin string');
            }
            return {
                amount: match[1].replace(/^0+/, '') || '0',
                denom: match[2],
            };
        });
};

export const getClosedAmount = (
    amount: number | bigint | string,
    decimals: number,
    ceilLimitAmount?: bigint,
    floorLimitAmount?: bigint,
): bigint => {
    const epsilon = BigInt(Math.max(1, 10 ** (decimals - 10)));
    let closedAmount = BigInt(amount);
    if (ceilLimitAmount && (closedAmount > ceilLimitAmount || ceilLimitAmount - closedAmount < epsilon)) {
        closedAmount = ceilLimitAmount;
    }
    if (floorLimitAmount && (closedAmount < floorLimitAmount || closedAmount - floorLimitAmount < epsilon)) {
        closedAmount = floorLimitAmount;
    }
    return closedAmount;
};

