import classNames from 'classnames';
import { uniqBy } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FireIcon } from '../../../../assets/icons/fire.svg';
import { ReactComponent as ThunderIcon } from '../../../../assets/icons/thunder2.svg';
import { ReactComponent as PauseIcon } from '../../../../assets/icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/play.svg';
import { ReactComponent as TrophyIcon } from '../../../../assets/icons/trophy.svg';
import { ReactComponent as ScheduleIcon } from '../../../../assets/icons/schedule.svg';
import Addresses from '../../../../shared/components/addresses/addresses';
import Badge from '../../../../shared/components/badge/badge';
import Button from '../../../../shared/components/button/button';
import Icon from '../../../../shared/components/icon/icon';
import Spinner from '../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import Swiper from '../../../../shared/components/swiper/swiper';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import { usePersistedState } from '../../../../shared/hooks/use-persisted-state';
import useVisibility from '../../../../shared/hooks/use-visibility';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useAmm } from '../../../amm/amm-context';
import { tradesObservable, useAsset } from '../../../asset/asset-context';
import { Asset } from '../../../asset/asset-types';
import { getCurrencyLogoPath, getMainCurrency, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import DisplayNameWithIro from '../../../currency/display-name-with-iro/display-name-with-iro';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { Network } from '../../../network/network-types';
import { TradeType } from '../../../trade/buy-sell/buy-sell';
import { getFixedTradeBase, loadTrades } from '../../../trade/trade-service';
import { Trade } from '../../../trade/types';
import { convertToHexAddress } from '../../../wallet/wallet-service';
import './rollapp-highlights.scss';

const HIGHLIGHT_CARD_SIZE = 4;
const HIGHLIGHT_CARDS_AMOUNT = 4;

interface RollappsHighlightsProps {
    className?: string;
}

const RollappsHighlights: React.FC<RollappsHighlightsProps> = ({ className }) => {
    const navigate = useNavigate();
    const { hubNetwork, getNetwork } = useNetwork();
    const { getAssetLink, mainAssetMap } = useAsset();
    const { rollapps } = useNetwork();
    const { assets } = useAsset();
    const { ammState } = useAmm();
    const visibility = useVisibility();
    const { isTablet, isMobile } = useWindowSize();
    const [ tradesLoading, setTradesLoading ] = useState(true);
    const [ toLoadTrades, setToLoadTrades ] = useState(true);
    const [ lastTrades, setLastTrades ] = useState<Trade[]>([]);
    const [ newTradeId, setNewTradeId ] = useState<string>();
    const [ pausedPageIndex, setPausedPageIndex ] = usePersistedState<number | undefined>('paused-page-index', undefined);
    const [ currentVisibleHighlightCard, setCurrentVisibleHighlightCard ] = useState(pausedPageIndex || 0);
    const [ autoScrollDirection, setAutoScrollDirection ] = useState(1);
    const [ autoScroll, setAutoScroll ] = useState(true);
    const [ , setAutoScrollChangeTimeout ] = useState<NodeJS.Timeout>();
    const cancelAndSetTradesPromise = useCancelablePromise<{ records: Trade[], count: number }>();

    useEffect(() => {
        if (!toLoadTrades || !hubNetwork || !assets || !ammState.params) {
            return;
        }
        cancelAndSetTradesPromise((signal) => loadTrades(hubNetwork.chainId, undefined, undefined, 0, HIGHLIGHT_CARD_SIZE, signal))
            .then(({ records }) => {
                const lastFixedTrades =
                    filterNonEmptyValues(records.map((trade) => getFixedTradeBase(trade, assets, ammState.params?.vsCoins.currency)));
                setLastTrades((lastTrades) =>
                    uniqBy([ ...lastFixedTrades, ...lastTrades ], (trade) => trade.id).slice(0, HIGHLIGHT_CARD_SIZE));
                setToLoadTrades(false);
            })
            .finally(() => setTradesLoading(false));
    }, [ ammState.params, assets, cancelAndSetTradesPromise, hubNetwork, lastTrades.length, toLoadTrades ]);

    useEffect(() => {
        if (pausedPageIndex !== undefined || !autoScroll || !visibility || (isTablet && !isMobile)) {
            return;
        }
        const interval = setInterval(() => {
            setCurrentVisibleHighlightCard((currentPage) => {
                let direction = autoScrollDirection;
                if (currentPage === 0) {
                    direction = 1;
                } else if (currentPage === HIGHLIGHT_CARDS_AMOUNT - 1) {
                    direction = -1;
                }
                setAutoScrollDirection(direction);
                return currentPage + direction;
            });
        }, 5000);
        return () => clearInterval(interval);
    }, [ pausedPageIndex, autoScroll, autoScrollDirection, visibility, isTablet, isMobile ]);

    const onSwipe = useCallback((card: number, mouse: boolean) => {
        if (!mouse) {
            return;
        }
        setCurrentVisibleHighlightCard((currentCard) => {
            if (card > currentCard) {
                setAutoScrollDirection(1);
            } else if (card < currentCard) {
                setAutoScrollDirection(-1);
            }
            return card;
        });
        setAutoScroll(false);
        setAutoScrollChangeTimeout((timeout) => {
            clearTimeout(timeout);
            return setTimeout(() => setAutoScroll(true), 5000);
        });
    }, []);

    useEffect(() => {
        if (visibility && rollapps.length) {
            setToLoadTrades(true);
        }
    }, [ rollapps.length, visibility ]);

    useEffect(() => {
        const subscription = tradesObservable.subscribe((newTrade) => {
            setLastTrades((trades) => {
                if (trades.some((trade) => trade.id === newTrade.id)) {
                    return trades;
                }
                setNewTradeId(newTrade.id);
                setTimeout(() => setNewTradeId(undefined), 50);
                return [ newTrade, ...trades ].slice(0, HIGHLIGHT_CARD_SIZE);
            });
        });
        return () => subscription.unsubscribe();
    }, []);

    const topIROs = useMemo(() => (assets || [])
        .filter((asset) => asset.network.status === 'IRO')
        .sort((asset1, asset2) => asset2.iroDymRaised - asset1.iroDymRaised)
        .slice(0, HIGHLIGHT_CARD_SIZE)
        .map((asset) => ({ asset, rollapp: asset.network })), [ assets ]);

    const newlyCreated = useMemo(() => [ ...rollapps ]
        .sort((rollapp1, rollapp2) => (rollapp2.creationDate || 0) - (rollapp1.creationDate || 0))
        .map((rollapp) => ({ rollapp, asset: mainAssetMap?.[rollapp.chainId] }))
        .filter(({ rollapp, asset }) => asset || rollapp.tokenless || rollapp.website || rollapp.apps?.length)
        .slice(0, HIGHLIGHT_CARD_SIZE), [ mainAssetMap, rollapps ]);

    const trending = useMemo(() => [ ...rollapps ]
        .map((rollapp) => ({ rollapp, asset: mainAssetMap?.[rollapp.chainId] }))
        .filter(({ asset }) => asset)
        .sort(({ asset: asset1 }, { asset: asset2 }) => (asset2?.volume || 0) - (asset1?.volume || 0))
        .slice(0, HIGHLIGHT_CARD_SIZE), [ mainAssetMap, rollapps ]);

    const getTargetAssetAndTradeType = (trade: Trade): { asset: CoinsAmount, type: TradeType } => {
        const vsCoins = ammState.params?.vsCoins;
        if (vsCoins && isCoinsEquals(trade.tokenIn, vsCoins)) {
            return { asset: trade.tokenOut, type: TradeType.BUY };
        }
        if (vsCoins && (isCoinsEquals(trade.tokenOut, vsCoins))) {
            return { asset: trade.tokenIn, type: TradeType.SELL };
        }
        return {
            asset: trade.tokenInNetwork === hubNetwork?.chainId ? trade.tokenOut : trade.tokenIn,
            type: trade.tokenInNetwork === hubNetwork?.chainId ? TradeType.BUY : TradeType.SELL,
        };
    };

    const renderTradesHighlightCard = (): ReactElement => {
        return (
            <div className='highlight-card activity section small'>
                <h6 className='card-label'><Icon><ThunderIcon /></Icon>&nbsp;Activity</h6>
                {tradesLoading && !lastTrades.length ? <Spinner /> : undefined}
                {lastTrades.map((trade) => {
                    const { asset, type } = getTargetAssetAndTradeType(trade);
                    const network = getNetwork(asset.networkId);
                    const typeColor = type === TradeType.BUY ? '--light-green-rgb' : '--red-rgb';
                    return (
                        <div
                            key={trade.id}
                            className={classNames('trade-row', { new: newTradeId === trade.id })}
                            onClick={() => navigate(getAssetLink(asset))}
                        >
                            {network && <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, network)} alt='asset logo' />}
                            <span className='trade-amount'>{formatPrice(asset.amount, '', { notation: 'compact' })}</span>
                            &nbsp;<DisplayNameWithIro className='trade-token' coins={asset} />
                            <Addresses className='ellipsis' canCopy addresses={[ convertToHexAddress(trade.address), trade.address ]} />
                            <span className='space' />
                            <Badge
                                className='trade-type-badge'
                                label={type}
                                size='small'
                                rounded
                                color={getCssVariableValue(typeColor).split(',').map(Number)}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    return <>
        <Swiper
            showOneInMobile
            containerClassName={className}
            itemWidth={isTablet ? undefined : 100}
            showOtherCardsEdges={isTablet}
            pageIndex={currentVisibleHighlightCard}
            onSwipe={onSwipe}
            footer={(
                <Button
                    className='pause-button'
                    trackEvent={pausedPageIndex !== undefined ? 'rollapp_highlights_play_click' : 'rollapp_highlights_pause_click'}
                    buttonType='icon'
                    onClick={() => setPausedPageIndex(pausedPageIndex !== undefined ? undefined : currentVisibleHighlightCard)}
                >
                    {pausedPageIndex !== undefined ? <PlayIcon /> : <PauseIcon />}
                </Button>
            )}
        >
            <RollappsHighlightCard label={<><Icon><FireIcon /></Icon>&nbsp;Trending</>} rollappAssetPairs={trending} />
            <RollappsHighlightCard label={<><Icon><TrophyIcon /></Icon>&nbsp;Top Launchpad</>} rollappAssetPairs={topIROs} />
            <RollappsHighlightCard label={<><Icon><ScheduleIcon /></Icon>&nbsp;Newly Created</>} rollappAssetPairs={newlyCreated} />
            {renderTradesHighlightCard()}
        </Swiper>
    </>;
};

interface RollappsHighlightProps {
    label: ReactElement;
    rollappAssetPairs: { rollapp: Network, asset?: Asset }[];
}

const RollappsHighlightCard: React.FC<RollappsHighlightProps> = ({ label, rollappAssetPairs }) => {
    const navigate = useNavigate();
    const { getAssetLink } = useAsset();

    return (
        <div className='highlight-card section small'>
            <h6 className='card-label'>{label}</h6>
            {rollappAssetPairs.map(({ rollapp, asset }) => {
                const logo = getNetworkLogoPath(rollapp);
                return (
                    <div
                        className='rollapp-row'
                        key={asset?.key || rollapp.chainId}
                        onClick={() => navigate(asset ? getAssetLink(asset) : `/rollapps/${rollapp.chainId}`)}
                    >
                        <img className='asset-logo' src={logo} alt='asset logo' />
                        <div className='rollapp-name-container'>
                            <span className='rollapp-name'>{rollapp.chainName}</span>
                            {!rollapp.tokenless && (
                                <span className='asset-name'>
                                    {asset?.currency.displayDenom || getMainCurrency(rollapp).displayDenom}
                                </span>
                            )}
                        </div>
                        {asset ? (
                            <StatisticsChange
                                showPeriod={false}
                                currentValue={asset.price}
                                previousValue={asset.previousDayPrice}
                                align='right'
                                period={'day'}
                            >
                                {formatPrice(asset.price, undefined, undefined, 6)}
                            </StatisticsChange>
                        ) : rollapp.tokenless ? <span className='secondary-text'>DYM Native</span> : undefined}
                    </div>
                );
            })}
        </div>
    );
};

export default RollappsHighlights;
