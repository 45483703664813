import { Decimal } from 'cosmjs/packages/math';
import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { firestore } from '../../../../shared/utils/firebase-utils';
import { Network } from '../../../network/network-types';
import {
    calculateSupplyAllocationAmounts,
    getAllocationsFromGenesis,
    getDistributionFromGenesis,
    loadGenesis,
    saveRollappTokenomics,
} from '../../../rollapp/manage-rollapps-page/create-rollapp-page/create-rollapp-service';
import { DEFAULT_DENOM_EXPONENT } from '../../../rollapp/manage-rollapps-page/create-rollapp-page/types';
import { RollAppTokenomics } from '../../../rollapp/rollapp-types';
import './network-tokenomics.scss';

const TOKENOMICS_DATABASE_PATH = 'rollapp-tokenomics';

interface UseNetworkTokenomicsValue {
    loading: boolean;
    tokenomics?: RollAppTokenomics;
}

const useNetworkTokenomics = (network: Network): UseNetworkTokenomicsValue => {
    const [ loading, setLoading ] = useState(true);
    const [ tokenomics, setTokenomics ] = useState<RollAppTokenomics>();

    const fetchFromGenesis = useCallback(async () => {
        if (!network.genesisUrl) {
            throw new Error('Missing genesis URL');
        }
        return loadGenesis(network.genesisUrl).then(({ genesisString }) => {
            const genesis = JSON.parse(genesisString);
            const { accounts, accountsSupply, communityPoolSupply, iroSupply } = getAllocationsFromGenesis(genesis);
            const totalSupply = accountsSupply + communityPoolSupply + iroSupply;
            const supplyAllocation = {
                iro: Number(Decimal.fromAtomics((BigInt(100 * 10 ** DEFAULT_DENOM_EXPONENT) *
                    iroSupply / totalSupply).toString(), DEFAULT_DENOM_EXPONENT).toString()),
                communityPool: Number(Decimal.fromAtomics((BigInt(100 * 10 ** DEFAULT_DENOM_EXPONENT) *
                    communityPoolSupply / totalSupply).toString(), DEFAULT_DENOM_EXPONENT).toString()),
            };
            const distribution = getDistributionFromGenesis(genesis);
            const supplyAllocationAmounts = calculateSupplyAllocationAmounts(supplyAllocation, totalSupply);
            return saveRollappTokenomics(network.chainId, accounts, supplyAllocationAmounts, distribution);
        });
    }, [ network.chainId, network.genesisUrl ]);

    useEffect(() => {
        getDoc(doc(firestore, `${TOKENOMICS_DATABASE_PATH}/${network.chainId}`))
            .then((snapshot) => !snapshot.exists() ? undefined : snapshot.data() as RollAppTokenomics)
            .catch(() => undefined)
            .then((result) => result || fetchFromGenesis())
            .then((result) => setTokenomics(result))
            .finally(() => setLoading(false));
    }, [ fetchFromGenesis, network.chainId ]);

    return { loading, tokenomics };
};

export default useNetworkTokenomics;
