import { useEffect, useMemo, useState } from 'react';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useAsset } from '../../../asset/asset-context';
import { useClient } from '../../../client/client-context';
import { getStakingCurrency } from '../../../currency/currency-service';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';
import { loadDelegations, loadRewards } from '../../../staking/staking-service';
import { Delegation, Reward } from '../../../staking/staking-types';
import { Validator } from '../../../staking/validator/validator-types';
import {
    getValidatorsWithDelegations,
    getValidatorsWithRewards,
    loadDelegatedValidators,
} from '../../../staking/validator/validator.service';
import { convertToBech32Address } from '../../../wallet/wallet-service';
import { useHubNetworkState } from '../../hub-network-state-context';
import { useAccountConfiguration } from '../use-account-configuration';

const LOADING_TIMES_OFFSET = 5000;

export interface UseAccountStakeValue {
    delegatedValidators?: Validator[];
    loading: boolean;
    unavailableEndpointRollapps: Network[];
}

export const useAccountStake = (toLoad?: boolean, dataRefreshing?: boolean): UseAccountStakeValue => {
    const hubNetworkState = useHubNetworkState();
    const { getNetwork } = useNetwork();
    const { getTokenPrice } = useAsset();
    const { configuration } = useAccountConfiguration();
    const { clientStateMap } = useClient();
    const [ delegatedValidators, setDelegatedValidators ] = useState<Validator[]>();
    const [ stakingDataLoading, setStakingDataLoading ] = useState(true);
    const [ unavailableEndpointRollapps, setUnavailableEndpointRollapps ] = useState<Network[]>([]);
    const cancelAndSetDelegatedValidatorsPromise = useCancelablePromise<PromiseSettledResult<[ Delegation[], Validator[], Reward[] ]>[]>();
    const [ , setPreviousLoadingDate ] = useState<number>(0);

    const loading = useMemo(
        () => stakingDataLoading ||
            Boolean(configuration?.visibleNetworks.some((networkId) => clientStateMap[networkId]?.connecting)),
        [ clientStateMap, configuration?.visibleNetworks, stakingDataLoading ],
    );

    useEffect(() => {
        setDelegatedValidators(undefined);
        setUnavailableEndpointRollapps([]);
        setPreviousLoadingDate(0);
        setStakingDataLoading(Boolean(hubNetworkState.address));
        cancelAndSetDelegatedValidatorsPromise();
    }, [ cancelAndSetDelegatedValidatorsPromise, hubNetworkState.address ]);

    useEffect(() => {
        setPreviousLoadingDate(0);
    }, [ configuration?.visibleNetworks, dataRefreshing ]);

    useEffect(() => {
        const clientStates = configuration?.visibleNetworks.map((networkId) => clientStateMap[networkId]);
        if (!toLoad ||
            !hubNetworkState.hexAddress ||
            !clientStates ||
            !configuration ||
            clientStates.some((state) => !state || state.connecting)) {
            return;
        }
        setPreviousLoadingDate((previousLoadingDate) => {
            if (Date.now() - previousLoadingDate < LOADING_TIMES_OFFSET) {
                return previousLoadingDate;
            }
            setStakingDataLoading(true);
            const unavailableEndpointRollapps = filterNonEmptyValues((configuration.visibleNetworks || [])
                .filter((networkId) => !clientStateMap[networkId]?.client).map((networkId) => getNetwork(networkId)));
            const clients = filterNonEmptyValues(clientStates.map((state) => state?.client));
            cancelAndSetDelegatedValidatorsPromise(() => Promise
                .allSettled(clients.map(async (client) => {
                    const address = convertToBech32Address(hubNetworkState.hexAddress || '', client.getNetwork().bech32Prefix || '');
                    const currency = getStakingCurrency(client.getNetwork());
                    return Promise.all([
                        loadDelegations(client, currency, address),
                        loadDelegatedValidators(client, currency, address),
                        loadRewards(client, address),
                    ]);
                })))
                .then((results) => {
                    const delegatedValidators = results.reduce((current, result, resultIndex) => {
                        const network = clients[resultIndex].getNetwork();
                        if (result.status === 'rejected') {
                            unavailableEndpointRollapps.push(network);
                            console.error(`Can't fetch staking data for ${network.chainId}`, result.reason);
                            return current;
                        }
                        const [ delegations, validators, rewards ] = result.value;
                        const currentDelegatedValidators =
                            getValidatorsWithRewards(getValidatorsWithDelegations(validators, delegations), rewards);
                        currentDelegatedValidators.forEach((validator) => validator.network = network);
                        return [ ...current, ...currentDelegatedValidators ];
                    }, [] as Validator[]).sort((validator1, validator2) => {
                        if (!validator1.network || !validator2.network) {
                            return 0;
                        }
                        const currency1 = getStakingCurrency(validator1.network);
                        const currency2 = getStakingCurrency(validator2.network);
                        const price1 = getTokenPrice({
                            amount: validator1.amountStaked?.amount || 0, currency: currency1, networkId: validator1.network?.chainId || '',
                        }) || 0;
                        const price2 = getTokenPrice({
                            amount: validator2.amountStaked?.amount || 0, currency: currency2, networkId: validator2.network?.chainId || '',
                        }) || 0;
                        return price2 - price1;
                    });
                    setDelegatedValidators(delegatedValidators);
                    setUnavailableEndpointRollapps(unavailableEndpointRollapps);
                })
                .catch(() => {
                    setDelegatedValidators([]);
                    setUnavailableEndpointRollapps(clients.map((client) => client.getNetwork()));
                })
                .finally(() => setStakingDataLoading(false));

            return Date.now();
        });
    }, [
        toLoad,
        cancelAndSetDelegatedValidatorsPromise,
        clientStateMap,
        configuration,
        getTokenPrice,
        getNetwork,
        hubNetworkState.hexAddress,
    ]);

    return { delegatedValidators, loading, unavailableEndpointRollapps };
};
