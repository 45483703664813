import React from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import { IbcTransferDetails } from '../../ibc-status-types';
import { useResolveTransfer } from '../use-resolve-transfer';
import './resolve-transfer-dialog.scss';

interface ResolveTransferDialogProps extends DialogProps {
    transfer: IbcTransferDetails;
    onResolve: () => void;
}

const ResolveTransferDialog: React.FC<ResolveTransferDialogProps> = ({ transfer, onResolve, ...otherDialogProps }) => {
    const { resolveAll, broadcasting, broadcast, setResolveAll } = useResolveTransfer(transfer, onResolve);

    return (
        <Dialog className='resolve-transfer-dialog' closable {...otherDialogProps}>
            <DialogTitle>Resolve Pending Transfer</DialogTitle>
            <DialogContent className='dialog-content'>
                This will attempt to finalize your pending transfer.
                <ul className='list'>
                    <li>If the transfer is still valid, it will be pushed through.</li>
                    <li>If it has timed out, the transfer will be marked as failed and your funds will be returned.</li>
                </ul>
                <label className='resolve-all-checkbox-label'>
                    <input
                        className='resolve-all-checkbox'
                        type='checkbox'
                        checked={resolveAll}
                        onChange={(event) => setResolveAll(event.target.checked)}
                    />
                    &nbsp;Also resolve all other pending transfers on these networks bridge<br/>
                    <small className='secondary-text'>When checked, the same action will be applied to all other pending transfers for these networks bridge</small>
                </label>
            </DialogContent>
            <DialogAction secondary close>Cancel</DialogAction>
            <DialogAction
                primary
                loading={broadcasting}
                disabled={broadcasting}
                onClick={() => broadcast()}
            >
                Resolve&nbsp;<span className='secondary-text'>(2{resolveAll ? '-4' : ''} signatures)</span>
            </DialogAction>
        </Dialog>
    );
};

export default ResolveTransferDialog;
