import { Any } from '@bufbuild/protobuf';
import { EncodeObject } from '@cosmjs/proto-signing';
import { GenericAuthorization, GrantAuthorization } from 'cosmjs-types/cosmos/authz/v1beta1/authz';
import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { BasicAllowance } from 'cosmjs-types/cosmos/feegrant/v1beta1/feegrant';
import { toTimestamp } from 'cosmjs-types/helpers';
import { fromBase64, toBase64 } from 'cosmjs/packages/encoding';
import { Registry } from 'cosmjs/packages/proto-signing';
import { MsgGrantEncodeObject } from 'cosmjs/packages/stargate';
import { MsgRevokeEncodeObject } from 'cosmjs/packages/stargate/src/modules/authz/messages';
import { Writer } from 'protobufjs';
import { filterNonEmptyValues } from '../../shared/utils/object-utils';
import { Network } from '../network/network-types';
import {
    QUICK_AUTH_MESSAGE_TYPES,
    QUICK_AUTH_MESSAGE_TYPES_KEY, QUICK_AUTH_ROLLAPP_EVM_MESSAGES, QUICK_AUTH_ROLLAPP_WASM_MESSAGES,
    QUICK_AUTH_TYPE_MESSAGES_MAP,
    QuickAuthAccount,
    QuickAuthMessageType,
} from './quick-auth-types';

export const createGrantAuthorizationMessages = (
    network: Network,
    granter: string,
    grantee: string,
    expiration: number,
    messageTypes?: string[],
): MsgGrantEncodeObject[] => {
    try {
        return (messageTypes || getQuickAuthMessages(network)).map((message) => {
            const writer = Writer.create();
            GenericAuthorization.encode({ msg: message }, writer);
            const authorization = new Any({ typeUrl: '/cosmos.authz.v1beta1.GenericAuthorization', value: writer.finish() });
            return {
                typeUrl: '/cosmos.authz.v1beta1.MsgGrant',
                value: { granter, grantee, grant: { authorization, expiration: toTimestamp(new Date(expiration)) } },
            };
        });
    } catch {
        return [];
    }
};

export const createGrantAllowanceMessages = (granter: string, grantee: string, expiration: number, spendLimit: Coin[]): EncodeObject => {
    const writer = Writer.create();
    BasicAllowance.encode({ spendLimit, expiration: toTimestamp(new Date(expiration)) }, writer);
    const allowance = new Any({ typeUrl: '/cosmos.feegrant.v1beta1.BasicAllowance', value: writer.finish() });
    return { typeUrl: '/cosmos.feegrant.v1beta1.MsgGrantAllowance', value: { granter, grantee, allowance } };
};

export const createRevokeAuthorizationMessages = (grantAuthorizations: GrantAuthorization[]): MsgRevokeEncodeObject[] => {
    return filterNonEmptyValues(grantAuthorizations.map(({ authorization, grantee, granter }) => {
        if (!authorization?.value) {
            return undefined;
        }
        const genericAuthorization = GenericAuthorization.decode(authorization.value);
        return {
            typeUrl: '/cosmos.authz.v1beta1.MsgRevoke',
            value: { granter, grantee, msgTypeUrl: genericAuthorization.msg },
        };
    }));
};

export const createRevokeAllowanceMessage = (granter: string, grantees: string[]): EncodeObject[] => {
    return grantees.map((grantee) => ({
        typeUrl: '/cosmos.feegrant.v1beta1.MsgRevokeAllowance',
        value: { granter, grantee },
    }));
};

export const isQuickAuthGrantedMessages = (network: Network, messages: EncodeObject[]): boolean => {
    const quickAuthMessages = getQuickAuthMessages(network);
    return messages.every((message) => quickAuthMessages.includes(message.typeUrl));
};

export const createGrantExecMessage = (grantee: string, messages: EncodeObject[], registry: Registry): EncodeObject => {
    const msgs = messages.map((message) => registry.encodeAsAny(message));
    return { typeUrl: '/cosmos.authz.v1beta1.MsgExec', value: { msgs, grantee } };
};

export const encryptQuickAuthAccount = async (account: QuickAuthAccount, pinCode: string, pinCodeExpiration: number): Promise<string> => {
    const data = `${account.mnemonic}-${account.expiration}-${account.sourceAccount.address}-${pinCodeExpiration}-` +
        account.sourceAccount.pubkey.join(',');
    const encoder = new TextEncoder();
    const mnemonicBytes = encoder.encode(data);
    const pinBytes = encoder.encode(pinCode);
    const encrypted = mnemonicBytes.map((byte, i) => byte ^ pinBytes[i % pinBytes.length]);
    return toBase64(encrypted);
};

export const decryptQuickAuthAccount = async (
    encryptedData: string,
    pinCode: string,
): Promise<{ error?: string, account?: QuickAuthAccount }> => {
    const decoder = new TextDecoder();
    const encryptedBytes = fromBase64(encryptedData);
    const pinBytes = new TextEncoder().encode(pinCode);
    const decryptedBytes = encryptedBytes.map((byte, i) => byte ^ pinBytes[i % pinBytes.length]);
    const decryptedString = decoder.decode(decryptedBytes);
    const [ mnemonic, expiration, address, pinCodeExpiration, pubkeyString ] = decryptedString.split('-');
    if (!mnemonic || mnemonic.split(' ').length !== 12) {
        return { error: 'Invalid pin code' };
    }
    if (Number(pinCodeExpiration) < Date.now()) {
        return { error: 'Expired pin code' };
    }
    const pubkey = Uint8Array.from(pubkeyString.split(',').map(Number));
    return { account: { mnemonic, expiration: Number(expiration), sourceAccount: { address, pubkey, algo: 'secp256k1' } } };
};

const getQuickAuthMessages = (network: Network): string[] => {
    if (network.type === 'RollApp') {
        return network.evmType === 'EVM' ? QUICK_AUTH_ROLLAPP_EVM_MESSAGES : QUICK_AUTH_ROLLAPP_WASM_MESSAGES;
    }
    const messageTypes = JSON.parse(
        localStorage.getItem(QUICK_AUTH_MESSAGE_TYPES_KEY) || JSON.stringify(QUICK_AUTH_MESSAGE_TYPES)) as QuickAuthMessageType[];
    return messageTypes.reduce<string[]>((current, type) => [ ...current, ...QUICK_AUTH_TYPE_MESSAGES_MAP[type] ], []);
};
