import React, { useEffect } from 'react';
import Alert from '../../../shared/components/alert/alert';
import Spinner from '../../../shared/components/spinner/spinner';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { useSponsorship } from '../sponsorship-context';
import SponsorshipStatistics from '../sponsorship-statistics/sponsorship-statistics';
import SponsorshipDistributionChart from '../sponsorship-distribution-chart/sponsorship-distribution-chart';
import SponsorshipList from '../sponsorship-list/sponsorship-list';
import './sponsorship-page.scss';

const SponsorshipPage: React.FC = () => {
    const { width } = useWindowSize();
    const { loading, voteLoading, vote, distribution, totalStreamed, daysStreamed, loadVote } = useSponsorship();

    useEffect(() => loadVote(), [ loadVote ]);

    return (
        <div className='sponsorship page'>
            <SponsorshipStatistics className='sponsorship-statistics' vertically={width > 1440} />

            <div className='sponsorship-chart-container section small'>
                {loading && distribution === undefined ? <Spinner className='chart-spinner' size='large' /> :
                    !distribution?.length ? <span className='no-data secondary-text'>No endorsement data</span> : undefined}
                <SponsorshipDistributionChart distribution={distribution} totalStreamed={totalStreamed} daysStreamed={daysStreamed} />
            </div>

            <Alert type='info' className='sponsorship-info-box'>
                Endorsement is a weight-based voting system for DYM stakers,
                responsible for choosing how to split the onchain incentives between RollApp creators and liquidity pools.
            </Alert>

            <SponsorshipList
                loading={voteLoading && vote === undefined}
                className='vote-list'
                title='My Endorsements'
                records={vote || []}
                isSponsored={() => true}
                headerClassName='account-sponsorships-header'
            />
            <SponsorshipList
                loading={loading && distribution === undefined}
                className='distribution-list'
                title='All Candidates'
                pagination
                records={distribution || []}
                sponsoredColumnName='Total Endorsed'
                isSponsored={(sponsorship) => Boolean(vote?.some((record) => record.gaugeId === sponsorship.gaugeId))}
            />
        </div>
    );
};

export default SponsorshipPage;
