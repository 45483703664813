import { CoinsAmount } from '../../currency/currency-types';
import { Validator as StakingValidator } from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import { Network } from '../../network/network-types';

export const MAX_VALIDATORS_COUNT = 1000;

export const VALIDATORS_TYPES = [ 'All', 'Staked', 'Unstaking' ] as const;
export type ValidatorsType = typeof VALIDATORS_TYPES[number];

export const VALIDATOR_STATUSES = [ 'Active', 'Deactivating', 'Inactive' ] as const;
export type ValidatorStatus = typeof VALIDATOR_STATUSES[number];

export interface Validator {
    name: string;
    tokens: CoinsAmount;
    status: ValidatorStatus;
    address: string;
    commission?: number;
    maxCommission?: number;
    amountStaked?: CoinsAmount;
    reward?: number;
    unstaking?: { completionTime: Date; amount: number; baseAmount: bigint; creationHeight: number },
    originalValidator: StakingValidator;
    network?: Network;
}
