import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import Alert from '../../../../shared/components/alert/alert';
import Button from '../../../../shared/components/button/button';
import Link from '../../../../shared/components/link/link';
import { ReactComponent as AddDelegationIcon } from '../../../../assets/icons/add-delegation.svg';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useAsset } from '../../../asset/asset-context';
import { useNetwork } from '../../../network/network-context';
import NetworkSelector from '../../../network/network-selector/network-selector';
import { Network } from '../../../network/network-types';
import { getValidatorLogoPath } from '../../../staking/validator/validator.service';
import { useNavigate } from 'react-router-dom';
import { getStakingCurrency } from '../../../currency/currency-service';
import { Validator } from '../../../staking/validator/validator-types';
import NetworksSelectorDialog from '../networks-selector-dialog/networks-selector-dialog';
import { useAccountConfiguration } from '../use-account-configuration';
import AccountTotalStakedValue from './account-total-staked-value/account-total-staked-value';
import './account-stake.scss';

interface AccountStakeProps {
    className?: string;
    optionalNetworks: string[];
    delegatedValidators?: Validator[];
    loading: boolean;
    onRequestClose?: () => void;
    unavailableEndpointRollapps: Network[];
}

const VALIDATOR_FALLBACK_IMAGE = require('../../../../assets/icons/validator.svg').default;

const AccountStake: React.FC<AccountStakeProps> = ({
    className,
    delegatedValidators,
    loading,
    unavailableEndpointRollapps,
    onRequestClose,
    optionalNetworks,
}) => {
    const navigate = useNavigate();
    const { getTokenPrice } = useAsset();
    const { getNetwork } = useNetwork();
    const [ networksSelectorDialogVisible, setNetworksSelectorDialogVisible ] = useState(false);
    const { configuration, setVisibleNetworks } = useAccountConfiguration();

    const networks = useMemo(
        () => filterNonEmptyValues(optionalNetworks.map((networkId) => getNetwork(networkId))),
        [ getNetwork, optionalNetworks ],
    );

    const onImageError = (imageElement: HTMLImageElement | null): void => {
        if (imageElement) {
            imageElement.src = VALIDATOR_FALLBACK_IMAGE;
            imageElement.classList.add('fallback');
        }
    };

    const onDelegatedValidatorClick = useCallback((validator: Validator) => {
        navigate(`${validator.network?.type === 'RollApp' ? `rollapps/${validator.network?.chainId}` : 'dymension'}/staking`);
        onRequestClose?.();
    }, [ navigate, onRequestClose ]);

    return <>
        <AccountTotalStakedValue delegatedValidators={delegatedValidators} loading={loading} />
        <ul className={classNames('account-stake', className)}>
            {loading && <Spinner className='delegations-loader' />}
            {delegatedValidators?.map((validator, validatorIndex) => {
                const stakeCurrency = validator.network && getStakingCurrency(validator.network);
                return (
                    <li key={validatorIndex} className='delegation-row' onClick={() => onDelegatedValidatorClick(validator)}>
                        <img
                            src={validator.network ? getValidatorLogoPath(validator.network, validator) : ''}
                            className='validator-logo'
                            alt='validator-logo'
                            onError={(error) => onImageError(error.target as HTMLImageElement)}
                        />
                        <span className='validator-name-container'>
                            <span className='ellipsis'>{validator.name}</span>
                            {validator.network && <span className='validator-network ellipsis'>{validator.network?.chainName}</span>}
                        </span>

                        <span className='delegation-amount'>
                            {formatPrice(validator.amountStaked?.amount || 0, stakeCurrency?.displayDenom, undefined, 9)}
                            <span className='delegation-value'>
                                {stakeCurrency && formatPrice(getTokenPrice({
                                    amount: validator.amountStaked?.amount || 0,
                                    currency: stakeCurrency,
                                    networkId: validator.network?.chainId || '',
                                }) || 0)}
                            </span>
                        </span>
                    </li>
                );
            })}

            {!loading || delegatedValidators?.length ? (
                <NetworkSelector
                    networks={networks}
                    hideWalletSelector
                    size='small'
                    fitToTriggerWidth={false}
                    optionsOverlayAlign='center'
                    onSelect={(value) => {
                        const network = getNetwork(value as string);
                        navigate(`${network?.type === 'RollApp' ? `rollapps/${network?.chainId}` : 'dymension'}/staking`);
                        onRequestClose?.();
                    }}
                    renderCustomTrigger={() => (
                        <Button trackEvent='account_menu_new_stake_click' buttonType='secondary' size='small' className='new-stake-button'><AddDelegationIcon />&nbsp;&nbsp;New Stake</Button>
                    )}
                />
            ) : undefined}

            {unavailableEndpointRollapps.length ? (
                <Alert className='endpoints-warning' type='warning'>
                    The following networks might not represent all delegations due to endpoint limitations:
                    <ul className='list'>
                        {unavailableEndpointRollapps.map((network) => <li key={network.chainId}>{network.chainName}</li>)}
                    </ul>
                </Alert>
            ) : undefined}
        </ul>

        <Link
            className='visible-networks-action'
            onClick={() => setNetworksSelectorDialogVisible(true)}
            trackEvent='account_menu_visible_rollapps_click'
        >
            Visible RollApps
        </Link>

        {networksSelectorDialogVisible &&
            <NetworksSelectorDialog
                initialNetworks={configuration?.visibleNetworks}
                onConfirm={setVisibleNetworks}
                optionalNetworks={optionalNetworks}
                onRequestClose={() => setNetworksSelectorDialogVisible(false)}
            />}
    </>;
};

export default AccountStake;
