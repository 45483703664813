import React, { useMemo } from 'react';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useAsset } from '../../../asset/asset-context';
import ClaimIro from '../../../iro/claim-iro/claim-iro';
import BuySell, { BuySellProps } from '../../../trade/buy-sell/buy-sell';
import { TradeContextProvider } from '../../../trade/trade-context';
import { useAmm } from '../../amm-context';
import { Asset } from '../../../asset/asset-types';
import { TokensSwapContext, TokensSwapContextProvider } from '../tokens-swap-context';

const SwapBuySell: React.FC<Omit<BuySellProps, 'tradeType' | 'setTradeType' | 'inverseAsset' | 'asset'> & { asset: Asset }> = (props) => {
    const { ammState } = useAmm();
    const { hubAsset } = useAsset();
    const { isTablet } = useWindowSize();

    const inverseAsset = useMemo(
        () => props.asset.network.type === 'Hub' ? ammState.params?.vsCoins : hubAsset,
        [ ammState.params?.vsCoins, hubAsset, props.asset.network.type ],
    );

    const availableBalances = useMemo(() => filterNonEmptyValues([ inverseAsset, props.asset ]), [ inverseAsset, props.asset ]);

    return (
        <TokensSwapContextProvider initialAsset1={inverseAsset} initialAsset2={props.asset} optionalAssets={availableBalances}>
            <TokensSwapContext.Consumer>
                {(context) => (
                    <TradeContextProvider {...context} slippageToleranceMode='alert'>
                        {!isTablet && <ClaimIro asset={props.asset} />}

                        <BuySell
                            {...props}
                            onTradeTypeChange={context.switchTokens}
                            asset={props.asset}
                            isExactAmountSpend
                            inverseAsset={inverseAsset}
                            canUseInverseAssetInBuy
                        />
                    </TradeContextProvider>
                )}
            </TokensSwapContext.Consumer>
        </TokensSwapContextProvider>
    );
};

export default SwapBuySell;
