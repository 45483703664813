import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import Alert from '../../../../../shared/components/alert/alert';
import Button from '../../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import Input from '../../../../../shared/components/form-controls/input/input';
import { filterNonEmptyValues } from '../../../../../shared/utils/object-utils';
import { CoinsAmount } from '../../../../currency/currency-types';
import { useNetwork } from '../../../../network/network-context';
import NetworkSelector from '../../../../network/network-selector/network-selector';
import AmountTx from '../../../../tx/amount-tx/amount-tx';
import { useAccountNetwork } from '../../../use-account-network';
import { useSendFunds } from './use-send-funds';
import './send-funds-dialog.scss';

interface SendFundsDialogProps extends DialogProps {
    optionalNetworkIds?: string[];
    initialCoins?: CoinsAmount;
}

const SendFundsDialog: React.FC<SendFundsDialogProps> = ({ optionalNetworkIds, initialCoins, className, ...otherDialogProps }) => {
    const { getNetwork } = useNetwork();
    const [ networkState, selectNetwork ] =
        useAccountNetwork(initialCoins?.containerNetworkId ? getNetwork(initialCoins.containerNetworkId) : undefined);
    const { destinationAddress, txState, amountTxState, haveEvmContract, setDestinationAddress, setCoins, broadcast } =
        useSendFunds(networkState, initialCoins);
    const [ memo, setMemo ] = useState<string>();

    const optionalNetworks = useMemo(
        () => !optionalNetworkIds ? undefined : filterNonEmptyValues(optionalNetworkIds.map((networkId) => getNetwork(networkId))),
        [ getNetwork, optionalNetworkIds ],
    );

    const network = useMemo(() => networkState.network, [ networkState.network ]);

    const optionalAddressPrefixes = useMemo(
        () => network ? [ network.bech32Prefix, '0x' ].filter(Boolean) as string[] : [],
        [ network ],
    );

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !destinationAddress ||
        (amountTxState.coins?.ibc && !haveEvmContract && network?.evmType !== 'WASM' && !network?.tokenless) ||
        optionalAddressPrefixes.every((prefix) => !destinationAddress.startsWith(prefix)) ||
        !amountTxState.coins?.amount);

    return (
        <Dialog closable className={classNames('send-funds-dialog', className)} {...otherDialogProps}>
            <DialogTitle>Send Funds</DialogTitle>
            <DialogContent>
                <label className='address-input-label'>Destination address</label>
                <NetworkSelector
                    networks={optionalNetworks}
                    networkData={networkState}
                    address={destinationAddress}
                    setAddress={setDestinationAddress}
                    onNetworkSelect={(network) => network && selectNetwork(getNetwork(network))}
                    showAddressInput
                    hideWalletSelector
                    hideAddress
                />

                <label className='address-input-label'>Memo (optional)</label>
                <Input onValueChange={setMemo} value={memo} controlSize='large' />

                <label className='address-input-label'>Token and amount</label>
                <AmountTx
                    controlSize='large'
                    txState={txState}
                    loading={networkState.balancesLoading}
                    amountTxState={amountTxState}
                    networkState={networkState}
                    onCoinsChange={setCoins}
                    submitButtonContainer={<>
                        {amountTxState.coins?.ibc && !haveEvmContract && network?.evmType !== 'WASM' && !network?.tokenless && (
                            <Alert type='error' className='send-warning'>
                                Non-native tokens without an ERC-20 contract cannot be sent on network {network?.chainName}
                            </Alert>
                        )}
                        <Alert type='warning' className='send-warning'>
                            <b>Warning:</b> Some centralized exchanges require memo when depositing to a native dym address.
                        </Alert>
                        <Button
                            size='x-large'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={confirmButtonDisabled}
                            onClick={() => broadcast(memo)}
                        >
                            Send
                        </Button>
                    </>}
                />
            </DialogContent>
        </Dialog>
    );
};

export default SendFundsDialog;
