import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import { ButtonProps } from '../button/button';
import InfoIndicator from '../info-indicator/info-indicator';
import './property.scss';
import Spinner from '../spinner/spinner';

export interface PropertyProps {
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
    valueSuffixClassName?: string;
    infoIndicatorClassName?: string;
    style?: CSSProperties;
    children: ReactNode;
    centered?: boolean;
    loading?: boolean;
    na?: boolean;
    label: ReactNode;
    size?: 'small' | 'medium';
    info?: ReactNode;
    valueSuffix?: ReactNode;
    infoIndicatorSize?: ButtonProps['size'];
}

const Property: React.FC<PropertyProps> = ({
    children,
    label,
    info,
    valueSuffix,
    centered,
    className,
    labelClassName,
    valueClassName,
    valueSuffixClassName,
    infoIndicatorClassName,
    loading,
    na,
    size = 'medium',
    style,
    infoIndicatorSize,
}) => {
    const propertyClassName = classNames('property', className, size, { centered });
    const propertyLabelClassName = classNames('property-label', labelClassName, { 'have-info': Boolean(info) });
    const propertyValueClassName = classNames('property-value', valueClassName);
    const propertyValueSuffixClassName = classNames('property-value-suffix', valueSuffixClassName);
    const propertyInfoIndicatorClassName = classNames('column-info-indicator', infoIndicatorClassName);

    return (
        <div className={propertyClassName} style={style}>
            <p className={propertyLabelClassName}>
                {label}
                {info ? (
                    <InfoIndicator
                        indicatorSize={infoIndicatorSize || size}
                        tooltipPlacement='top'
                        className={propertyInfoIndicatorClassName}
                    >
                        {info}
                    </InfoIndicator>
                ) : null}
            </p>
            <div className={propertyValueClassName}>
                {loading ? <Spinner size='small' /> : na ? <span className='secondary-text'>N/A</span> :
                    <>{children}{valueSuffix && <>&nbsp;<span className={propertyValueSuffixClassName}>{valueSuffix}</span></>}</>}
            </div>
        </div>
    );
};

export default Property;
