import { Any } from '@bufbuild/protobuf';
import { toTimestamp } from 'cosmjs-types/helpers';
import { MsgGrantEncodeObject, MsgRevokeEncodeObject } from 'cosmjs/packages/stargate';
import { Writer } from 'protobufjs';
import { convertDecimalToInt, convertIntToDecimal } from '../../../shared/utils/number-utils';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import { ClientError } from '../../client/client-error';
import { ALL_ITEMS_PAGINATION_LONG } from '../../client/client-types';
import { FulfillOrderAuthorization, RollappCriteria } from '../../client/station-clients/dymension/generated/eibc/authz';
import { StationClient } from '../../client/station-clients/station-client';
import { convertToCoin, convertToCoinsAmount } from '../../currency/currency-service';
import { RollappLiquidity } from './eibc-client-types';

const AUTHORIZATION_TYPE = '/dymensionxyz.dymension.eibc.FulfillOrderAuthorization';
const AUTHORIZATION_REVOKE_TYPE = '/dymensionxyz.dymension.eibc.MsgFulfillOrderAuthorized';

export const createGrantFulfillOrderAuthorizationMessage = (
    address: string,
    updatedLiquidity: RollappLiquidity,
    liquidityList: RollappLiquidity[] = [],
    toRemove?: boolean,
): MsgGrantEncodeObject | MsgRevokeEncodeObject | undefined => {
    const fixedLiquidityList = toRemove ? [] : [ updatedLiquidity ];
    fixedLiquidityList.push(...liquidityList.filter((liquidity) => liquidity.rollapp.chainId !== updatedLiquidity.rollapp.chainId &&
        liquidity.operatorGroupAddress === updatedLiquidity.operatorGroupAddress));
    const rollappCriteriaList = fixedLiquidityList.map((liquidity): RollappCriteria => {
        const spendLimit = liquidity.tokens.map((coins) => convertToCoin(coins))
            .sort((coin1, coin2) => coin1.denom === coin2.denom ? 0 : coin1.denom > coin2.denom ? 1 : -1);
        return {
            rollappId: liquidity.rollapp.chainId,
            denoms: spendLimit.map((token) => token.denom),
            maxPrice: spendLimit,
            spendLimit,
            settlementValidated: false,
            minLpFeePercentage: { dec: BigInt(convertIntToDecimal(liquidity.minLpFee || 0)).toString() },
            operatorFeeShare: { dec: BigInt(convertIntToDecimal(liquidity.operatorFeeShare || 0)).toString() },
        };
    });
    if (!rollappCriteriaList.length) {
        return {
            typeUrl: '/cosmos.authz.v1beta1.MsgRevoke',
            value: { granter: address, grantee: updatedLiquidity.operatorGroupAddress, msgTypeUrl: AUTHORIZATION_REVOKE_TYPE },
        };
    }
    try {
        const writer = Writer.create();
        FulfillOrderAuthorization.encode({ rollapps: rollappCriteriaList }, writer);
        const authorizationValue = new Any({ typeUrl: AUTHORIZATION_TYPE, value: writer.finish() });
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1000);
        date.setMilliseconds(0);
        return {
            typeUrl: '/cosmos.authz.v1beta1.MsgGrant',
            value: {
                granter: address,
                grantee: updatedLiquidity.operatorGroupAddress,
                grant: { authorization: authorizationValue, expiration: toTimestamp(date) },
            },
        };
    } catch {}
};

export const getRollappLiquidityList = async (client: StationClient, address: string): Promise<RollappLiquidity[]> => {
    const network = client.getNetwork();
    const { grants } = await client.getAuthZQueryClient()
        .GranterGrants({ granter: address, pagination: ALL_ITEMS_PAGINATION_LONG })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', network, error);
        });
    const eibcAuthorizations = filterNonEmptyValues(grants.filter((grant) => grant.authorization?.typeUrl === AUTHORIZATION_TYPE));
    const liquidityGroups = await Promise.all(eibcAuthorizations.map(async (grant) => {
        const rollappCriteriaList = grant.authorization ? FulfillOrderAuthorization.decode(grant.authorization.value).rollapps : [];
        return Promise.all(rollappCriteriaList.map(async (criteria) => {
            const rollapp = client.getAllNetworks().find((network) => network.chainId === criteria.rollappId);
            const tokens = await Promise.all(criteria.spendLimit.map((coin) => convertToCoinsAmount(coin, client)));
            return !rollapp ? undefined : {
                rollapp,
                minLpFee: convertDecimalToInt(Number(criteria.minLpFeePercentage?.dec) || 0),
                operatorFeeShare: convertDecimalToInt(Number(criteria.operatorFeeShare?.dec) || 0),
                tokens: filterNonEmptyValues(tokens),
                operatorGroupAddress: grant.grantee,
            };
        }));
    }));
    return filterNonEmptyValues(liquidityGroups.reduce((current, liquidityList) => [ ...current, ...liquidityList ], []));
};
