import React, { ReactElement, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Alert from '../../../shared/components/alert/alert';
import Badge from '../../../shared/components/badge/badge';
import Button from '../../../shared/components/button/button';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { timeToMilliseconds } from '../../../shared/utils/date-utils';
import { getCurrencyLogoPath } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { ReactComponent as EndorsementIcon } from '../../../assets/icons/endorsement.svg';
import { ReactComponent as SwapIcon } from '../../../assets/icons/swap-horiz.svg';
import { ReactComponent as ThunderIcon } from '../../../assets/icons/thunder.svg';
import DisplayNameWithIro from '../../currency/display-name-with-iro/display-name-with-iro';
import AddIncentivesDialog from '../../incentives/add-incentives-dialog/add-incentives-dialog';
import IncentiveBadge from '../../incentives/incentive-badge/incentive-badge';
import { useIncentives } from '../../incentives/incentives-context';
import { useNetwork } from '../../network/network-context';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import { useSponsorship } from '../../sponsorship/sponsorship-context';
import SponsorshipVoteDialog from '../../sponsorship/sponsorship-vote/sponsorship-vote-dialog';
import TokensSwapDialog from '../tokens-swap/tokens-swap-dialog/tokens-swap-dialog';
import { usePoolDashboard } from './pool-dashboard-context';
import PoolPositions from './pool-positions/pool-positions';
import PoolStatistics from './pool-statistics/pool-statistics';
import './pool-dashboard.scss';

export const BOND_DIFF_EPSILON = 10 ** 10;

const PoolDashboard: React.FC = () => {
    const { getNetwork } = useNetwork();
    const { incentivesState } = useIncentives();
    const { pool } = usePoolDashboard();
    const { distribution, sponsorshipPoolAprs } = useSponsorship();
    const [ tokenSwapDialogOpen, setTokenSwapDialogOpen ] = useState(false);
    const [ addIncentivesDialogOpen, setAddIncentivesDialogOpen ] = useState(false);
    const [ sponsorshipVoteDialogOpen, setSponsorshipVoteDialogOpen ] = useState(false);

    const sponsorship = distribution?.find((record) => record.pool?.id === pool.id);

    const haveIncentives = sponsorshipPoolAprs[pool.id] ||
        incentivesState.incentives?.[pool.lpTokenDenom]?.some((incentive) => incentive.coins.length);

    const canBondTokens = useMemo(
        () => Boolean(pool.position?.shares &&
            Math.abs(Number(pool.position.shares) - Number(pool.position?.bondedShares || 0)) >= BOND_DIFF_EPSILON),
        [ pool.position?.bondedShares, pool.position?.shares ],
    );

    const showInactiveLabel = useMemo(() => {
        const network = pool.assets.map((asset) => getNetwork(asset.networkId)).find((network) => network?.type !== 'Hub');
        return (network?.status === 'Unavailable' || network?.status === 'Degraded') &&
            (network?.inactiveTime && network.inactiveTime < Date.now() - timeToMilliseconds({ days: 7 }));
    }, [ getNetwork, pool.assets ]);

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = getNetwork(asset.networkId);

        return currencyNetwork &&
            <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='asset logo' />;
    };

    const renderAssetName = (asset: CoinsAmount): ReactElement => {
        const assetKey = `${asset.networkId}/${asset.currency.baseDenom}`;
        return (
            <NavLink className='denom-name' to={`/amm/asset/${encodeURIComponent(assetKey)}`}>
                <DisplayNameWithIro coins={asset} />
            </NavLink>
        );
    };

    return <>
        <PathNav>
            <PathNavItem label='Trade' />
            <PathNavItem label='Pools' url='/amm/pools' />
            <PathNavItem label={`Pool #${pool.id.toString().padStart(3, '0')}`} />
        </PathNav>

        <div className='pool-header'>
            {renderAssetLogo(pool.assets[0])}
            {renderAssetLogo(pool.assets[1])}
            <h3 className='pool-name'>
                <span className='pool-asset-names'>
                    {renderAssetName(pool.assets[0])}&nbsp;/&nbsp;{renderAssetName(pool.assets[1])}
                    {haveIncentives ? <IncentiveBadge denom={pool.lpTokenDenom} infoPlacement='bottom' /> : undefined}
                </span>
                <span className='pool-number'>Pool #{pool.id.toString().padStart(3, '0')}</span>
            </h3>

            <div className='pool-dashboard-actions'>
                <Button
                    className='pool-dashboard-action'
                    buttonType='secondary'
                    size='small'
                    onClick={() => setAddIncentivesDialogOpen(true)}
                >
                    <ThunderIcon />&nbsp;Add Incentives
                </Button>

                <Button
                    className='pool-dashboard-action'
                    buttonType='secondary'
                    size='small'
                    disabled={!sponsorship}
                    onClick={() => setSponsorshipVoteDialogOpen(true)}
                >
                    <EndorsementIcon />&nbsp;Endorse
                </Button>

                <Button
                    className='pool-dashboard-action'
                    buttonType='primary'
                    size='small'
                    onClick={() => setTokenSwapDialogOpen(true)}
                >
                    <SwapIcon />&nbsp;Trade
                </Button>
            </div>
        </div>

        {showInactiveLabel && (
            <Badge
                color={getCssVariableValue('--red-rgb').split(',').map(Number)}
                className='pool-network-inactive-badge'
                label='RollApp has been inactive for over a week'
            />
        )}

        <PoolStatistics className='pool-statistics' />

        {canBondTokens && (
            <Alert className='bonding-warning' type='warning'>
                You have liquidity tokens that are currently <b>unbonded</b>. By not bonding them, you <b>miss out on additional incentive rewards</b>.
            </Alert>
        )}

        <PoolPositions pool={pool} bondActionHighlighted={canBondTokens} />

        {tokenSwapDialogOpen && (
            <TokensSwapDialog
                fromCoins={pool.assets[0]}
                toCoins={pool.assets[1]}
                onRequestClose={() => setTokenSwapDialogOpen(false)}
            />
        )}

        {addIncentivesDialogOpen && (
            <AddIncentivesDialog
                denom={pool.lpTokenDenom}
                onRequestClose={() => setAddIncentivesDialogOpen(false)}
            />
        )}

        {sponsorshipVoteDialogOpen && sponsorship &&
            <SponsorshipVoteDialog sponsorship={sponsorship} onRequestClose={() => setSponsorshipVoteDialogOpen(false)} />}
    </>;
};

export default PoolDashboard;
