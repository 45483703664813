import React, { useMemo, useState } from 'react';
import { PieLabel, PieLabelRenderProps } from 'recharts/types/polar/Pie';
import { getCssVariableValue } from '../../../../../shared/utils/color-utils';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';
import './tokenomics-chart.scss';

export interface TokenomicsDataValue {
    name: string;
    value: number;
    rgbColor: string;
    lockedValue?: number;
}

interface TokenomicsChartProp {
    chartData: TokenomicsDataValue[];
}

const PIE_CHART_SIZE = 200;
const PIE_CHART_SIZE_SMALL = 160;

export const TokenomicsChart: React.FC<TokenomicsChartProp> = ({ chartData }) => {
    const [ chartWidth, setChartWidth ] = useState(0);

    const pieChartSize = useMemo(() => chartWidth <= 320 ? PIE_CHART_SIZE_SMALL : PIE_CHART_SIZE, [ chartWidth ]);

    const renderCustomizedLabel: PieLabel<PieLabelRenderProps> = (props) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
        if (!percent) {
            return undefined;
        }
        const RADIAN = Math.PI / 180;
        const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
        const x = Number(cx) + radius * Math.cos(-Number(midAngle) * RADIAN) * 1.2;
        const y = Number(cy) + radius * Math.sin(-Number(midAngle) * RADIAN) * 1.2;
        return (
            <text fontSize={12} className='customized-label' x={x} y={y} fontWeight='bold' textAnchor='middle' dominantBaseline='central'>
                {`${formatNumber((Number(percent) * 100), { maximumFractionDigits: 2 })}%`}
            </text>
        );
    };

    return (
        <ResponsiveContainer className='tokenomics-chart-container' height={pieChartSize} onResize={(width) => setChartWidth(width)}>
            <PieChart className='pie-chart-container'>
                {chartData.map((dataItem) => {
                    if (!dataItem.lockedValue) {
                        return undefined;
                    }
                    const outerRadius = pieChartSize * (0.15 + (0.3 * (dataItem.value - dataItem.lockedValue) / dataItem.value));
                    return (
                        <Pie
                            key={dataItem.name}
                            data={chartData}
                            outerRadius={outerRadius}
                            innerRadius={pieChartSize * 0.15}
                            labelLine={false}
                            minAngle={5}
                            legendType='none'
                            isAnimationActive={false}
                            stroke='transparent'
                            dataKey='value'
                        >
                            {chartData?.map((dataItem) =>
                                <Cell key={dataItem.name} fill={dataItem.lockedValue ? `rgb(${dataItem.rgbColor})` : 'transparent'} />)}
                        </Pie>
                    );
                })}

                <Pie
                    data={chartData.map((dataItem) =>
                        !dataItem.lockedValue ? dataItem : ({ ...dataItem, name: `${dataItem.name} (locked)` }))}
                    outerRadius={pieChartSize * 0.5}
                    innerRadius={pieChartSize * 0.15}
                    label={renderCustomizedLabel}
                    labelLine={false}
                    minAngle={5}
                    isAnimationActive={false}
                    strokeWidth={2}
                    stroke={getCssVariableValue('--black')}
                    nameKey='name'
                    dataKey='value'
                >
                    {chartData?.map((dataItem) =>
                        <Cell key={dataItem.name} fill={`rgba(${dataItem.rgbColor}, ${dataItem.lockedValue ? 0.25 : 1})`} />)}
                </Pie>

                <Legend
                    verticalAlign='middle'
                    layout='vertical'
                    content={({ payload }) => payload?.map((dataItem) => {
                        if (dataItem.color === 'transparent') {
                            return undefined;
                        }
                        return (
                            <div className='legend-item' key={dataItem.value}>
                                <span className='bullet' style={{ color: dataItem.color }}>●</span> {dataItem.value}
                            </div>
                        );
                    })}
                    iconType='circle'
                    iconSize={8}
                    align='right'
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default TokenomicsChart;
