import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { SocialLinks } from '../../footer/footer';
import { useAsset } from '../asset-context';
import './scrolling-trade-pairs.scss';

interface ScrollingTradePairsProps {
    className?: string;
}

const ScrollingTradePairs: React.FC<ScrollingTradePairsProps> = ({ className }) => {
    const { width, isTablet, isMobile } = useWindowSize();
    const { assets, getAssetLink } = useAsset();
    const navigate = useNavigate();

    const filteredAssets = useMemo(() => {
        const assetsWithPrice = (assets || []).filter((asset) => asset.price && asset.previousDayPrice);
        const filteredAssets = assetsWithPrice.filter((asset) => asset.volume);
        const minItems = Math.ceil(width / 130);
        if (assetsWithPrice.length < minItems) {
            filteredAssets.push(...assetsWithPrice.filter((asset) => !asset.volume).slice(0, minItems - assetsWithPrice.length));
        }
        return filteredAssets;
    }, [ assets, width ]);

    const scrollingDuration = useMemo(() => isMobile ? 5 : isTablet ? 4 : 3, [ isMobile, isTablet ]);

    return (
        <div className={classNames('scrolling-trade-pairs-container', className)}>
            <div className='scrolling-trade-pairs-carousel-container'>
                <div
                    className='scrolling-trade-pairs-carousel'
                    style={{ '--animation-duration': `${filteredAssets.length * scrollingDuration}s` } as CSSProperties}
                >
                    {filteredAssets.map((asset) => (
                        <StatisticsChange
                            currentValue={asset.price}
                            previousValue={asset.previousDayPrice}
                            period='day'
                            key={asset.key}
                            size='small'
                            onClick={() => navigate(getAssetLink(asset))}
                            showPeriod={false}
                        >
                            {asset.currency.displayDenom}/USD
                        </StatisticsChange>
                    ))}
                </div>
            </div>
            <SocialLinks className='social-links' />
        </div>
    );
};

export default ScrollingTradePairs;

