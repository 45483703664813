import classNames from 'classnames';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import Badge from '../../../shared/components/badge/badge';
import Link from '../../../shared/components/link/link';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import TabBar, { Tab } from '../../../shared/components/tabs/tab-bar';
import { formatPrice } from '../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { CoinsAmount } from '../../currency/currency-types';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../tx/tx-types';
import ConfirmTradeButton from '../confirm-trade-button/confirm-trade-button';
import SlippageAlerts from '../slippage-alerts/slippage-alerts';
import TradeDetails from '../trade-details/trade-details';
import { useTrade } from '../trade-context';
import './buy-sell.scss';

export enum TradeType {
    BUY = 'Buy',
    SELL = 'Sell'
}

export interface BuySellProps {
    className?: string;
    asset: CoinsAmount;
    inverseAsset?: CoinsAmount;
    detailsExpanded?: boolean;
    onTradeTypeChange?: (type: TradeType) => void;
    useInverseAsset?: boolean;
    isExactAmountSpend?: boolean;
    canUseInverseAssetInBuy?: boolean;
    amountShortcuts?: number[];
}

const BuySell: React.FC<BuySellProps> = ({
    className,
    asset,
    inverseAsset,
    detailsExpanded,
    isExactAmountSpend,
    canUseInverseAssetInBuy,
    onTradeTypeChange,
    amountShortcuts,
}) => {
    const networkState = useHubNetworkState();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        txState,
        loading,
        useInverseAsset,
        setUseInverseAsset,
        updateAsset1Coins,
        updateAsset2Coins,
    } = useTrade();
    const [ tradeType, setTradeType ] = useState<TradeType>(TradeType.BUY);

    const availableBalances = useMemo(
        () => tradeType === 'Sell' || !useInverseAsset ? [ asset ] : inverseAsset ? [ inverseAsset ] : [],
        [ asset, inverseAsset, tradeType, useInverseAsset ],
    );

    const amountTxState = useMemo(
        () => tradeType === 'Sell' || useInverseAsset ? asset1AmountTxState : asset2AmountTxState,
        [ asset1AmountTxState, asset2AmountTxState, tradeType, useInverseAsset ],
    );

    const invertAssetAmountTxState = useMemo(
        () => tradeType === 'Sell' || useInverseAsset ? asset2AmountTxState : asset1AmountTxState,
        [ asset1AmountTxState, asset2AmountTxState, tradeType, useInverseAsset ],
    );

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            return { content: tradeType === 'Buy' ? 'Purchase successfully completed!' : 'Sale successfully completed!' };
        }
    }, [ tradeType ]);

    const inverseAssetAmount = useMemo((): ReactElement | string => {
        if (!networkState.network || !networkState.balances || !amountTxState.coins?.amount) {
            return '';
        }
        const prefix = tradeType === 'Sell' || useInverseAsset ? 'Est. Received' : `${isExactAmountSpend ? '' : 'Est. '}Spend`;
        if ((!networkState.balances && networkState.balancesLoading) || (!txState.fee && txState.feeLoading)) {
            return `${prefix}: loading...`;
        }
        const invertAssetAmountTxState = tradeType === 'Sell' || useInverseAsset ? asset2AmountTxState : asset1AmountTxState;
        const { coins } = invertAssetAmountTxState;
        if (!coins || coins.amount <= 0) {
            return '';
        }
        return <>{prefix}: <span className='nowrap'>{formatPrice(coins.amount, coins.currency.displayDenom)}</span></>;
    }, [
        amountTxState.coins?.amount,
        asset1AmountTxState,
        asset2AmountTxState,
        isExactAmountSpend,
        networkState.balances,
        networkState.balancesLoading,
        networkState.network,
        tradeType,
        txState.fee,
        txState.feeLoading,
        useInverseAsset,
    ]);

    const onCoinsChange = useCallback((coins: CoinsAmount) => {
        if (tradeType === 'Sell' || useInverseAsset) {
            updateAsset1Coins(coins);
        } else {
            updateAsset2Coins(coins);
        }
    }, [ tradeType, updateAsset1Coins, updateAsset2Coins, useInverseAsset ]);

    const onTradeTypeTabChange = useCallback((value: TradeType) => {
        setTradeType(value);
        onTradeTypeChange?.(value);
    }, [ onTradeTypeChange ]);

    return (
        <section className={classNames('section small buy-sell-section', className)}>
            <TabBar uniformTabWidth className='buy-sell-tab-bar' onTabChange={(value) => onTradeTypeTabChange(value as TradeType)}>
                <Tab className='buy-sell-tab' label='Buy' tabKey={TradeType.BUY} />
                <Tab className='buy-sell-tab' label='Sell' tabKey={TradeType.SELL} />
            </TabBar>

            <AmountTx
                txState={txState}
                amountTxState={amountTxState}
                getTxResponseMessage={getTxResponseMessage}
                networkState={networkState}
                loading={loading}
                displayFee={false}
                displayBalance={false}
                hideMaxValueAction={tradeType === 'Buy' && !useInverseAsset}
                onCoinsChange={onCoinsChange}
                availableBalances={availableBalances}
                submitButtonContainer={<>
                    <div className='inverse-asset-section'>
                        {canUseInverseAssetInBuy && tradeType === 'Buy' && (
                            <Link onClick={() => setUseInverseAsset(!useInverseAsset)} className='switch-token' inline>
                                Switch to {invertAssetAmountTxState.coins?.currency.displayDenom}
                            </Link>
                        )}
                        <p className='inverse-asset-amount'>{inverseAssetAmount}</p>
                    </div>

                    {!amountShortcuts?.length || !amountTxState.coins ? undefined : (
                        <div className='amount-shortcuts'>
                            {amountShortcuts.map((amount) => (
                                <Badge
                                    rounded
                                    className='amount-badge'
                                    size='small'
                                    key={amount}
                                    label={`${amount} ${amountTxState.coins?.currency.displayDenom || ''}`}
                                    onClick={() => amountTxState.coins && onCoinsChange({ ...amountTxState.coins, amount })}
                                />
                            ))}
                        </div>
                    )}

                    <ConfirmTradeButton className={classNames('trade-button', tradeType.toLowerCase())}>
                        {tradeType}
                    </ConfirmTradeButton>
                </>}
            />

            <SlippageAlerts />

            <TradeDetails
                className='trade-details-section'
                expanded={detailsExpanded}
                inverseSpotPrice={tradeType === 'Buy' && !useInverseAsset}
            />
        </section>
    );
};

export default BuySell;
