import React, { ReactElement, useMemo } from 'react';
import Badge from '../../../shared/components/badge/badge';
import Spinner from '../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { getShortDateTimeString } from '../../../shared/utils/date-utils';
import { formatPrice } from '../../../shared/utils/number-utils';
import PoolEntry from '../../amm/pool-entry/pool-entry';
import { useAsset } from '../../asset/asset-context';
import { getCurrencyLogoPath } from '../../currency/currency-service';
import DisplayNameWithIro from '../../currency/display-name-with-iro/display-name-with-iro';
import { useNetwork } from '../../network/network-context';
import NetworkEntry from '../../network/network-entry/network-entry';
import { EARNING_TYPE_DESCRIPTION_MAP, EarningRecord } from '../earnings-types';
import './earning-list.scss';

export interface EarningListProps {
    records?: EarningRecord[];
    loading?: boolean;
}

const MAX_VISIBLE_TOKENS = 3;

const EarningList: React.FC<EarningListProps> = ({ records, loading }) => {
    const { getNetwork } = useNetwork();
    const { getTokenPrice } = useAsset();

    const dayRecordList = useMemo(() => records?.reduce((current, record) => {
        const date = new Date(record.time).toDateString().slice(4);
        let currentItem = current.find((list) => list.date === date);
        if (!currentItem) {
            currentItem = { records: [], date };
            current.push(currentItem);
        }
        currentItem.records.push(record);
        return current;
    }, [] as { date: string, records: EarningRecord[] }[]), [ records ]);

    const renderEarningRecordHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn nowrap className='time-column'>Time</TableColumn>
                <TableColumn>Entity</TableColumn>
                <TableColumn>Type</TableColumn>
                <TableColumn>Amount</TableColumn>
                <TableColumn>Value</TableColumn>
            </TableRow>
        );
    };

    const renderTypeColumn = (record: EarningRecord): ReactElement => {
        return (
            <TableColumn>
                <Badge
                    color={getCssVariableValue(EARNING_TYPE_DESCRIPTION_MAP[record.type].colorVariable).split(',').map(Number)}
                    rounded
                    noWrap
                    label={record.type}
                />
            </TableColumn>
        );
    };

    const renderEntityColumn = (record: EarningRecord): ReactElement => {
        return (
            <TableColumn className='entity-column'>
                {record.network ? <NetworkEntry size='small' network={record.network} /> :
                    record.pool ? <PoolEntry size='small' pool={record.pool} /> : (
                        <span className='unknown-entity secondary-text horizontally-centered'>
                            <img className='network-logo' src={require('../../../assets/icons/token.svg').default} alt='network logo' />N/A
                        </span>
                    )}
            </TableColumn>
        );
    };

    const renderMoreTooltipTokens = (record: EarningRecord): ReactElement => {
        return (
            <div className='more-tokens-tooltip'>
                {record.amount.map((coins, tokenIndex) => {
                    const tokenNetwork = getNetwork(coins.networkId);
                    return !tokenNetwork ? undefined : (
                        <span className='more-tooltip-token horizontally-centered' key={tokenIndex}>
                            <img className='token-logo' src={getCurrencyLogoPath(coins.currency, tokenNetwork)} alt='token logo' />
                            {formatPrice(coins.amount, '', { notation: 'compact' })}
                            &nbsp;<DisplayNameWithIro coins={coins} className='token-name' />
                         </span>
                    );
                })}
            </div>
        );
    };

    const renderAmountColumn = (record: EarningRecord): ReactElement => {
        return (
            <TableColumn className='amount-column' contentClassName='amount-column-content'>
                {record.amount.slice(0, MAX_VISIBLE_TOKENS).map((coins, tokenIndex) => {
                    const tokenNetwork = getNetwork(coins.networkId);
                    return !tokenNetwork ? undefined : (
                        <Badge
                            rounded
                            key={tokenIndex}
                            className='token-badge'
                            label={<>
                                <img className='token-logo' src={getCurrencyLogoPath(coins.currency, tokenNetwork)} alt='token logo' />
                                {formatPrice(coins.amount, '', { notation: 'compact' })}
                                &nbsp;<DisplayNameWithIro coins={coins} className='token-name' />
                            </>}
                        />
                    );
                })}
                {record.amount.length <= MAX_VISIBLE_TOKENS ? undefined : (
                    <Tooltip title={renderMoreTooltipTokens(record)}>
                        <span className='secondary-text nowrap more-tokens-label'>+{record.amount.length - MAX_VISIBLE_TOKENS} more</span>
                    </Tooltip>
                )}
            </TableColumn>
        );
    };

    const renderValueColumn = (record: EarningRecord): ReactElement => {
        const value = record.amount.reduce((current, token) => current + (getTokenPrice(token) || 0), 0);
        return <TableColumn>{formatPrice(value)}</TableColumn>;
    };

    const renderTimeColumn = (record: EarningRecord): ReactElement => {
        return (
            <TableColumn className='time-column' nowrap>{getShortDateTimeString(record.time)}</TableColumn>
        );
    };

    const renderDaySummaryRow = (date: string, records: EarningRecord[]): ReactElement => {
        const totalValue = records.reduce((current, record) => {
            const value = record.amount.reduce((current, token) => current + (getTokenPrice(token) || 0), 0);
            return current + value;
        }, 0);
        return (
            <TableRow key={date} className='earning-row summary'>
                <TableColumn nowrap className='time-column'>
                    {date}&nbsp;<small className='secondary-text'>({records.length})</small>
                </TableColumn>
                <TableColumn />
                <TableColumn />
                <TableColumn />
                <TableColumn>{formatPrice(totalValue)}</TableColumn>
            </TableRow>
        );
    };

    const renderEarningRecordRow = (record: EarningRecord, key: string): ReactElement => {
        return (
            <TableRow key={key} className='earning-row'>
                {renderTimeColumn(record)}
                {renderEntityColumn(record)}
                {renderTypeColumn(record)}
                {renderAmountColumn(record)}
                {renderValueColumn(record)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!records?.length) {
            return <div className='no-data'>No Earnings Yet</div>;
        }
    };

    return (
        <div className='earning-list-container'>
            <Table className='earning-list' firstColumnSticky bottomBar={renderBottomBar()}>
                {renderEarningRecordHeaderRow()}
                {dayRecordList?.reduce((current, { date, records }) => {
                    current.push(renderDaySummaryRow(date, records));
                    current.push(...records.map((record, recordIndex) => renderEarningRecordRow(record, `${date}-${recordIndex}`)));
                    return current;
                }, [] as ReactElement[])}
            </Table>
        </div>
    );
};

export default EarningList;
