import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../../shared/components/button/button';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { getShortenedAddress } from '../../../../../shared/utils/text-utils';
import PathNav, { PathNavItem } from '../../../../path-nav/path-nav';
import { useWallet } from '../../../../wallet/wallet-context';
import { WalletInfoMap } from '../../../../wallet/wallet-types';
import ResolveTransferDialog from '../../resolve-transfer/resolve-transfer-dialog/resolve-transfer-dialog';
import IbcTransferStatus from '../ibc-transfer-status';
import { useIbcTransferStatus } from '../use-ibc-transfer-status';
import './ibc-transfer-status-page.scss';

const ROLLAPP_EVM_DRS_SUPPORT = 6;
const ROLLAPP_WASM_DRS_SUPPORT = 9;

const IbcTransferStatusPage: React.FC = () => {
    const { id } = useParams();
    const { networkWalletMap } = useWallet();
    const { transfer, loading, refresh } = useIbcTransferStatus({ transferId: id });
    const [ resolveTransferDialogOpen, setResolveTransferDialogOpen ] = useState(false);

    const resolveEnabled = useMemo(() => {
        if (transfer?.time && transfer.time + 60000 >= Date.now()) {
            return;
        }
        const sourceNetwork = transfer?.sourceNetwork;
        if (sourceNetwork?.type === 'Hub' && transfer?.status === 'Pending') {
            const networkWallet = sourceNetwork ? networkWalletMap[sourceNetwork.chainId] : null;
            return networkWallet && WalletInfoMap[networkWallet.getWalletType()].type !== 'evm';
        }
        if (sourceNetwork?.type === 'RollApp' && transfer?.status === 'Initiated' && sourceNetwork.drs) {
            return sourceNetwork.evmType === 'EVM' ?
                sourceNetwork.drs >= ROLLAPP_EVM_DRS_SUPPORT : sourceNetwork.drs >= ROLLAPP_WASM_DRS_SUPPORT;
        }
    }, [ networkWalletMap, transfer?.sourceNetwork, transfer?.status, transfer?.time ]);

    if (!transfer) {
        return loading ?
            <Spinner size='large' className='ibc-transfer-status-loader' /> :
            <h5 className='not-found-message'>IBC Transfer not found</h5>;
    }
    return <>
        <div className='page ibc-transfer-status'>
            <PathNav>
                <PathNavItem label='Transfers' />
                <PathNavItem label='History' url='/ibc/status' />
                <PathNavItem label={getShortenedAddress(transfer.hash, 6, 6)} />
            </PathNav>
            <h5 className='ibc-transfer-status-header'>
                Transfer Details
                <span className='space' />
                {resolveEnabled &&
                    <Button onClick={() => setResolveTransferDialogOpen(true)} className='resolve-button' size='small'>
                        Resolve Transfer
                    </Button>}
            </h5>
            <IbcTransferStatus transfer={transfer} />
        </div>
        {resolveTransferDialogOpen && (
            <ResolveTransferDialog
                transfer={transfer}
                onResolve={() => {
                    refresh();
                    setResolveTransferDialogOpen(false);
                }}
                onRequestClose={() => setResolveTransferDialogOpen(false)}
            />
        )}
    </>;
};

export default IbcTransferStatusPage;
