import React, { useEffect, useMemo } from 'react';
import Input from '../../../../shared/components/form-controls/input/input';
import { getFormattedLocalDateTime } from '../../../../shared/utils/date-utils';
import { MsgSoftwareUpgrade } from '../../../client/station-clients/dymension/generated/timeupgrade/tx';
import './proposal-type.scss';

interface RollAppSoftwareUpgradeProps {
    content?: MsgSoftwareUpgrade;
    onInit?: (data: MsgSoftwareUpgrade) => void;
    onChange?: (data: Partial<MsgSoftwareUpgrade>, isValid: boolean) => void;
}

const RollAppSoftwareUpgrade: React.FC<RollAppSoftwareUpgradeProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(() => Boolean(content?.drs && content.authority && content.upgradeTime), [ content ]);

    useEffect(() => onInit?.({
        drs: undefined as any,
        authority: '',
        upgradeTime: undefined,
    }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    const currentUpgradeTime = useMemo(
        () => content?.upgradeTime && getFormattedLocalDateTime(content.upgradeTime),
        [ content?.upgradeTime ],
    );

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Authority</label>
            <Input
                value={content?.authority}
                onValueChange={(value) => onChange?.({ authority: value.toString() || '' }, isValid)}
            />

            <label className='proposal-control-label'>DRS</label>
            <Input
                value={content?.drs}
                type='number'
                onValueChange={(value) => onChange?.({ drs: !value ? undefined : Number(value) }, isValid)}
            />

            <label className='proposal-control-label'>Upgrade Time</label>
            <Input
                type='datetime-local'
                min={getFormattedLocalDateTime(new Date())}
                value={currentUpgradeTime}
                onValueChange={(value) => onChange?.({ upgradeTime: new Date(value) }, isValid)}
            />
        </div>
    );
};

export default RollAppSoftwareUpgrade;
