import { DocumentSnapshot, Unsubscribe } from '@firebase/firestore';
import { uploadBytes } from '@firebase/storage';
import { getDownloadURL, ref } from 'firebase/storage';
import { uniq, uniqBy } from 'lodash';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TextHighlight } from '../../../shared/components/highlight-text/HighlightText';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { getFileSuffix } from '../../../shared/utils/file-utils';
import { storage } from '../../../shared/utils/firebase-utils';
import { useAuthUser } from '../../account/auth-user/auth-user-context';
import { User } from '../../account/auth-user/types';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useAsset } from '../../asset/asset-context';
import { useDymns } from '../../dymns/dymns-context';
import { useNetwork } from '../network-context';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { Network } from '../network-types';
import {
    LIKE_ABORTED_ERROR_MESSAGE,
    likeNetworkComment,
    loadUsers,
    postNetworkComment,
    setNetworkCommentsListener,
    setUserCommentLikesListener,
} from './network-comments-service';
import { DEFAULT_COMMENTS_PAGE_LIMIT, NetworkComment, SortType } from './types';

interface NetworkCommentsContextValue {
    sortType: SortType,
    comments: NetworkComment[];
    loading: boolean;
    posting: boolean;
    postedSuccessfully: boolean;
    currentCommentMessage: string;
    currentCommentImageFile?: File;
    commentTextHighlight: TextHighlight;
    getUser: (comment: NetworkComment) => User | undefined;
    isLike: (comment: NetworkComment) => boolean;
    getPendingLike: (comment: NetworkComment) => number;
    setSortType: (type: SortType) => void;
    setCurrentCommentMessage: (message: string) => void;
    setCurrentCommentImageFile: (imageFile?: File) => void;
    likeComment: (comment: NetworkComment) => void;
    postComment: () => Promise<boolean>;
    loadNext: () => void;
}

export const NetworkCommentsContext = createContext<NetworkCommentsContextValue>({} as NetworkCommentsContextValue);

export const useNetworkComments = (): NetworkCommentsContextValue => useContext(NetworkCommentsContext);

export const NetworkCommentsContextProvider: React.FC<{ network: Network, children: ReactNode }> = ({ network, children }) => {
    const { showSuccessMessage, showErrorMessage } = useSnackbar();
    const { handleWalletError } = useWallet();
    const hubNetworkState = useHubNetworkState();
    const { user, signInUser, saveUserDialogOpen } = useAuthUser();
    const { networks } = useNetwork();
    const { dymnsState } = useDymns();
    const { assets } = useAsset();
    const { getAssetLink } = useAsset();
    const [ userSinging, setUserSinging ] = useState(false);
    const [ sortType, setSortType ] = useState<SortType>(SortType.RECENT);
    const [ comments, setComments ] = useState<NetworkComment[]>([]);
    const [ loading, setLoading ] = useState(false);
    const [ posting, setPosting ] = useState(false);
    const [ postedSuccessfully, setPostedSuccessfully ] = useState(false);
    const [ haveMore, setHaveMore ] = useState(true);
    const [ usersMap, setUsersMap ] = useState<{ [userId: string]: User }>({});
    const [ userLikes, setUserLikes ] = useState<{ [commentId: string]: boolean }>({});
    const [ pendingLikes, setPendingLikes ] = useState<{ [commentId: string]: number }>({});
    const [ lastCommentSnapshot, setLastCommentSnapshot ] = useState<DocumentSnapshot | undefined>();
    const [ currentCommentMessage, setCurrentCommentMessage ] = useState('');
    const [ currentCommentImageFile, setCurrentCommentImageFile ] = useState<File>();
    const [ , setUnsubscribes ] = useState<Unsubscribe[]>([]);

    const commentTextHighlight = useMemo<TextHighlight>(() => ({
        regex: /([$@#][^\s$@#]+)/g,
        replace: (word, key) => {
            const fixedWord = word.slice(1).toLowerCase();
            const network = networks.find((network) =>
                network.chainName.toLowerCase() === fixedWord || network.chainId.toLowerCase() === fixedWord ||
                dymnsState.aliasesMap[network.chainId]?.aliases[0]?.toLowerCase() === fixedWord);
            if (network?.type === 'Hub') {
                return <NavLink key={key} className='network-link' to='/dymension'>{word}</NavLink>;
            }
            if (network?.type === 'RollApp') {
                return <NavLink key={key} className='network-link' to={`/rollapps/${network.chainId}`}>{word}</NavLink>;
            }
            const asset = assets?.find((asset) =>
                asset.currency.baseDenom.toLowerCase() === fixedWord || asset.currency.displayDenom.toLowerCase() === fixedWord);
            const assetLink = asset && getAssetLink(asset);
            if (assetLink) {
                return <NavLink key={key} className='asset-link' to={assetLink}>{word}</NavLink>;
            }
            return <b key={key}>{word}</b>;
        },
    }), [ assets, dymnsState.aliasesMap, getAssetLink, networks ]);

    const onCommentLoaded = useCallback((comments: NetworkComment[], lastSnapshot: DocumentSnapshot) => {
        setUsersMap((usersMap) => {
            const userIds = uniq(comments.map((comment) => comment.userId).filter((userId) => userId && !usersMap[userId]));
            loadUsers(userIds as string[]).then((users) => {
                setUsersMap(users.reduce((current, user) => ({ ...current, [user.id]: user }), usersMap));
                setComments((currentComments) => uniqBy([ ...comments, ...currentComments ], 'id')
                    .sort((comment1, comment2) =>
                        sortType === SortType.TOP ? comment2.likes - comment1.likes : comment2.date - comment1.date));
                setLoading(false);
            });
            return usersMap;
        });
        setLastCommentSnapshot(lastSnapshot);
        if (comments.length < DEFAULT_COMMENTS_PAGE_LIMIT) {
            setHaveMore(false);
        }
    }, [ sortType ]);

    const loadComments = useCallback((fromSnapshot?: DocumentSnapshot) => {
        setUnsubscribes((unsubscribes) => {
            setLoading(true);
            setHaveMore(true);
            if (!fromSnapshot) {
                setComments([]);
                unsubscribes.forEach((unsubscribe) => unsubscribe());
            }
            const unsubscribe = setNetworkCommentsListener(network.chainId, sortType, fromSnapshot, {
                next: onCommentLoaded,
                error: (error) => {
                    console.error(`Can't load comments: `, error);
                    setLoading(false);
                },
                complete: () => setLoading(false),
            });
            return !fromSnapshot ? [ unsubscribe ] : [ ...unsubscribes, unsubscribe ];
        });
    }, [ network.chainId, onCommentLoaded, sortType ]);

    const loadNext = useCallback(() => {
        if (haveMore && !loading) {
            loadComments(lastCommentSnapshot);
        }
    }, [ haveMore, loading, loadComments, lastCommentSnapshot ]);

    const getUser = useCallback(
        (comment: NetworkComment): User | undefined => comment.userId ? usersMap[comment.userId] : undefined,
        [ usersMap ],
    );

    const isLike = useCallback((comment: NetworkComment) => Boolean(userLikes[comment.id]), [ userLikes ]);

    const getPendingLike = useCallback((comment: NetworkComment) => pendingLikes[comment.id], [ pendingLikes ]);

    const postComment = useCallback(async (): Promise<boolean> => {
        setPostedSuccessfully(false);
        if (!hubNetworkState.hexAddress) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, hubNetworkState.network));
            return false;
        }
        if (!currentCommentMessage || currentCommentMessage.length < 3) {
            showErrorMessage('Your message must be at least 3 characters long.');
            return false;
        }
        setPosting(true);
        if (!user.address) {
            setUserSinging(true);
            signInUser().then((uuid) => {
                if (!uuid) {
                    setPosting(false);
                    setUserSinging(false);
                }
            }).catch(() => {
                setPosting(false);
                setUserSinging(false);
            });
            return false;
        }
        const comment: NetworkComment = {
            id: '',
            likes: 0,
            networkId: network.chainId,
            userId: user.address,
            body: currentCommentMessage,
            date: new Date().getTime(),
        };
        if (currentCommentImageFile) {
            const fileName = `comment-images/${comment.networkId}-${comment.userId}-${comment.date}.${getFileSuffix(currentCommentImageFile.name)}`;
            const fileRef = ref(storage, fileName);
            comment.image = await uploadBytes(fileRef, currentCommentImageFile)
                .then((result) => getDownloadURL(result.ref))
                .catch((error) => {
                    console.error(`Can't upload comment image: `, error);
                    showErrorMessage(`Can't post comment, please try again later`);
                    return '';
                });
            if (!comment.image) {
                return false;
            }
        }
        return postNetworkComment(comment)
            .then((ref) => {
                showSuccessMessage({ content: 'Your comment has been posted successfully!', key: ref.id });
                setSortType(SortType.RECENT);
                setCurrentCommentMessage('');
                setCurrentCommentImageFile(undefined);
                setPostedSuccessfully(true);
                return true;
            })
            .catch((error) => {
                console.error(`Can't post comment: `, error);
                showErrorMessage(`Can't post comment, please try again later`);
                return false;
            })
            .finally(() => setPosting(false));
    }, [
        hubNetworkState.hexAddress,
        hubNetworkState.network,
        currentCommentMessage,
        user.address,
        network.chainId,
        currentCommentImageFile,
        handleWalletError,
        showErrorMessage,
        signInUser,
        showSuccessMessage,
    ]);

    useEffect(() => {
        if (userSinging && posting && user.address) {
            setUserSinging(false);
            postComment().then();
        }
    }, [ postComment, posting, user.address, userSinging ]);

    useEffect(() => {
        if (!saveUserDialogOpen && !user.address) {
            setPosting(false);
        }
    }, [ saveUserDialogOpen, user.address ]);

    const likeComment = useCallback((comment: NetworkComment) => {
        if (!hubNetworkState.hexAddress) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, hubNetworkState.network));
            return;
        }
        pendingLikes[comment.id] = ((pendingLikes[comment.id] || 0) * -1) || (userLikes[comment.id] ? -1 : 1);
        setPendingLikes({ ...pendingLikes });
        likeNetworkComment(comment.id, hubNetworkState.hexAddress.toLowerCase(), pendingLikes[comment.id])
            .then((liked) => {
                setUserLikes((userLikes) => ({ ...userLikes, [comment.id]: liked }));
                setPendingLikes((pendingLikes) => ({ ...pendingLikes, [comment.id]: 0 }));
            })
            .catch((error) => {
                if (!error.message.includes(LIKE_ABORTED_ERROR_MESSAGE)) {
                    showErrorMessage('Failed to record your like, please try again later');
                    setPendingLikes((pendingLikes) => ({ ...pendingLikes, [comment.id]: 0 }));
                }
            });
    }, [ handleWalletError, hubNetworkState.hexAddress, hubNetworkState.network, showErrorMessage, userLikes, pendingLikes ]);

    useEffect(() => {
        if (hubNetworkState.hexAddress) {
            return setUserCommentLikesListener(hubNetworkState.hexAddress?.toLowerCase(), { next: setUserLikes });
        } else {
            setUserLikes({});
        }
    }, [ hubNetworkState.hexAddress ]);

    useEffect(() => loadComments(), [ sortType, loadComments ]);

    useEffect(() => () => {
        setUnsubscribes((unsubscribes) => {
            unsubscribes.forEach((unsubscribe) => unsubscribe());
            return [];
        });
    }, []);

    return (
        <NetworkCommentsContext.Provider
            value={{
                sortType,
                comments,
                loading,
                posting,
                currentCommentMessage,
                currentCommentImageFile,
                commentTextHighlight,
                postedSuccessfully,
                postComment,
                loadNext,
                getUser,
                likeComment,
                isLike,
                getPendingLike,
                setCurrentCommentMessage,
                setCurrentCommentImageFile,
                setSortType,
            }}
        >
            {children}
        </NetworkCommentsContext.Provider>
    );
};
