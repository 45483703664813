import { Params } from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import { Long } from 'cosmjs-types/helpers';
import { isEmpty } from 'lodash';
import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { filterNonEmptyValues } from '../../shared/utils/object-utils';
import { getNetworkData } from '../network/network-service';
import { Delegation, Reward, StakingParams } from './staking-types';
import { MsgWithdrawDelegatorRewardEncodeObject } from 'cosmjs/packages/stargate';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoinsAmount, getMainCurrency, getMaxDenomAmount } from '../currency/currency-service';
import { timeToMilliseconds } from '../../shared/utils/date-utils';
import { ClientError } from '../client/client-error';
import { VALIDATORS_PAGINATION } from './validator/validator.service';
import { Currency } from '../currency/currency-types';

export const DEFAULT_UNBOUNDING_TIME = 14;

export const loadStakingParams = async (client: StationClient, signal: AbortSignal): Promise<StakingParams> => {
    const network = client.getNetwork();
    let params: Params | undefined;
    if (network.collectData) {
        params = await getNetworkData<Params>(network.chainId, 'staking-params', true, signal);
    }
    if (!params || isEmpty(params)) {
        const response = await client.getStakingQueryClient().Params().catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', network, error);
        });
        params = response.params;
    }
    return {
        unbondingTime: params?.unbondingTime?.seconds ?
            Math.ceil(Long.fromValue(params.unbondingTime.seconds).toInt() * 1000 / timeToMilliseconds({ days: 1 })) :
            DEFAULT_UNBOUNDING_TIME,
        maxValidatorUnstakingEntries: params?.maxEntries,
    };
};

export const loadDelegations = async (client: StationClient, stakeCurrency: Currency, delegatorAddress: string): Promise<Delegation[]> => {
    const { delegationResponses } = await client.getStakingQueryClient()
        .DelegatorDelegations({ delegatorAddr: delegatorAddress, pagination: VALIDATORS_PAGINATION })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    return delegationResponses
        .filter((response) => Boolean(response.delegation && response.balance))
        .map((response) => ({
            validatorAddress: response.delegation?.validatorAddress,
            coins: {
                amount: getMaxDenomAmount(Number(response.balance?.amount) || 0, stakeCurrency),
                currency: stakeCurrency,
                baseAmount: BigInt(response.balance?.amount || '0'),
            },
        }) as Delegation);
};

export const loadRewards = async (client: StationClient, delegatorAddress: string): Promise<Reward[]> => {
    const { rewards } = await client.getDistributionQueryClient().DelegationTotalRewards({ delegatorAddress: delegatorAddress })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    const mainCurrency = getMainCurrency(client.getNetwork());
    return Promise.all(rewards.map(async (reward) => {
        const rewards = filterNonEmptyValues(await Promise.all(reward.reward.map((coin) => convertToCoinsAmount(coin, client))));
        const mainReward = rewards.find((coins) => coins.currency.baseDenom === mainCurrency.baseDenom);
        if (mainReward) {
            mainReward.amount = convertDecimalToInt(mainReward.amount);
        }
        return { validatorAddress: reward.validatorAddress, coins: mainReward };
    }));
};

export const createRewardMessage = (delegatorAddress: string, validatorAddress: string): MsgWithdrawDelegatorRewardEncodeObject => {
    return {
        typeUrl: '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
        value: { delegatorAddress, validatorAddress },
    };
};




