import React, { useCallback, useEffect, useMemo } from 'react';
import Alert from '../../../../../shared/components/alert/alert';
import Button from '../../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import { useSnackbar } from '../../../../../shared/components/snackbar/snackbar-context';
import { SnackbarMessage } from '../../../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { useClient } from '../../../../client/client-context';
import { getMainCurrency } from '../../../../currency/currency-service';
import AmountTx from '../../../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../../../tx/tx-types';
import { useWallet } from '../../../../wallet/wallet-context';
import { useStaking } from '../../../staking-context';
import { DEFAULT_UNBOUNDING_TIME } from '../../../staking-service';
import { TotalValidators } from '../../../validator/total-validators/total-validators';
import { useValidatorList } from '../../../validator/validator-list/use-validator-list';
import { Validator } from '../../../validator/validator-types';
import { NewDelegationType } from '../new-delegation-types';
import { useNewDelegation } from '../use-new-delegation';
import './new-delegation-dialog.scss';

export interface NewDelegationDialogProps {
    type: NewDelegationType;
    validator: Validator;
    onRequestClose?: () => void;
}

function NewDelegationDialog({ validator, type, onRequestClose }: NewDelegationDialogProps): JSX.Element | null {
    const { showErrorMessage } = useSnackbar();
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const { network, networkState, stakingDataState, setStakeValidatorsType } = useStaking();
    const {
        alreadyDelegated,
        txState,
        redelegateTo,
        amountTxState,
        availableBalances,
        reduceFeeFromBalances,
        error,
        setCoins,
        setRedelegateTo,
        broadcast,
    } = useNewDelegation(validator, type);
    const validatorsData = useValidatorList(network, networkState, 'All', stakingDataState, true);
    const clientState = clientStateMap[network.chainId];
    const networkWallet = networkWalletMap[network.chainId];

    const mainCurrency = useMemo(() => getMainCurrency(network), [ network ]);

    const fixedValidatorsData = useMemo(() => {
        if (type === 'redelegate' && validatorsData.state.validators) {
            validatorsData.state.validators =
                validatorsData.state.validators.filter((currentValidator) => validator.name !== currentValidator.name);
        }
        return validatorsData;
    }, [ type, validator.name, validatorsData ]);

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            onRequestClose?.();
            if (type === 'undelegate') {
                setStakeValidatorsType('Unstaking');
            } else {
                setStakeValidatorsType('Staked');
            }
        }
    }, [ txState.response, type, onRequestClose, setStakeValidatorsType ]);

    useEffect(() => {
        if (!error) {
            return;
        }
        switch (error.code) {
            case 'TOO_MANY_UNSTAKING_DELEGATIONS':
                const allowedCountMessagePart = !stakingDataState?.stakeParams?.maxValidatorUnstakingEntries ? null : <>
                    <br />
                    The maximum number of unstaking entries allowed for a validator
                    is {stakingDataState?.stakeParams?.maxValidatorUnstakingEntries}.
                </>;
                showErrorMessage({
                    content: <>Too many unstaking delegations for {validator.name}{allowedCountMessagePart}</>,
                    duration: 30000,
                    key: 'too-many-unstaking-delegations-' + validator.name,
                });
                break;
            case 'REDELEGATION_ALREADY_IN_PROGRESS':
                showErrorMessage({
                    content: <>Redelegation to {validator.name} is still in progress<br />
                        A new redelegation cannot be initiated until the current one is complete.
                    </>,
                    duration: 30000,
                    key: 'too-many-unstaking-delegations-' + validator.name,
                });
                break;
            default:
                showErrorMessage('Delegation failed, please try again later');
        }
    }, [ error, stakingDataState?.stakeParams?.maxValidatorUnstakingEntries, validator.name, showErrorMessage ]);

    const getDialogTitle = (): JSX.Element => {
        if (type === 'delegate') {
            return <>Stake to <u>{validator.name}</u></>;
        } else if (type === 'undelegate') {
            return <>Unstake from <u>{validator.name}</u></>;
        } else if (type === 'cancel-undelegate') {
            return <>Cancel unstaking from <u>{validator.name}</u></>;
        }
        return <>Redelegate from <u>{validator.name}</u> to <u>{redelegateTo?.name || ''}</u></>;
    };

    const getConfirmActionLabel = (): string => {
        if (type === 'delegate') {
            return 'Stake';
        } else if (type === 'undelegate') {
            return 'Unstake';
        } else if (type === 'cancel-undelegate') {
            return 'Cancel Unstaking';
        } else {
            return 'Redelegate';
        }
    };

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            if (type === 'undelegate') {
                return { content: 'Your unstaking successfully submitted!' };
            } else if (type === 'cancel-undelegate') {
                return { content: 'Your unstaking cancellation successfully submitted!' };
            }
            return { content: 'Your staking successfully submitted!' };
        }
    }, [ type ]);

    const renderDelegationWarning = (): JSX.Element | null => {
        if (!stakingDataState?.stakeParams && stakingDataState?.stakeParamsLoading) {
            return <Spinner className='delegation-warning-loader' />;
        }
        const unboundingTime = stakingDataState?.stakeParams?.unbondingTime || DEFAULT_UNBOUNDING_TIME;
        if (type === 'delegate') {
            return (
                <Alert className='delegation-warning' type='warning' title={`Staking will lock up your funds for ${unboundingTime} days`}>
                    To have access to your staked assets once again, you must go through the process of un-staking.
                </Alert>
            );
        } else if (type === 'undelegate') {
            return (
                <Alert
                    className='delegation-warning'
                    type='warning'
                    title={`Access to the tokens will be granted in ${unboundingTime} days`}
                >
                    During this period, you will be unable to receive staking rewards and terminate the un-bonding process.
                </Alert>
            );
        } else if (type === 'redelegate') {
            return (
                <Alert
                    className='delegation-warning'
                    type='warning'
                    title={`The redelegation will take place in ${unboundingTime} days`}
                >
                    Restaking, behind the scenes, involves both unstaking and staking processes. During this period, you will
                    continue to receive the staking rewards of the previous delegator, and you will not have the ability to
                    redelegate again.
                </Alert>
            );
        }
        return null;
    };

    const confirmButtonDisabled = Boolean(
        (!stakingDataState?.stakeParams && stakingDataState?.stakeParamsLoading) ||
        txState.broadcasting ||
        txState.feeLoading ||
        !amountTxState.coins?.amount ||
        (type === 'redelegate' && !redelegateTo) ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    const renderDialogContent = (): JSX.Element | null => {
        return (
            <DialogContent>
                {renderDelegationWarning()}

                {type === 'redelegate' && (
                    <TotalValidators
                        containerClassName='total-validators-container'
                        validatorsData={fixedValidatorsData}
                        simpleMode
                        selected={redelegateTo}
                        onSelect={setRedelegateTo}
                        header='Select validator'
                        className='total-validators'
                        headerClassName='validator-list-header'
                    />
                )}

                <p className='current-delegation'>
                    Current {type === 'cancel-undelegate' ? 'unstaking' : 'stake'} amount:&nbsp;
                    <b>{formatNumber(
                        type === 'cancel-undelegate' ? validator.unstaking?.amount || 0 : alreadyDelegated,
                        { maximumFractionDigits: 4 },
                    )} {mainCurrency.displayDenom}</b>
                </p>

                <AmountTx
                    txState={txState}
                    amountTxState={amountTxState}
                    networkState={networkState}
                    availableBalances={availableBalances}
                    getTxResponseMessage={getTxResponseMessage}
                    reduceFeeFromBalances={reduceFeeFromBalances}
                    onCoinsChange={setCoins}
                    submitButtonContainer={(
                        <Button
                            size='x-large'
                            className='delegation-confirm-button'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={confirmButtonDisabled}
                            onClick={() => broadcast()}
                        >
                            {getConfirmActionLabel()}
                        </Button>
                    )}
                />
            </DialogContent>
        );
    };

    return (
        <Dialog closable className='new-delegation-dialog' onRequestClose={onRequestClose}>
            <DialogTitle>{getDialogTitle()}</DialogTitle>
            {renderDialogContent()}
        </Dialog>
    );
}

export default NewDelegationDialog;
