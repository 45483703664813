import classNames from 'classnames';
import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as TimelapseIcon } from '../../../../assets/icons/timelapse.svg';
import { millisecondsToTime, timeToMilliseconds, TimeUnit } from '../../../utils/date-utils';
import Icon from '../../icon/icon';
import Overlay, { OverlayRefProps } from '../../overlay/overlay';
import Spinner from '../../spinner/spinner';
import './duration.scss';

const DEFAULT_MAX_DURATION = timeToMilliseconds({ days: 365 });
const UNIT_VALUE_HEIGHT = 34;
const MAX_VISIBLE_UNIT_VALUES = 9;

export interface DurationProps {
    value?: number,
    className?: string;
    pickerClassName?: string;
    disabled?: boolean;
    visibleAlways?: boolean;
    loading?: boolean;
    minTimeUnit?: TimeUnit;
    maxTimeUnit?: TimeUnit;
    minDuration?: number;
    maxDuration?: number;
    triggerOnHover?: boolean;
    onValueChange?: (value: number) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
    onOpenChange?: (value: boolean) => void;
}

const Duration: React.FC<DurationProps> = ({
    value,
    className,
    pickerClassName,
    triggerOnHover,
    onMouseLeave,
    disabled,
    loading,
    visibleAlways,
    onOpenChange,
    onValueChange,
    minDuration,
    maxDuration = DEFAULT_MAX_DURATION,
    minTimeUnit = 'seconds',
    maxTimeUnit = 'days',
}) => {
    const [ open, setOpen ] = useState(visibleAlways);
    const overlayRef = useRef<OverlayRefProps>(null);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const timeUnitSelectorContainerRefs = useRef<{ [unit in TimeUnit]?: HTMLDivElement | null }>({});

    const valueTime = useMemo(() => {
        const time = millisecondsToTime(value);
        if (minTimeUnit !== 'seconds') {
            time.seconds = 0;
        }
        if (minTimeUnit === 'hours' || minTimeUnit === 'days') {
            time.minutes = 0;
        }
        if (minTimeUnit === 'days') {
            time.hours = 0;
        }
        if (maxTimeUnit !== 'days') {
            time.hours = time.hours + time.days * 24;
            time.days = 0;
        }
        if (maxTimeUnit === 'minutes' || maxTimeUnit === 'seconds') {
            time.minutes = time.minutes + time.hours * 60;
            time.hours = 0;
        }
        if (maxTimeUnit === 'seconds') {
            time.seconds = time.seconds + time.minutes * 60;
            time.minutes = 0;
        }
        return time;
    }, [ maxTimeUnit, minTimeUnit, value ]);

    const visibleUnits = useMemo(() => {
        const timeUnits: TimeUnit[] = [ 'seconds', 'minutes', 'hours', 'days' ];
        return timeUnits.slice(timeUnits.indexOf(minTimeUnit), timeUnits.indexOf(maxTimeUnit) + 1).reverse();
    }, [ maxTimeUnit, minTimeUnit ]);

    const togglePicker = useCallback((value: boolean) => {
        if (!disabled && !visibleAlways) {
            setOpen(value);
            onOpenChange?.(value);
            if (value) {
                setTimeout(() => visibleUnits.forEach((unit) => {
                    const scrollTo = Math.max(0, (valueTime[unit] - Math.floor(MAX_VISIBLE_UNIT_VALUES / 2)) * UNIT_VALUE_HEIGHT);
                    timeUnitSelectorContainerRefs.current[unit]?.scrollTo({ top: scrollTo });
                }));
            }
        }
    }, [ disabled, onOpenChange, valueTime, visibleAlways, visibleUnits ]);

    const onValueSelect = useCallback((unit: TimeUnit, value: number) => {
        valueTime[unit] = value;
        onValueChange?.(timeToMilliseconds(valueTime));
    }, [ onValueChange, valueTime ]);

    const onMouseLeaveOverlay = (event: React.MouseEvent): void => {
        if (triggerOnHover &&
            (!overlayRef.current?.contains?.(event.relatedTarget as Node) && !triggerRef.current?.contains(event.relatedTarget as Node))) {
            togglePicker(false);
        }
    };

    const onClickOutside = (event: MouseEvent): void => {
        if (!triggerRef.current?.contains(event.target as Node)) {
            togglePicker(false);
        }
    };

    const renderTimeUnitSelector = (unit: TimeUnit): ReactElement => {
        let maxNumUnits = maxDuration / timeToMilliseconds({ [unit]: 1 });
        maxNumUnits = (maxTimeUnit !== unit ? Math.min(unit === 'days' ? 365 : unit === 'hours' ? 24 : 60, maxNumUnits) : maxNumUnits + 1);
        const unitValues = Array.from({ length: maxNumUnits }).map((value, valueIndex) => valueIndex);
        return (
            <div
                className='time-unit-selector-container'
                style={{ maxHeight: MAX_VISIBLE_UNIT_VALUES * UNIT_VALUE_HEIGHT }}
                key={unit}
                ref={(ref) => timeUnitSelectorContainerRefs.current[unit] = ref}
            >
                {unitValues.map((unitValue, unitValueIndex) => {
                    const newValue = (value || 0) + (timeToMilliseconds({ [unit]: unitValue - valueTime[unit] }));
                    const isDisabled = newValue > maxDuration || newValue < (minDuration || 0);
                    return (
                        <button
                            className={classNames('unit-value', { active: valueTime[unit] === unitValueIndex })}
                            style={{ minHeight: UNIT_VALUE_HEIGHT }}
                            key={unitValue}
                            disabled={isDisabled}
                            onClick={() => onValueSelect(unit, unitValue)}
                        >
                            {unitValue}
                        </button>
                    );
                })}
            </div>
        );
    };

    return <>
        <button
            disabled={disabled}
            className={classNames('duration-trigger interactive form-control', className, { disabled })}
            ref={triggerRef}
            onClick={() => togglePicker(!open)}
        >
            {loading && <><Spinner size='small' />&nbsp;</>}
            {valueTime.days ? <>{valueTime.days}d&nbsp;</> : undefined}
            {valueTime.hours ? <>{valueTime.hours}h&nbsp;</> : undefined}
            {valueTime.minutes ? <>{valueTime.minutes}m&nbsp;</> : undefined}
            {valueTime.seconds ? <>{valueTime.seconds}s</> : undefined}
            <span className='space' />
            <Icon className='timelapse-icon'><TimelapseIcon /></Icon>
        </button>

        {open && (
            <Overlay
                onClickOutside={onClickOutside}
                onMouseLeave={(event) => {
                    onMouseLeaveOverlay(event);
                    onMouseLeave?.(event);
                }}
                ref={overlayRef}
                styles={{ minWidth: triggerRef.current?.clientWidth }}
                attachedTo={triggerRef.current}
                className={classNames('duration-modal section small', pickerClassName)}
            >
                <div className='time-unit-labels'>
                    {visibleUnits.map((unit) => <span className='unit-label' key={unit}>{unit}</span>)}
                </div>
                <div className='time-unit-selectors'>
                    {visibleUnits.map(renderTimeUnitSelector)}
                </div>
            </Overlay>
        )}
    </>;
};

export default Duration;
