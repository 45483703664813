import { uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useClient } from '../../../client/client-context';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';
import { convertToBech32Address } from '../../../wallet/wallet-service';
import { getBalances, getERC20Balances } from '../../account-service';
import { useHubNetworkState } from '../../hub-network-state-context';
import { useAccountConfiguration } from '../use-account-configuration';

const LOADING_TIMES_OFFSET = 5000;

export interface UseAccountBalancesValue {
    balances?: CoinsAmount[];
    loading: boolean;
    unavailableEndpointRollapps: Network[];
}

export const useAccountBalances = (toLoad?: boolean, dataRefreshing?: boolean): UseAccountBalancesValue => {
    const hubNetworkState = useHubNetworkState();
    const { getNetwork } = useNetwork();
    const { configuration, saveVisibleErc20Tokens } = useAccountConfiguration();
    const { clientStateMap } = useClient();
    const [ bankBalances, setBankBalances ] = useState<CoinsAmount[]>();
    const [ erc20Balances, setErc20Balances ] = useState<CoinsAmount[]>();
    const [ bankBalancesLoading, setBankBalancesLoading ] = useState(true);
    const [ erc20BalancesLoading, setErc20BalancesLoading ] = useState(true);
    const [ unavailableBankEndpointRollapps, setUnavailableBankEndpointRollapps ] = useState<string[]>([]);
    const [ unavailableErc20EndpointRollapps, setUnavailableErc20EndpointRollapps ] = useState<string[]>([]);
    const cancelAndSetBankBalancesPromise = useCancelablePromise<PromiseSettledResult<CoinsAmount[]>[]>();
    const cancelAndSetErc20BalancesPromise = useCancelablePromise<PromiseSettledResult<CoinsAmount[]>[]>();
    const [ , setPreviousBankLoadingDate ] = useState<number>(0);
    const [ , setPreviousErc20LoadingDate ] = useState<number>(0);

    const unavailableEndpointRollapps = useMemo(
        () => filterNonEmptyValues(uniq([ ...unavailableBankEndpointRollapps, ...unavailableErc20EndpointRollapps ])
            .map((networkId) => getNetwork(networkId))),
        [ getNetwork, unavailableBankEndpointRollapps, unavailableErc20EndpointRollapps ],
    );

    const balances = useMemo(() => {
        if (!bankBalances && !erc20Balances) {
            return undefined;
        }
        return [ ...(bankBalances || []), ...(erc20Balances || []) ].sort((balance1, balance2) =>
            ((balance2?.amount ? 1 : 0) - (balance1?.amount ? 1 : 0)) || ((balance1?.ibc ? 1 : 0) - (balance2?.ibc ? 1 : 0)));
    }, [ bankBalances, erc20Balances ]);

    const loading = useMemo(
        () => bankBalancesLoading || erc20BalancesLoading ||
            Boolean(configuration?.visibleNetworks.some((networkId) => clientStateMap[networkId]?.connecting)),
        [ bankBalancesLoading, clientStateMap, configuration?.visibleNetworks, erc20BalancesLoading ],
    );

    useEffect(() => {
        setBankBalances(undefined);
        setErc20Balances(undefined);
        setUnavailableBankEndpointRollapps([]);
        setUnavailableErc20EndpointRollapps([]);
        setBankBalancesLoading(Boolean(hubNetworkState.address));
        setErc20BalancesLoading(Boolean(hubNetworkState.address));
        cancelAndSetBankBalancesPromise();
        cancelAndSetErc20BalancesPromise();
        setPreviousBankLoadingDate(0);
        setPreviousErc20LoadingDate(0);
    }, [ cancelAndSetBankBalancesPromise, cancelAndSetErc20BalancesPromise, hubNetworkState.address ]);

    useEffect(() => {
        setPreviousBankLoadingDate(0);
        setPreviousErc20LoadingDate(0);
    }, [ configuration?.visibleNetworks, dataRefreshing ]);

    useEffect(() => {
        const clientStates = configuration?.visibleNetworks.map((networkId) => clientStateMap[networkId]);
        if (!toLoad ||
            !hubNetworkState.hexAddress ||
            !configuration ||
            !clientStates ||
            clientStates.some((state) => !state || state.connecting)) {
            return;
        }
        setPreviousBankLoadingDate((previousLoadingDate) => {
            if (Date.now() - previousLoadingDate < LOADING_TIMES_OFFSET) {
                return previousLoadingDate;
            }
            setBankBalancesLoading(true);
            const unavailableEndpointRollapps = filterNonEmptyValues((configuration?.visibleNetworks || [])
                .filter((networkId) => !clientStateMap[networkId]?.client));
            const clients = filterNonEmptyValues(clientStates.map((state) => state?.client));
            cancelAndSetBankBalancesPromise(() => Promise
                .allSettled(clients.map(async (client) => {
                    const address = convertToBech32Address(hubNetworkState.hexAddress || '', client.getNetwork().bech32Prefix || '');
                    return getBalances(client, address);
                })))
                .then((results) => {
                    const balances = results.reduce((current, result, resultIndex) => {
                        if (result.status === 'rejected') {
                            const network = clients[resultIndex].getNetwork();
                            unavailableEndpointRollapps.push(network.chainId);
                            console.error(`Can't fetch balance for ${network.chainId}`, result.reason);
                            return current;
                        }
                        return [ ...current, ...result.value ];
                    }, [] as CoinsAmount[]);
                    setBankBalances(balances);
                    setUnavailableBankEndpointRollapps(unavailableEndpointRollapps);
                })
                .catch(() => {
                    setBankBalances([]);
                    setUnavailableBankEndpointRollapps(clients.map((client) => client.getNetwork().chainId));
                })
                .finally(() => setBankBalancesLoading(false));

            return Date.now();
        });
    }, [ toLoad, cancelAndSetBankBalancesPromise, clientStateMap, configuration, hubNetworkState.hexAddress ]);

    useEffect(() => {
        const clientStates = configuration?.visibleNetworks.map((networkId) => clientStateMap[networkId]);
        if (!toLoad ||
            !hubNetworkState.hexAddress ||
            !configuration ||
            !clientStates ||
            clientStates.some((state) => !state || state.connecting)) {
            return;
        }
        setPreviousErc20LoadingDate((previousLoadingDate) => {
            if (Date.now() - previousLoadingDate < LOADING_TIMES_OFFSET) {
                return previousLoadingDate;
            }
            setErc20BalancesLoading(true);
            const unavailableEndpointRollapps = filterNonEmptyValues((configuration?.visibleNetworks || [])
                .filter((networkId) => !clientStateMap[networkId]?.client));
            const clients = filterNonEmptyValues(clientStates.map((state) => state?.client));
            cancelAndSetErc20BalancesPromise(() => Promise
                .allSettled(clients.map(async (client) => {
                    const networkId = client.getNetwork().chainId;
                    const erc20Addresses = dataRefreshing ? undefined : configuration?.visibleErc20Tokens[networkId];
                    return getERC20Balances(client, hubNetworkState.hexAddress || '', erc20Addresses).then(({ balances, erc20Tokens }) => {
                        saveVisibleErc20Tokens(networkId, erc20Tokens);
                        return balances;
                    });
                })))
                .then((results) => {
                    const balances = results.reduce((current, result, resultIndex) => {
                        if (result.status === 'rejected') {
                            const network = clients[resultIndex].getNetwork();
                            unavailableEndpointRollapps.push(network.chainId);
                            console.error(`Can't fetch erc20 balance for ${network.chainId}`, result.reason);
                            return current;
                        }
                        return [ ...current, ...result.value ];
                    }, [] as CoinsAmount[]);
                    setErc20Balances(balances);
                    setUnavailableErc20EndpointRollapps(unavailableEndpointRollapps);
                })
                .catch(() => {
                    setErc20Balances([]);
                    setUnavailableErc20EndpointRollapps(clients.map((client) => client.getNetwork().chainId));
                })
                .finally(() => setErc20BalancesLoading(false));

            return Date.now();
        });
    }, [
        toLoad,
        cancelAndSetErc20BalancesPromise,
        clientStateMap,
        configuration,
        dataRefreshing,
        hubNetworkState.hexAddress,
        saveVisibleErc20Tokens,
    ]);

    return { balances, loading, unavailableEndpointRollapps };
};
