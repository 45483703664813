import { CommissionRates, Description, Validator } from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import { validateUrl } from '../../../../shared/utils/text-utils';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import {
    createEmptyCoinsAmount,
    getMaxDenomAmount,
    getMinDenomAmount,
    getStakingCurrency,
    isCoinsEquals,
} from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { AmountTxState } from '../../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../../tx/tx-state';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import { useStaking } from '../../staking-context';
import {
    createCreateValidatorMessage,
    createEmptyCommission,
    createEmptyDescription,
    createUpdateValidatorMessage,
} from './edit-validator-service';

interface UseEditValidatorValue {
    txState: TxState;
    amountTxState: AmountTxState;
    availableBalances: CoinsAmount[];
    commission: CommissionRates;
    description: Description;
    showErrors: boolean;
    commissionError?: string;
    setCoins: (coins: CoinsAmount) => void;
    updateDescription: (data: Partial<Description>) => void;
    updateCommission: (date: Partial<CommissionRates>) => void;
    saveValidator: () => void;
}

const useEditValidator = (validator?: Validator): UseEditValidatorValue => {
    const { networkDenoms } = useNetwork();
    const { showErrorMessage } = useSnackbar();
    const { handleClientError } = useClient();
    const { handleWalletError } = useWallet();
    const { network, networkState } = useStaking();
    const [ showErrors, setShowErrors ] = useState(false);
    const [ description, setDescription ] = useState<Description>(createEmptyDescription());
    const [ commission, setCommission ] = useState<CommissionRates>(createEmptyCommission());

    const validatorName = network.type === 'RollApp' ? 'Governor' : 'Validator';

    const availableBalances = useMemo(() => {
        const stakingCurrency = getStakingCurrency(network);
        const emptyCoins = createEmptyCoinsAmount(network, stakingCurrency, networkDenoms);
        if (validator) {
            return [ { ...emptyCoins, amount: getMaxDenomAmount(Number(validator.tokens), stakingCurrency) } ];
        }
        const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, emptyCoins));
        return [ balance || emptyCoins ];
    }, [ network, networkDenoms, networkState.balances, validator ]);

    const editValidatorMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = networkState.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!coins || !balance || !networkState.address) {
            return [];
        }
        let baseAmountWithoutFee = undefined;
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.max(0, Math.min(coins.amount, balance.amount - fee.amount)) };
            baseAmountWithoutFee =
                balance.baseAmount ? balance.baseAmount - BigInt(getMinDenomAmount(fee.amount, fee.currency)) : undefined;
            baseAmountWithoutFee = baseAmountWithoutFee && baseAmountWithoutFee < BigInt(0) ? BigInt(0) : baseAmountWithoutFee;
        }
        const message = validator ?
            createUpdateValidatorMessage(validator, description, commission) :
            createCreateValidatorMessage(
                description,
                commission,
                networkState.address,
                coins,
                network,
                baseAmountWithoutFee,
            );
        return [ message ];
    }, [ commission, description, network, networkState.address, networkState.balances, validator ]);

    const { txState, amountTxState, calculateFee, clearFee, setAmount, setCoins, broadcast } = useAmountTx({
        networkState,
        availableBalances,
        amountTxMessagesCreator: editValidatorMessagesCreator,
    });

    useEffect(() => {
        if (validator && !txState.broadcasting) {
            setDescription((description) => validator.description || description);
            setCommission((commission) => validator.commission?.commissionRates || commission);
            setAmount(getMaxDenomAmount(Number(validator.tokens), getStakingCurrency(network)));
        }
    }, [ network, setAmount, txState.broadcasting, validator ]);

    const commissionError = useMemo(() => {
        if (!commission.rate) {
            return 'Missing commission rate';
        }
        if (!commission.maxChangeRate) {
            return 'Missing commission maximum-change rate';
        }
        if (Number(commission.rate) > Number(commission.maxRate)) {
            return 'The commission rate is higher than the maximum rate.';
        }
        if (validator && Number(commission.rate) &&
            Math.abs(Number(commission.rate) - Number(validator.commission?.commissionRates?.rate)) > Number(commission.maxChangeRate)) {
            return 'The commission rate was increased by an amount that exceeds the maximum allowed daily increase.';
        }
    }, [ commission.maxChangeRate, commission.maxRate, commission.rate, validator ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            if (error.code === 'BROADCAST_TX_FAILED' && error.originalError) {
                const result = /commission to less than minimum rate of (0\.\d+)/.exec(error.originalError.message);
                if (result && result[1]) {
                    showErrorMessage(`Cannot set ${validatorName} commission to less than minimum rate of ${Number(result[1]) * 100}`);
                } else {
                    handleClientError(error);
                }
            } else {
                handleClientError(error);
            }
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, showErrorMessage, validatorName ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => {
        if (networkState.address && amountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ amountTxState.coins?.currency, calculateFee, clearFee, networkState.address ]);

    const updateDescription = useCallback(
        (data: Partial<Description>): void => setDescription((description) => ({ ...description, ...data })),
        [],
    );

    const updateCommission = useCallback(
        (data: Partial<CommissionRates>): void => setCommission((commission) => ({ ...commission, ...data })),
        [],
    );

    const saveValidator = useCallback(() => {
        if (!description.moniker || commissionError || (description.website && validateUrl(description.website))) {
            setShowErrors(true);
            return;
        }
        setShowErrors(false);
        broadcast().then();
    }, [ broadcast, commissionError, description.moniker, description.website ]);

    return {
        description,
        commission,
        txState,
        amountTxState,
        availableBalances,
        updateDescription,
        updateCommission,
        showErrors,
        saveValidator,
        commissionError,
        setCoins,
    };
};

export default useEditValidator;
