import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { useNetworkAddress } from '../../../modules/account/use-network-address';
import { getShortenedAddress } from '../../utils/text-utils';
import Icon from '../icon/icon';
import Menu, { MenuAction } from '../menu/menu';
import Tooltip from '../tooltip/tooltip';
import './addresses.scss';

interface AddressesProps {
    addresses: string[];
    canCopy?: boolean;
    className?: string;
    copyTooltipAddressesOnly?: boolean;
    size?: 'small' | 'medium';
    display?: 'short' | 'long' | 'full';
    trigger?: ReactNode,
    hideShortAddressPrefix?: boolean;
}

const Addresses: React.FC<AddressesProps> = ({
    addresses,
    canCopy,
    className,
    size = 'medium',
    display = 'short',
    trigger,
    copyTooltipAddressesOnly,
    hideShortAddressPrefix,
}) => {
    const { copyAddress } = useNetworkAddress();
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);

    const addressContainer = (
        <span
            className={classNames('address-container', className, size, { canCopy, isMenuOpen })}
            data-ga-event='copy_address_click'
            onClick={(event) => {
                event.preventDefault();
                if (canCopy && !copyTooltipAddressesOnly) {
                    event.stopPropagation();
                    copyAddress(addresses[0], true);
                }
            }}
        >
            {trigger || (display === 'full' ? addresses[0] : getShortenedAddress(
                addresses[0] || '',
                hideShortAddressPrefix ? 0 : display === 'long' ? 12 : undefined,
                display === 'long' ? 10 : undefined,
            ))}
        </span>
    );
    if (!canCopy) {
        return addressContainer;
    }
    if (addresses.length === 1) {
        return <Tooltip title='Copy address' clickTitle='Address copied!'>{addressContainer}</Tooltip>;
    }
    return (
        <Menu overlayAlign='center' triggerOnHover trigger={addressContainer} onOpenChange={setIsMenuOpen}>
            {addresses.map((address) => (
                <MenuAction
                    onClick={() => copyAddress(address, true)}
                    key={address}
                    size='small'
                    trackEvent={!address ? undefined : address.startsWith('0x') ? 'copy_hex_address_click' : 'copy_native_address_click'}
                >
                    <Icon><CopyIcon /></Icon>&nbsp;&nbsp;
                    {display === 'full' ? address : getShortenedAddress(
                        address,
                        display === 'long' ? 12 : undefined,
                        display === 'long' ? 10 : undefined,
                    )}
                </MenuAction>
            ))}
        </Menu>
    );
};

export default Addresses;
