import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../../shared/components/badge/badge';
import Icon from '../../../../shared/components/icon/icon';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as DymensionLogo } from '../../../../assets/logos/dymension-logo.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import { ReactComponent as EthereumLogo } from '../../../../assets/logos/ethereum-logo2.svg';
import { ReactComponent as CosmWasmLogo } from '../../../../assets/logos/cosm-wasm-logo.svg';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { getHistoryValuesInPeriod } from '../../../../shared/components/statistics/statistics-change/statistics-change-service';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { getTimeLeftText, getTimeOffset } from '../../../../shared/utils/date-utils';
import { formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import PoolApr from '../../../amm/pools/pool-apr/pool-apr';
import { useAsset } from '../../../asset/asset-context';
import { getMaxDenomAmount } from '../../../currency/currency-service';
import { useIRO } from '../../../iro/iro-context';
import IroProgress from '../../../iro/iro-progress/iro-progress';
import IroStatusIndicator from '../../../iro/iro-status/iro-status-indicator';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';
import SimpleLineChart from '../../../network/statistics/charts/simple-line-chart/simple-line-chart';
import { useSponsorship } from '../../../sponsorship/sponsorship-context';
import RollappSummary from '../../rollapp-summary/rollapp-summary';
import { useRollapps } from '../rollapps-context';
import './rollapp-list.scss';

const RollappList: React.FC = () => {
    const navigate = useNavigate();
    const { loading, hubCurrency } = useNetwork();
    const {
        filteredRollapps,
        statusFilter,
        rollappsAnalyticsState,
        launchType,
        orderBy,
        orderDirection,
        setOrder,
    } = useRollapps();
    const { vsAsset, mainAssetMap, loading: assetsLoading } = useAsset();
    const { getIroPlan, loading: iroLoading } = useIRO();
    const { isMobile } = useWindowSize();
    const { distribution, loading: distributionLoading } = useSponsorship();

    const renderRollappNameColumn = (rollapp: Network): ReactElement => {
        return <TableColumn className='name-column'><RollappSummary rollapp={rollapp} showFairLaunch /></TableColumn>;
    };

    const renderSponsoredColumn = (rollapp: Network): ReactElement => {
        const sponsored = distribution?.find((record) => record.rollapp?.chainId === rollapp.chainId);

        return (
            <TableColumn align='right'>
                {!distribution && distributionLoading ? <Spinner size='small' /> : <>
                    {formatPrice(sponsored?.power || 0, '', { minimumFractionDigits: 0 })}&nbsp;
                    <small className='font-weight-600'>{hubCurrency?.displayDenom}</small>
                </>}
            </TableColumn>
        );
    };

    const renderMarketCapColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        const totalSupply = getMaxDenomAmount(rollapp.totalSupply?.value.amount || 0, asset?.currency);
        const currentMarketCap = (asset?.price || 0) * totalSupply;
        const previousMarketCap = (asset?.previousDayPrice || 0) * totalSupply;
        const marketCapValue = currentMarketCap && <>
            {asset?.invalidMarketCap &&
                <Icon className='mc-warning-icon' tooltip='Misleading valuation due to illiquid token.'><WarningIcon /></Icon>}
            {formatPrice(currentMarketCap, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </>;
        return (
            <TableColumn align='right' key='market-cap' className={classNames('market-cap-column', { invalid: asset?.invalidMarketCap })}>
                {rollapp.tokenless ? (
                    <Badge
                        className='dym-native-badge'
                        info='This RollApp is powered by DYM'
                        noWrap
                        rounded
                        size={isMobile ? 'small' : 'medium'}
                        label={<><Icon className='dym-native-badge-icon'><DymensionLogo /></Icon>DYM Native</>}
                    />
                ) : !mainAssetMap && assetsLoading ? <Spinner size='small' /> :
                    !currentMarketCap || asset?.futureIRO ? <span className='secondary-text'>N/A</span> : (
                        <StatisticsChange
                            showChangeAffect
                            align='right'
                            period='day'
                            currentValue={currentMarketCap}
                            previousValue={previousMarketCap}
                        >
                            {marketCapValue}
                        </StatisticsChange>
                    )}
            </TableColumn>
        );
    };

    const renderTvlColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn align='right' key='tvl'>
                {rollapp.status === 'IRO' || rollapp.totalSupply?.value.tvl === undefined ? <span className='secondary-text'>N/A</span> : (
                    <StatisticsChange
                        showChangeAffect
                        align='right'
                        period='day'
                        currentValue={rollapp.totalSupply?.value.tvl || 0}
                        previousValue={rollapp.totalSupply?.previousDayValue?.tvl || 0}
                    >
                        {formatPrice(getMaxDenomAmount(rollapp.totalSupply?.value.tvl || 0, vsAsset?.currency))}
                    </StatisticsChange>
                )}
            </TableColumn>
        );
    };

    const renderVmColumn = (rollapp: Network): ReactElement => {
        return (
            <TableColumn align='right' key='vm'>
                <Icon className='vm-logo' iconColorMode='original'>{rollapp.evmType === 'EVM' ? <EthereumLogo /> : <CosmWasmLogo />}</Icon>
                {rollapp.evmType}
            </TableColumn>
        );
    };

    const renderIroProgressColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        return (
            <TableColumn align='right' key='iro-progress'>
                {!asset?.iroProgress && iroLoading ? <Spinner size='small' /> : !asset?.iroProgress ?
                    <span className='secondary-text'>N/A</span> : <IroProgress className='iro-bonding-curv-progress' asset={asset} />}
            </TableColumn>
        );
    };

    const renderDymRaisedColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        return (
            <TableColumn align='right' key='dym-raised'>
                {!asset?.iroDymRaised && iroLoading ? <Spinner size='small' /> : !asset?.iroDymRaised ?
                    <span className='secondary-text'>N/A</span> : formatPrice(asset.iroDymRaised, hubCurrency?.displayDenom)}
            </TableColumn>
        );
    };

    const renderIroTimeColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        const iroPlan = asset && getIroPlan(asset.networkId);
        const today = new Date();
        const startTime = iroPlan?.startTime ? new Date(iroPlan.startTime) : today;
        const endTime = iroPlan?.preLaunchTime ? new Date(iroPlan.preLaunchTime) : today;

        return (
            <TableColumn
                align='right'
                key='iro-time'
                className={classNames('iro-time-column', { launchable: iroPlan && endTime <= today })}
            >
                {!iroPlan || !asset ? <span className='secondary-text'>N/A</span> : <>
                    <IroStatusIndicator asset={asset} className='iro-status-indicator' />
                    {endTime <= today ? 'Ready to Launch' :
                        getTimeLeftText(getTimeOffset(startTime > today ? startTime : endTime), undefined, true)}
                </>}
            </TableColumn>
        );
    };

    const renderLiquidityColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];

        return (
            <TableColumn align='right' key='liquidity'>
                {rollapp.tokenless ? undefined : !mainAssetMap && assetsLoading ? <Spinner size='small' /> :
                    !asset || asset.futureIRO ? <span className='secondary-text'>N/A</span> :
                        formatPrice(asset?.liquidity || 0, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </TableColumn>
        );
    };

    const renderPoolAprColumn = (rollapp: Network): ReactElement => {
        const pool = mainAssetMap?.[rollapp.chainId]?.pools?.[0];
        return (
            <TableColumn align='right' key='pool-apr' className='apr-value'>
                {rollapp.tokenless ? undefined :
                    !pool || rollapp.tokenless ? <span className='secondary-text'>N/A</span> : <PoolApr pool={pool} />}
            </TableColumn>
        );
    };

    const renderStakingAprColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        const onAprValueClick = (event: React.MouseEvent) => {
            event.stopPropagation();
            navigate(`/rollapps/${rollapp.chainId}/staking`);
        };
        return (
            <TableColumn align='right' key='staking-apr'>
                {rollapp.tokenless ? undefined : !asset?.stakingApr ? <span className='secondary-text'>N/A</span> :
                    <span className='staking-apr-value' onClick={onAprValueClick}>{roundNumber(asset.stakingApr, 2)}%</span>}
            </TableColumn>
        );
    };

    const renderSimpleChangeChart = (rollapp: Network, type: 'FDV' | 'TVB'): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        const data = rollappsAnalyticsState.analyticsMap?.[rollapp.chainId]?.totalSupply;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');
        const isLoading = rollappsAnalyticsState.loadingMap?.[rollapp.chainId] !== false;

        return (
            <TableColumn align='right' className='simple-chart-column'>
                {!data && isLoading ? <Spinner size='small' /> :
                    !weekData || (type === 'FDV' ? (!asset && !rollapp.tokenless) : !data?.value.tvl) || asset?.futureIRO ?
                        <span className='secondary-text'>N/A</span> : (
                            <SimpleLineChart
                                historyList={weekData}
                                fetchComparableValues={({ marketCap, tvl }) => type === 'FDV' ? marketCap : tvl}
                            />
                        )}
            </TableColumn>
        );
    };

    const renderRollappsHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn
                    sortable
                    orderDirection={orderBy === 'name' ? orderDirection : ''}
                    onSort={(direction) => setOrder('name', direction)}
                >
                    Name
                </TableColumn>
                {launchType !== 'DYM Native' && (
                    <TableColumn
                        align='right'
                        key='market-cap'
                        sortable
                        orderDirection={orderBy === 'market-cap' ? orderDirection : ''}
                        onSort={(direction) => setOrder('market-cap', direction)}
                    >
                        {isMobile ? 'FDV MC' : 'FDV Market Cap'}
                    </TableColumn>
                )}
                {launchType === 'DYM Native' ? [
                    <TableColumn
                        align='right'
                        sortable
                        key='tvl'
                        info='Total amount of IBC tokens bridged to the RollApp'
                        orderDirection={orderBy === 'tvl' ? orderDirection : ''}
                        onSort={(direction) => setOrder('tvl', direction)}
                    >
                        TVB
                    </TableColumn>,
                    <TableColumn align='right' key='liquidity'>Virtual Machine</TableColumn>,
                ] : statusFilter !== 'IRO' ? [
                    <TableColumn
                        align='right'
                        sortable
                        key='liquidity'
                        orderDirection={orderBy === 'liquidity' ? orderDirection : ''}
                        onSort={(direction) => setOrder('liquidity', direction)}
                    >
                        Liquidity
                    </TableColumn>,
                    <TableColumn
                        align='right'
                        key='staking-apr'
                        sortable
                        info={<>
                            <p>Annual yield for staking the RollApp token.</p>
                            <p>To stake, deposit the native token to the RollApp.</p>
                        </>}
                        orderDirection={orderBy === 'staking-apr' ? orderDirection : ''}
                        onSort={(direction) => setOrder('staking-apr', direction)}
                    >
                        Staking APR
                    </TableColumn>,
                    <TableColumn
                        align='right'
                        key='pool-apr'
                        sortable
                        orderDirection={orderBy === 'pool-apr' ? orderDirection : ''}
                        onSort={(direction) => setOrder('pool-apr', direction)}
                        info={<>
                            <p>APR (Annual Percentage Rate) indicates the expected yearly yield for providing liquidity to the pool.</p>
                            <p>You can earn additional rewards by bonding your LP (Liquidity Provider) tokens.</p>
                        </>}
                    >
                        Pool APR
                    </TableColumn>,
                ] : [
                    <TableColumn
                        sortable
                        key='iro-time'
                        align='right'
                        orderDirection={orderBy === 'iro-time' ? orderDirection : ''}
                        onSort={(direction) => setOrder('iro-time', direction)}
                        oppositeSort
                    >
                        Time Left
                    </TableColumn>,
                    <TableColumn
                        sortable
                        align='right'
                        key='dym-raised'
                        orderDirection={orderBy === 'dym-raised' ? orderDirection : ''}
                        onSort={(direction) => setOrder('dym-raised', direction)}
                    >
                        DYM Raised
                    </TableColumn>,
                    <TableColumn
                        sortable
                        key='iro-progress'
                        align='right'
                        orderDirection={orderBy === 'iro-progress' ? orderDirection : ''}
                        onSort={(direction) => setOrder('iro-progress', direction)}
                    >
                        Progress
                    </TableColumn>,
                ]}
                <TableColumn
                    align='right'
                    sortable
                    orderDirection={orderBy === 'total-endorsed' ? orderDirection : ''}
                    onSort={(direction) => setOrder('total-endorsed', direction)}
                    info='The total staked DYM endorsed for this RollApp. This value determines the proportion of DYM rewards allocated to it.'
                >
                    Total Endorsed
                </TableColumn>
                <TableColumn align='right' className='simple-chart-column' nowrap>{launchType === 'DYM Native' ?
                    'TVB' :
                    'FDV'} Change (7d)</TableColumn>
            </TableRow>
        );
    };

    const renderRollappRow = (rollapp: Network): ReactElement => {
        return (
            <TableRow key={rollapp.chainId} onSelect={() => navigate(`/rollapps/${rollapp.chainId}`)}>
                {renderRollappNameColumn(rollapp)}
                {launchType !== 'DYM Native' && renderMarketCapColumn(rollapp)}
                {launchType === 'DYM Native' ? [
                    renderTvlColumn(rollapp),
                    renderVmColumn(rollapp),
                ] : statusFilter !== 'IRO' ? [
                    renderLiquidityColumn(rollapp),
                    renderStakingAprColumn(rollapp),
                    renderPoolAprColumn(rollapp),
                ] : [
                    renderIroTimeColumn(rollapp),
                    renderDymRaisedColumn(rollapp),
                    renderIroProgressColumn(rollapp),
                ]}
                {renderSponsoredColumn(rollapp)}
                {renderSimpleChangeChart(rollapp, launchType === 'DYM Native' ? 'TVB' : 'FDV')}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!filteredRollapps.length) {
            return <div className='no-data'>No RollApps</div>;
        }
    };

    return (
        <div className={classNames('rollapps-table-container', launchType?.toLowerCase())}>
            <Table indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                {renderRollappsHeaderRow()}
                {filteredRollapps.map(renderRollappRow)}
            </Table>
        </div>
    );
};

export default RollappList;
