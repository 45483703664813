import { Connection, PublicKey } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import Web3 from 'web3';
import { filterNonEmptyValues } from '../../shared/utils/object-utils';
import { POOL_SHARE_PREFIX } from '../amm/amm.service';
import { ALL_ITEMS_PAGINATION } from '../client/client-types';
import { Network } from '../network/network-types';
import { CoinsAmount, NetworkDenom } from '../currency/currency-types';
import { convertToCoinsAmount, getMaxDenomAmount } from '../currency/currency-service';
import { StationClient } from '../client/station-clients/station-client';
import { readStream } from '../../shared/utils/file-utils';

export const getBalances = async (client: StationClient, address: string): Promise<CoinsAmount[]> => {
    const { balances } = await client.getBankQueryClient().AllBalances({ address, pagination: ALL_ITEMS_PAGINATION }).catch((error) => {
        return { balances: [], fetchError: error };
    });
    return filterNonEmptyValues(await Promise.all(balances
        .filter((balance) => !balance.denom.startsWith(POOL_SHARE_PREFIX))
        .map((coin) => convertToCoinsAmount(coin, client)),
    ));
};

export const getERC20Tokens = async (
    client: StationClient,
    erc20Tokens?: { denom: string, erc20Address: string }[],
): Promise<{ coins: CoinsAmount[], erc20Tokens: { denom: string, erc20Address: string }[] }> => {
    const network = client.getNetwork();
    if (!network.rest || network.type === 'Hub') {
        return { coins: [], erc20Tokens: [] };
    }
    if (!erc20Tokens) {
        const tokenPairResponse = await fetch(`${network.rest}/evmos/erc20/v1/token_pairs`);
        if (!tokenPairResponse?.ok) {
            throw new Error(`Can't fetch token pairs`);
        }
        const tokenPairResponseText = tokenPairResponse?.body ? await readStream(tokenPairResponse.body) : undefined;
        erc20Tokens = ((JSON.parse(tokenPairResponseText || '{}')?.token_pairs || []) as { erc20_address: string, denom: string }[])
            .map(({ erc20_address, denom }) => ({ erc20Address: erc20_address, denom }));
    }
    const tokens = await Promise.all(erc20Tokens.map(async (tokenPair) => {
        const coins = await convertToCoinsAmount({ amount: '0', denom: tokenPair.denom }, client);
        return coins ? { ...coins, erc20Address: tokenPair.erc20Address } : coins;
    }));
    return { coins: tokens.filter(Boolean) as CoinsAmount[], erc20Tokens };
};

export const getERC20Balances = async (
    client: StationClient,
    hexAddress: string,
    erc20Tokens?: { denom: string, erc20Address: string }[],
): Promise<{ balances: CoinsAmount[], erc20Tokens: { denom: string, erc20Address: string }[] }> => {
    const network = client.getNetwork();
    if (!network.evm?.rpc) {
        return { balances: [], erc20Tokens: [] };
    }
    const tokens = await getERC20Tokens(client, erc20Tokens);
    if (!tokens.coins.length) {
        return { balances: [], erc20Tokens: [] };
    }
    const Web3Client = new Web3(new Web3.providers.HttpProvider(network.evm.rpc));
    const balanceOfABI = [
        {
            constant: true,
            inputs: [ { name: '_owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ];
    const balances = await Promise.all(tokens.coins.map(async (coins) => {
        const contract = new Web3Client.eth.Contract(balanceOfABI as any, coins.erc20Address);
        const balance = await contract.methods.balanceOf(hexAddress).call();
        return { ...coins, amount: getMaxDenomAmount(Number(balance), coins.currency), baseAmount: BigInt(balance) };
    }));
    const nonEmptyBalances = balances.filter((balance) => balance.amount);
    const nonEmptyErc20Tokens = tokens.erc20Tokens.filter((token) =>
        nonEmptyBalances.some((balance) => balance.erc20Address === token.erc20Address));
    return { balances: nonEmptyBalances, erc20Tokens: nonEmptyErc20Tokens };
};

export const getEvmBalances = async (
    network: Network,
    hexAddress: string,
    networkDenoms?: NetworkDenom[],
): Promise<CoinsAmount[]> => {
    if (!network.evm?.rpc) {
        return [];
    }
    const Web3Client = new Web3(new Web3.providers.HttpProvider(network.evm.rpc));
    const balanceOfABI = [
        {
            constant: true,
            inputs: [ { name: '_owner', type: 'address' } ],
            name: 'balanceOf',
            outputs: [ { name: 'balance', type: 'uint256' } ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ];
    return Promise.all(network.currencies.map(async (currency) => {
        let balance;
        if (currency.type === 'main') {
            balance = Number(await Web3Client.eth.getBalance(hexAddress));
        } else {
            const contract = new Web3Client.eth.Contract(balanceOfABI as any, currency.bridgeDenom || '');
            balance = Number(await contract.methods.balanceOf(hexAddress).call());
        }
        let ibc: CoinsAmount['ibc'] | undefined = undefined;
        let networkId = currency.type === 'main' ? network.chainId : '';
        if (currency.ibcRepresentation) {
            const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
            networkId = networkDenom?.ibcNetworkId || networkId;
            ibc = { representation: currency.ibcRepresentation, path: networkDenom?.path || '' };
        }
        return { currency, amount: getMaxDenomAmount(balance, currency), ibc, networkId };
    }));
};

export const getSolanaBalances = async (
    network: Network,
    address: string,
    networkDenoms?: NetworkDenom[],
): Promise<CoinsAmount[]> => {
    if (!network.rpc) {
        return [];
    }
    const connection = new Connection(network.rpc);
    const payerKey = new PublicKey(address);
    return Promise.all(network.currencies
        .filter((currency) => currency.solanaMintAccount && currency.ibcRepresentation)
        .map(async (currency) => {
            const ata = await getAssociatedTokenAddress(new PublicKey(currency.solanaMintAccount || ''), payerKey);
            const accountData = await getAccount(connection, ata, 'confirmed');
            const amount = Number(accountData.amount) || 0;
            const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
            const ibc: CoinsAmount['ibc'] = {
                representation: currency.ibcRepresentation || '',
                path: networkDenom?.path || '',
            };
            return { currency, amount: getMaxDenomAmount(amount, currency), ibc, networkId: networkDenom?.ibcNetworkId || network.chainId };
        }));
};
