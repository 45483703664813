import { uniqBy } from 'lodash';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useAsset } from '../../asset/asset-context';
import { StationClient } from '../../client/station-clients/station-client';
import { createEmptyCoinsAmount, getMainCurrency, getMaxDenomAmount } from '../../currency/currency-service';
import { useNetwork } from '../../network/network-context';
import { Network } from '../../network/network-types';
import { useWallet } from '../../wallet/wallet-context';
import {
    getFilteredInitiatedTransfers,
    getFixedTransfer as getFixedTransferBase,
    getTransferEibcFeeCoins,
    loadIbcTransfer,
    loadIbcTransfers,
    loadInitiatedTransfers,
} from './ibc-status-service';
import { IbcTransferDetails, IbcTransferStatus, MIN_LIQUIDITY_VALUE_ALERT } from './ibc-status-types';

const PAGE_SIZE = 20;

export interface IbcStatusContextValue {
    transfers: IbcTransferDetails[];
    allInitiatedTransfers: IbcTransferDetails[];
    totalCount: number;
    loading: boolean;
    showAll: boolean;
    initiatedTransfersLoading: boolean;
    searchText: string;
    statusesFilter?: IbcTransferStatus[];
    lowLiquidityRollapps: Network[];
    initiatedTransferCreating: boolean;
    setSearchText: (value: string) => void;
    setStatusesFilter: (statuses?: IbcTransferStatus[]) => void;
    getFixedTransfer: (transfer: IbcTransferDetails, fulfiller?: boolean) => IbcTransferDetails | undefined;
    getFixedFullTransfer: (transfer: IbcTransferDetails, client: StationClient) => Promise<IbcTransferDetails>;
    setInitiatedTransferCreating: (value: boolean) => void;
    listenToIbcTransfer: (transferId: string, showMessages?: boolean) => void;
    updateShowAll: (value: boolean, persist?: boolean) => void;
    setHaveEibcOrder: (value: boolean) => void;
    loadMore: () => void;
    refreshData: () => void;
    updateTransfer: (transfer: IbcTransferDetails) => void;
}

export const IbcStatusContext = createContext<IbcStatusContextValue>({} as IbcStatusContextValue);

export const useIbcStatus = (): IbcStatusContextValue => useContext(IbcStatusContext);

export const IbcStatusContextProvider = ({ children }: { children: ReactNode }) => {
    const { networks, hubNetwork, hubChannelNetworkMap, rollapps, eibcLiquidity, networkDenoms } = useNetwork();
    const { showMessage, removeMessage, getMessage } = useSnackbar();
    const { hubWallet } = useWallet();
    const { getTokenPrice, loading: assetLoading } = useAsset();
    const hubNetworkData = useHubNetworkState();
    const [ loading, setLoading ] = useState(true);
    const [ indexedTransfers, setIndexedTransfers ] = useState<IbcTransferDetails[]>([]);
    const [ allInitiatedTransfers, setAllInitiatedTransfers ] = useState<IbcTransferDetails[]>([]);
    const [ initiatedTransfersLoading, setInitiatedTransfersLoading ] = useState(true);
    const [ indexedTotalCount, setIndexedTotalCount ] = useState(0);
    const [ toRefresh, setToRefresh ] = useState(true);
    const [ searchText, setSearchText ] = useState('');
    const [ haveEibcOrder, setHaveEibcOrder ] = useState(false);
    const [ statusesFilter, setStatusesFilter ] = useState<IbcTransferStatus[]>();
    const [ initiatedTransferCreating, setInitiatedTransferCreating ] = useState(false);
    const [ page, setPage ] = useState(0);
    const [ , setStatusesListener ] = useState<NodeJS.Timer>();
    const [ listenedTransferIds, setListenedTransferIds ] = useState<{ transferId: string, showMessages: boolean }[]>([]);
    const [ canLoadMore, setCanLoadMore ] = useState(true);
    const [ showAll, setShowAll, , setShowAllWithoutPersist ] = usePersistedState('show-all-ibc-transfers', false);
    const cancelAndSetInitialTransfersPromise = useCancelablePromise<IbcTransferDetails[]>();
    const cancelAndSetIbcTransfersPromise = useCancelablePromise<{ totalCount: number, transfers: IbcTransferDetails[] }>();

    const updateShowAll = useCallback(
        (value: boolean, persist = true) => persist ? setShowAll(value) : setShowAllWithoutPersist(value),
        [ setShowAll, setShowAllWithoutPersist ],
    );

    const updateTransfer = useCallback((updatedTransfer: IbcTransferDetails) => {
        let transferIndex = allInitiatedTransfers.findIndex((transfer) => transfer.id === updatedTransfer.id);
        if (transferIndex >= 0) {
            allInitiatedTransfers[transferIndex] = updatedTransfer;
            setAllInitiatedTransfers([ ...allInitiatedTransfers ]);
        }
        transferIndex = indexedTransfers.findIndex((transfer) => transfer.id === updatedTransfer.id);
        if (transferIndex >= 0) {
            indexedTransfers[transferIndex] = updatedTransfer;
            setIndexedTransfers([ ...indexedTransfers ]);
        }
    }, [ allInitiatedTransfers, indexedTransfers ]);

    useEffect(() => setToRefresh(true), [ showAll, hubWallet, searchText, statusesFilter ]);

    useEffect(() => {
        if (toRefresh || !initiatedTransferCreating) {
            setIndexedTransfers([]);
            setLoading(true);
            setToRefresh(false);
            setCanLoadMore(true);
            setPage(0);
            setAllInitiatedTransfers([]);
            setInitiatedTransfersLoading(Boolean(hubWallet));
            if (!hubWallet) {
                cancelAndSetInitialTransfersPromise();
            }
        }
    }, [ cancelAndSetIbcTransfersPromise, cancelAndSetInitialTransfersPromise, hubWallet, initiatedTransferCreating, toRefresh ]);

    const getFixedTransfer = useCallback((transfer: IbcTransferDetails, fulfiller?: boolean): IbcTransferDetails | undefined => {
        return hubNetwork ? getFixedTransferBase(transfer, hubChannelNetworkMap, hubNetwork, networks, fulfiller) : undefined;
    }, [ hubChannelNetworkMap, hubNetwork, networks ]);

    useEffect(() => {
        setStatusesListener((listener) => {
            if (listener) {
                clearInterval(listener);
            }
            return setInterval(async () => {
                const updatedListenedTransferIds = await Promise.all(listenedTransferIds.map(async ({ transferId, showMessages }) => {
                    const ibcTransfer = await loadIbcTransfer(transferId);
                    if (!ibcTransfer) {
                        return { transferId, showMessages };
                    }
                    const fixedTransfer = getFixedTransfer(ibcTransfer);
                    if (fixedTransfer) {
                        const transferIndex = indexedTransfers.findIndex((transfer) => transfer.id === transferId);
                        if (transferIndex < 0) {
                            setAllInitiatedTransfers((transfers) => transfers.filter((transfer) => transfer.id !== transferId));
                            setIndexedTransfers((transfers) => [ fixedTransfer, ...transfers ]);
                        } else {
                            setIndexedTransfers((transfers) => {
                                transfers[transferIndex] = fixedTransfer;
                                return [ ...transfers ];
                            });
                        }
                    }
                    if (fixedTransfer?.baseStatus === 'Pending') {
                        return { transferId, showMessages };
                    }
                    if (showMessages) {
                        const message = getMessage(transferId);
                        removeMessage(transferId);
                        showMessage({
                            key: transferId,
                            type: fixedTransfer?.baseStatus === 'Success' ? 'success' : 'error',
                            duration: 600000,
                            title: fixedTransfer?.baseStatus === 'Success' ? 'Success' : 'Failure',
                            content: fixedTransfer?.baseStatus === 'Success' ?
                                'Your transfer has completed successfully' :
                                'Your transfer has failed. Please check the explorer for details',
                            action: message?.action ? { ...message.action, close: true } : undefined,
                        });
                    }
                }));
                setListenedTransferIds(filterNonEmptyValues(updatedListenedTransferIds));
            }, 5000);
        });
    }, [ getFixedTransfer, getMessage, indexedTransfers, listenedTransferIds, removeMessage, showMessage ]);

    const getFixedFullTransfer = useCallback(async (
        transfer: IbcTransferDetails,
        client: StationClient,
    ): Promise<IbcTransferDetails> => {
        if (transfer.eibcFee) {
            transfer.eibcFeeCoins = (await getTransferEibcFeeCoins(transfer.eibcFee, client)) || undefined;
        }
        return { ...transfer };
    }, []);

    useEffect(() => {
        if (!hubNetwork || !hubNetworkData?.address || !initiatedTransfersLoading) {
            return;
        }
        cancelAndSetInitialTransfersPromise((signal) => loadInitiatedTransfers(hubNetwork.chainId, signal, hubNetworkData.address))
            .then((transfers) => transfers.map((transfer) => getFixedTransfer(transfer)).filter(Boolean) as IbcTransferDetails[])
            .then(setAllInitiatedTransfers)
            .finally(() => setInitiatedTransfersLoading(false));
    }, [ cancelAndSetInitialTransfersPromise, getFixedTransfer, hubNetwork, hubNetworkData.address, initiatedTransfersLoading ]);

    useEffect(() => {
        if (!hubNetwork || !canLoadMore || !loading) {
            return;
        }
        const lowerCaseSearchText = searchText.toLowerCase();
        const channels = !searchText ? [] : Object.values(hubChannelNetworkMap)
            .filter((network) =>
                network.chainName.toLowerCase().includes(lowerCaseSearchText) ||
                network.chainId.toLowerCase().includes(lowerCaseSearchText))
            .map((network) => network.ibc?.hubChannel) as string[];
        const address = !showAll ? hubNetworkData.address : undefined;
        cancelAndSetIbcTransfersPromise((signal) =>
            loadIbcTransfers(hubNetwork.chainId, signal, PAGE_SIZE, page, statusesFilter, searchText, channels, address, haveEibcOrder))
            .then(({ totalCount, transfers }) => {
                if (transfers.length < PAGE_SIZE) {
                    setCanLoadMore(false);
                }
                transfers = transfers.map((transfer) => getFixedTransfer(transfer)).filter(Boolean) as IbcTransferDetails[];
                setIndexedTransfers((currentTransfers) => !page ? transfers :
                    uniqBy([ ...currentTransfers, ...transfers ], (transfer) => transfer.id));
                setIndexedTotalCount(totalCount);
            })
            .finally(() => setLoading(false));
    }, [
        cancelAndSetIbcTransfersPromise,
        getFixedTransfer,
        canLoadMore,
        haveEibcOrder,
        loading,
        hubChannelNetworkMap,
        hubNetwork,
        hubNetworkData.address,
        page,
        searchText,
        statusesFilter,
        showAll,
    ]);

    const initiatedTransfers = useMemo(() => {
        if (loading || initiatedTransfersLoading) {
            return [];
        }
        const lowerCaseSearchText = searchText.toLowerCase();
        const channels = !searchText ? [] : Object.values(hubChannelNetworkMap)
            .filter((network) =>
                network.chainName.toLowerCase().includes(lowerCaseSearchText) ||
                network.chainId.toLowerCase().includes(lowerCaseSearchText))
            .map((network) => network.ibc?.hubChannel) as string[];
        return getFilteredInitiatedTransfers(allInitiatedTransfers, statusesFilter, searchText, channels)
            .filter((transfer) =>
                !indexedTransfers.some((indexedTransfer) => indexedTransfer.id === transfer.id))
            .sort((transfer1, transfer2) => transfer2.time - transfer1.time);
    }, [
        allInitiatedTransfers,
        hubChannelNetworkMap,
        indexedTransfers,
        initiatedTransfersLoading,
        loading,
        searchText,
        statusesFilter,
    ]);

    const transfers = useMemo(() => {
        if (!hubNetwork || initiatedTransfersLoading) {
            return [];
        }
        return [ ...initiatedTransfers, ...indexedTransfers ]
            .sort((transfer1, transfer2) => transfer2.time - transfer1.time)
            .filter((transfer) => transfer.denom !== '-')
            .filter(Boolean) as IbcTransferDetails[];
    }, [ hubNetwork, indexedTransfers, initiatedTransfers, initiatedTransfersLoading ]);

    const loadMore = useCallback(() => {
        if (!loading && canLoadMore) {
            setLoading(true);
            setPage(page + 1);
        }
    }, [ canLoadMore, loading, page ]);

    const listenToIbcTransfer = useCallback((transferId: string, showMessages = true) => {
        setListenedTransferIds((transferIds) => [ ...transferIds, { transferId, showMessages } ]);
    }, []);

    const lowLiquidityRollapps = useMemo(() => {
        if (!eibcLiquidity?.length || !rollapps.length || !hubNetworkData.address || !networkDenoms?.length || assetLoading) {
            return [];
        }
        const ownedRollapp = rollapps
            .filter((rollapp) => rollapp.owner === hubNetworkData.address)
            .filter((rollapp) => rollapp.status === 'Active' || rollapp.status === 'Degraded' || rollapp.status === 'Unavailable');

        return ownedRollapp.filter((rollapp) => {
            const record = eibcLiquidity.find((record) => record.rollappId === rollapp.chainId);
            if (!record) {
                return true;
            }
            const nativeCurrency = getMainCurrency(rollapp);
            const nativeDenom = networkDenoms?.find((networkDenom) =>
                networkDenom.ibcNetworkId === rollapp.chainId && networkDenom.baseDenom === nativeCurrency?.baseDenom)?.denom;
            if (!nativeDenom) {
                return true;
            }
            const nativeAmount = getMaxDenomAmount(record.liquidity[nativeDenom], nativeCurrency);
            const nativeValue = getTokenPrice(createEmptyCoinsAmount(rollapp, nativeCurrency, networkDenoms, nativeAmount)) || 0;
            return nativeValue < MIN_LIQUIDITY_VALUE_ALERT;
        });
    }, [ assetLoading, eibcLiquidity, getTokenPrice, hubNetworkData.address, networkDenoms, rollapps ]);

    return (
        <IbcStatusContext.Provider
            value={{
                totalCount: indexedTotalCount + initiatedTransfers.length,
                transfers,
                updateTransfer,
                lowLiquidityRollapps,
                allInitiatedTransfers,
                loading,
                initiatedTransfersLoading,
                initiatedTransferCreating,
                searchText,
                statusesFilter,
                loadMore,
                getFixedTransfer,
                getFixedFullTransfer,
                showAll,
                listenToIbcTransfer,
                updateShowAll,
                setHaveEibcOrder,
                refreshData: () => setToRefresh(true),
                setSearchText,
                setStatusesFilter,
                setInitiatedTransferCreating,
            }}
        >
            {children}
        </IbcStatusContext.Provider>
    );
};
