import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { rollappPacket_TypeFromJSON, RollappPacket_Type } from 'cosmjs/packages/stargate/build/modules/delayedack/ts-client/tx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { CoinsAmount } from '../../currency/currency-types';
import { IbcTransferDetails } from '../../ibc-transfer/ibc-status/ibc-status-types';
import { DeliveryTxCode } from '../../tx/tx-types';
import { TxValue, useTx } from '../../tx/use-tx';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useHubNetworkState } from '../hub-network-state-context';
import { createClaimMessage } from './claimable-tokens-service';

interface UseClaimableTokensValue extends Omit<TxValue, 'calculateFee' | 'clearFee' | 'txStateDispatch' | 'setGasPrice' | 'broadcast'> {
    finalizedTransfers: IbcTransferDetails[];
    claim: () => void;
}

export const useClaimableTokens = (transfers?: IbcTransferDetails[]): UseClaimableTokensValue => {
    const networkState = useHubNetworkState();
    const { handleClientError } = useClient();
    const { handleWalletError } = useWallet();
    const [ onRecvClaimed, setOnRecvClaimed ] = useState(false);

    const finalizedTransfers = useMemo(() => (transfers || []).filter((transfer) => transfer.claimable), [ transfers ]);

    const getFilterTransfers = useCallback((onRecv: boolean): IbcTransferDetails[] => finalizedTransfers.filter((transfer) =>
        (rollappPacket_TypeFromJSON(transfer.packetType) === RollappPacket_Type.ON_RECV) === onRecv), [ finalizedTransfers ]);

    const claimMessagesCreator = useCallback((fee?: CoinsAmount, onRecv?: boolean, broadcasting?: boolean): EncodeObject[] => {
        if (!networkState.address || !finalizedTransfers.length) {
            return [];
        }
        let transfers = finalizedTransfers;
        if (broadcasting) {
            transfers = getFilterTransfers(Boolean(onRecv));
            if (!transfers.length) {
                setOnRecvClaimed(true);
                transfers = getFilterTransfers(false);
            }
        }
        return transfers.map((transfer) => createClaimMessage(networkState.address!, transfer));
    }, [ finalizedTransfers, getFilterTransfers, networkState.address ]);

    const { txState, calculateFee, clearFee, broadcast } = useTx({
        networkState: networkState,
        txMessagesCreator: claimMessagesCreator,
    });

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS && !onRecvClaimed) {
            setOnRecvClaimed(true);
            broadcast().then();
        }
    }, [ broadcast, onRecvClaimed, txState.params, txState.response?.deliveryTxCode ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => {
        if (networkState.address && finalizedTransfers.length) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, networkState.address, finalizedTransfers.length ]);

    const claim = useCallback(() => broadcast(undefined, true), [ broadcast ]);

    return { txState, finalizedTransfers, claim };
};
