import React, { useCallback, useEffect, useMemo } from 'react';
import Duration from '../../../../../../../shared/components/form-controls/duration/duration';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import { timeToMilliseconds } from '../../../../../../../shared/utils/date-utils';
import { useIncentives } from '../../../../../../incentives/incentives-context';
import { useIRO } from '../../../../../../iro/iro-context';
import { useCreateRollapp } from '../../../create-rollapp-context';
import './iro-distribution-plan-section.scss';

const IroDistributionPlanSection: React.FC = () => {
    const { iroParams, iroParamsLoading } = useIRO();
    const { iro, haveIRO, rollapp, setIro } = useCreateRollapp();
    const { incentivesState } = useIncentives();

    const minStartTime = useMemo(
        () => timeToMilliseconds({ seconds: iroParams?.incentivesMinStartTimeAfterSettlement?.seconds || 0 }),
        [ iroParams?.incentivesMinStartTimeAfterSettlement?.seconds ],
    );

    const minDistributionTime = useMemo(
        () => timeToMilliseconds({ days: iroParams?.incentivesMinNumEpochsPaidOver || 0 }),
        [ iroParams?.incentivesMinNumEpochsPaidOver ],
    );

    useEffect(() => {
        if (iro.startTimeAfterSettlement < minStartTime) {
            setIro({ ...iro, startTimeAfterSettlement: minStartTime });
        }
    }, [ iro, minStartTime, setIro ]);

    useEffect(() => {
        if (iro.incentivesDistributionTime < minDistributionTime) {
            setIro({ ...iro, incentivesDistributionTime: minDistributionTime });
        }
    }, [ iro, minDistributionTime, setIro ]);

    const onStartTimeChange = useCallback((value: number) => setIro({ ...iro, startTimeAfterSettlement: value }), [ iro, setIro ]);

    const onDistributionTimeChange = useCallback((value: number) => setIro({ ...iro, incentivesDistributionTime: value }), [ iro, setIro ]);

    return (
        <div className='iro-distribution-plan-section section'>
            <h5 className='section-header'>
                Incentives Distribution Plan
                <InfoIndicator indicatorSize='small'>
                    Any tokens not sold during the Launchpad will be allocated as incentives for liquidity providers in the RollApp
                    token and DYM pool, which will open once the RollApp launches.
                </InfoIndicator>
            </h5>

            <div className='controls-row'>
                <div className='control-container'>
                    <div className='control-label-container'>
                        <label>Distribution Delay Period</label>
                        <InfoIndicator indicatorSize='small'>
                            Specify how long after the Launchpad ends the incentive distribution should begin.
                        </InfoIndicator>
                    </div>
                    <Duration
                        minTimeUnit='minutes'
                        value={iro.startTimeAfterSettlement}
                        minDuration={minStartTime}
                        onValueChange={onStartTimeChange}
                        loading={!iroParams && iroParamsLoading}
                        disabled={Boolean(!iroParams || rollapp.sealed || !haveIRO)}
                    />
                </div>

                <div className='control-container'>
                    <div className='control-label-container'>
                        <label>Distribution Duration</label>
                        <InfoIndicator indicatorSize='small'>
                            Select the total duration over which the incentives will be distributed.
                        </InfoIndicator>
                    </div>
                    <Duration
                        minTimeUnit='minutes'
                        value={iro.incentivesDistributionTime}
                        minDuration={minDistributionTime}
                        onValueChange={onDistributionTimeChange}
                        maxDuration={timeToMilliseconds({ days: 365 * 4 })}
                        loading={(!iroParams && iroParamsLoading) || (!incentivesState.params && incentivesState.paramsLoading)}
                        disabled={Boolean(!iroParams || rollapp.sealed || !haveIRO)}
                    />
                </div>
            </div>
        </div>
    );
};

export default IroDistributionPlanSection;
