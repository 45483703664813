import { MsgSend } from 'cosmjs-types/cosmos/bank/v1beta1/tx';
import { MsgSendEncodeObject } from 'cosmjs/packages/stargate';
import Web3 from 'web3';
import { convertToCoin, getClosedAmount } from '../../../../currency/currency-service';
import { CoinsAmount } from '../../../../currency/currency-types';
import { convertToHexAddress } from '../../../../wallet/wallet-service';

export const createSendMessage = (
    fromAddress: string,
    toAddress: string,
    coins: CoinsAmount,
    balance: CoinsAmount,
    maxAmount?: bigint,
): MsgSendEncodeObject => {
    const amount = [ convertToCoin(coins) ];
    amount[0].amount = getClosedAmount(amount[0].amount, coins.currency.decimals, maxAmount).toString();
    return { typeUrl: '/cosmos.bank.v1beta1.MsgSend', value: { fromAddress, toAddress, amount } };
};

export const getSendERC20DataField = (message: MsgSend): string => {
    const TRANSFER_FUNCTION_ABI = {
        constant: false,
        inputs: [ { 'name': '_to', 'type': 'address' }, { 'name': '_value', 'type': 'uint256' } ],
        name: 'transfer',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    };
    return new Web3().eth.abi.encodeFunctionCall(
        TRANSFER_FUNCTION_ABI as any,
        [ convertToHexAddress(message.toAddress), message.amount[0].amount ],
    );
};
