import { Pool } from '../amm/types';
import { CoinsAmount } from '../currency/currency-types';
import { Network } from '../network/network-types';

export const EARNINGS_START_DATE = 1738784065;

export const EARNING_TYPES = [ 'Pool LP', 'Staking', 'Bridge LP', 'Endorsement', 'Royalty' ] as const;
export type EarningType = typeof EARNING_TYPES[number];

export const EARNING_TYPE_DESCRIPTION_MAP: { [type in EarningType]: { description: string, colorVariable: string, url: string } } = {
    'Pool LP': {
        description: 'Provide liquidity on Dymension DEX and earn swap fees, endorsement DYM and RollApp incentives.',
        colorVariable: '--light-green-rgb',
        url: '/amm/pools',
    },
    'Bridge LP': {
        description: 'Provide liquidity for RollApp bridges and earn a % of the traffic with withdrawal fees.',
        colorVariable: '--beige-rgb',
        url: '/ibc/eibc-client',
    },
    Royalty: { description: '', colorVariable: '--light-red-rgb', url: '/rollapps/manage' },
    Staking: {
        description: 'Stake your DYM with validators to secure the network and earn inflation.',
        colorVariable: '--orange-rgb',
        url: '/dymension/staking',
    },
    Endorsement: {
        description: 'Endorse RollApp founders and pools to determine how the onchain incentives budget is distributed.',
        colorVariable: '--light-blue-rgb',
        url: '/dymension/endorsement',
    },
};

export interface EarningRecord {
    block: number;
    type: EarningType;
    time: number;
    amount: CoinsAmount[];
    network?: Network;
    pool?: Pool;
}
