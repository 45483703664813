import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as EndorsementIcon } from '../../../assets/icons/endorsement.svg';
import { ReactComponent as AddPositionIcon } from '../../../assets/icons/add-position.svg';
import { ReactComponent as FairLaunchTagIcon } from '../../../assets/icons/fair-launch-tag.svg';
import Badge from '../../../shared/components/badge/badge';
import Button from '../../../shared/components/button/button';
import CopyableItem from '../../../shared/components/copyable-item/copyable-item';
import Icon from '../../../shared/components/icon/icon';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { timeToMilliseconds } from '../../../shared/utils/date-utils';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import CreatePoolDialog from '../../amm/create-pool-dialog/create-pool-dialog';
import { useAsset } from '../../asset/asset-context';
import ImportTokenDialog from '../../asset/import-token-dialog/import-token-dialog';
import { useDymns } from '../../dymns/dymns-context';
import IbcTransferDialog from '../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import { DepositWithdrawMode } from '../../ibc-transfer/ibc-transfer-types';
import { useNetwork } from '../../network/network-context';
import { getNetworkLogoPath } from '../../network/network-service';
import { Network, ROLLAPP_STATUS_INFO_MAP } from '../../network/network-types';
import RollappStatusIndicator from '../../rollapp/rollapp-status/indicator/rollapp-status-indicator';
import { useSponsorship } from '../../sponsorship/sponsorship-context';
import SponsorshipVoteDialog from '../../sponsorship/sponsorship-vote/sponsorship-vote-dialog';
import './network-header.scss';

interface NetworkHeaderProps {
    network: Network;
    className?: string;
}

export const NetworkHeader: React.FC<NetworkHeaderProps> = ({ network, className }) => {
    const { hubNetwork, networks } = useNetwork();
    const { dymnsState } = useDymns();
    const { vsAsset, mainAssetMap } = useAsset();
    const { distribution } = useSponsorship();
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const [ depositWithdrawMode, setDepositWithdrawMode ] = useState<DepositWithdrawMode>('Deposit');
    const [ depositWithdrawDialogOpen, setDepositWithdrawDialogOpen ] = useState(false);
    const [ importTokenDialogOpen, setImportTokenDialogOpen ] = useState(false);
    const [ sponsorshipVoteDialogOpen, setSponsorshipVoteDialogOpen ] = useState(false);
    const [ createPoolDialogOpen, setCreatePoolDialogOpen ] = useState<boolean>();

    const depositWithdrawOptionalVsNetworks = useMemo(
        () => network?.type === 'Hub' ?
            networks.filter((network) => network.type !== 'Hub').map((network) => network.chainId) :
            filterNonEmptyValues([ hubNetwork?.chainId ]),
        [ hubNetwork?.chainId, network?.type, networks ],
    );

    const asset = useMemo(() => network?.chainId ? mainAssetMap?.[network?.chainId] : undefined, [ mainAssetMap, network?.chainId ]);

    const sponsorship = useMemo(
        () => distribution?.find((record) => record.rollapp?.chainId === network?.chainId),
        [ distribution, network?.chainId ],
    );

    const depositInactive = useMemo(
        () => network?.type === 'RollApp' && network.status !== 'Active' && network.status !== 'Degraded',
        [ network?.status, network?.type ],
    );

    const inactiveTooltip = useMemo(
        () => !network.status || network.status === 'Active' || network.status === 'Degraded' ?
            'Available upon initial transfer of tokens from RollApp to Dymension Hub' :
            network.status === 'IRO' ? 'This token has not launched a rollapp yet.' :
                ROLLAPP_STATUS_INFO_MAP[network.status].infoText,
        [ network.status ],
    );

    const alias = useMemo(() => network && dymnsState.aliasesMap[network.chainId]?.aliases?.[0], [ dymnsState.aliasesMap, network ]);

    const showInactiveLabel = useMemo(
        () => (network?.status === 'Unavailable' || network?.status === 'Degraded') &&
            (network?.inactiveTime && network.inactiveTime < Date.now() - timeToMilliseconds({ days: 7 })),
        [ network?.inactiveTime, network?.status ],
    );

    if (!network) {
        return <></>;
    }
    return (
        <div className={classNames('network-header-container', className)}>
            <div className='network-header'>
                <img className='network-logo' src={getNetworkLogoPath(network)} alt='network logo' />
                <div className='network-details'>
                    <h3 className='network-name-container'>
                        <span className='network-name'>{network.chainName}</span>
                        <div className='horizontally-centered'>
                            {alias && <CopyableItem className='rollapp-alias' text={`@${alias}`} dataName='Domain' />}
                            {network.status && <RollappStatusIndicator status={network.status} />}
                            {network.fairLaunch && (
                                <Icon tooltip='Fair Launch - 100% of the supply allocated to Launchpad' className='fair-launch-tag'>
                                    <FairLaunchTagIcon />
                                </Icon>
                            )}
                        </div>
                    </h3>
                    {(network.shortDescription || network.website || showInactiveLabel) &&
                        <span className='network-short-description'>
                            {network.shortDescription ||
                                <a href={network.website} target='_blank' rel='noreferrer' className='website'>{network.website}</a>}
                            &nbsp;&nbsp;
                            {showInactiveLabel && (
                                <Badge
                                    color={getCssVariableValue('--red-rgb').split(',').map(Number)}
                                    className='inactive-badge'
                                    label='Inactive for over a week'
                                />
                            )}
                        </span>
                    }
                </div>
            </div>

            <div className='network-actions'>
                {/*<Button*/}
                {/*    className='add-asset-to-wallet-action'*/}
                {/*    buttonType='secondary'*/}
                {/*    size='small'*/}
                {/*    onClick={() => setImportTokenDialogOpen(true)}*/}
                {/*    tooltip={!nativeAsset && inactiveTooltip}*/}
                {/*    disabled={!nativeAsset}*/}
                {/*>*/}
                {/*    Add Asset to Wallet&nbsp;<WalletIcon />*/}
                {/*</Button>*/}

                <Button
                    size='small'
                    buttonType='secondary'
                    onClick={() => setDepositWithdrawDialogOpen(true)}
                    tooltip={depositInactive && inactiveTooltip}
                    disabled={depositInactive}
                >
                    <DownloadIcon />&nbsp;Deposit
                </Button>

                {network.type !== 'Hub' && (
                    <Button
                        size='small'
                        buttonType='secondary'
                        disabled={!sponsorship}
                        onClick={() => setSponsorshipVoteDialogOpen(true)}
                    >
                        <EndorsementIcon />&nbsp;Endorse
                    </Button>
                )}

                {network.status !== 'IRO' && (
                    <Button
                        size='small'
                        buttonType='primary'
                        disabled={!asset}
                        onClick={() => asset?.pools.length ? navigate(`/amm/pool/${asset?.pools[0]?.id}`) : setCreatePoolDialogOpen(true)}
                    >
                        <AddPositionIcon />&nbsp;{width < 480 ? '' : 'Add '}Liquidity
                    </Button>
                )}
            </div>

            {depositWithdrawDialogOpen && hubNetwork && (
                <IbcTransferDialog
                    className='network-deposit-withdraw-dialog'
                    optionalSourceNetworks={depositWithdrawMode === 'Deposit' ? depositWithdrawOptionalVsNetworks : [ network.chainId ]}
                    optionalDestinationNetworks={
                        depositWithdrawMode === 'Deposit' ? [ network.chainId ] : depositWithdrawOptionalVsNetworks}
                    initialSourceNetwork={
                        depositWithdrawMode === 'Deposit' && network.type === 'Hub' ? vsAsset?.network.chainId : undefined}
                    initialDestinationNetwork={
                        depositWithdrawMode === 'Withdraw' && network.type === 'Hub' ? vsAsset?.network.chainId : undefined}
                    onRequestClose={() => {
                        setDepositWithdrawDialogOpen(false);
                        setDepositWithdrawMode('Deposit');
                    }}
                    depositWithdrawMode={depositWithdrawMode}
                    onDepositWithdrawModeChange={setDepositWithdrawMode}
                    initialAsset={depositWithdrawMode === 'Deposit' ? asset : undefined}
                />
            )}

            {asset && importTokenDialogOpen && <ImportTokenDialog token={asset} onRequestClose={() => setImportTokenDialogOpen(false)} />}

            {sponsorshipVoteDialogOpen && sponsorship &&
                <SponsorshipVoteDialog sponsorship={sponsorship} onRequestClose={() => setSponsorshipVoteDialogOpen(false)} />}

            {createPoolDialogOpen && <CreatePoolDialog asset={asset} onRequestClose={() => setCreatePoolDialogOpen(false)} />}
        </div>
    );
};

export default NetworkHeader;
