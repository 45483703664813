import React, { CSSProperties, DOMAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import useOutsideClick from '../../hooks/use-outside-click';
import useScrollPosition from '../../hooks/use-scroll-position';
import useWindowSize from '../../hooks/use-window-size';
import './overlay.scss';

export type OverlayAlign = 'right' | 'left' | 'center';
const DEFAULT_PADDING = 24;
const DEFAULT_MOBILE_PADDING = 16;
const MOBILE_WIDTH = 720;

export interface OverlayProps extends DOMAttributes<HTMLDivElement> {
    dimContainer?: boolean;
    className?: string;
    styles?: CSSProperties;
    overlayAlign?: OverlayAlign;
    attachedTo?: HTMLElement | null;
    onClickOutside?: (event: MouseEvent) => void;
    relativePosition?: boolean;
}

export interface OverlayRefProps extends HTMLDivElement {
    calcOverlayBounds?: () => OverlayBounds;
}

interface OverlayBounds {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    maxWidth?: number;
    maxHeight?: number;
}

const Overlay: React.ForwardRefRenderFunction<OverlayRefProps, OverlayProps> = ({
    children,
    className,
    styles,
    attachedTo,
    onClickOutside,
    dimContainer,
    relativePosition,
    overlayAlign = 'left',
    ...otherDomAttributes
}, forwardedRef) => {
    const [ bounds, setBounds ] = useState<OverlayBounds>();
    const overlayPaneRef = useOutsideClick<HTMLDivElement>(onClickOutside);
    const { scrollPosition } = useScrollPosition();
    const windowSize = useWindowSize();

    const calcOverlayBounds = useCallback(() => {
        if (!attachedTo || !overlayPaneRef.current || attachedTo.offsetParent === null) {
            return {};
        }
        const targetBounds = attachedTo.getBoundingClientRect();
        const currentBounds = overlayPaneRef.current.getBoundingClientRect();
        const result: OverlayBounds = {};

        const haveOverflowFromBottom = targetBounds.bottom + currentBounds.height > windowSize.height;
        const haveOverflowFromTop = targetBounds.top - currentBounds.height < 0;
        const maxHeightFromBottom = windowSize.height - targetBounds.bottom;
        const maxHeightFromTop = targetBounds.top;
        if (!haveOverflowFromBottom || (haveOverflowFromTop && maxHeightFromBottom >= maxHeightFromTop)) {
            result.top = targetBounds.bottom;
            result.maxHeight = haveOverflowFromBottom ? maxHeightFromBottom : undefined;
        } else {
            result.bottom = windowSize.height - targetBounds.top;
            result.maxHeight = haveOverflowFromTop ? maxHeightFromTop : undefined;
        }

        const haveOverflowFromLeft = targetBounds.left + currentBounds.width > windowSize.width;
        const haveOverflowFromRight = targetBounds.right - currentBounds.width < 0;
        const maxWidthFromLeft = windowSize.width - targetBounds.left;
        const maxWidthFromRight = targetBounds.right;
        const stylesMaxWidth = styles?.maxWidth ? Number(styles.maxWidth) : undefined;

        if (overlayAlign === 'center') {
            const padding = windowSize.width <= MOBILE_WIDTH ? DEFAULT_MOBILE_PADDING : DEFAULT_PADDING;
            const maxLeft = windowSize.width - currentBounds.width - padding;
            result.left = Math.min(Math.max(targetBounds.left - (currentBounds.width / 2) + (targetBounds.width / 2), padding), maxLeft);
        } else if ((overlayAlign === 'left' && !haveOverflowFromLeft) || (haveOverflowFromRight && maxWidthFromLeft >= maxWidthFromRight)) {
            result.left = targetBounds.left;
            result.maxWidth = haveOverflowFromLeft ? maxWidthFromLeft : stylesMaxWidth;
        } else {
            result.right = windowSize.width - targetBounds.right;
            result.maxWidth = haveOverflowFromRight ? maxWidthFromRight : stylesMaxWidth;
        }
        setBounds(result);
    }, [ attachedTo, overlayPaneRef, windowSize.height, windowSize.width, styles?.maxWidth, overlayAlign ]);

    useImperativeHandle(
        forwardedRef,
        () => ({ ...overlayPaneRef.current, calcOverlayBounds } as OverlayRefProps),
        [ calcOverlayBounds, overlayPaneRef ],
    );

    useEffect(() => {
        if (attachedTo && overlayPaneRef.current) {
            calcOverlayBounds();
        }
    }, [ attachedTo, calcOverlayBounds, overlayPaneRef, scrollPosition ]);

    const onRef = (element: OverlayRefProps): void => {
        overlayPaneRef.current = element;
        if (typeof forwardedRef === 'function') {
            forwardedRef(element);
        } else if (forwardedRef) {
            forwardedRef.current = element;
        }
    };

    const overlayContainerClassName = classNames('overlay-container', { dim: dimContainer, relative: relativePosition });
    const overlayPaneClassName = classNames('overlay-pane', className, { visible: !attachedTo || bounds });

    return (
        <div className={overlayContainerClassName}>
            <div className={overlayPaneClassName} style={{ ...styles, ...bounds }} ref={onRef} {...otherDomAttributes}>{children}</div>
        </div>
    );
};

export default forwardRef(Overlay);
