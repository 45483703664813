import classNames from 'classnames';
import React, { useCallback, useMemo, useRef } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import AlertBox from '../../../shared/components/alert-box/alert-box';
import Input from '../../../shared/components/form-controls/input/input';
import Icon from '../../../shared/components/icon/icon';
import ToggleSwitch from '../../../shared/components/toggle-switch/toggle-switch';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { formatPrice } from '../../../shared/utils/number-utils';
import TransactionFee from '../../tx/transaction-fee/transaction-fee';
import { Fee } from '../../tx/tx-types';
import { DEFAULT_SLIPPAGE_TOLERANCE, useTrade } from '../trade-context';
import './trade-details.scss';

interface TradeDetailsProps {
    className?: string;
    expanded?: boolean;
    persistedExpanded?: boolean;
    inverseSpotPrice?: boolean;
}

const TRADE_DETAILS_EXPANDED_KEY = 'tradeDetailsExpandedKey';

const TradeDetails: React.FC<TradeDetailsProps> = ({ className, expanded, persistedExpanded, inverseSpotPrice }) => {
    const {
        txState,
        setSlippageTolerance,
        asset1AmountTxState,
        asset2AmountTxState,
        slippageTolerance,
        fees,
        slippageToleranceMode,
        spotPrice,
        slippageDisabled,
        setSlippageDisabled,
    } = useTrade();
    const [ tradeDetailsExpanded, setTradeDetailsExpanded ] =
        usePersistedState(TRADE_DETAILS_EXPANDED_KEY, expanded, undefined, persistedExpanded ? 'storage' : 'none');
    const feesContainerRef = useRef<HTMLDivElement>(null);

    const onSlippageToleranceChange = useCallback((value: string): string => {
        if (!value) {
            setSlippageTolerance?.(undefined);
            return '';
        }
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        const numberValue = Number(value);
        setSlippageTolerance?.(Math.min(100, Math.max(0, numberValue)));
        if (numberValue < 0) {
            return '0';
        }
        if (numberValue > 100) {
            return '100';
        }
        return numberValue.toString();
    }, [ setSlippageTolerance ]);

    const transactionFee = useMemo((): Fee => {
        return { value: txState.fee?.coins || '-', loading: txState.feeLoading, label: 'Transaction fee' };
    }, [ txState.fee?.coins, txState.feeLoading ]);

    if (!asset1AmountTxState.coins || !asset2AmountTxState.coins) {
        return <></>;
    }
    return (
        <AlertBox hideAlertIcon className={classNames('trade-details', className, { expanded: tradeDetailsExpanded })}>
            <button className='token-price' onClick={() => setTradeDetailsExpanded(!tradeDetailsExpanded)}>
                {!inverseSpotPrice ? <>
                    1 {asset1AmountTxState.coins.currency.displayDenom} ≈&nbsp;
                    {formatPrice(spotPrice, asset2AmountTxState.coins.currency.displayDenom)}
                </> : <>
                    1 {asset2AmountTxState.coins.currency.displayDenom} ≈&nbsp;
                    {formatPrice(!spotPrice ? 0 : 1 / spotPrice, asset1AmountTxState.coins.currency.displayDenom)}
                </>}&nbsp;
                <span className='space' />
                <Icon className='arrow-down-icon'><ArrowDownIcon /></Icon>
            </button>

            <div
                className='collapsible-fees-container'
                style={{ maxHeight: (tradeDetailsExpanded ? 1 + (feesContainerRef.current?.clientHeight || 0) : 0) }}
            >
                <div className='fees-container' ref={feesContainerRef}>
                    <TransactionFee className='fee-property' valueClassName='fee-value' fee={transactionFee} />
                    {fees?.map((fee, index) => <TransactionFee
                        key={index}
                        fee={fee}
                        className='fee-property'
                        valueClassName='fee-value'
                    />)}
                    {slippageToleranceMode !== 'ignore' && <>
                        <div className='fee-property border'>
                            {setSlippageDisabled && (
                                <ToggleSwitch
                                    className='slippage-disabled-switch'
                                    isChecked={!slippageDisabled}
                                    onCheck={(value) => setSlippageDisabled(!value)}
                                />
                            )}
                            <span className={slippageDisabled ? 'slippage-disabled' : ''}>Slippage Tolerance</span>
                            <span className='space' />
                            <Input
                                className='fee-value'
                                suffix='%'
                                min={0}
                                max={100}
                                type='number'
                                disabled={slippageDisabled}
                                value={slippageTolerance}
                                placeholder={DEFAULT_SLIPPAGE_TOLERANCE.toString()}
                                onValueChange={onSlippageToleranceChange}
                            />
                        </div>
                    </>}
                </div>
            </div>
        </AlertBox>
    );
};

export default TradeDetails;
