import classNames from 'classnames';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import Button from '../../../shared/components/button/button';
import HighlightText from '../../../shared/components/highlight-text/HighlightText';
import Icon from '../../../shared/components/icon/icon';
import Spinner from '../../../shared/components/spinner/spinner';
import TabBar, { Tab } from '../../../shared/components/tabs/tab-bar';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import { getShortDateTimeString } from '../../../shared/utils/date-utils';
import { ReactComponent as LikeIcon } from '../../../assets/icons/like.svg';
import { ReactComponent as AccountCircleIcon } from '../../../assets/icons/account-cricle.svg';
import { ReactComponent as FilledLikeIcon } from '../../../assets/icons/filled-like.svg';
import { useNetworkComments } from './network-comments-context';
import { SortType } from './types';
import './network-comments.scss';

interface NetworkCommentsProps {
    className?: string;
}

const NetworkComments: React.FC<NetworkCommentsProps> = ({ className }) => {
    const {
        commentTextHighlight,
        loading,
        comments,
        sortType,
        isLike,
        getPendingLike,
        likeComment,
        getUser,
        setSortType,
        loadNext,
    } = useNetworkComments();
    const commentsContainerRef = useRef<HTMLDivElement>(null);
    const { canLoadMore } = useScrollPosition(commentsContainerRef.current || undefined);

    const commentsData = useMemo(() => {
        return comments.map((comment) => {
            const pendingLike = getPendingLike(comment);
            const liked = pendingLike !== -1 && (isLike(comment) || pendingLike === 1);
            return { comment, user: getUser(comment), liked, pendingLike };
        }).filter(({ user }) => Boolean(user));
    }, [ comments, getUser, isLike, getPendingLike ]);

    useEffect(() => {
        if (commentsContainerRef.current && canLoadMore) {
            loadNext();
        }
    }, [ loadNext, canLoadMore ]);

    // todo: add read-all if more then 6 lines (120px)

    const renderComments = (): ReactNode => {
        return (
            <div className='comments-container'>
                {commentsData.map(({ comment, user, liked, pendingLike }) => (
                    <div className='comment' key={comment.id}>
                        <div className='comment-header'>
                            {user?.avatar ?
                                <img src={user.avatar} className='user-avatar' alt='user avatar' /> :
                                <Icon className='fallback-user-avatar'><AccountCircleIcon /></Icon>}
                            {user?.displayName}
                            <span className='space' />
                            <span className='comment-date'>{getShortDateTimeString(comment.date)}</span>
                        </div>
                        <p className='comment-body'>
                            <HighlightText highlights={[ commentTextHighlight ]}>{comment.body}</HighlightText>
                        </p>
                        {comment.image && <img className='comment-image' src={comment.image} alt='comment' />}
                        <div className='comment-footer'>
                            <Button
                                className={classNames('reaction', { liked })}
                                buttonType='secondary'
                                size='xs'
                                onClick={() => likeComment(comment)}
                            >
                                {liked ? <FilledLikeIcon /> : <LikeIcon />}
                                <span className='likes-value'>{comment.likes + (pendingLike || 0)}</span>
                            </Button>
                        </div>
                    </div>
                ))}
                {loading ? <Spinner className='comments-loader' /> :
                    !commentsData.length ? <span className='no-comments'>No comments yet</span> : undefined}
            </div>
        );
    };

    return (
        <div className={classNames('network-comments section small', className)}>
            <TabBar
                uniformTabWidth
                activeTabKey={sortType}
                onTabChange={(tabKey) => setSortType(tabKey as SortType)}
            >
                <Tab scrolled label={SortType.RECENT} tabKey={SortType.RECENT} containerRef={commentsContainerRef}>{renderComments()}</Tab>
                <Tab scrolled label={SortType.TOP} tabKey={SortType.TOP} containerRef={commentsContainerRef}>{renderComments()}</Tab>
            </TabBar>
        </div>
    );
};

export default NetworkComments;
