import React, { useEffect, useMemo } from 'react';
import Input from '../../../../shared/components/form-controls/input/input';
import { MsgCancelUpgrade } from '../../../client/station-clients/dymension/generated/timeupgrade/tx';
import './proposal-type.scss';

interface RollAppCancelSoftwareUpgradeProps {
    content?: MsgCancelUpgrade;
    onInit?: (data: MsgCancelUpgrade) => void;
    onChange?: (data: Partial<MsgCancelUpgrade>, isValid: boolean) => void;
}

const RollAppCancelSoftwareUpgrade: React.FC<RollAppCancelSoftwareUpgradeProps> = ({ content, onInit, onChange }) => {
    const isValid = useMemo(() => Boolean(content?.authority), [ content ]);

    useEffect(() => onInit?.({
        authority: '',
    }), [ onInit ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Authority</label>
            <Input
                value={content?.authority}
                onValueChange={(value) => onChange?.({ authority: value.toString() || '' }, isValid)}
            />
        </div>
    );
};

export default RollAppCancelSoftwareUpgrade;
