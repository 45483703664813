import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useAsset } from '../../asset/asset-context';
import { useClient } from '../../client/client-context';
import { useNetwork } from '../../network/network-context';
import { getNetworkData } from '../../network/network-service';
import { useWallet } from '../../wallet/wallet-context';
import { useIbcStatus } from '../ibc-status/ibc-status-context';
import { loadFulfilledTransfers } from '../ibc-status/ibc-status-service';
import { IbcTransferDetails } from '../ibc-status/ibc-status-types';
import { getRollappLiquidityList } from './eibc-client-serice';
import { OperatorGroup, RollappLiquidity } from './eibc-client-types';

interface EibcClientContextValue {
    loading: boolean;
    ordersLoading: boolean;
    groupsLoading: boolean;
    groups?: OperatorGroup[];
    liquidityList?: RollappLiquidity[];
    orders?: IbcTransferDetails[];
}

export const EibcClientContext = createContext<EibcClientContextValue>({} as EibcClientContextValue);

export const useEibcClient = (): EibcClientContextValue => useContext(EibcClientContext);

export const EibcClientContextProvider = ({ children }: { children: ReactNode }) => {
    const { getFixedTransfer, getFixedFullTransfer } = useIbcStatus();
    const { clientStateMap, handleClientError } = useClient();
    const { hubWallet } = useWallet();
    const { loading: assetsLoading } = useAsset();
    const { hubNetwork } = useNetwork();
    const networkState = useHubNetworkState();
    const [ loading, setLoading ] = useState(true);
    const [ orders, setOrders ] = useState<IbcTransferDetails[]>();
    const [ ordersLoading, setOrdersLoading ] = useState(true);
    const [ liquidityList, setLiquidityList ] = useState<RollappLiquidity[]>();
    const [ groups, setGroups ] = useState<OperatorGroup[]>();
    const [ groupsLoading, setGroupsLoading ] = useState(true);
    const cancelAndSetLiquidityListPromise = useCancelablePromise<RollappLiquidity[]>();
    const cancelAndSetGroupsPromise = useCancelablePromise<OperatorGroup[]>();

    const clientState = networkState.network ? clientStateMap[networkState.network.chainId] : null;

    useEffect(() => {
        if (hubWallet) {
            setLoading(true);
            setOrdersLoading(true);
        } else {
            setLiquidityList(undefined);
            setLoading(false);
            setOrders(undefined);
            setOrdersLoading(false);
        }
    }, [ hubWallet ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setLoading(false);
            return;
        }
        if (!networkState.address || !clientState?.client || clientState?.connecting) {
            return;
        }
        setLoading(true);
        cancelAndSetLiquidityListPromise(getRollappLiquidityList(clientState.client, networkState.address))
            .then(setLiquidityList)
            .catch((error) => {
                handleClientError(error);
                setLiquidityList(undefined);
            })
            .finally(() => setLoading(false));
    }, [ cancelAndSetLiquidityListPromise, clientState, handleClientError, networkState.address ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setOrdersLoading(false);
            return;
        }
        if (!hubNetwork || !networkState.address || !clientState?.client || clientState?.connecting || assetsLoading) {
            return;
        }
        setOrdersLoading(true);
        loadFulfilledTransfers(hubNetwork.chainId, networkState.address)
            .then((orders) => filterNonEmptyValues(orders.map((order) => getFixedTransfer(order, true))))
            .then((orders) => Promise.all(orders.map((order) => getFixedFullTransfer(order, clientState.client!))))
            .then(setOrders)
            .finally(() => setOrdersLoading(false));
    }, [ assetsLoading, clientState, getFixedFullTransfer, getFixedTransfer, hubNetwork, networkState.address ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setGroupsLoading(false);
            return;
        }
        if (!hubNetwork || !clientState?.client || clientState?.connecting) {
            return;
        }
        setGroupsLoading(true);
        cancelAndSetGroupsPromise((signal) => getNetworkData<OperatorGroup[]>(hubNetwork.chainId, 'groups', false, signal))
            .then(setGroups)
            .catch(handleClientError)
            .finally(() => setGroupsLoading(false));
    }, [ cancelAndSetGroupsPromise, clientState, handleClientError, hubNetwork ]);

    return (
        <EibcClientContext.Provider
            value={{
                liquidityList,
                orders,
                groups,
                groupsLoading,
                ordersLoading,
                loading,
            }}
        >
            {children}
        </EibcClientContext.Provider>
    );
};
