import { SKIP_API_URL, SkipClient, UserAddress } from '@skip-go/client';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SignDoc } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { OfflineAminoSigner, StdSignDoc } from 'cosmjs/packages/amino';
import { OfflineSigner, OfflineDirectSigner } from 'cosmjs/packages/proto-signing';
import Long from 'long';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Chain, createWalletClient, custom } from 'viem';
import { toAccount } from 'viem/accounts';
import * as chains from 'viem/chains';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import Spinner from '../../../shared/components/spinner/spinner';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import { AccountNetworkState } from '../../account/account-network-state';
import { useAmm } from '../../amm/amm-context';
import { ClientError } from '../../client/client-error';
import { ReactComponent as ExplorerIcon } from '../../../assets/icons/explorer.svg';
import { getFixedDenom, getMaxDenomAmount } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { TxError } from '../../tx/tx-error';
import { useWallet } from '../../wallet/wallet-context';
import { createWallet, isWalletSupported } from '../../wallet/wallet-service';
import { Wallet } from '../../wallet/wallet-types';
import { EthereumWallet } from '../../wallet/wallets/ethereum-wallet';
import { BridgeState, bridgeStateReducer } from './bridge-state';
import { findRoute } from './bridge-service';
import { TemporarySessionWallet } from '../../wallet/wallets/temporary-session-wallet';
import { BridgeData } from './bridge-types';
import { BridgeTransfer } from './history/bridge-hisotry-types';
import { useBridgeHistory } from './history/bridge-history-context';

interface UseBridgeValue {
    bridgeState: BridgeState;
    setBroadcasting: (value?: boolean) => void;
    broadcastBridgeTransfer: (ibcHash?: string) => Promise<void>;
    setBridgeCoins: (coins?: CoinsAmount) => void;
    fetchUserWallets: () => Promise<{ [chainId: string]: Wallet | undefined }>;
    saveCurrentTransfer: (data: Partial<BridgeTransfer>) => Promise<void>;
    showTransferMessage: (
        key: string,
        message: string,
        bridgeData?: BridgeData,
        duration?: number,
        hash?: string,
        ibcHash?: string,
        completed?: boolean,
    ) => void;
}

let userWallets: { [chainId: string]: Wallet | undefined } = {};

const useBridge = (
    sourceData: AccountNetworkState,
    destinationData: AccountNetworkState,
    onExploreClick: (transfer: BridgeTransfer) => void,
    findRoutes?: boolean,
): UseBridgeValue => {
    const { ammState } = useAmm();
    const { networkWalletMap, fetchMostSuitableWallet } = useWallet();
    const { networkDenoms, networks, getNetwork } = useNetwork();
    const { getTransfer, saveTransfer } = useBridgeHistory();
    const { showMessage, removeMessage } = useSnackbar();
    const [ coins, setBridgeCoins ] = useState<CoinsAmount>();
    const [ bridgeState, bridgeStateDispatch ] = useReducer(bridgeStateReducer, {});
    const [ , setCreateBridgeRouteTimeout ] = useState<NodeJS.Timeout>();

    const client = useMemo(() => new SkipClient({
        apiURL: SKIP_API_URL,
        endpointOptions: {
            endpoints: networks
                .filter((network) => network.type === 'Solana' || network.type === 'Hub')
                .reduce((current, network) => ({ ...current, [network.chainId]: { rpc: network.rpc, rest: network.rest } }), {}),
        },
        getCosmosSigner: async (chainId) => {
            const wallet = userWallets[chainId];
            const network = getNetwork(chainId);
            if (!wallet || !network) {
                throw new TxError('MISSING_DATA', network, 'Missing network or wallet for: ' + chainId);
            }
            const signer = await wallet.getOfflineSigner(network) as OfflineSigner;
            const directSigner = signer as OfflineDirectSigner;
            const aminoSigner = signer as OfflineAminoSigner;
            return {
                getAccounts: async () => signer.getAccounts(),
                signDirect: !directSigner.signDirect ? undefined : async (signerAddress: string, signDoc: SignDoc) => {
                    const accountNumber = Long.fromNumber(Number(signDoc.accountNumber));
                    const result = await directSigner.signDirect(signerAddress, { ...signDoc, accountNumber });
                    return { signed: signDoc, signature: result.signature };
                },
                signAmino: !aminoSigner.signAmino ? undefined : async (signerAddress: string, signDoc: StdSignDoc) => {
                    const result = await aminoSigner.signAmino(
                        signerAddress, { ...signDoc, account_number: signDoc.account_number.toString() },
                    );
                    return { signed: signDoc, signature: result.signature };
                },
            } as any;
        },
        getEVMSigner: async (chainId) => {
            const network = networks.find((network) => Number(network.evm?.chainId).toString() === chainId);
            const evmWallet = userWallets[chainId] as EthereumWallet;
            const address = network && evmWallet && await evmWallet.getAddress(network);
            if (!address?.hexAddress || !evmWallet) {
                throw new TxError('MISSING_DATA', network, 'Missing network or wallet for: ' + chainId);
            }
            return createWalletClient({
                chain: (Object.values(chains) as Chain[]).find((chain) => chain.id === Number(chainId)),
                transport: custom(await evmWallet.getProvider()),
                account: toAccount(address.hexAddress as any),
            });
        },
        getSVMSigner: async () => {
            const adapter = new PhantomWalletAdapter();
            await adapter.connect();
            return adapter;
        },
    }), [ getNetwork, networks ]);

    const bridgeData = useMemo((): BridgeData => {
        const sourceNetwork = sourceData.network;
        const destinationNetwork = destinationData.network;
        const bridgeData: BridgeData = {
            sourceNetwork,
            destinationNetwork,
            sourceBridgeId: sourceNetwork?.type === 'EVM' ? Number(sourceNetwork.evm?.chainId).toString() : sourceNetwork?.chainId,
            destinationBridgeId: destinationNetwork?.type === 'EVM' ?
                Number(destinationNetwork.evm?.chainId).toString() : destinationNetwork?.chainId,
            sourceAddress: sourceNetwork?.type === 'EVM' ? sourceData.hexAddress : sourceData.address,
            destinationAddress: destinationNetwork?.type === 'EVM' ? destinationData.hexAddress : destinationData.address,
        };
        if (coins) {
            const sourceCurrency = coins.currency;
            if (sourceCurrency?.bridgeDenom) {
                bridgeData.sourceDenom = sourceCurrency.bridgeDenom;
                if (destinationData.network?.type === 'Hub') {
                    bridgeData.destinationDenom = sourceCurrency.ibcRepresentation || '';
                } else if (coins.networkId === destinationData.network?.chainId) {
                    bridgeData.destinationDenom = destinationData.network?.currencies
                        .find((currency) => currency.baseDenom === sourceCurrency.baseDenom)?.baseDenom || '';
                } else {
                    bridgeData.destinationDenom = destinationData.network?.currencies.find((currency) =>
                        currency.bridgeDenom && currency.ibcRepresentation === sourceCurrency.ibcRepresentation)?.bridgeDenom || '';
                }
            } else if (coins.ibc) {
                bridgeData.sourceDenom = coins.ibc.representation;

                bridgeData.destinationDenom = destinationData.network?.currencies.find((currency) =>
                    currency.bridgeDenom && currency.ibcRepresentation === coins?.ibc?.representation)?.bridgeDenom || '';
            } else {
                bridgeData.sourceDenom = sourceCurrency?.baseDenom;
                const networkDenom = networkDenoms?.find((networkDenom) =>
                    networkDenom.baseDenom === sourceCurrency?.baseDenom && networkDenom.ibcNetworkId === sourceData.network?.chainId);
                bridgeData.destinationDenom = destinationData.network?.currencies.find((currency) =>
                    currency.bridgeDenom && currency.ibcRepresentation === networkDenom?.denom)?.bridgeDenom || '';
            }
        }
        return bridgeData;
    }, [
        networkDenoms,
        coins,
        destinationData.address,
        destinationData.hexAddress,
        destinationData.network,
        sourceData.address,
        sourceData.hexAddress,
        sourceData.network,
    ]);

    useEffect(() => bridgeStateDispatch({
        type: 'set-to-use-bridge',
        payload: Boolean(
            bridgeData.sourceNetwork &&
            bridgeData.destinationNetwork &&
            bridgeData.sourceDenom &&
            bridgeData.destinationDenom &&
            bridgeData.sourceBridgeId &&
            bridgeData.destinationBridgeId &&
            bridgeData.sourceAddress &&
            bridgeData.destinationAddress),
    }), [
        bridgeData.destinationAddress,
        bridgeData.destinationBridgeId,
        bridgeData.destinationDenom,
        bridgeData.destinationNetwork,
        bridgeData.sourceAddress,
        bridgeData.sourceBridgeId,
        bridgeData.sourceDenom,
        bridgeData.sourceNetwork,
    ]);

    const getUserAddresses = useCallback(async (): Promise<UserAddress[]> => {
        if (!bridgeState.route) {
            return [];
        }
        return filterNonEmptyValues(await Promise.all(bridgeState.route.requiredChainAddresses.map(async (chainId) => {
            let address = chainId === bridgeData.sourceBridgeId ? bridgeData.sourceAddress :
                chainId === bridgeData.destinationBridgeId ? bridgeData.destinationAddress : undefined;
            if (address) {
                return { chainID: chainId, address: address };
            }
            const network = networks.find((network) =>
                network.type === 'EVM' ? Number(network.evm?.chainId).toString() === chainId : network.chainId === chainId);
            const wallet = userWallets[chainId];
            const addresses = wallet && network && await wallet.getAddress(network);
            address = network?.type === 'EVM' ? addresses?.hexAddress : addresses?.address;
            if (address) {
                return { chainID: chainId, address };
            }
        })));
    }, [
        bridgeData.destinationAddress,
        bridgeData.destinationBridgeId,
        bridgeData.sourceAddress,
        bridgeData.sourceBridgeId,
        bridgeState.route,
        networks,
    ]);

    const setBroadcasting = useCallback((value = true) => bridgeStateDispatch({ type: 'set-broadcasting', payload: value }), []);

    const fetchUserWallets = useCallback(async (): Promise<{ [chainId: string]: Wallet | undefined }> => {
        if (!bridgeState.route) {
            return userWallets;
        }
        const { sourceBridgeId, destinationBridgeId, sourceNetwork, destinationNetwork, sourceAddress } = bridgeData;
        if (!sourceBridgeId || !destinationBridgeId || !sourceNetwork || !destinationNetwork || !sourceAddress) {
            return userWallets;
        }
        userWallets[sourceBridgeId] = networkWalletMap[sourceNetwork.chainId];
        if (!userWallets[sourceBridgeId]) {
            const walletType = fetchMostSuitableWallet(sourceNetwork);
            userWallets[sourceBridgeId] = walletType && await createWallet(walletType);
        }
        userWallets[destinationBridgeId] = networkWalletMap[destinationNetwork.chainId];
        if (!userWallets[destinationBridgeId]) {
            const walletType = fetchMostSuitableWallet(destinationNetwork);
            userWallets[destinationBridgeId] = walletType && await createWallet(walletType);
        }
        const sourceWallet = userWallets[sourceBridgeId];
        if (!sourceWallet || !bridgeState.intermediateNetwork || bridgeState.intermediateNetwork.type !== 'Regular') {
            return userWallets;
        }
        if (isWalletSupported(bridgeState.intermediateNetwork, sourceWallet.getWalletType())) {
            userWallets[bridgeState.intermediateNetwork.chainId] = userWallets[sourceBridgeId];
        } else {
            const wallet = new TemporarySessionWallet();
            await wallet.init(sourceWallet, sourceAddress, sourceNetwork, bridgeState.intermediateNetwork);
            userWallets[bridgeState.intermediateNetwork.chainId] = wallet;
        }
        const intermediateNetworkWallet = userWallets[bridgeState.intermediateNetwork.chainId];
        if (intermediateNetworkWallet) {
            const addresses = await intermediateNetworkWallet.getAddress(bridgeState.intermediateNetwork);
            bridgeStateDispatch({ type: 'set-intermediate-network-address', payload: addresses.address });
        }
        return userWallets;
    }, [ bridgeData, bridgeState.intermediateNetwork, bridgeState.route, fetchMostSuitableWallet, networkWalletMap ]);

    useEffect(() => {
        if (bridgeState.error) {
            setTimeout(() => bridgeStateDispatch({ type: 'set-error', payload: undefined }), 50); // todo: do it different (the error object should be paces at the specific modules)
        }
    }, [ bridgeState.error ]);

    const createBridgeRoute = useCallback(async (): Promise<void> => {
        const vsCoins = ammState.params?.vsCoins;
        if (!bridgeState.toUseBridge || !coins || !client || !vsCoins) {
            bridgeStateDispatch({ type: 'set-route', payload: undefined });
            return;
        }
        let route = await findRoute(client, bridgeData, coins, { allowMultiTx: true }).catch((error) => {
            console.error(`Can't find route: `, error);
            return undefined;
        });
        if (!route) {
            bridgeStateDispatch({ type: 'set-route', payload: undefined });
            return;
        }
        if (route.requiredChainAddresses.length > 3) {
            console.error('Route have more than 3 required addresses');
            bridgeStateDispatch({ type: 'set-route', payload: undefined });
            return;
        }
        const intermediateChainId = route.requiredChainAddresses
            .find((chainId) => chainId !== bridgeData.sourceBridgeId && chainId !== bridgeData.destinationBridgeId);
        const intermediateNetwork = !intermediateChainId ? undefined : networks.find((network) => network.type === 'EVM' ?
            Number(network.evm?.chainId).toString() === intermediateChainId : network.chainId === intermediateChainId);
        bridgeStateDispatch({ type: 'set-intermediate-network', payload: intermediateNetwork });
        const gasLimit = intermediateNetwork ? Number(process.env.REACT_APP_CCTP_GAS_LIMIT) : 0;
        if (intermediateNetwork) {
            const currency = intermediateNetwork.currencies.find((currency) => currency.baseDenom === coins.currency.baseDenom);
            const intermediateBridgeData: BridgeData = {
                ...bridgeData,
                sourceNetwork: intermediateNetwork,
                sourceBridgeId: intermediateNetwork.type === 'EVM' ?
                    Number(intermediateNetwork.evm?.chainId).toString() : intermediateNetwork.chainId,
                sourceDenom: currency?.bridgeDenom || currency?.baseDenom || bridgeData.sourceDenom,
                sourceAddress: '',
            };
            route = await findRoute(client, intermediateBridgeData, { ...coins, amount: coins.amount - gasLimit }).catch((error) => {
                console.error(`Can't find route: `, error);
                return undefined;
            });
            if (!route) {
                bridgeStateDispatch({ type: 'set-route', payload: undefined });
                return;
            }
        }
        const fee = {
            ...vsCoins, amount: gasLimit + getMaxDenomAmount(Number(route.amountIn) - Number(route.amountOut), vsCoins.currency),
        };
        bridgeStateDispatch({ type: 'set-fee', payload: fee });
        bridgeStateDispatch({ type: 'set-route', payload: route });
    }, [ ammState.params?.vsCoins, bridgeData, bridgeState.toUseBridge, client, coins, networks ]);

    const showTransferMessage = useCallback((
        key: string,
        message: string,
        bridgeData?: BridgeData,
        duration?: number,
        hash?: string,
        ibcHash?: string,
        completed?: boolean,
    ) => {
        const path = (bridgeData?.sourceNetwork && bridgeData?.destinationNetwork) ?
            <b>{bridgeData.sourceNetwork.chainName} {'->'} {bridgeData.destinationNetwork.chainName}</b> : undefined;
        removeMessage(key);
        showMessage({
            key,
            content: (
                <div className='horizontally-centered'>
                    {!completed && <><Spinner size='small' />&nbsp;&nbsp;</>}
                    <div>
                        {completed ? <b>{message}</b> : message}
                        {path || duration ? <br /> : undefined}{path}{duration && <b> (Est. {duration}m)</b>}
                    </div>
                </div>
            ),
            type: completed ? 'success' : 'info',
            action: !hash && !ibcHash ? undefined : {
                label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>,
                callback: () => {
                    const transfer = getTransfer(ibcHash, hash);
                    return transfer && onExploreClick(transfer);
                },
            },
            duration: 1200000,
        });
    }, [ getTransfer, onExploreClick, removeMessage, showMessage ]);

    const saveCurrentTransfer = useCallback(async (data: Partial<BridgeTransfer>): Promise<void> => {
        if (!coins ||
            !bridgeData.sourceBridgeId ||
            !bridgeData.destinationBridgeId ||
            !bridgeData.sourceAddress ||
            !bridgeData.destinationAddress
        ) {
            console.error('Missing transfer data', data);
            return;
        }
        const transfer = {
            ...(getTransfer(data.ibcHash, data.hash) || {
                sourceAddress: bridgeData.sourceAddress,
                destinationAddress: bridgeData.destinationAddress,
                sourceId: bridgeData.sourceBridgeId,
                destinationId: bridgeData.destinationBridgeId,
                intermediateId: bridgeState.intermediateNetwork?.chainId,
                intermediateAddress: bridgeState.intermediateNetworkAddress,
                denom: getFixedDenom(coins),
                amount: coins.amount,
                time: Date.now(),
                status: 'Pending',
            }),
            ...data,
        };
        return saveTransfer(transfer);
    }, [
        bridgeData.destinationAddress,
        bridgeData.destinationBridgeId,
        bridgeData.sourceAddress,
        bridgeData.sourceBridgeId,
        bridgeState.intermediateNetwork?.chainId,
        bridgeState.intermediateNetworkAddress,
        coins,
        getTransfer,
        saveTransfer,
    ]);

    const broadcastBridgeTransfer = useCallback(async (ibcHash?: string): Promise<void> => {
        if (!bridgeState.route || !bridgeData.sourceNetwork) {
            bridgeStateDispatch({ type: 'set-error', payload: new TxError('MISSING_ROUTE') });
            return saveCurrentTransfer({ status: 'Failed', ibcHash });
        }
        bridgeStateDispatch({ type: 'set-broadcasting' });
        const route = bridgeState.route;
        const duration = Math.round(route.estimatedRouteDurationSeconds / 60);
        const fixedBridgeData = { ...bridgeData, sourceNetwork: bridgeState.intermediateNetwork || bridgeData.sourceNetwork };
        showTransferMessage(
            route.sourceAssetChainID,
            `Bridging transfer initiated${ibcHash ? ' - Please wait and avoid closing this window' : ''}`,
        );
        client.executeRoute({
            route: bridgeState.route,
            userAddresses: await getUserAddresses(),
            onApproveAllowance: async ({ status }) => {
                if (status === 'pending' || status === 'completed') {
                    showTransferMessage(route.sourceAssetChainID, 'Your wallet is waiting for confirmation and a signature...');
                } else {
                    removeMessage(route.sourceAssetChainID);
                    bridgeStateDispatch({ type: 'set-error', payload: new ClientError('REQUEST_REJECTED') });
                    return saveCurrentTransfer({ status: 'Failed', ibcHash });
                }
            },
            onTransactionSigned: async () => {
                showTransferMessage(route.sourceAssetChainID, 'Bridging transfer initiated - Please wait and avoid closing this window');
            },
            onTransactionBroadcast: async ({ txHash }) => {
                removeMessage(route.sourceAssetChainID);
                bridgeStateDispatch({ type: 'set-broadcasting', payload: false });
                showTransferMessage(txHash, 'Bridging transfer is in progress', fixedBridgeData, duration, txHash, ibcHash);
                return saveCurrentTransfer({ status: 'BridgeTransferring', ibcHash, hash: txHash });
            },
            onTransactionCompleted: async (chainID, txHash, status) => {
                bridgeStateDispatch({ type: 'set-route', payload: undefined });
                bridgeStateDispatch({ type: 'set-broadcasting', payload: false });
                removeMessage(route.sourceAssetChainID);
                removeMessage(txHash);
                if (status.error || status.state !== 'STATE_COMPLETED_SUCCESS') {
                    bridgeStateDispatch({
                        type: 'set-error',
                        payload: new ClientError('BROADCAST_TX_FAILED', sourceData.network, status.error || status.state),
                    });
                    return saveCurrentTransfer({ status: 'Failed', ibcHash, hash: txHash });
                }
                showTransferMessage(
                    txHash, 'Bridging transfer successfully completed!', undefined, undefined, txHash, ibcHash, true);
                return saveCurrentTransfer({ status: 'Success', ibcHash, hash: txHash });
            },
        }).catch((error) => {
            bridgeStateDispatch({ type: 'set-error', payload: new ClientError('BROADCAST_TX_FAILED', sourceData.network, error) });
            removeMessage(route.sourceAssetChainID);
            return saveCurrentTransfer({ status: 'Failed', ibcHash });
        });
    }, [
        bridgeData,
        bridgeState.intermediateNetwork,
        bridgeState.route,
        client,
        getUserAddresses,
        removeMessage,
        saveCurrentTransfer,
        showTransferMessage,
        sourceData.network,
    ]);

    useEffect(() => {
        if (bridgeState.toUseBridge && findRoutes) {
            bridgeStateDispatch({ type: 'set-route-searching' });
            setCreateBridgeRouteTimeout((timeout) => {
                clearTimeout(timeout);
                return setTimeout(createBridgeRoute, 300);
            });
        }
    }, [ bridgeState.toUseBridge, createBridgeRoute, findRoutes ]);

    return {
        bridgeState,
        broadcastBridgeTransfer,
        fetchUserWallets,
        showTransferMessage,
        setBroadcasting,
        saveCurrentTransfer,
        setBridgeCoins,
    };
};

export default useBridge;
