import classNames from 'classnames';
import React from 'react';
import Alert from '../../../shared/components/alert/alert';
import { DEFAULT_SLIPPAGE_TOLERANCE, useTrade } from '../trade-context';
import './slippage-alerts.scss';

interface SlippageAlertsProps {
    className?: string;
}

const SlippageAlerts: React.FC<SlippageAlertsProps> = ({ className }) => {
    const { currentSlippage, slippageTolerance, slippageToleranceMode, slippageDisabled } = useTrade();

    if (slippageToleranceMode !== 'alert' || slippageDisabled ||
        ((currentSlippage || 0) < (slippageTolerance ?? DEFAULT_SLIPPAGE_TOLERANCE))) {
        return <></>;
    }
    return (
        <Alert className={classNames('slippage-alert', className)} type='warning'>
            <b>Attention</b>: this trade is above {slippageTolerance === undefined ? 'the standard' : 'your'} slippage tolerance.
        </Alert>
    );
};

export default SlippageAlerts;
