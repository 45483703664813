import React from 'react';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import AmountTx from '../../../tx/amount-tx/amount-tx';
import useHandleTxResponses from '../../../tx/use-handle-tx-responses';
import { useWallet } from '../../../wallet/wallet-context';
import { IbcTransferDetails } from '../ibc-status-types';
import { useEibcFeeEditModal } from './use-eibc-fee-edit-modal';
import './eibc-fee-edit-modal.scss';

interface IbcTransferStatusProps extends DialogProps {
    transfer: IbcTransferDetails;
}

const EibcFeeEditModal: React.FC<IbcTransferStatusProps> = ({ transfer, ...otherDialogProps }) => {
    const networkState = useHubNetworkState();
    const { hubWallet } = useWallet();
    const { txState, amountTxState, setAmount, broadcast } = useEibcFeeEditModal(transfer);
    useHandleTxResponses(txState, hubWallet, () => {
        transfer.eibcFeeCoins = amountTxState.coins;
        otherDialogProps.onRequestClose?.();
    });

    return (
        <Dialog className='eibc-fee-edit-modal' closable {...otherDialogProps}>
            <DialogTitle>Update eIBC fee</DialogTitle>
            <DialogContent className='modal-content'>
                <div className='control-label-container'><label>eIBC tip</label></div>
                <AmountTx
                    txState={txState}
                    amountTxState={amountTxState}
                    networkState={networkState}
                    onCoinsChange={(coins) => setAmount(coins.amount)}
                    error={!transfer.coins?.amount ? 'Missing amount' : ''}
                    availableBalances={filterNonEmptyValues([ transfer.coins ])}
                    submitButtonContainer={<>
                        <Button
                            className='submit-button'
                            loading={txState.broadcasting || txState.feeLoading}
                            disabled={Boolean(txState.broadcasting || txState.feeLoading || !amountTxState.coins?.amount)}
                            onClick={() => broadcast()}
                        >
                            Save
                        </Button>
                    </>}
                />
            </DialogContent>
        </Dialog>
    );
};

export default EibcFeeEditModal;
