import { cloneDeep, uniq } from 'lodash';
import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { filterNonEmptyValues } from '../../../shared/utils/object-utils';
import { useNetwork } from '../../network/network-context';
import { useHubNetworkState } from '../hub-network-state-context';

interface UseAccountConfiguration {
    visibleNetworks: string[];
    visibleErc20Tokens: { [networkId: string]: { denom: string, erc20Address: string }[] };
}

interface AccountConfigurationContextValue {
    configuration?: UseAccountConfiguration;
    setVisibleNetworks: (networkIds: string[]) => void;
    saveVisibleErc20Tokens: (networkId: string, tokens: { denom: string, erc20Address: string }[]) => void;
    setVisibleNetworksForSession: (networkIds: string[]) => void;
}

export const AccountConfigurationContext = createContext<AccountConfigurationContextValue>({} as AccountConfigurationContextValue);

export const useAccountConfiguration = (): AccountConfigurationContextValue => useContext(AccountConfigurationContext);

export const AccountConfigurationContextProvider = ({ children }: { children: ReactNode }) => {
    const hubNetworkState = useHubNetworkState();
    const { hubNetwork } = useNetwork();
    const [ visibleNetworksForSession, setVisibleNetworksForSession ] = useState<string[]>();
    const [ configurationMap, setConfigurationMap ] =
        usePersistedState<{ [address: string]: UseAccountConfiguration }>('account-configuration', {});

    const address = useMemo(() => hubNetworkState.hexAddress, [ hubNetworkState.hexAddress ]);

    const emptyConfiguration = useMemo<UseAccountConfiguration>(() => ({
        visibleNetworks: hubNetwork ? [ hubNetwork.chainId ] : [],
        visibleErc20Tokens: {},
    }), [ hubNetwork ]);

    const configuration = useMemo(
        () => ({
            ...emptyConfiguration,
            ...(address && configurationMap[address]),
            ...(visibleNetworksForSession &&
                { visibleNetworks: uniq(filterNonEmptyValues([ hubNetwork?.chainId, ...visibleNetworksForSession ])) }),
        }),
        [ address, configurationMap, emptyConfiguration, hubNetwork?.chainId, visibleNetworksForSession ],
    );

    const setVisibleNetworks = useCallback((networkIds: string[]) => {
        if (!address || !hubNetwork) {
            return;
        }
        setConfigurationMap((configurationMap) => {
            configurationMap[address] = configurationMap[address] || cloneDeep(emptyConfiguration);
            configurationMap[address].visibleNetworks = uniq([ hubNetwork.chainId, ...networkIds ]);
            return { ...configurationMap, [address]: { ...configurationMap[address] } };
        });
    }, [ address, emptyConfiguration, hubNetwork, setConfigurationMap ]);

    const saveVisibleErc20Tokens = useCallback((networkId: string, tokens: { denom: string, erc20Address: string }[]) => {
        if (!address) {
            return;
        }
        setConfigurationMap((configurationMap) => {
            configurationMap[address] = configurationMap[address] || cloneDeep(emptyConfiguration);
            configurationMap[address].visibleErc20Tokens[networkId] = tokens;
            return configurationMap;
        });
    }, [ address, emptyConfiguration, setConfigurationMap ]);

    return (
        <AccountConfigurationContext.Provider
            value={{
                configuration,
                setVisibleNetworks,
                saveVisibleErc20Tokens,
                setVisibleNetworksForSession,
            }}
        >
            {children}
        </AccountConfigurationContext.Provider>
    );
};
