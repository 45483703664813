import { IbcClient, Link } from '@confio/relayer';
import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { calculateFee, GasPrice, SigningStargateClient } from 'cosmjs/packages/stargate';
import { partition } from 'lodash';
import { DEFAULT_GAS_ADJUSTMENT } from '../../../client/client-types';
import { StationClient } from '../../../client/station-clients/station-client';
import { Network } from '../../../network/network-types';
import { createGrantExecMessage } from '../../../quick-auth/quick-auth-service';
import { IbcTransferDetails } from '../ibc-status-types';

const TRANSFER_PORT = 'transfer';

export const relayPacket = async (
    transfer: IbcTransferDetails,
    senderAddress: string,
    signer: OfflineSigner,
    gasPrice: GasPrice,
    sourceClient: StationClient,
    destinationClient: StationClient,
    relayAll?: boolean,
    onSignatureRequired?: () => void,
): Promise<void> => {
    const sourceIbcClient = transfer.sourceNetwork &&
        await getIbcClient(transfer.sourceNetwork, senderAddress, signer, gasPrice);
    const destinationIbcClient = transfer.destinationNetwork &&
        await getIbcClient(transfer.destinationNetwork, senderAddress, signer, gasPrice);
    if (!sourceIbcClient || !destinationIbcClient) {
        return;
    }
    sourceIbcClient.tm.blockchain = async (minHeight, maxHeight) => sourceClient.getTmClient()!.blockchain(minHeight, maxHeight);
    destinationIbcClient.tm.blockchain = async (minHeight, maxHeight) => destinationClient.getTmClient()!.blockchain(minHeight, maxHeight);
    const [ { channel: sourceChannel }, { channel: destinationChannel } ] = await Promise.all([
        sourceClient.getIbcChannelQueryClient().Channel({ portId: TRANSFER_PORT, channelId: transfer.sourceChannel }),
        destinationClient.getIbcChannelQueryClient().Channel({ portId: TRANSFER_PORT, channelId: transfer.destinationChannel }),
    ]);
    const sourceConnection = sourceChannel?.connectionHops.slice(-1).pop();
    const destinationConnection = destinationChannel?.connectionHops.slice(-1).pop();
    if (!sourceConnection || !destinationConnection) {
        throw new Error('Transfer connection is missing, please try again later');
    }
    const link = await Link.createWithExistingConnections(sourceIbcClient, destinationIbcClient, sourceConnection, destinationConnection);
    let packets = (await link.getPendingPackets('A'));
    if (!relayAll) {
        packets = packets.filter((packet) => Number(packet.packet.sequence) === transfer.sequence);
    }
    const lastDstHeight = await link.endB.client.tm.status().then((status) => status.syncInfo.latestBlockHeight);
    const [ timedOutPackets, canRelayPackets ] = partition(
        packets, ({ packet }) => packet.timeoutTimestamp < Date.now() || packet.timeoutHeight.revisionHeight < lastDstHeight,
    );
    onSignatureRequired?.();
    if (timedOutPackets.length) {
        await link.timeoutPackets('A', timedOutPackets);
    }
    if (canRelayPackets.length) {
        const acks = await link.relayPackets('A', canRelayPackets);
        await link.relayAcks('B', acks);
    }
};

const getIbcClient = async (
    network: Network,
    senderAddress: string,
    signer: OfflineSigner,
    gasPrice: GasPrice,
): Promise<IbcClient | undefined> => {
    if (!network?.rpc) {
        return;
    }
    const signerAddress = (await signer.getAccounts())?.[0]?.address;
    const useAuthZ = signerAddress !== senderAddress;
    const signingClient = await SigningStargateClient.connectWithSigner(network.rpc, signer, { gasPrice, evm: !useAuthZ });
    const originalSignAndBroadcast = signingClient.signAndBroadcast.bind(signingClient);
    signingClient.signAndBroadcast = async (senderAddress, messages, fee, memo) => {
        if (useAuthZ) {
            const gas = Math.round(await signingClient.simulate(signerAddress, messages, memo) * DEFAULT_GAS_ADJUSTMENT);
            fee = { ...calculateFee(gas, gasPrice), granter: senderAddress };
            messages = [ createGrantExecMessage(signerAddress, [ ...messages ], signingClient.registry) ];
        }
        return originalSignAndBroadcast(signerAddress, messages, fee, memo, 'direct');
    };
    return IbcClient.connectWithClient(network.rpc, signingClient as any, senderAddress, gasPrice as any);
};

