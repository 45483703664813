import { GrantAuthorization } from 'cosmjs-types/cosmos/authz/v1beta1/authz';
import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Grant } from 'cosmjs-types/cosmos/feegrant/v1beta1/feegrant';
import { Decimal } from 'cosmjs/packages/math';
import { DirectSecp256k1HdWallet, EncodeObject } from 'cosmjs/packages/proto-signing';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { usePersistedState } from '../../shared/hooks/use-persisted-state';
import { trackEvent } from '../../shared/utils/event-tracker-utils';
import { generatePinCode } from '../../shared/utils/text-utils';
import { AccountNetworkState } from '../account/account-network-state';
import { useAccountNetwork } from '../account/use-account-network';
import { useClient } from '../client/client-context';
import { ALL_ITEMS_PAGINATION_LONG } from '../client/client-types';
import { getMainCurrency } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { useNetwork } from '../network/network-context';
import { Network } from '../network/network-types';
import { DeliveryTxCode } from '../tx/tx-types';
import { useTx } from '../tx/use-tx';
import {
    createGrantAllowanceMessages,
    createGrantAuthorizationMessages,
    createRevokeAllowanceMessage,
    createRevokeAuthorizationMessages,
    encryptQuickAuthAccount,
} from './quick-auth-service';
import {
    DEFAULT_SESSION_DURATION,
    QUICK_AUTH_MESSAGE_TYPES_KEY,
    QUICK_AUTH_MESSAGE_TYPES,
    SESSION_DURATION_DELAY,
    PIN_CODE_LENGTH,
    PIN_CODE_EXPIRATION_DURATION,
    QuickAuthMessageType,
    QuickAuthAccount,
} from './quick-auth-types';

interface QuickAuthContextValue {
    connect: () => void;
    revoke: () => void;
    isConnected: boolean;
    connecting: boolean;
    sessionDuration: number;
    expiration: number;
    pinCode: string;
    accountSerialization: string;
    pinCodeExpiration: number;
    quickAuthMessageTypes: QuickAuthMessageType[];
    networkState: AccountNetworkState;
    setNetwork: (network: Network) => void;
    setQuickAuthMessageTypes: (value: QuickAuthMessageType[]) => void;
    setSessionDuration: (value: number) => void;
    setFromQuickAuthAccount: (account: QuickAuthAccount) => void;
}

const DEFAULT_FEE_GRANT_SPEND_LIMIT = '1000';

export const QuickAuthContext = createContext<QuickAuthContextValue>({} as QuickAuthContextValue);

export const useQuickAuth = (): QuickAuthContextValue => useContext(QuickAuthContext);

export const QuickAuthContextProvider = ({ children }: { children: ReactNode }) => {
    const { signingClientStateMap, handleClientError } = useClient();
    const { hubNetwork } = useNetwork();
    const [ expiration, setExpiration ] = usePersistedState<number>('quick-auth-expiration', 0);
    const [ mnemonic, setMnemonic ] = usePersistedState<string | undefined>('quick-auth-mnemonic', undefined);
    const [ address, setAddress ] = usePersistedState<string | undefined>('quick-auth-address', undefined);
    const [ connecting, setConnecting ] = useState(false);
    const [ sessionDuration, setSessionDuration ] = usePersistedState('quick-auth-duration', DEFAULT_SESSION_DURATION);
    const [ pinCode, setPinCode ] = useState('');
    const [ accountSerialization, setAccountSerialization ] = useState('');
    const [ pinCodeExpiration, setPinCodeExpiration ] = useState(0);
    const [ networkState, setNetwork ] = useAccountNetwork(hubNetwork);
    const [ quickAuthMessageTypes, setQuickAuthMessageTypes ] =
        usePersistedState<QuickAuthMessageType[]>(QUICK_AUTH_MESSAGE_TYPES_KEY, [ ...QUICK_AUTH_MESSAGE_TYPES ]);
    const [ , refresh ] = useState({});

    const signingClientState = networkState.network && signingClientStateMap[networkState.network.chainId];

    const isConnected = useMemo(() => Boolean(mnemonic && Date.now() <= expiration), [ expiration, mnemonic ]);

    const setFromQuickAuthAccount = useCallback((account: QuickAuthAccount) => {
        setMnemonic(account.mnemonic);
        setExpiration(account.expiration);
        setAddress(account.sourceAccount.address);
    }, [ setAddress, setExpiration, setMnemonic ]);

    useEffect(() => {
        const sourceWallet = signingClientState?.client?.getWallet();
        if (mnemonic && pinCode && sourceWallet && networkState.network) {
            sourceWallet.getOfflineSigner(networkState.network)
                .then((sourceSigner) => sourceSigner.getAccounts())
                .then((accounts) => {
                    const account: QuickAuthAccount = { mnemonic, sourceAccount: accounts[0], expiration };
                    return encryptQuickAuthAccount(account, pinCode, pinCodeExpiration);
                })
                .then(setAccountSerialization);
        } else {
            setAccountSerialization('');
        }
    }, [ expiration, mnemonic, networkState.network, pinCode, pinCodeExpiration, signingClientState?.client ]);

    useEffect(() => {
        if (networkState.address && networkState.address !== address) {
            setMnemonic(undefined);
            setAddress(networkState.address);
        }
    }, [ address, networkState.address, setAddress, setMnemonic ]);

    const updatePinCodeState = useCallback(() => {
        setPinCodeExpiration((pinCodeExpiration) => {
            refresh({});
            if (Date.now() < pinCodeExpiration) {
                return pinCodeExpiration;
            }
            setPinCode(generatePinCode(PIN_CODE_LENGTH, true));
            return Date.now() + PIN_CODE_EXPIRATION_DURATION;
        });
    }, []);

    useEffect(() => {
        updatePinCodeState();
        if (!isConnected) {
            return;
        }
        const interval = setInterval(updatePinCodeState, 1000);
        return () => clearInterval(interval);
    }, [ isConnected, updatePinCodeState ]);

    const grantMessagesCreator = useCallback((
        fee?: CoinsAmount,
        params?: { address: string, expiration: number, feeGrant?: boolean },
    ): EncodeObject[] => {
        if (!networkState.network || !networkState.address || !params?.address || !params.expiration) {
            return [];
        }
        const mainCurrency = getMainCurrency(networkState.network);
        const spendLimit: Coin[] = [
            { amount: Decimal.fromUserInput(DEFAULT_FEE_GRANT_SPEND_LIMIT, mainCurrency.decimals).atomics, denom: mainCurrency.baseDenom },
        ];
        return params.feeGrant ?
            [ createGrantAllowanceMessages(networkState.address, params.address, params.expiration, spendLimit) ] :
            createGrantAuthorizationMessages(networkState.network, networkState.address, params.address, params.expiration);
    }, [ networkState.network, networkState.address ]);

    const revokeMessagesCreator = useCallback((
        fee?: CoinsAmount,
        params?: { grants: GrantAuthorization[], allowances: Grant[], feeGrant?: boolean },
    ): EncodeObject[] => {
        if (!networkState.address || (!params?.grants && !params?.allowances)) {
            return [];
        }
        if (!params.feeGrant && params.grants) {
            return createRevokeAuthorizationMessages(params.grants);
        }
        return createRevokeAllowanceMessage(networkState.address, params.allowances.map((allowance) => allowance.grantee));
    }, [ networkState.address ]);

    const { txState, broadcast } = useTx({
        networkState: networkState,
        txMessagesCreator: isConnected ? revokeMessagesCreator : grantMessagesCreator,
    });

    useEffect(() => {
        if (txState.error) {
            setConnecting(false);
        }
    }, [ setMnemonic, txState.error ]);

    useEffect(() => {
        if (!txState.response || !signingClientState?.client) {
            return;
        }
        if (txState.response.deliveryTxCode !== DeliveryTxCode.SUCCESS) {
            setConnecting(false);
            return;
        }
        const { expiration, signer, address, allowances, feeGrant } = txState.response.params || {};
        if (!feeGrant && (signer || allowances)) {
            broadcast(undefined, { signer, address, expiration, allowances, feeGrant: true }, true).then();
        } else if (signer && expiration) {
            setMnemonic(signer.mnemonic);
            setExpiration(expiration);
            signingClientState.client.setQuickAuthSigner(signer)
                .then(() => trackEvent('quick_auth_connection_success', txState.response?.hash))
                .finally(() => setConnecting(false));
        } else {
            setMnemonic(undefined);
            setConnecting(false);
        }
    }, [ address, broadcast, setExpiration, setMnemonic, signingClientState?.client, txState.response ]);

    useEffect(() => {
        setTimeout(() => setMnemonic(undefined), Math.max(0, expiration - Date.now()));
    }, [ expiration, setMnemonic ]);

    useEffect(() => {
        if (!signingClientState?.client || !networkState.network) {
            return;
        }
        if (!isConnected || !mnemonic) {
            signingClientState.client.setQuickAuthSigner(undefined).finally(() => setMnemonic(undefined));
            return;
        }
        DirectSecp256k1HdWallet.fromMnemonic(mnemonic, { prefix: networkState.network.bech32Prefix })
            .then((signer) => signingClientState.client?.setQuickAuthSigner(signer));
    }, [ isConnected, mnemonic, networkState.network, setMnemonic, signingClientState?.client ]);

    useEffect(() => {
        if (!isConnected) {
            setExpiration(0);
            setMnemonic(undefined);
        }
    }, [ isConnected, setExpiration, setMnemonic ]);

    const revoke = useCallback(async () => {
        if (!networkState.address || !signingClientState?.client) {
            return;
        }
        const address = (await signingClientState.client.getQuickAuthClient()?.getActiveSigner().getAccounts())?.[0]?.address;
        if (!address) {
            return;
        }
        setConnecting(true);
        const [ grants, allowances ] = await Promise
            .all([
                signingClientState.client.getStationQueryClient().getAuthZQueryClient()
                    .GranterGrants({ granter: networkState.address, pagination: ALL_ITEMS_PAGINATION_LONG }),
                signingClientState.client.getStationQueryClient().getFeeGrantQueryClient()
                    .AllowancesByGranter({ granter: networkState.address, pagination: ALL_ITEMS_PAGINATION_LONG }),
            ])
            .then(([ { grants }, { allowances } ]) => [
                grants.filter((grant) => grant.authorization?.typeUrl === '/cosmos.authz.v1beta1.GenericAuthorization'),
                allowances.filter((allowance) => allowance.allowance?.typeUrl === '/cosmos.feegrant.v1beta1.BasicAllowance'),
            ])
            .catch((error) => {
                setConnecting(false);
                handleClientError(error);
                return [ undefined, undefined ];
            });
        if (!grants?.length && !allowances?.length) {
            return;
        }
        return broadcast(undefined, { grants, allowances }, true).catch(() => setConnecting(false));
    }, [ broadcast, handleClientError, networkState.address, signingClientState?.client ]);

    const connect = useCallback(() => {
        if (!networkState.network) {
            return;
        }
        setConnecting(true);
        const expiration = Date.now() + sessionDuration + SESSION_DURATION_DELAY;
        DirectSecp256k1HdWallet.generate(12, { prefix: networkState.network.bech32Prefix })
            .then(async (signer) => {
                const address = (await signer.getAccounts())?.[0]?.address;
                return broadcast(undefined, { signer, address, expiration }, true);
            })
            .catch(() => setConnecting(false));
    }, [ broadcast, networkState.network, sessionDuration ]);

    return (
        <QuickAuthContext.Provider
            value={{
                connect,
                revoke,
                isConnected,
                connecting,
                sessionDuration,
                expiration,
                quickAuthMessageTypes,
                pinCode,
                pinCodeExpiration,
                accountSerialization,
                setFromQuickAuthAccount,
                networkState,
                setNetwork,
                setQuickAuthMessageTypes,
                setSessionDuration,
            }}
        >
            {children}
        </QuickAuthContext.Provider>
    );
};
