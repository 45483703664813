import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { PieLabel, PieLabelRenderProps } from 'recharts/types/polar/Pie';
import { getCssVariableValue } from '../../../../../shared/utils/color-utils';
import { AnalyticsData, HistoryList } from '../../../../analytics/analytics-types';
import { useNetworkDashboard } from '../../../../network-dashboard/network-dashboard-context';
import { convertDecimalToInt, formatNumber, formatPrice } from '../../../../../shared/utils/number-utils';
import { getMainCurrency, getMaxDenomAmount } from '../../../../currency/currency-service';
import { PieChart, Pie, Legend, Cell, Tooltip } from 'recharts';
import { TotalSupply } from '../../analytics/network-analytics-types';
import ChartContainer, { SelectableChartProps } from '../chart-container/chart-container';
import SimpleChartTooltip from '../chart-tooltip/simple-chart-tooltip';
import './total-supply-chart.scss';

interface DataValue {
    name: string;
    value: number;
    color: string;
    tooltip?: ReactNode;
}

const SMALL_CHART_SIZE = 500;

interface TotalSupplyChartProps extends SelectableChartProps {
    className?: string;
    showSupplyDivisionChart?: boolean;
}

export const TotalSupplyChart: React.FC<TotalSupplyChartProps> = ({ className, showSupplyDivisionChart, ...selectableChartProps }) => {
    const { network } = useNetworkDashboard();
    const [ chartSize, setChartSize ] = useState<{ width: number, height: number }>({ width: 0, height: 0 });
    const [ stakingChartData, setStakingChartData ] = useState<DataValue[]>([]);
    const [ lockingChartData, setLockingChartData ] = useState<DataValue[]>([]);
    const isSmallChartSize = useMemo(() => chartSize.width <= SMALL_CHART_SIZE, [ chartSize.width ]);

    const mainCurrency = useMemo(() => getMainCurrency(network), [ network ]);

    const currentBonded = useMemo(
        () => getMaxDenomAmount(network.totalSupply?.value.bondedAmount || 0, mainCurrency),
        [ network.totalSupply?.value.bondedAmount, mainCurrency ],
    );

    const currentUnbonded = useMemo(
        () => getMaxDenomAmount((network.totalSupply?.value.amount || 0) - (network.totalSupply?.value.bondedAmount || 0), mainCurrency),
        [ network.totalSupply?.value, mainCurrency ],
    );

    const inflationValue = useMemo(
        () => convertDecimalToInt(network.totalSupply?.value.inflation || 0) * 100,
        [ network.totalSupply?.value.inflation ],
    );

    const currenOnChainGov = useMemo(
        () => getMaxDenomAmount(network.totalSupply?.value.onChainGov || 0, mainCurrency),
        [ network.totalSupply?.value.onChainGov, mainCurrency ],
    );

    const currentTotalSupply = useMemo(
        () => getMaxDenomAmount(network.totalSupply?.value.amount || 0, mainCurrency),
        [ network.totalSupply?.value.amount, mainCurrency ],
    );

    const currenVesting = useMemo(
        () => getMaxDenomAmount(network.totalSupply?.value.vesting || 0, mainCurrency),
        [ network.totalSupply?.value.vesting, mainCurrency ],
    );

    const currentUnlocked = useMemo(
        () => currentTotalSupply - currenOnChainGov - currenVesting,
        [ currenOnChainGov, currenVesting, currentTotalSupply ],
    );

    const chartData = useMemo((): AnalyticsData<TotalSupply | undefined> => {
        const historyList: HistoryList<any> = [ { value: network.totalSupply?.value, date: new Date().getTime() } ];
        return {
            dayHistory: historyList,
            monthHistory: historyList,
            totalHistory: historyList,
            value: network.totalSupply?.value,
        };
    }, [ network.totalSupply?.value ]);

    const getPaddingAngle = useCallback((chartData: DataValue[]) => chartData?.filter((item) => item.value).length === 1 ? 0 : 3, []);

    useEffect(() => {
        setTimeout(() => {
            setStakingChartData([
                { name: 'Bonded', value: currentBonded, color: getCssVariableValue('--blue') },
                { name: 'Unbonded', value: currentUnbonded, color: getCssVariableValue('--cream') },
            ]);
            setLockingChartData([
                {
                    name: 'Onchain Gov',
                    value: currenOnChainGov,
                    color: getCssVariableValue('--orange'),
                    tooltip: <>
                        <i>Onchain Gov</i> is an <b>Onchain</b> governance-controlled budget, comprised of two modules:
                        the <i>Community Pool</i> and the <i>Incentive Manager</i>.<br />
                        Both modules necessitate governance vote approval for expenditure and are not stakable.
                    </>,
                },
                { name: 'Vesting', value: currenVesting, color: getCssVariableValue('--red') },
                { name: 'Circulating', value: currentUnlocked, color: getCssVariableValue('--dark-green') },
            ]);
        });
    }, [
        currenVesting,
        currenOnChainGov,
        currentBonded,
        currentUnbonded,
        currentUnlocked,
    ]);

    const renderCustomizedLabel: PieLabel<PieLabelRenderProps & { headerLabel: string, headerValue: string, totalValue: number, valueSuffix?: ReactNode }> = (props) => {
        const { index, cx, cy, midAngle, fill, outerRadius, name, value, headerLabel, headerValue, valueSuffix, totalValue } = props;
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        let startX = Number(cx) + Number(outerRadius) * cos;
        const startY = Number(cy) + Number(outerRadius) * sin;
        let middleX = Number(cx) + (Number(outerRadius) + 12) * cos;
        const middleY = Number(cy) + (Number(outerRadius) + 12) * sin;
        const endX = middleX + (cos >= 0 ? 1 : -1) * 16;
        const endY = middleY;
        const startLabelX = endX + (cos >= 0 ? 1 : -1) * 8;
        const startLabelY = endY + 4;
        const labelAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g className='customized-label'>
                {index === 0 && (
                    <text x={cx} y={cy} textAnchor='middle'>
                        <tspan fontWeight={400} x={cx} dy={headerValue ? -5 : 5}>{headerLabel}</tspan>
                        {headerValue && <tspan fontWeight='bold' x={cx} dy={20}>{headerValue}</tspan>}
                    </text>
                )}
                {value && !isSmallChartSize && <>
                    <path stroke={fill} fill='none' d={`M${startX},${startY}L${middleX},${middleY}L${endX},${endY}`} />
                    <circle cx={endX} cy={endY} r={2} fill={fill} stroke='none' />
                    <text x={startLabelX} y={startLabelY} fill={fill} fontWeight={400} textAnchor={labelAnchor}>{name}</text>
                    <text x={startLabelX} y={startLabelY} dy={18} fontSize={12} textAnchor={labelAnchor} opacity={0.7}>
                        {formatPrice(value, '', { notation: 'compact', maximumFractionDigits: 2 })}
                        {valueSuffix || <>&nbsp;{mainCurrency.displayDenom}</>} ({(value / totalValue * 100).toFixed(2)}%)
                    </text>
                </>}
            </g>
        );
    };

    const legendFormatter = (type: string, chartData: DataValue[], valueSuffix?: ReactNode): ReactElement => {
        const value = chartData.find((item) => item.name === type)?.value || 0;
        const total = chartData.reduce((sum, item) => sum + item.value, 0);

        return (
            <div className='customized-legend'>
                {type}<br />
                <span className='value'>
                    <b>{formatNumber(value, { notation: 'compact', maximumFractionDigits: 2 })}</b>
                    {valueSuffix || <>&nbsp;{mainCurrency.displayDenom}</>}
                    <span className='percentages'>({(value / total * 100).toFixed(2)}%)</span>
                </span>
            </div>
        );
    };

    const renderPieChart = (
        chartData: DataValue[],
        headerLabel: string,
        headerValue?: string,
        startAngle: number = 0,
        totalValue?: number,
        valueSuffix?: ReactNode,
    ): ReactElement => {
        return (
            <PieChart
                key={headerLabel}
                className={classNames(
                    'pie-chart-container',
                    { visible: stakingChartData?.length && lockingChartData?.length, 'small-chart': isSmallChartSize },
                )}
            >
                <Pie
                    data={chartData}
                    innerRadius={chartSize.height * 0.4}
                    outerRadius={chartSize.height * 0.5}
                    paddingAngle={getPaddingAngle(chartData)}
                    isAnimationActive={false}
                    minAngle={3}
                    label={(props) => renderCustomizedLabel({ ...props, headerLabel, headerValue, totalValue, valueSuffix })}
                    labelLine={false}
                    startAngle={startAngle}
                    endAngle={360 + startAngle}
                    legendType='plainline'
                    stroke='transparent'
                    nameKey='name'
                    dataKey='value'
                >
                    {chartData?.map((dataItem) => <Cell key={dataItem.name} fill={dataItem.color} />)}
                </Pie>
                {isSmallChartSize && (
                    <Legend
                        align='right'
                        verticalAlign='middle'
                        layout='vertical'
                        formatter={(type) => legendFormatter(type, chartData, valueSuffix)}
                    />
                )}
                <Tooltip
                    content={(props) => (
                        <SimpleChartTooltip
                            {...props}
                            getTooltipContent={(type) => chartData.find((item) => item.name === type)?.tooltip}
                        />
                    )}
                />
            </PieChart>
        );
    };

    const stakingChart = renderPieChart(
        stakingChartData,
        'Inflation',
        !inflationValue ? '-' : `${formatNumber(inflationValue, { minimumFractionDigits: 1, maximumFractionDigits: 2 })}%`,
        45 + (-180 * currentBonded / (currentBonded + currentUnbonded)),
        currentBonded + currentUnbonded,
    );

    const renderSupplyDivisionChart = () => renderPieChart(
        lockingChartData,
        'Supply',
        formatNumber(currentTotalSupply, { notation: 'compact', minimumFractionDigits: 2, maximumSignificantDigits: 4 }),
        -10,
        currentBonded + currentUnbonded,
    );

    return (
        <ChartContainer
            {...selectableChartProps}
            label='Total Supply'
            na={!network.totalSupply}
            formatValueOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 0 }}
            formatValue={(value, options) => formatPrice(value, '', options)}
            className={classNames('total-supply-chart-container', className)}
            data={chartData}
            fetchComparableValues={(item) => item?.amount || 0}
            currency={mainCurrency}
            onResize={setChartSize}
        >
            {showSupplyDivisionChart ? [ stakingChart, renderSupplyDivisionChart() ] : stakingChart}
        </ChartContainer>
    );
};

export default TotalSupplyChart;
