import classNames from 'classnames';
import React, { ReactElement, useCallback, useMemo } from 'react';
import Button from '../../../../shared/components/button/button';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as AddPositionIcon } from '../../../../assets/icons/add-position.svg';
import { formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import DisplayNameWithIro from '../../../currency/display-name-with-iro/display-name-with-iro';
import IncentiveBadge from '../../../incentives/incentive-badge/incentive-badge';
import { useIncentives } from '../../../incentives/incentives-context';
import { AccountNetworkState } from '../../account-network-state';
import { useAmm } from '../../../amm/amm-context';
import { useNavigate } from 'react-router-dom';
import { getPositionPart } from '../../../amm/amm.service';
import { Pool } from '../../../amm/types';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import AccountTotalLiquidityValue from './account-total-liquidity-value/account-total-liquidity-value';
import './account-pools.scss';

interface AccountPoolsProps {
    className?: string;
    networkState: AccountNetworkState;
    onRequestClose?: () => void;
}

const AccountPools: React.FC<AccountPoolsProps> = ({ className, networkState, onRequestClose }) => {
    const navigate = useNavigate();
    const { getNetwork } = useNetwork();
    const { ammState, getPoolLiquidity } = useAmm();
    const { incentivesState } = useIncentives();

    const pools = useMemo<Pool[]>(() => (ammState.pools || []).filter((pool) => pool.position), [ ammState.pools ]);

    const onPoolClick = useCallback((pool: Pool) => {
        navigate(`/amm/pool/${pool.id}`);
        onRequestClose?.();
    }, [ navigate, onRequestClose ]);

    const renderAssetLogo = (asset: CoinsAmount): ReactElement | undefined => {
        const currencyNetwork = getNetwork(asset.networkId);

        return currencyNetwork &&
            <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, currencyNetwork)} alt='currency logo' />;
    };

    return <>
        <AccountTotalLiquidityValue networkState={networkState} />
        <ul className={classNames('account-pools', className)}>
            {ammState.loading ? <Spinner className='positions-loader' /> : undefined}
            {pools?.map((pool, poolIndex) => {
                const liquidity = getPoolLiquidity(pool) || 0;
                const sharesPart = getPositionPart(pool);
                return (
                    <li className='position-row' key={poolIndex} onClick={() => onPoolClick(pool)}>
                        {renderAssetLogo(pool.assets[0])}
                        {renderAssetLogo(pool.assets[1])}
                        <span className='pool-assets-names'>
                            <DisplayNameWithIro coins={pool.assets[0]} />
                            &nbsp;/&nbsp;
                            <DisplayNameWithIro coins={pool.assets[1]} />
                            {incentivesState.incentives?.[pool.lpTokenDenom]?.some((incentive) => incentive.coins.length) ?
                                <IncentiveBadge dot denom={pool.lpTokenDenom} /> : null}
                        </span>
                        <span className='position-amount'>
                            {formatPrice(liquidity * sharesPart, undefined, undefined, 12)}
                            <span className='bonded-value'>
                                bonded {roundNumber(Number(pool.position?.bondedShares) * 100 / Number(pool.position?.shares), 2)}%
                            </span>
                        </span>
                    </li>
                );
            })}
            {!ammState.loading || pools.length ? (
                <Button
                    buttonType='secondary'
                    size='small'
                    className='new-position-button'
                    onClick={() => {
                        navigate('/amm/pools');
                        onRequestClose?.();
                    }}
                    trackEvent='account_menu_new_liquidity_click'
                >
                    <AddPositionIcon />&nbsp;&nbsp;Add Liquidity
                </Button>
            ) : undefined}
        </ul>
    </>;
};

export default AccountPools;
