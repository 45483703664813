import React, { useMemo } from 'react';
import Badge, { BadgeProps } from '../../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { ROLLAPP_STATUS_INFO_MAP, RollappStatus } from '../../../network/network-types';
import './rollapp-status-badge.scss';

interface RollappStatusBadgeProps extends Omit<BadgeProps, 'label'> {
    status?: RollappStatus;
    endedIro?: boolean;
}

const RollappStatusBadge: React.FC<RollappStatusBadgeProps> = ({ endedIro, status = 'Registered', ...badgeProps }) => {
    const color = useMemo(() => getCssVariableValue(ROLLAPP_STATUS_INFO_MAP[status].variableColor).split(',').map(Number), [ status ]);

    return (
        <Badge
            className='rollapp-status-badge'
            label={<span className='status-label'>{endedIro ? 'Token' : ROLLAPP_STATUS_INFO_MAP[status].name}</span>}
            rounded
            color={color}
            info={endedIro ? 'Token is trading while RollApp can launch at any time.' : ROLLAPP_STATUS_INFO_MAP[status].infoText}
            {...badgeProps} />
    );
};

export default RollappStatusBadge;

