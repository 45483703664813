import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import ButtonsGroup from '../../../shared/components/buttons-group/buttons-group';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../shared/components/form-controls/input/input';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import InfoIndicator from '../../../shared/components/info-indicator/info-indicator';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { useLayout } from '../../app/layout/layout-context';
import ScrollingTradePairs from '../../asset/scrolling-trade-pairs/scrolling-trade-pairs';
import Button from '../../../shared/components/button/button';
import { ReactComponent as MagicWandIcon } from '../../../assets/icons/magic-wand.svg';
import { ReactComponent as FairLaunchIcon } from '../../../assets/icons/fair-launch-tag.svg';
import { ReactComponent as MoreMenuIcon } from '../../../assets/icons/menu-more.svg';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';
import DaSelectorDialog from '../manage-rollapps-page/create-rollapp-page/da-selector-dialog/da-selector-dialog';
import { ROLLAPP_LIST_COLUM_TYPES, ROLLAPP_TAGS, RollappColumnTypeNameMap, RollappListColumnType, RollAppTag } from '../rollapp-types';
import RollappCards from './rollapp-cards/rollapp-cards';
import RollappHighlights from './rollapp-highlights/rollapp-highlights';
import RollappList from './rollapp-list/rollapp-list';
import { RollappsContextProvider, useRollapps } from './rollapps-context';
import RollappsHero from './rollapps-hero/rollapps-hero';
import RollappsStatistics from './rollapps-statistics/rollapps-statistics';
import './rollapps-page.scss';

const MAX_VISIBLE_ROLLAPP_TAGS = 5;
const SMALL_WINDOW_WIDTH = 856;

const RollappsPage: React.FC = () => {
    const {
        statusFilter,
        searchText,
        launchType,
        tagsFilter,
        viewMode,
        setOrder,
        setViewMode,
        setTagsFilter,
        switchTagFilter,
        setLaunchType,
        updateSearchText,
        setStatusFilter,
        loadMore,
    } = useRollapps();
    const { setFooterClassName, setCtaClassName } = useLayout();
    const { isMobile, isTablet, width } = useWindowSize();
    const { canLoadMore } = useScrollPosition();
    const [ preventLoadMore, setPreventLoadMore ] = useState(false);
    const [ daSelectorDialogVisible, setDaSelectorDialogVisible ] = useState(false);

    useEffect(() => {
        setFooterClassName('rollapps-page-footer');
        setCtaClassName('rollapps-page-cta');
        return () => {
            setFooterClassName();
            setCtaClassName();
        };
    }, [ setCtaClassName, setFooterClassName ]);

    useEffect(() => {
        if (!preventLoadMore && canLoadMore) {
            setPreventLoadMore(true);
            loadMore();
            setTimeout(() => setPreventLoadMore(false), 50);
        }
    }, [ loadMore, canLoadMore, preventLoadMore ]);

    const selectedHiddenTags = useMemo(
        () => ROLLAPP_TAGS.slice(isMobile ? 0 : MAX_VISIBLE_ROLLAPP_TAGS).filter((tag) => tagsFilter.includes(tag)),
        [ isMobile, tagsFilter ],
    );

    return (
        <div className='page rollapps-page'>
            <RollappsStatistics />

            {isTablet ? <RollappHighlights /> : <RollappsHero />}

            <div className='rollapps-actionbar'>
                <div className='horizontally-centered rollapp-button-filters'>
                    <ButtonsGroup className='common-status-buttons'>
                        <Button
                            buttonType='secondary'
                            size='small'
                            onClick={() => setStatusFilter(undefined)}
                            className={classNames('common-status', { active: !statusFilter })}
                            trackEvent='all_rollapps_switch_click'
                        >
                            All
                        </Button>
                        <Button
                            buttonType='secondary'
                            size='small'
                            onClick={() => setStatusFilter('Live')}
                            className={classNames('common-status', { active: statusFilter === 'Live' })}
                            trackEvent='live_rollapps_switch_click'
                        >
                            Live
                        </Button>
                        <Button
                            buttonType='secondary'
                            size='small'
                            onClick={() => setStatusFilter('IRO')}
                            className={classNames('common-status nowrap', { active: statusFilter === 'IRO' })}
                            trackEvent='iro_rollapps_switch_click'
                        >
                            Launchpad
                            <InfoIndicator indicatorSize='small' tooltipClassName='iro-common-status-tooltip'>
                                Launchpad allows projects to launch a future RollApp token for early trading.
                                It’s a fair and transparent way to seed liquidity and engage the community.
                                <ul className='list'>
                                    <li><b>Step 1:</b> Choose a Launchpad you want to participate in.</li>
                                    <li><b>Step 2:</b> Buy RollApp tokens using the bonding curve.</li>
                                    <li><b>Step 3:</b> Tokens can be sold at any time.</li>
                                    <li>
                                        <b>Step 4:</b> As more users buy on the bonding curve, liquidity accumulates for the RollApp, and upon launch, a trading pool will open at the last traded price.
                                    </li>
                                    <li>
                                        <b>Step 5:</b> Unsold tokens from the Launchpad will be used as incentives for liquidity providers after the RollApp launches.
                                    </li>
                                </ul>
                            </InfoIndicator>
                        </Button>
                    </ButtonsGroup>

                    <div className='switch-filters'>
                        <Button
                            buttonType='icon'
                            className={classNames('filter-switch fair-launch', { active: launchType === 'Fair Launch' })}
                            onClick={() => setLaunchType(launchType === 'Fair Launch' ? undefined : 'Fair Launch')}
                            size='small'
                            trackEvent={launchType !== 'Fair Launch' ? 'fair_launch_filter_click' : undefined}
                        >
                            <FairLaunchIcon />{width >= 420 && <>&nbsp;Fair Launch</>}
                        </Button>

                        {statusFilter !== 'IRO' && (
                            <Button
                                buttonType='icon'
                                className={classNames('filter-switch', { active: launchType === 'DYM Native' })}
                                onClick={() => setLaunchType(launchType === 'DYM Native' ? undefined : 'DYM Native')}
                                size='small'
                                trackEvent={launchType !== 'DYM Native' ? 'dym_native_filter_click' : undefined}
                            >
                                DYM Native
                            </Button>
                        )}

                        <div className='rollapp-tag-switches'>
                            {width > SMALL_WINDOW_WIDTH && ROLLAPP_TAGS.slice(0, 5).map((tag) => (
                                <Button
                                    buttonType='icon'
                                    key={tag}
                                    className={classNames('filter-switch', { active: tagsFilter.includes(tag) })}
                                    size='small'
                                    trackEvent='rollapp_tag_filter_click'
                                    onClick={() => switchTagFilter(tag)}
                                >
                                    {tag}
                                </Button>
                            ))}
                            <Select
                                multiselect
                                value={tagsFilter}
                                className={classNames('tags-selector filter-switch', { active: selectedHiddenTags.length })}
                                buttonType='icon'
                                placeholder={<MoreMenuIcon />}
                                renderTriggerSelectedOption={() =>
                                    width <= SMALL_WINDOW_WIDTH ? <MoreMenuIcon /> : !selectedHiddenTags.length ?
                                        <MoreMenuIcon /> : <small className='more-label'>&nbsp;(+{selectedHiddenTags.length} more)</small>}
                                onSelect={(value) => setTagsFilter(value as RollAppTag[])}
                            >
                                {ROLLAPP_TAGS.map((tag) =>
                                    <Option trackEvent='rollapps_tag_filter_click' value={tag} key={tag}>{tag}</Option>)}
                            </Select>
                        </div>

                        {isTablet && (
                            <div className='create-rollapp-button-container'>
                                <span className='space' />
                                <Button
                                    buttonType='primary'
                                    className='create-rollapp-button nowrap'
                                    onClick={() => setDaSelectorDialogVisible(true)}
                                    trackEvent='launch_rollapp_click'
                                >
                                    <MagicWandIcon />&nbsp;&nbsp;Launch a rollapp
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                <span className='space' />

                <div className='rollapps-actions'>
                    <ControlsComposer className='controls'>
                        <Input
                            controlSize='medium'
                            value={searchText}
                            type='search'
                            placeholder='Search RollApps...'
                            onValueChange={updateSearchText}
                        />

                        <Select
                            className='sort-selector'
                            placeholder={<SortIcon />}
                            onSelect={(value) => setOrder(
                                value as RollappListColumnType,
                                value === 'name' || value === 'iro-time' ? 'desc' : 'asc',
                            )}
                            renderTriggerSelectedOption={() => <SortIcon />}
                        >
                            <Option value='' key=''>-</Option>
                            {ROLLAPP_LIST_COLUM_TYPES.map((type) =>
                                <Option value={type} key={type}>{RollappColumnTypeNameMap[type]}</Option>)}

                        </Select>
                    </ControlsComposer>

                    <ButtonsGroup className='view-mode'>
                        <Button
                            buttonType='secondary'
                            onClick={() => setViewMode('Grid')}
                            className={classNames({ active: viewMode === 'Grid' })}
                            trackEvent='grid_view_mode_click'
                        >
                            <GridIcon />
                        </Button>
                        <Button
                            buttonType='secondary'
                            onClick={() => setViewMode('List')}
                            className={classNames({ active: viewMode === 'List' })}
                            trackEvent='list_view_mode_click'
                        >
                            <ListIcon />
                        </Button>
                    </ButtonsGroup>
                </div>
            </div>

            {viewMode === 'List' ? <RollappList /> : <RollappCards />}

            <ScrollingTradePairs className='scrolling-trade-pairs' />

            {daSelectorDialogVisible && <DaSelectorDialog onRequestClose={() => setDaSelectorDialogVisible(false)} />}
        </div>
    );
};

const RollappsPageWithContext = () =>
    <RollappsContextProvider><RollappsPage /></RollappsContextProvider>;

export default RollappsPageWithContext;
