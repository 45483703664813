import { Long } from 'cosmjs-types/helpers';
import { MsgTransferEncodeObject } from 'cosmjs/packages/stargate/build/modules/ibc/messages';
import { AccountNetworkState } from '../account/account-network-state';
import { convertToCoin, getClosedAmount } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { Network } from '../network/network-types';

interface TransferTxMessageParams {
    sourceData: AccountNetworkState;
    destinationData: AccountNetworkState
    hubNetworkData: AccountNetworkState,
    coins: CoinsAmount,
    balance: CoinsAmount;
    eibcFee?: number
    maxAmount?: bigint
}

const TRANSFER_PORT = 'transfer';

export const getSourceChannel = (sourceNetwork?: Network, destinationNetwork?: Network) => {
    if (!sourceNetwork || !destinationNetwork) {
        return '';
    } else if (sourceNetwork.type === 'Hub') {
        return destinationNetwork.ibc?.hubChannel;
    } else {
        return sourceNetwork.ibc?.channel;
    }
};

export const createTransferMessage = ({
    sourceData,
    destinationData,
    hubNetworkData,
    coins,
    balance,
    eibcFee,
    maxAmount
}: TransferTxMessageParams): MsgTransferEncodeObject => {
    const sourceChannel = getSourceChannel(sourceData.network, destinationData.network);
    const isHubMiddleware = sourceData.network?.type !== 'Hub' && destinationData.network?.type !== 'Hub';
    const timeoutTimestamp = Long.fromNumber(new Date().getTime() + (sourceData.network?.ibc?.timeout || 0)).multiply(1_000_000);
    const token = convertToCoin({ ...coins, amount: coins.amount || Math.round(balance.amount / 2) });
    const transferMemo = createTransferMemo(sourceData, destinationData, coins, eibcFee);
    token.amount = getClosedAmount(token.amount, coins.currency.decimals, maxAmount).toString();

    return {
        typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
        value: {
            sourceChannel,
            sender: sourceData.address,
            receiver: isHubMiddleware ? hubNetworkData.address : destinationData.address,
            sourcePort: TRANSFER_PORT,
            token,
            // todo: find better way
            timeoutHeight: { revisionNumber: Long.fromNumber(9999), revisionHeight: Long.ONE },
            timeoutTimestamp,
            memo: transferMemo,
        },
    };
};

export const createTransferMemo = (
    sourceData: AccountNetworkState,
    destinationData: AccountNetworkState,
    coins?: CoinsAmount,
    eibcFee?: number,
): string | undefined => {
    const eibcMemo = !eibcFee || sourceData.network?.type !== 'RollApp' || !coins ? undefined : {
        eibc: { fee: BigInt(Math.round(Number(convertToCoin(coins).amount) * eibcFee / 100)).toString() },
    };
    const forwardMemo = (sourceData.network?.type === 'Hub' ||
        destinationData.network?.type === 'Hub' ||
        !destinationData.address ||
        !destinationData.network?.ibc?.hubChannel) ? undefined : {
        forward: {
            port: TRANSFER_PORT,
            receiver: destinationData.address,
            channel: destinationData.network.ibc.hubChannel,
        },
    };
    return !eibcMemo && !forwardMemo ? undefined : JSON.stringify({ ...eibcMemo, ...forwardMemo });
};
