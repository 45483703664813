import { EncodeObject } from 'cosmjs/packages/proto-signing';
import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { convertDecimalToInt, roundNumber } from '../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useAsset } from '../../asset/asset-context';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { Plan } from '../../client/station-clients/dymension/generated/iro/iro';
import { isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { getNetworkDataItem } from '../../network/network-service';
import { TradeType } from '../../trade/buy-sell/buy-sell';
import { DEFAULT_SLIPPAGE_TOLERANCE } from '../../trade/trade-context';
import { AmountTxState } from '../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { getBaseAmountWithoutFee } from '../../tx/tx-service';
import { TxState } from '../../tx/tx-state';
import { Fee } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useIRO } from '../iro-context';
import { getBuyCost, getBuyExactSpendCost, getPrice, getSellCost } from '../iro-service';
import { createIroBuyExactSpendMessage, createIroBuyMessage, createIroSellMessage } from './iro-buy-sell-service';

const IRO_SLIPPAGE_TOLERANCE_KEY = 'iroSlippageToleranceKey';
const IRO_SLIPPAGE_TOLERANCE_ENABLED_KEY = 'iroSlippageToleranceEnabledKey';

interface IroBuySellContextValue {
    asset1AmountTxState: AmountTxState;
    asset2AmountTxState: AmountTxState;
    updateAsset1Coins: (coins: CoinsAmount) => void;
    updateAsset2Coins: (coins: CoinsAmount) => void;
    availableBalances?: CoinsAmount[];
    useInverseAsset: boolean;
    setUseInverseAsset: (value: boolean) => void;
    slippageTolerance?: number;
    setSlippageTolerance: (value?: number) => void;
    slippageDisabled: boolean;
    setSlippageDisabled: (value: boolean) => void;
    txState: TxState;
    fees: Fee[];
    spotPrice: number;
    loading: boolean;
    broadcast: (memo?: string) => void;
    disabled?: boolean;
}

export const IroBuySellContext = createContext<IroBuySellContextValue>({} as IroBuySellContextValue);

export const IroBuySellContextProvider = ({
    children,
    asset,
    tradeType,
}: { children: ReactNode, asset: CoinsAmount, tradeType: TradeType }) => {
    const { hubNetwork, getNetwork } = useNetwork();
    const { hubAsset } = useAsset();
    const { showWarningMessage } = useSnackbar();
    const { iroParams, iroParamsLoading } = useIRO();
    const [ slippageTolerance, setSlippageTolerance ] = usePersistedState<number | undefined>(IRO_SLIPPAGE_TOLERANCE_KEY, undefined);
    const [ slippageDisabled, setSlippageDisabled ] = usePersistedState(IRO_SLIPPAGE_TOLERANCE_ENABLED_KEY, true);
    const { clientStateMap, handleClientError } = useClient();
    const networkState = useHubNetworkState();
    const { hubWallet, handleWalletError } = useWallet();
    const [ asset1Fees, setAsset1Fees ] = useState<CoinsAmount[]>([]);
    const [ iroPlan, setIroPlan ] = useState<Plan>();
    const [ iroPlanLoading, setIroPlanLoading ] = useState(false);
    const [ useInverseAsset, setUseInverseAsset ] = useState(true);
    const cancelAndSetIroPlanPromise = useCancelablePromise<Plan>();

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    const takerFee = useMemo(() => convertDecimalToInt(Number(iroParams?.takerFee) || 0), [ iroParams?.takerFee ]);

    const tradeFactor = useMemo(
        () => Math.min(1, takerFee + (slippageDisabled ? 0 : ((slippageTolerance || DEFAULT_SLIPPAGE_TOLERANCE) / 100))),
        [ slippageDisabled, slippageTolerance, takerFee ],
    );

    const availableBalances = useMemo(
        () => ([ asset, hubAsset ].filter(Boolean) as CoinsAmount[]).map((asset) => {
            const balance = networkState.balances?.find((balance) => isCoinsEquals(balance, asset));
            return ({ ...asset, amount: balance?.amount || 0, baseAmount: balance?.baseAmount });
        }),
        [ networkState.balances, asset, hubAsset ],
    );

    const {
        amountTxState: asset1AmountTxState,
        setCoins: setAsset1Coins,
        setAmount: setAsset1Amount,
    } = useAmountTx({ networkState, availableBalances, extraFees: asset1Fees });

    const createTradeMessagesCreator = useCallback((fee?: CoinsAmount, coins2?: CoinsAmount): EncodeObject[] => {
        if (!iroPlan || !asset1AmountTxState.coins || !coins2 || !networkState.address) {
            return [];
        }
        if (tradeType === 'Sell') {
            const asset = { ...asset1AmountTxState.coins, amount: asset1AmountTxState.coins.amount || 1 };
            const inverseAsset = { ...coins2, amount: slippageDisabled ? 0 : getSellCost(iroPlan, asset, tradeFactor) };
            const baseAmountWithoutFee = getBaseAmountWithoutFee(asset, networkState.balances, fee);
            return [ createIroSellMessage(networkState.address, iroPlan, asset, inverseAsset, baseAmountWithoutFee) ];
        }
        if (useInverseAsset) {
            const inverseAsset = { ...asset1AmountTxState.coins, amount: asset1AmountTxState.coins.amount || 1 };
            const asset = { ...coins2, amount: slippageDisabled ? 0 : getBuyExactSpendCost(iroPlan, inverseAsset, tradeFactor) };
            const baseSpendAmountWithoutFee = getBaseAmountWithoutFee(inverseAsset, networkState.balances, fee);
            return [ createIroBuyExactSpendMessage(networkState.address, iroPlan, asset, inverseAsset, baseSpendAmountWithoutFee) ];
        }
        const asset = { ...coins2, amount: coins2.amount || 1 };
        const inverseAsset = { ...asset1AmountTxState.coins, amount: getBuyCost(iroPlan, asset, tradeFactor) };
        if (slippageDisabled) {
            inverseAsset.amount = Math.min(
                10 * inverseAsset.amount,
                Number(asset1AmountTxState.coins.baseAmount) / (10 ** asset1AmountTxState.coins.currency.decimals),
            );
        }
        const baseSpendAmountWithoutFee = getBaseAmountWithoutFee(inverseAsset, networkState.balances, fee);
        return [ createIroBuyMessage(networkState.address, iroPlan, asset, inverseAsset, baseSpendAmountWithoutFee) ];
    }, [
        slippageDisabled,
        asset1AmountTxState.coins,
        iroPlan,
        networkState.address,
        networkState.balances,
        tradeFactor,
        tradeType,
        useInverseAsset,
    ]);

    const {
        txState,
        amountTxState: asset2AmountTxState,
        setCoins: setAsset2Coins,
        setAmount: setAsset2Amount,
        broadcast,
        calculateFee,
        clearFee,
    } = useAmountTx({ networkState, selectInitialCurrency: false, availableBalances, amountTxMessagesCreator: createTradeMessagesCreator });

    useEffect(() => {
        if (!txState.fee) {
            return;
        }
        if (asset1Fees.length && isCoinsEquals(txState.fee.coins, asset1Fees[0])) {
            return;
        }
        setAsset1Fees([ txState.fee.coins ]);
    }, [ asset1Fees, txState.fee ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (!hubNetwork || !clientState || !tradeType) {
            return;
        }
        setIroPlanLoading(true);
        cancelAndSetIroPlanPromise((signal) => getNetworkDataItem<Plan>(hubNetwork.chainId, 'iro-plans', asset.networkId, signal))
            .then(setIroPlan)
            .catch(handleClientError)
            .finally(() => setIroPlanLoading(false));
    }, [ tradeType, clientState, cancelAndSetIroPlanPromise, handleClientError, hubNetwork, asset.networkId ]);

    useEffect(() => {
        if (hubWallet &&
            iroPlan?.id &&
            networkState.network &&
            asset1AmountTxState.coins?.currency &&
            asset2AmountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [
        asset1AmountTxState.coins?.currency,
        asset2AmountTxState.coins?.currency,
        calculateFee,
        clearFee,
        iroPlan?.id,
        hubWallet,
        networkState.network,
    ]);

    const loading = useMemo(() => iroPlanLoading && !iroPlan, [ iroPlan, iroPlanLoading ]);

    useEffect(() => {
        if (txState.response) {
            setAsset1Amount(0);
        }
    }, [ setAsset1Amount, txState.response ]);

    const updateAsset1Coins = useCallback((coins: CoinsAmount) => {
        setAsset1Coins(coins);
        if (!asset2AmountTxState.coins || !coins.amount || !iroPlan) {
            return;
        }
        if (tradeType === 'Sell') {
            const cost = getSellCost(iroPlan, coins, takerFee);
            setAsset2Amount(cost);
        } else {
            const cost = getBuyExactSpendCost(iroPlan, coins, takerFee);
            setAsset2Amount(cost);
        }
    }, [ tradeType, takerFee, asset2AmountTxState.coins, iroPlan, setAsset1Coins, setAsset2Amount ]);

    const updateAsset2Coins = useCallback((coins: CoinsAmount) => {
        const amountToBuy =
            (Number(iroPlan?.totalAllocation?.amount || '0') - Number(iroPlan?.soldAmt || '0')) / (10 ** coins.currency.decimals);
        if (coins.amount > amountToBuy) {
            coins.amount = amountToBuy;
        }
        setAsset2Coins(coins);

        if (!asset1AmountTxState.coins || !coins.amount || !iroPlan) {
            return;
        }
        const cost = getBuyCost(iroPlan, coins, takerFee);
        setAsset1Amount(Math.min(cost, asset1AmountTxState.availableAmount));
    }, [ takerFee, asset1AmountTxState.availableAmount, asset1AmountTxState.coins, iroPlan, setAsset1Amount, setAsset2Coins ]);

    const fees = useMemo((): Fee[] => [
        {
            label: 'Protocol burn fee',
            value: `${roundNumber(takerFee * 100, 2)}%`,
            loading: !iroParams && iroParamsLoading,
        },
    ], [ iroParams, iroParamsLoading, takerFee ]);

    useEffect(() => {
        if (!hubAsset) {
            return;
        }
        const assetBalance = availableBalances.find((balance) => isCoinsEquals(balance, asset)) || asset;
        const hubAssetBalance = availableBalances.find((balance) => isCoinsEquals(balance, hubAsset)) || hubAsset;
        if (tradeType === TradeType.SELL && (!asset1AmountTxState.coins || !isCoinsEquals(asset1AmountTxState.coins, asset))) {
            setAsset1Coins({ ...assetBalance, amount: 0 });
            setAsset2Coins({ ...hubAssetBalance, amount: 0 });
        }
        if (tradeType === TradeType.BUY && (!asset2AmountTxState.coins || !isCoinsEquals(asset2AmountTxState.coins, asset))) {
            setAsset2Coins({ ...assetBalance, amount: 0 });
            setAsset1Coins({ ...hubAssetBalance, amount: 0 });
        }
    }, [
        asset,
        asset1AmountTxState.coins,
        asset2AmountTxState.coins,
        availableBalances,
        hubAsset,
        setAsset1Coins,
        setAsset2Coins,
        tradeType,
    ]);

    const spotPrice = useMemo(() => {
        if (!iroPlan) {
            return 0;
        }
        const price = getPrice(iroPlan, 1);
        if (tradeType === TradeType.SELL) {
            return price;
        }
        return !price ? 0 : 1 / price;
    }, [ iroPlan, tradeType ]);

    const disabled = useMemo(
        () => !iroPlan || (iroPlan.startTime && new Date(iroPlan.startTime).getTime() > Date.now() &&
            getNetwork(iroPlan.rollappId)?.owner !== networkState.address),
        [ getNetwork, iroPlan, networkState.address ],
    );

    useEffect(() => {
        if (disabled && asset1AmountTxState.coins?.amount && asset2AmountTxState.coins?.amount) {
            showWarningMessage({ content: 'The Launchpad plan has not started', duration: 3000 });
        }
    }, [ asset1AmountTxState.coins, asset2AmountTxState.coins, disabled, showWarningMessage ]);

    return (
        <IroBuySellContext.Provider
            value={{
                asset1AmountTxState,
                asset2AmountTxState,
                txState,
                fees,
                loading,
                spotPrice,
                disabled,
                slippageTolerance,
                slippageDisabled,
                setSlippageDisabled,
                setSlippageTolerance,
                updateAsset1Coins,
                updateAsset2Coins,
                availableBalances,
                broadcast,
                useInverseAsset,
                setUseInverseAsset,
            }}
        >
            {children}
        </IroBuySellContext.Provider>
    );
};


