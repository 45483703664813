import { useCallback, useEffect, useState } from 'react';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import { useClient } from '../../../client/client-context';
import { useNetwork } from '../../../network/network-context';
import { useIbcStatus } from '../ibc-status-context';
import { loadIbcTransfer } from '../ibc-status-service';
import { IbcTransferDetails } from '../ibc-status-types';

export interface IbcTransferStatusValue {
    transfer?: IbcTransferDetails;
    loading: boolean;
    refresh: () => void;
}

interface IbcTransferStatusParams {
    transferId?: string;
}

export const useIbcTransferStatus = ({ transferId }: IbcTransferStatusParams): IbcTransferStatusValue => {
    const { hubNetwork } = useNetwork();
    const { clientStateMap } = useClient();
    const {
        allInitiatedTransfers,
        transfers,
        loading: ibcStatusLoading,
        updateTransfer,
        getFixedTransfer,
        getFixedFullTransfer,
    } = useIbcStatus();
    const [ transfer, setTransfer ] = useState<IbcTransferDetails>();
    const [ loading, setLoading ] = useState(false);
    const cancelAndSetIbcTransferPromise = useCancelablePromise<IbcTransferDetails | undefined>();
    const clientState = hubNetwork?.chainId ? clientStateMap[hubNetwork.chainId] : undefined;

    const refresh = useCallback((): void => {
        if (!transferId) {
            return;
        }
        setLoading(true);
        cancelAndSetIbcTransferPromise((signal) => loadIbcTransfer(transferId, signal))
            .then((transfer) => {
                transfer = transfer && getFixedTransfer(transfer);
                if (transfer) {
                    setTransfer(transfer);
                    updateTransfer(transfer);
                }
            })
            .finally(() => setLoading(false));
    }, [ cancelAndSetIbcTransferPromise, getFixedTransfer, transferId, updateTransfer ]);

    useEffect(() => {
        if (!transferId || ibcStatusLoading) {
            return;
        }
        const existingTransfer = [ ...allInitiatedTransfers, ...transfers ].find((transfer) => transfer.id === transferId);
        if (existingTransfer) {
            setTransfer(existingTransfer);
            return;
        }
        refresh();
    }, [ allInitiatedTransfers, ibcStatusLoading, refresh, transferId, transfers ]);

    useEffect(() => {
        if ((clientState && !clientState.client && !clientState.connecting) || (transfer && !transfer.eibcFee)) {
            setLoading(false);
            return;
        }
        if (!transfer || transfer.eibcFeeCoins || !clientState?.client || clientState?.connecting) {
            return;
        }
        getFixedFullTransfer(transfer, clientState.client)
            .then((fixedFullTransfer) => fixedFullTransfer.eibcFeeCoins && setTransfer(fixedFullTransfer))
            .finally(() => setLoading(false));
    }, [ clientState, getFixedFullTransfer, transfer ]);

    useEffect(() => {
        const interval = setInterval(refresh, 5000);
        return () => clearInterval(interval);
    }, [ refresh ]);

    return { transfer, loading: loading || ibcStatusLoading, refresh };
};
