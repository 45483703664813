import { readStream } from '../../shared/utils/file-utils';
import { filterNonEmptyValues } from '../../shared/utils/object-utils';
import { Pool } from '../amm/types';
import { Gauge } from '../client/station-clients/dymension/generated/incentives/gauge';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoinsAmount, parseCoins } from '../currency/currency-service';
import { EarningRecord, EARNINGS_START_DATE, EarningType } from './earnings-types';

const EARNING_MINIMUM_VALUE = 1 / (10 ** 12);

export const loadEarnings = async (
    client: StationClient,
    address: string,
    allPools: Pool[],
    gauges: Gauge[],
    page: number,
    pageSize: number,
    type?: EarningType,
    signal?: AbortSignal,
): Promise<any[]> => {
    const nativeTypes = type ? convertFromEarningTypes(type) : [];
    const typeQueryParam = nativeTypes.reduce((current, type) => `${current}&type=${type}`, '');
    const response = await fetch(
        `${process.env.REACT_APP_FYI_API}?module=dymension&action=account-earnings-by-address&addr=${address}&page=${page}&size=${pageSize}${typeQueryParam}`,
        { signal },
    ).catch((error) => {
        if (!signal?.aborted) {
            console.error('Failed to fetch earnings', { error });
        }
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    const result = JSON.parse(responseText || '{}')
        .result as { list: { h: number, t: string, v: string, e: number, s: string, r: string, a: string }[] };
    const hubNetwork = client.getNetwork();
    const allNetworks = client.getAllNetworks();

    return (await Promise.all(result.list.filter((item) => item.e > EARNINGS_START_DATE).map(async (item) => {
        const type = convertToEarningType(item.t);
        const amount = filterNonEmptyValues(
            await Promise.all(parseCoins(item.v.replaceAll(' ', '')).map((coin) => convertToCoinsAmount(coin, client))));
        if (!type || !amount.length || amount.every((coins) => coins.amount < EARNING_MINIMUM_VALUE)) {
            return [];
        }
        const emptyRecord: EarningRecord = { block: item.h, type, time: item.e * 1000, amount };
        if (type === 'Staking') {
            return [ { ...emptyRecord, network: hubNetwork } ];
        }
        try {
            const subjectData = JSON.parse(item.s || '{}') as { r?: string, p?: string[] };
            if (subjectData.r) {
                const rollapp = allNetworks.find((network) => network.chainId === subjectData.r);
                return [ { ...emptyRecord, network: rollapp } ];
            }
            if (subjectData.p) {
                const pool = !subjectData.p?.length ? undefined : allPools.find((pool) => pool.id === Number(subjectData.p?.[0]));
                const poolRollappId = pool?.assets.find((asset) => asset.networkId !== hubNetwork.chainId)?.networkId;
                const rollapp = poolRollappId ? allNetworks.find((network) => network.chainId === poolRollappId) : undefined;
                return [ { ...emptyRecord, network: rollapp } ];
            }
            const gaugesData = JSON.parse(item.a || '{}') as { attributes?: { key: string; value: string }[]; };
            if (gaugesData.attributes?.length) {
                const gaugesAmounts = filterNonEmptyValues(await Promise.all(gaugesData.attributes?.map(async ({ key, value }) => {
                    const gauge = gauges.find((gauge) => gauge.id === Number(key.replace('gauge_id_', '')));
                    const amount =
                        filterNonEmptyValues(await Promise.all(parseCoins(value).map((coin) => convertToCoinsAmount(coin, client))));
                    return gauge && amount.length && (gauge.rollapp?.rollappId || gauge.asset?.denom) ? { gauge, amount } : undefined;
                }) || []));
                const gaugeRollappRecords = gaugesAmounts.filter(({ gauge, amount }) => gauge.rollapp?.rollappId)
                    .map(({ gauge, amount }) =>
                        ({ ...emptyRecord, network: allNetworks.find((network) => network.chainId === gauge.rollapp?.rollappId), amount }))
                    .reduce<EarningRecord[]>((current, record) => {
                        const currentRecord = current.find((currentRecord) => currentRecord.network?.chainId === record.network?.chainId);
                        if (!currentRecord) {
                            current.push(record);
                        } else {
                            currentRecord.amount.push(...record.amount);
                        }
                        return current;
                    }, []);
                const gaugePoolAmounts = gaugesAmounts.filter(({ gauge }) => !gauge.rollapp && gauge.asset?.denom)
                    .map(({ gauge, amount }) =>
                        ({ ...emptyRecord, pool: allPools.find((pool) => pool.lpTokenDenom === gauge.asset?.denom), amount }))
                    .reduce<EarningRecord[]>((current, record) => {
                        const currentRecord = current.find((currentRecord) => currentRecord.pool?.id === record.pool?.id);
                        if (!currentRecord) {
                            current.push(record);
                        } else {
                            currentRecord.amount.push(...record.amount);
                        }
                        return current;
                    }, []);
                return [ ...gaugeRollappRecords, ...gaugePoolAmounts ];
            }
        } catch {}
        return [ emptyRecord ];
    }))).reduce((current, records) => [ ...current, ...records ], []);
};

const convertToEarningType = (type: string): EarningType | undefined => {
    switch (type) {
        case 'plp':
            return 'Pool LP';
        case 'endorse':
            return 'Endorsement';
        case 'dist_wrw':
            return 'Staking';
        case 'royalty':
            return 'Royalty';
        case 'eibcff':
        case 'eibcflp':
            return 'Bridge LP';
    }
    return undefined;
};

const convertFromEarningTypes = (type: EarningType): string[] => {
    switch (type) {
        case 'Bridge LP':
            return [ 'eibcff', 'eibcflp' ];
        case 'Endorsement':
            return [ 'endorse' ];
        case 'Pool LP':
            return [ 'plp' ];
        case 'Royalty':
            return [ 'royalty' ];
        case 'Staking':
            return [ 'dist_wrw' ];
    }
    return [];
};

