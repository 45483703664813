import React, { useState } from 'react';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icons/discord.svg';
import { ReactComponent as DymensionFullLogo } from '../../assets/logos/dymension-full-logo.svg';
import { ReactComponent as DymensionLogo } from '../../assets/logos/dymension-logo.svg';
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg';
import { ReactComponent as MoreMenuIcon } from '../../assets/icons/menu-more.svg';
import NavBar, { NavBarItem } from '../../shared/components/nav-bar/nav-bar';
import Icon from '../../shared/components/icon/icon';
import classNames from 'classnames';
import { useLayout } from '../app/layout/layout-context';
import { useNetwork } from '../network/network-context';
import './footer.scss';

interface SocialLinksProps {
    className?: string;
}

export const SocialLinks: React.FC<SocialLinksProps> = ({ className }) => {
    return (
        <div className={classNames('social-links', className)}>
            <a className='social-link' href='https://dymension.xyz/' target='_blank' rel='noreferrer'>
                <Icon><DymensionLogo /></Icon>
            </a>
            <a className='social-link' href='https://twitter.com/dymension' target='_blank' rel='noreferrer'>
                <Icon><TwitterIcon /></Icon>
            </a>
            <a className='social-link' href='https://discord.gg/dymension' target='_blank' rel='noreferrer'>
                <Icon><DiscordIcon /></Icon>
            </a>
            <a className='social-link' href='https://github.com/dymensionxyz' target='_blank' rel='noreferrer'>
                <Icon><GithubIcon /></Icon>
            </a>
        </div>
    );
};

const Footer: React.FC = () => {
    const { hubNetwork } = useNetwork();
    const { footerClassName } = useLayout();
    const [ moreMenuOpen, setMoreMenuOpen ] = useState(false);

    return (
        <div className={classNames('footer', footerClassName)}>
            <a href='/'>
                <Icon className='full-logo'><DymensionFullLogo /></Icon>
            </a>

            <SocialLinks />

            <NavBar className='footer-nav-bar'>
                <NavBarItem label={<>Roll<b><i>Apps</i></b></>} url='/rollapps' className='footer-nav-item' />
                <NavBarItem label='Endorse' url='/endorsement' className='footer-nav-item' />
                <NavBarItem label='Transfers' url='/ibc' preventRedirect className='footer-nav-item'>
                    <NavBarItem label='Transfer' url='/transfer' />
                    <NavBarItem label='History' url='/status' />
                    <NavBarItem label='Provide Liquidity' url='/eibc-client' />
                </NavBarItem>
                <NavBarItem label='Trade' url='/amm' preventRedirect className='footer-nav-item'>
                    <NavBarItem label='Swap' url='/swap' />
                    <NavBarItem label='Pools' url='/pools' />
                    <NavBarItem label='Assets' url='/assets' />
                </NavBarItem>
                <NavBarItem label='DYM' url='/dymension' preventRedirect className='footer-nav-item'>
                    <NavBarItem label='Dashboard' url='/dashboard' />
                    <NavBarItem label='Trade' url='/token' />
                    <NavBarItem label='Stake' url='/staking' />
                    <NavBarItem label='Vote' url='/governance' />
                </NavBarItem>
                <NavBarItem
                    label={<MoreMenuIcon />}
                    hideMoreMenuIcon
                    onClick={() => setMoreMenuOpen(!moreMenuOpen)}
                />
            </NavBar>

            <div className={classNames('section nav-bar-more-menu', { open: moreMenuOpen })}>
                <NavBar className='more-menu-nav-items'>
                    <NavBarItem
                        label='Documentation'
                        url='https://docs.dymension.xyz'
                        external
                        className='more-menu-nav-item'
                        trackEvent='nav_bar_documentation_click'
                    />
                    <NavBarItem
                        label='Forum'
                        url='https://forum.dymension.xyz'
                        external
                        className='more-menu-nav-item'
                        trackEvent='nav_bar_forum_click'
                    />
                    <NavBarItem
                        label='Explorer'
                        url={hubNetwork?.explorerUrl || ''}
                        disabled={!hubNetwork?.explorerUrl}
                        external
                        className='more-menu-nav-item'
                        trackEvent='nav_bar_explorer_click'
                    />
                    <NavBarItem
                        label='Blog'
                        url='https://medium.com/@dymension'
                        external
                        className='more-menu-nav-item'
                        trackEvent='nav_bar_blog_click'
                    />
                </NavBar>

                <SocialLinks className='more-menu-social-links' />
            </div>
        </div>
    );
};

export default Footer;
