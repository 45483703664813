import { deleteObject, uploadBytes } from '@firebase/storage';
import { Decimal } from 'cosmjs/packages/math';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { getDownloadURL, uploadString } from 'firebase/storage';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../shared/components/button/button';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import Spinner from '../../../../shared/components/spinner/spinner';
import useCopyToClipboard from '../../../../shared/hooks/use-copy-to-clipboard';
import { usePersistedState } from '../../../../shared/hooks/use-persisted-state';
import { exportFile, getFileSuffix, readStream } from '../../../../shared/utils/file-utils';
import { sortObject, URL_REGEX } from '../../../../shared/utils/text-utils';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useAsset } from '../../../asset/asset-context';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import { getFeeCurrency } from '../../../currency/currency-service';
import { CoinsAmount, Currency } from '../../../currency/currency-types';
import { useDymns } from '../../../dymns/dymns-context';
import { useIncentives } from '../../../incentives/incentives-context';
import { epochToMilliseconds } from '../../../incentives/incentives.service';
import { createIroBuyExactSpendMessage } from '../../../iro/iro-buy-sell/iro-buy-sell-service';
import { useIRO } from '../../../iro/iro-context';
import { convertToBondingCurve, createIroDenom } from '../../../iro/iro-service';
import { useNetwork } from '../../../network/network-context';
import { fetchRollAppIdName, fetchRollAppIdNumber } from '../../../network/network-service';
import { DataAvailability, Network } from '../../../network/network-types';
import { useQuickAuth } from '../../../quick-auth/quick-auth-context';
import { TxState } from '../../../tx/tx-state';
import { DeliveryTxCode, Fee } from '../../../tx/tx-types';
import { useTx } from '../../../tx/use-tx';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import { WalletInfoMap } from '../../../wallet/wallet-types';
import {
    calculateChecksum,
    createEmptySupplyAllocation,
    createEmptyDistribution,
    createEmptyToken,
    createRegisterRollappMessage,
    createUpdateRollappMessage,
    generateGenesis,
    getAccountsSupply,
    getDistributionFromGenesis,
    getGenesisFileRef,
    getRollappLogoFileRef,
    getTokenFromGenesis,
    loadGenesis,
    getAllocationsFromGenesis,
    createEmptyIro,
    createIroPlanMessage,
    createEmptyRollapp,
    getDaFromGenesis,
    saveRollappTokenomics,
    calculateSupplyAllocationAmounts,
} from './create-rollapp-service';
import { AccountStorage } from './sections/tokenomics/accounts/accounts-storage';
import {
    Account,
    SupplyAllocation,
    CreateRollappStep,
    DEFAULT_DENOM_EXPONENT,
    DEFAULT_TOTAL_SUPPLY,
    Distribution,
    Token,
    SupplyAllocationAmounts,
    IRO,
    MIN_TOKEN_SYMBOL_LENGTH,
    MAX_ACCOUNTS,
    MAX_HUB_ACCOUNTS,
} from './types';
import { useIroQuickAuth } from './use-iro-quick-auth';

interface CreateRollappContextValue {
    rollapp: Network;
    updateRollapp: (key: keyof Network, value: Network[keyof Network]) => void;
    showErrors: boolean;
    alias: string;
    rollappDataLoaded: boolean;
    currentStep: CreateRollappStep;
    visibleStep: CreateRollappStep;
    token: Token,
    distribution: Distribution,
    supplyAllocation: SupplyAllocation,
    accounts: Account[],
    rollappLogoFile?: File;
    totalSupply?: bigint;
    isAliasExists: boolean;
    initialPurchaseEnabled: boolean;
    genesisGenerating: boolean;
    iroQuickAuthConnecting: boolean;
    logoUploading: boolean;
    isComplete: boolean;
    haveIRO: boolean;
    genesisValidating: boolean;
    supplyAllocationAmounts: SupplyAllocationAmounts;
    rollappTxState: TxState;
    iroPlanTxState: TxState;
    setAlias: (value: string) => void;
    setToken: (value: Token) => void;
    setDistribution: (value: Distribution) => void;
    setSupplyAllocation: (value: SupplyAllocation) => void;
    updateAccounts: (value: Account[]) => Promise<void>;
    aliasFee: Fee,
    iro: IRO,
    da?: DataAvailability,
    setDa: (value?: DataAvailability) => void;
    iroLaunchedModalVisible: boolean;
    setIroLaunchedModalVisible: (value: boolean) => void;
    isMinSequencerBondValid?: boolean;
    setTotalSupply: (value: bigint | undefined) => void;
    launchIro: () => void;
    saveRollapp: () => void;
    setRollappLogoFile: (file: File) => void;
    setVisibleStep: (value: CreateRollappStep) => void;
    setCurrentStep: (value: CreateRollappStep) => void;
    setIro: (value: IRO) => void;
    setShouldGenerateGenesis: (value: boolean) => void;
}

const QUICK_AUTH_TRANSACTION_IN_PROGRESS_KEY = 'quick-auth-transaction-in-progress-key';

export const CreateRollappContext = createContext<CreateRollappContextValue>({} as CreateRollappContextValue);

export const useCreateRollapp = (): CreateRollappContextValue => useContext(CreateRollappContext);

export const CreateRollappContextProvider = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate();
    const { id: rollappIdParam = '' } = useParams();
    const { iroPlans, getIroPlan } = useIRO();
    const {
        connect: connectIroQuickAuth,
        revoke: revokeIroQuickAuth,
        connecting: iroQuickAuthConnecting,
        isConnected: iroQuickAuthConnected,
    } = useIroQuickAuth();
    const { isConnected: quickAuthConnected } = useQuickAuth();
    const { incentivesState } = useIncentives();
    const copyToClipboard = useCopyToClipboard();
    const { showSuccessMessage, showMessage, showWarningMessage, showErrorMessage, removeMessage, getMessage } = useSnackbar();
    const { hubWallet, handleWalletError } = useWallet();
    const { addAsset } = useAsset();
    const { clientError, handleClientError } = useClient();
    const { rollAppParams, hubNetwork, networks, loading: networksLoading, getNetwork, refreshRollapp } = useNetwork();
    const { dymnsState, refreshAliases } = useDymns();
    const networkState = useHubNetworkState();
    const [ rollapp, setRollapp ] = usePersistedState<Network>(`rollapp-${rollappIdParam}`, createEmptyRollapp());
    const [ alias, setAlias ] = usePersistedState(`alias-${rollappIdParam}`, '');
    const [ da, setDa ] = usePersistedState<DataAvailability | undefined>(`da-${rollappIdParam}`, undefined);
    const [ accounts, setAccounts ] = useState<Account[]>([]);
    const [ distribution, setDistribution ] = usePersistedState<Distribution>(`distribution-${rollappIdParam}`, createEmptyDistribution());
    const [ iro, setIro ] = usePersistedState<IRO>(`iro-${rollappIdParam}`, createEmptyIro());
    const [ supplyAllocation, setSupplyAllocation ] =
        usePersistedState<SupplyAllocation>(`supply-allocation-${rollappIdParam}`, createEmptySupplyAllocation());
    const [ token, setToken ] = usePersistedState<Token>(`token-${rollappIdParam}`, createEmptyToken());
    const [ currentStep, setCurrentStep ] = usePersistedState<CreateRollappStep>(`current-step-${rollappIdParam}`, 'Register');
    const [ isComplete, setIsComplete ] = usePersistedState(`is-complete-${rollappIdParam}`, false);
    const [ totalSupply, setTotalSupply ] = usePersistedState<bigint | undefined>(`total-supply-${rollappIdParam}`, DEFAULT_TOTAL_SUPPLY);
    const [ shouldGenerateGenesis, setShouldGenerateGenesis ] = usePersistedState(`should-generate-genesis-${rollappIdParam}`, false);
    const [ rollappLogoFile, setRollappLogoFile ] = useState<File>();
    const [ showErrors, setShowErrors ] = useState(false);
    const [ , setRollappTxResponseHash ] = useState('');
    const [ iroPlanTxResponseHash, setIroPlanTxResponseHash ] = useState('');
    const [ visibleStep, setVisibleStep ] = useState<CreateRollappStep>(rollapp.launched ? 'Register' : currentStep);
    const [ logoUploading, setLogoUploading ] = useState(false);
    const [ genesisGenerating, setGenesisGenerating ] = useState(false);
    const [ genesisLoading, setGenesisLoading ] = useState(false);
    const [ rollappDataLoaded, setRollappDataLoaded ] = useState(false);
    const [ iroLaunchedModalVisible, setIroLaunchedModalVisible ] = useState(false);
    const [ genesisValidating, setGenesisValidating ] = useState(false);
    const [ shouldBroadcastIroPlanTx, setShouldBroadcastIroPlanTx ] = useState(false);

    const isAliasExists = useMemo(
        () => currentStep === 'Register' && Object.keys(dymnsState.aliasesMap)
            .some((chainId) => chainId === alias || dymnsState.aliasesMap[chainId]?.aliases?.includes(alias)),
        [ alias, dymnsState.aliasesMap, currentStep ],
    );

    const haveIRO = useMemo(() => Boolean(!token.tokenless && supplyAllocation.iro), [ supplyAllocation.iro, token.tokenless ]);

    const initialPurchaseEnabled = useMemo(() => Boolean(iroPlans && !iro.startTime), [ iro.startTime, iroPlans ]);

    const isMinSequencerBondValid = useMemo(() => {
        const minSequencerBond = BigInt(rollapp.minSequencerBond?.[0]?.amount || '0');
        const minGlobalSequencerBond = BigInt(rollAppParams?.minSequencerBondGlobal?.amount || '0');
        return minSequencerBond >= minGlobalSequencerBond;
    }, [ rollAppParams?.minSequencerBondGlobal?.amount, rollapp.minSequencerBond ]);

    const supplyAllocationAmounts = useMemo(
        () => calculateSupplyAllocationAmounts(supplyAllocation, totalSupply),
        [ supplyAllocation, totalSupply ],
    );

    const updateAccounts = useCallback(async (accounts: Account[]): Promise<void> => {
        if (rollappDataLoaded && !rollapp.genesisUrl) {
            const storage = new AccountStorage(`accounts-${rollappIdParam}`);
            await storage.openDatabase().then(() => storage.replaceAllAccounts(accounts)).finally(() => storage.close());
        }
        setAccounts(accounts);
    }, [ rollapp.genesisUrl, rollappDataLoaded, rollappIdParam ]);

    const updateRollapp = useCallback((key: keyof Network, value: Network[keyof Network]): void => {
        setRollapp((rollapp) => ({ ...rollapp, [key]: value }));
    }, [ setRollapp ]);

    const checkRollappIdExists = useCallback(() => {
        const rollappIdNumber = fetchRollAppIdNumber(rollapp.chainId);
        const rollappIdName = fetchRollAppIdName(rollapp.chainId);
        const isRollappIdNumberExists = networks.some((network) => fetchRollAppIdNumber(network.chainId) === rollappIdNumber);
        const isRollappIdNameExists = networks.some((network) => fetchRollAppIdName(network.chainId) === rollappIdName);
        return isRollappIdNumberExists || isRollappIdNameExists;
    }, [ networks, rollapp.chainId ]);

    const validateRegistrationParams = useCallback((): string[] => {
        let errors: string[] = [];
        if (!rollapp.chainId) {
            errors.push('Missing RollApp ID');
        } else if (currentStep === 'Register') {
            const rollappIdNumber = fetchRollAppIdNumber(rollapp.chainId);
            if (!/^[a-z]+_\d+-\d+$/.test(rollapp.chainId) || rollappIdNumber.length < 4 || rollappIdNumber.startsWith('0')) {
                errors.push('Invalid RollApp ID');
            }
        }
        if (!alias) {
            errors.push('Missing domain');
        } else if (isAliasExists) {
            errors.push('Invalid domain');
        }
        if (!rollapp.chainName) {
            errors.push('Missing RollApp display name');
        }
        if (!da) {
            errors.push('Missing base layer');
        }
        if (!rollappLogoFile && !rollapp.logo) {
            errors.push('Missing RollApp logo');
        }
        if (rollapp.website && !URL_REGEX.test(rollapp.website)) {
            errors.push('Invalid website URL');
        }
        if (rollapp.tgAccount && !URL_REGEX.test(rollapp.tgAccount)) {
            errors.push('Invalid Telegram URL');
        }
        if (rollapp.xAccount && !URL_REGEX.test(rollapp.xAccount)) {
            errors.push('Invalid X URL');
        }
        return errors;
    }, [
        da,
        rollapp.chainId,
        rollapp.chainName,
        rollapp.logo,
        rollappLogoFile,
        rollapp.website,
        rollapp.tgAccount,
        rollapp.xAccount,
        currentStep,
        alias,
        isAliasExists,
    ]);

    const validateGenesisParams = useCallback((): string[] => {
        let errors: string[] = [];
        if (token.tokenless) {
            if (!rollapp.bech32Prefix) {
                errors.push('Missing address prefix');
            } else if (rollapp.bech32Prefix.length < MIN_TOKEN_SYMBOL_LENGTH) {
                errors.push('Invalid address prefix');
            }
        } else {
            if (!token.name) {
                errors.push('Missing token name');
            } else if (token.name.length < MIN_TOKEN_SYMBOL_LENGTH) {
                errors.push('Invalid token name');
            }
            if (!totalSupply) {
                errors.push('Missing total supply');
            } else if (accounts.length > MAX_ACCOUNTS) {
                errors.push(`Supporting for up to ${MAX_ACCOUNTS} accounts is available. For assistance with larger account needs, please reach out for technical support on Discord.`);
            } else if (accounts.filter((account) => account.hub).length >= MAX_HUB_ACCOUNTS) {
                errors.push(`Supporting for up to ${MAX_HUB_ACCOUNTS} hub accounts is available.`);
            } else {
                const accountsTotalSupply = getAccountsSupply(accounts);
                if (accountsTotalSupply !== supplyAllocationAmounts.accounts) {
                    errors.push(`The declared accounts supply doesn't match the amount allocated on your accounts`);
                }
            }
            if ((token.minimumInflationRate || 0) > (token.initialInflationRate || 0)) {
                errors.push('Invalid inflation rates.');
            }
        }
        return errors;
    }, [
        token.tokenless,
        token.name,
        token.minimumInflationRate,
        token.initialInflationRate,
        totalSupply,
        rollapp.bech32Prefix,
        accounts,
        supplyAllocationAmounts.accounts,
    ]);

    const validateIroParams = useCallback((broadcasting?: boolean): string[] => {
        if (!haveIRO) {
            return [];
        }
        let errors: string[] = [];
        if (!iro.targetRaise) {
            errors.push('Missing target raise');
        }
        if (broadcasting && iro.initialPurchase === undefined && initialPurchaseEnabled) {
            errors.push('Missing initial purchase');
        }
        return errors;
    }, [ haveIRO, iro.targetRaise, iro.initialPurchase, initialPurchaseEnabled ]);

    const validateSetSequencerParams = useCallback((): string[] => {
        let errors: string[] = [];
        if (visibleStep === 'Launch RollApp' && !rollapp.initialSequencer) {
            errors.push('Missing operator address');
        } else if (!isMinSequencerBondValid) {
            errors.push('Invalid minimum sequencer bond');
        } else if (rollapp.initialSequencer && rollapp.initialSequencer !== '*' &&
            (!rollapp.initialSequencer?.startsWith('dym1') || rollapp.initialSequencer.length !== 42)) {
            errors.push('Invalid operator address');
        }
        return errors;
    }, [ isMinSequencerBondValid, rollapp.initialSequencer, visibleStep ]);

    const validateParams = useCallback((): string[] => {
        let currentErrors = validateRegistrationParams();
        if (!currentErrors.length && currentStep !== 'Register' && !rollapp.sealed) {
            currentErrors = validateGenesisParams();
        }
        if (!currentErrors.length && currentStep === 'Launchpad' && !rollapp.sealed) {
            currentErrors = validateIroParams();
        }
        if (!currentErrors.length && (currentStep === 'Launch RollApp' || visibleStep === 'Launch RollApp') && !rollapp.launched) {
            currentErrors = validateSetSequencerParams();
        }
        return currentErrors;
    }, [
        currentStep,
        visibleStep,
        rollapp.launched,
        rollapp.sealed,
        validateGenesisParams,
        validateIroParams,
        validateRegistrationParams,
        validateSetSequencerParams,
    ]);

    const createMessagesCreator = useCallback((fee?: CoinsAmount, params?: { logoUrl?: string }): EncodeObject[] => {
        if (!networkState.address || validateParams().length || !rollAppParams?.minSequencerBondGlobal) {
            return [];
        }
        return [
            createRegisterRollappMessage(
                rollapp,
                networkState.address,
                alias,
                params?.logoUrl || '',
                rollAppParams?.minSequencerBondGlobal,
            ),
        ];
    }, [ alias, networkState.address, rollAppParams?.minSequencerBondGlobal, rollapp, validateParams ]);

    const updateMessagesCreator = useCallback((
        fee?: CoinsAmount,
        params?: { genesisUrl: string, genesisChecksum: string, logoUrl?: string },
    ): EncodeObject[] => {
        if (!networkState.address || validateParams().length) {
            return [];
        }
        const { genesisChecksum, genesisUrl, logoUrl } = params || {};
        const nativeToken: Currency | undefined = token.name ? {
            displayDenom: token.name,
            baseDenom: token.denom,
            decimals: DEFAULT_DENOM_EXPONENT,
            type: 'main',
        } : undefined;
        return [
            createUpdateRollappMessage(
                rollapp,
                networkState.address,
                token.tokenless ? undefined : accounts,
                token.tokenless ? undefined : nativeToken,
                undefined,
                // !token.dymFee || !hubCurrency ? undefined : { ...hubCurrency, baseDenom: ROLLAPP_DYM_IBC },
                genesisChecksum,
                genesisUrl,
                logoUrl,
                token.tokenless ? undefined : totalSupply,
                token.tokenless ? undefined : supplyAllocationAmounts.iro,
            ),
        ];
    }, [
        networkState.address,
        accounts,
        rollapp,
        token.denom,
        token.name,
        token.tokenless,
        totalSupply,
        validateParams,
        supplyAllocationAmounts.iro,
    ]);

    const iroPlanMessagesCreator = useCallback((fee?: CoinsAmount, params?: any, broadcasting?: boolean): EncodeObject[] => {
        if (!networkState.address ||
            !incentivesState.params ||
            !haveIRO ||
            !iro.bondingCurve ||
            !rollapp.genesisChecksum ||
            validateIroParams().length) {
            return [];
        }
        const messages: EncodeObject[] = [
            createIroPlanMessage(rollapp, networkState.address, iro, supplyAllocationAmounts.iro, incentivesState.params.epochIdentifier),
        ];
        if (initialPurchaseEnabled && iro.initialPurchase?.amount && iroPlans) {
            const sortedIroPlans = [ ...iroPlans ].sort((plan1, plan2) => plan1.id - plan2.id);
            const newIroId = sortedIroPlans[sortedIroPlans.length - 1].id + 1;
            messages.push(createIroBuyExactSpendMessage(
                networkState.address,
                {
                    rollappId: rollapp.chainId,
                    soldAmt: '1',
                    id: broadcasting ? newIroId : sortedIroPlans[0].id,
                    settledDenom: '',
                    moduleAccAddress: '',
                    claimedAmt: '0',
                    bondingCurve: undefined,
                    totalAllocation: undefined,
                    incentivePlanParams: undefined,
                    preLaunchTime: undefined,
                    startTime: undefined,
                },
                {
                    amount: 1,
                    currency: { type: 'main', decimals: DEFAULT_DENOM_EXPONENT, baseDenom: '', displayDenom: '' },
                    networkId: rollapp.chainId,
                },
                iro.initialPurchase,
            ));
        }
        return messages;
    }, [
        haveIRO,
        incentivesState.params,
        initialPurchaseEnabled,
        iro,
        iroPlans,
        networkState.address,
        rollapp,
        supplyAllocationAmounts.iro,
        validateIroParams,
    ]);

    const {
        txState: rollappTxState,
        broadcast: broadcastRollappTx,
        calculateFee: calculateRollappTxFee,
        clearFee: clearRollappTxFee,
    } = useTx({
        networkState,
        txMessagesCreator: currentStep === 'Register' ? createMessagesCreator : updateMessagesCreator,
    });

    const {
        txState: iroPlanTxState,
        broadcast: broadcastIroPlanTx,
        calculateFee: calculateIroPlanTxFee,
        clearFee: clearIroPlanTxFee,
    } = useTx({
        networkState,
        txMessagesCreator: iroPlanMessagesCreator,
        signMethod: iro.initialPurchase?.amount ? 'direct' : undefined,
    });

    const validateGenesis = useCallback(async (): Promise<boolean> => {
        if (!hubNetwork?.rpc) {
            return false;
        }
        setGenesisValidating(true);
        const url = new URL(hubNetwork.rpc);
        const nodeAddress = `${url.origin}:443${url.pathname}${url.search}${url.hash}`;
        return fetch(process.env.REACT_APP_GENESIS_VALIDATOR_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'rollapp-id': rollapp.chainId,
                'settlement-chain-id': hubNetwork.chainId,
                'settlement-node-address': nodeAddress,
            }),
        })
            .then(async (response) => {
                const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
                const { status, message, error } =
                    JSON.parse(responseText || '{}') as { error?: string, status?: string, message?: string };
                if (error || [ status, message ].every((value) => value !== 'Genesis validation succeeded')) {
                    showErrorMessage('Genesis validation failed. Try updating the Tokenomics step to regenerate the genesis');
                    return false;
                }
                showSuccessMessage('Genesis validation succeeded!');
                return true;
            })
            .catch((error) => {
                console.error(error);
                showErrorMessage('Unable to validate the genesis. Please try again later.');
                return false;
            })
            .finally(() => setGenesisValidating(false));
    }, [ hubNetwork?.chainId, hubNetwork?.rpc, rollapp.chainId, showErrorMessage, showSuccessMessage ]);

    const aliasFee = useMemo((): Fee => {
        const aliasPriceSteps = dymnsState.params?.aliasPriceSteps;
        const currency = hubNetwork && getFeeCurrency(hubNetwork);

        return {
            label: <>
                Domain handle fee
                <InfoIndicator tooltipPlacement='top' indicatorSize='xs'>
                    RollApp domain handle costs are burned and are determined by length. Handles with six characters or less incur a premium fee.
                </InfoIndicator>
            </>,
            loading: (!alias.length || !aliasPriceSteps || !currency) && (networksLoading || dymnsState.paramsLoading),
            value: !alias.length || !aliasPriceSteps || !currency ? '-' :
                { currency, amount: aliasPriceSteps[Math.min(aliasPriceSteps.length, alias.length) - 1], networkId: hubNetwork?.chainId },
        };
    }, [ alias.length, dymnsState.params?.aliasPriceSteps, dymnsState.paramsLoading, hubNetwork, networksLoading ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error.originalError?.message?.includes('rollapp already exists')) {
            showErrorMessage('A network with the same chain ID (number or prefix) already exists');
        } else if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateRollappTxFee(false);
        calculateIroPlanTxFee(false);
        const { genesisUrl, logoUrl } = rollappTxState.params || {};
        if (genesisUrl && rollapp.genesisUrl !== genesisUrl) {
            deleteObject(getGenesisFileRef(genesisUrl)).then().catch((error) => {
                console.error(`Can't delete rollapp genesis`, error);
            });
        }
        if (logoUrl && rollapp.logo !== logoUrl) {
            deleteObject(getRollappLogoFileRef(logoUrl)).then().catch((error) => {
                console.error(`Can't delete rollapp logo`, error);
            });
        }
    }, [
        calculateRollappTxFee,
        calculateIroPlanTxFee,
        handleClientError,
        handleWalletError,
        rollapp.genesisUrl,
        rollapp.logo,
        rollappTxState.params,
        showErrorMessage,
    ]);

    useEffect(() => handleError(rollappTxState.error), [ handleError, rollappTxState.error ]);

    useEffect(() => handleError(iroPlanTxState.error), [ handleError, iroPlanTxState.error ]);

    useEffect(() => setVisibleStep(rollapp.launched ? 'Register' : currentStep), [ currentStep, rollapp.launched ]);

    useEffect(() => {
        if (networkState.address && !validateParams().length) {
            calculateRollappTxFee();
        } else {
            clearRollappTxFee();
        }
    }, [ calculateRollappTxFee, clearRollappTxFee, networkState.address, validateParams ]);

    useEffect(() => {
        if (networkState.address &&
            !validateIroParams().length &&
            !rollapp.sealed &&
            iro.bondingCurve &&
            haveIRO &&
            rollapp.genesisChecksum) {
            calculateIroPlanTxFee();
        } else {
            clearIroPlanTxFee();
        }
    }, [
        initialPurchaseEnabled,
        calculateIroPlanTxFee,
        clearIroPlanTxFee,
        haveIRO,
        iro.bondingCurve,
        networkState.address,
        rollapp.genesisChecksum,
        rollapp.sealed,
        validateIroParams,
    ]);

    const changeRollappDataLocalStorage = useCallback(() => {
        if (rollappIdParam === rollapp?.chainId && !localStorage.getItem(`rollapp-${rollappIdParam}`)) {
            setRollapp(rollapp);
            setAlias(alias);
            setCurrentStep(currentStep);
            setTotalSupply(totalSupply);
            setDa(da);
            localStorage.removeItem('rollapp-');
            localStorage.removeItem('alias-');
            localStorage.removeItem('da-');
            localStorage.removeItem('current-step-');
            localStorage.removeItem('total-supply-');
        }
    }, [ rollappIdParam, rollapp, setRollapp, setAlias, alias, setCurrentStep, currentStep, setTotalSupply, totalSupply, setDa, da ]);

    useEffect(() => {
        if (!hubNetwork || !rollapp.preLaunchTime) {
            return;
        }
        const iroPlan = getIroPlan(rollapp.chainId);
        if (!iroPlan) {
            return;
        }
        const newIro = createEmptyIro();
        const startTime = new Date(iroPlan.startTime || Date.now()).getTime();
        newIro.startTime = startTime;
        if (iroPlan.preLaunchTime) {
            newIro.planDuration = new Date(iroPlan.preLaunchTime).getTime() - startTime;
        }
        if (iroPlan.bondingCurve) {
            newIro.bondingCurve = convertToBondingCurve(iroPlan.bondingCurve);
        }
        if (iroPlan.incentivePlanParams) {
            newIro.startTimeAfterSettlement = Number(iroPlan.incentivePlanParams.startTimeAfterSettlement?.seconds) * 1000;
            if (incentivesState.params) {
                newIro.incentivesDistributionTime =
                    iroPlan.incentivePlanParams.numEpochsPaidOver * epochToMilliseconds(incentivesState.params.epochIdentifier);
            } else {
                newIro.incentivesDistributionTime = 0;
            }
        }
        setIro(newIro);
    }, [ getIroPlan, hubNetwork, incentivesState.params, rollapp.chainId, rollapp.preLaunchTime, setIro ]);

    useEffect(() => {
        const rollapp = getNetwork(rollappIdParam, true);
        if (rollapp) {
            updateRollapp('apps', rollapp.apps);
        }
    }, [ getNetwork, rollappIdParam, updateRollapp ]);

    useEffect(() => {
        const bech32Prefix = token.name.toLowerCase();
        if (rollappDataLoaded && !token.tokenless && rollapp.bech32Prefix !== bech32Prefix) {
            updateRollapp('bech32Prefix', bech32Prefix);
        }
    }, [ rollapp.bech32Prefix, rollappDataLoaded, token.name, token.tokenless, updateRollapp ]);

    useEffect(() => {
        if (dymnsState.aliasesLoading || genesisLoading) {
            return;
        }
        if (!rollappIdParam) {
            setRollappDataLoaded(true);
            return;
        }
        if (rollappDataLoaded) {
            changeRollappDataLocalStorage();
            return;
        }
        let currentRollapp = getNetwork(rollappIdParam, true);
        if (currentRollapp) {
            setRollapp(currentRollapp);
            setAlias(dymnsState.aliasesMap[rollappIdParam]?.aliases?.[0]);
        } else if (!rollapp.chainId) {
            navigate('/rollapps/manage');
            showErrorMessage('RollApp not exists');
            return;
        } else {
            currentRollapp = rollapp;
        }
        if (!currentRollapp.genesisChecksum || !currentRollapp.genesisUrl) {
            setCurrentStep('Tokenomics');
            setShouldGenerateGenesis(true);
            setRollappDataLoaded(true);
            const storage = new AccountStorage(`accounts-${rollappIdParam}`);
            storage.openDatabase().then(() => storage.getAllAccounts()).then(setAccounts).finally(() => storage.close());
            return;
        } else if (currentRollapp.sealed || currentRollapp.initialSequencer) {
            setCurrentStep('Launch RollApp');
            setIsComplete(Boolean(currentRollapp.initialSequencer));
        } else {
            setCurrentStep('Launchpad');
        }
        if (rollapp.launched) {
            setRollappDataLoaded(true);
            return;
        }
        const loadingKey = `genesis-loading-${currentRollapp.chainId}`;
        if (getMessage(loadingKey)) {
            return;
        }
        showMessage({
            content: <div className='horizontally-centered'><Spinner size='small' />&nbsp;&nbsp;Genesis loading...</div>,
            duration: 600000,
            key: loadingKey,
        });
        setGenesisLoading(true);
        loadGenesis(currentRollapp.genesisUrl)
            .then(({ genesisString, genesisChecksum }) => {
                if (genesisChecksum !== currentRollapp?.genesisChecksum) {
                    throw new Error(`Genesis checksum does not match: ${genesisChecksum} != ${currentRollapp?.genesisChecksum}`);
                }
                const genesis = JSON.parse(genesisString);
                const { accounts, accountsSupply, communityPoolSupply, iroSupply } = getAllocationsFromGenesis(genesis);
                const totalSupply = accountsSupply + communityPoolSupply + iroSupply;
                setDa(getDaFromGenesis(genesis));
                setAccounts(accounts);
                setTotalSupply(totalSupply);
                setToken(getTokenFromGenesis(genesis));
                setDistribution(getDistributionFromGenesis(genesis));
                if (totalSupply) {
                    setSupplyAllocation({
                        iro: Number(Decimal.fromAtomics((BigInt(100 * 10 ** DEFAULT_DENOM_EXPONENT) *
                            iroSupply / totalSupply).toString(), DEFAULT_DENOM_EXPONENT).toString()),
                        communityPool: Number(Decimal.fromAtomics((BigInt(100 * 10 ** DEFAULT_DENOM_EXPONENT) *
                            communityPoolSupply / totalSupply).toString(), DEFAULT_DENOM_EXPONENT).toString()),
                    });
                }
            })
            .catch((error) => {
                console.error('Invalid genesis', error);
                setCurrentStep('Tokenomics');
            })
            .finally(() => {
                setGenesisLoading(false);
                setRollappDataLoaded(true);
                setTimeout(() => removeMessage(loadingKey), 150);
            });
    }, [
        changeRollappDataLocalStorage,
        dymnsState.aliasesLoading,
        dymnsState.aliasesMap,
        getMessage,
        getNetwork,
        setIro,
        setDa,
        navigate,
        genesisLoading,
        removeMessage,
        rollapp,
        rollappDataLoaded,
        rollappIdParam,
        updateRollapp,
        setAlias,
        setCurrentStep,
        setDistribution,
        setIsComplete,
        setRollapp,
        setShouldGenerateGenesis,
        setSupplyAllocation,
        setToken,
        setTotalSupply,
        showErrorMessage,
        showMessage,
    ]);

    const prepareIroPlanTx = useCallback(async () => {
        if (!hubWallet || !hubNetwork || !networkState.address) {
            return;
        }
        if (WalletInfoMap[hubWallet.getWalletType()].type === 'cosmos' || !iro.initialPurchase?.amount) {
            return broadcastIroPlanTx();
        }
        if (quickAuthConnected) {
            showWarningMessage('You should revoke Quick-Auth before proceeding.');
            return;
        }
        showMessage({
            content: <div className='horizontally-centered'><Spinner size='small' />&nbsp;&nbsp;Transaction is in progress.</div>,
            duration: 600000,
            key: QUICK_AUTH_TRANSACTION_IN_PROGRESS_KEY,
        });
        setShouldBroadcastIroPlanTx(true);
        connectIroQuickAuth();
    }, [
        broadcastIroPlanTx,
        connectIroQuickAuth,
        hubNetwork,
        hubWallet,
        iro.initialPurchase?.amount,
        networkState.address,
        quickAuthConnected,
        showMessage,
        showWarningMessage,
    ]);

    useEffect(() => {
        if (clientError || iroPlanTxState.error) {
            revokeIroQuickAuth();
        }
    }, [ clientError, iroPlanTxState.error, revokeIroQuickAuth ]);

    useEffect(() => {
        if (iroQuickAuthConnected && shouldBroadcastIroPlanTx) {
            setShouldBroadcastIroPlanTx(false);
            removeMessage(QUICK_AUTH_TRANSACTION_IN_PROGRESS_KEY);
            setTimeout(() => broadcastIroPlanTx(), 50);
        }
    }, [ broadcastIroPlanTx, iroQuickAuthConnected, removeMessage, shouldBroadcastIroPlanTx ]);

    useEffect(() => {
        const { deliveryTxCode, hash, params } = rollappTxState.response || {};
        setRollappTxResponseHash((rollappTxResponseHash) => {
            if (deliveryTxCode !== DeliveryTxCode.SUCCESS || hash === rollappTxResponseHash) {
                return rollappTxResponseHash;
            }
            refreshRollapp(rollapp.chainId);

            if (currentStep === 'Register') {
                setCurrentStep('Tokenomics');
                navigate(`/rollapps/manage/create/${rollapp.chainId}`, { replace: true });
                fetch(process.env.REACT_APP_COLLECT_ALIASES_URL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ data: {} }),
                }).then(refreshAliases);
                setTimeout(() => window.scrollTo({ top: 0 }));
            }
            if (visibleStep === 'Tokenomics' && currentStep === 'Tokenomics') {
                setCurrentStep('Launchpad');
                setTimeout(() => window.scrollTo({ top: 0 }));
            }
            if (visibleStep === 'Launch RollApp' && currentStep === 'Launch RollApp') {
                setIsComplete(true);
            }
            const { genesisChecksum, genesisUrl, logoUrl } = params || {};
            if (logoUrl) {
                if (rollapp.logo && rollapp.logo !== logoUrl) {
                    try {
                        const fileRef = getRollappLogoFileRef(rollapp.logo);
                        deleteObject(fileRef).then().catch((error) => {
                            console.error(`Can't delete rollapp logo`, error);
                        });
                    } catch (ignore) {}
                }
                updateRollapp('logo', logoUrl);
                setRollappLogoFile(undefined);
            }
            if (genesisChecksum && genesisUrl) {
                if (rollapp.genesisUrl && rollapp.genesisUrl !== genesisUrl) {
                    try {
                        const fileRef = getGenesisFileRef(rollapp.genesisUrl);
                        deleteObject(fileRef).then().catch((error) => {
                            console.error(`Can't delete rollapp genesis`, error);
                        });
                    } catch (ignore) {}
                }
                updateRollapp('genesisChecksum', genesisChecksum);
                updateRollapp('genesisUrl', genesisUrl);
                setShouldGenerateGenesis(false);
                const storage = new AccountStorage(`accounts-${rollapp.chainId}`);
                storage.openDatabase().then(() => storage.replaceAllAccounts([])).finally(() => storage.close());
                saveRollappTokenomics(rollapp.chainId, accounts, supplyAllocationAmounts, distribution).catch();
            }
            if (visibleStep === 'Launchpad' && currentStep === 'Launchpad') {
                validateGenesis().then((isValid) => isValid ? prepareIroPlanTx() : undefined);
            }
            return hash || '';
        });
    }, [
        accounts,
        distribution,
        supplyAllocationAmounts,
        validateGenesis,
        currentStep,
        navigate,
        refreshAliases,
        prepareIroPlanTx,
        refreshRollapp,
        rollapp.chainId,
        rollapp.genesisChecksum,
        rollapp.genesisUrl,
        rollapp.logo,
        rollappTxState.response,
        setShouldGenerateGenesis,
        setCurrentStep,
        setIsComplete,
        updateRollapp,
        visibleStep,
    ]);

    useEffect(() => {
        const { deliveryTxCode, hash } = iroPlanTxState.response || {};
        if (deliveryTxCode !== DeliveryTxCode.SUCCESS || hash === iroPlanTxResponseHash) {
            return;
        }
        setIroPlanTxResponseHash(hash || '');
        refreshRollapp(rollapp.chainId);
        revokeIroQuickAuth();

        if (visibleStep === 'Launchpad' && currentStep === 'Launchpad') {
            updateRollapp('sealed', true);
            const currency: Currency = {
                displayDenom: token.name,
                baseDenom: token.denom,
                decimals: DEFAULT_DENOM_EXPONENT,
                type: 'main',
            };
            addAsset({
                network: { ...rollapp, currencies: [ currency ], status: 'IRO' },
                currency,
                pools: [],
                key: `${rollapp.chainId}/${currency.baseDenom}`,
                networkId: rollapp.chainId,
                iroDenom: createIroDenom(rollapp.chainId),
                price: 0,
                stakingApr: 0,
                previousDayStakingApr: 0,
                previousHourPrice: 0,
                previousDayPrice: 0,
                previousDayLiquidity: 0,
                liquidity: 0,
                volume: 0,
                firstPrice: 0,
                locked: 0,
                previousDayVolume: 0,
                amount: 0,
                iroProgress: 0,
                iroDymRaised: 0,
                invalidMarketCap: false,
            });
            setCurrentStep('Launch RollApp');
            setTimeout(() => window.scrollTo({ top: 0 }));
            setIroLaunchedModalVisible(true);
        }
    }, [
        addAsset,
        currentStep,
        iroPlanTxResponseHash,
        iroPlanTxState.response,
        refreshRollapp,
        revokeIroQuickAuth,
        rollapp,
        setCurrentStep,
        token.denom,
        token.name,
        updateRollapp,
        visibleStep,
    ]);

    useEffect(() => {
        if (!rollappIdParam) {
            return;
        }
        const rollapp = getNetwork(rollappIdParam, true);
        if (!networkState.addressLoading && (!networkState.address || (rollapp && rollapp.owner !== networkState.address))) {
            navigate('/rollapps/manage');
            showErrorMessage(`You don't have permission to access this rollapp. Please contact support if you believe this is an error.`);
            return;
        }
    }, [ getNetwork, navigate, networkState.address, networkState.addressLoading, rollappIdParam, showErrorMessage ]);

    const showUploadSuccessMessage = useCallback((url: string, genesisFileName: string) => {
        showSuccessMessage({
            title: 'Genesis successfully uploaded!',
            content: (
                <div className='horizontally-centered'>
                    <span className='ellipsis genesis-url'>{url}</span>
                    <Button
                        size='small'
                        buttonType='icon'
                        onClick={(event) => {
                            event.stopPropagation();
                            copyToClipboard(url, 'Genesis url');
                        }}
                    >
                        <CopyIcon />
                    </Button>
                </div>
            ),
            action: {
                label: 'Download',
                close: true,
                callback: () => {
                    const downloadingKey = `genesis-downloading-${rollapp.chainId}`;
                    showMessage({
                        content: <div className='horizontally-centered'><Spinner size='small' />&nbsp;&nbsp;Genesis downloading...</div>,
                        duration: 600000,
                        key: downloadingKey,
                    });
                    setTimeout(() => {
                        exportFile(url, genesisFileName);
                        removeMessage(downloadingKey);
                    }, 50);
                },
            },
            duration: 60000,
        });
    }, [ copyToClipboard, removeMessage, rollapp.chainId, showMessage, showSuccessMessage ]);

    const uploadGenesis = useCallback(async (genesisString: string): Promise<string> => {
        const uploadingKey = `genesis-uploading-${rollapp.chainId}`;
        showMessage({
            content: <div className='horizontally-centered'><Spinner size='small' />&nbsp;&nbsp;Genesis uploading...</div>,
            duration: 600000,
            key: uploadingKey,
        });
        const fileName = `${rollapp.chainId}-genesis-${new Date().getTime()}.json`;
        const fileRef = getGenesisFileRef(fileName);
        return uploadString(fileRef, genesisString)
            .then((result) => getDownloadURL(result.ref))
            .then((url) => {
                showUploadSuccessMessage(url, fileRef.name);
                return url;
            })
            .catch((error) => {
                console.error(`Can't upload genesis file`, error);
                showErrorMessage(`Can't upload genesis, please try again later`);
                throw error;
            })
            .finally(() => setTimeout(() => removeMessage(uploadingKey), 50));
    }, [ removeMessage, rollapp.chainId, showErrorMessage, showMessage, showUploadSuccessMessage ]);

    const uploadRollappLogo = useCallback(async (): Promise<string> => {
        if (!rollappLogoFile) {
            return '';
        }
        const fileName = `${rollapp.chainId}-logo-${new Date().getTime()}.${getFileSuffix(rollappLogoFile.name)}`;
        const fileRef = getRollappLogoFileRef(fileName);
        return uploadBytes(fileRef, rollappLogoFile)
            .then((result) => getDownloadURL(result.ref))
            .catch((error) => {
                console.error(`Can't upload rollapp logo`, error);
                showErrorMessage(`Can't upload rollapp logo, please try again later`);
                throw error;
            });
    }, [ rollapp.chainId, rollappLogoFile, showErrorMessage ]);

    const updateRollappWithGenesis = useCallback(async (logoUrl?: string) => {
        setGenesisGenerating(true);
        setTimeout(() => {
            try {
                const genesisString = JSON.stringify(sortObject(generateGenesis(
                    rollapp,
                    token,
                    distribution,
                    supplyAllocationAmounts,
                    accounts,
                    totalSupply || BigInt(0),
                    da,
                )));
                const genesisChecksum = calculateChecksum(genesisString.trim());
                uploadGenesis(genesisString)
                    .then((genesisUrl) => broadcastRollappTx(undefined, { genesisUrl, genesisChecksum, logoUrl }))
                    .finally(() => setGenesisGenerating(false));
            } catch (error) {
                console.error(error);
                showErrorMessage(`Can't generate genesis, please contact support`);
                setGenesisGenerating(false);
            }
        });
    }, [
        da,
        accounts,
        broadcastRollappTx,
        distribution,
        showErrorMessage,
        rollapp,
        supplyAllocationAmounts,
        token,
        totalSupply,
        uploadGenesis,
    ]);

    const saveRollapp = useCallback(async () => {
        const errors = validateParams();
        if (currentStep === 'Register' && checkRollappIdExists()) {
            errors.push('A network with the same chain ID (number or prefix) already exists');
        }
        if (errors.length) {
            errors.forEach(showErrorMessage);
            setShowErrors(true);
            return;
        }
        if (!networkState.address) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, networkState.network));
            return;
        }
        setShowErrors(false);
        let logoUrl = '';
        if (rollappLogoFile) {
            setLogoUploading(true);
            logoUrl = await uploadRollappLogo();
            setLogoUploading(false);
        }
        if (!shouldGenerateGenesis) {
            return broadcastRollappTx(undefined, { logoUrl });
        }
        return updateRollappWithGenesis(logoUrl);
    }, [
        broadcastRollappTx,
        handleWalletError,
        checkRollappIdExists,
        currentStep,
        networkState.address,
        networkState.network,
        rollappLogoFile,
        shouldGenerateGenesis,
        showErrorMessage,
        updateRollappWithGenesis,
        uploadRollappLogo,
        validateParams,
    ]);

    const launchIro = useCallback(async () => {
        const errors = validateIroParams(true);
        if (errors.length) {
            errors.forEach(showErrorMessage);
            setShowErrors(true);
            return;
        }
        if (!networkState.address) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, networkState.network));
            return;
        }
        setShowErrors(false);
        if (!shouldGenerateGenesis) {
            const isGenesisValid = await validateGenesis();
            if (!isGenesisValid) {
                return;
            }
            return prepareIroPlanTx();
        }
        return saveRollapp();
    }, [
        prepareIroPlanTx,
        handleWalletError,
        validateGenesis,
        networkState.address,
        networkState.network,
        saveRollapp,
        shouldGenerateGenesis,
        showErrorMessage,
        validateIroParams,
    ]);

    return (
        <CreateRollappContext.Provider
            value={{
                rollapp,
                alias,
                token,
                haveIRO,
                aliasFee,
                distribution,
                rollappDataLoaded,
                accounts,
                iroQuickAuthConnecting,
                rollappTxState,
                iroPlanTxState,
                showErrors,
                totalSupply,
                currentStep,
                visibleStep,
                isAliasExists,
                genesisGenerating,
                updateRollapp,
                saveRollapp,
                rollappLogoFile,
                isComplete,
                logoUploading,
                initialPurchaseEnabled,
                supplyAllocation,
                updateAccounts,
                isMinSequencerBondValid,
                supplyAllocationAmounts,
                iro,
                launchIro,
                iroLaunchedModalVisible,
                genesisValidating,
                da,
                setDa,
                setIroLaunchedModalVisible,
                setIro,
                setSupplyAllocation,
                setCurrentStep,
                setShouldGenerateGenesis,
                setTotalSupply,
                setToken,
                setDistribution,
                setAlias,
                setVisibleStep,
                setRollappLogoFile,
            }}
        >
            {children}
        </CreateRollappContext.Provider>
    );
};
