import classNames from 'classnames';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../../shared/components/icon/icon';
import { useAsset } from '../asset/asset-context';
import Governance from '../governance/governance';
import { ReactComponent as SwapIcon } from '../../assets/icons/swap-horiz.svg';
import { ROLLAPP_STATUS_INFO_MAP } from '../network/network-types';
import RollappStatusBadge from '../rollapp/rollapp-status/badge/rollapp-status-badge';
import NetworkAsset from './network-asset/network-asset';
import NetworkHeader from './network-header/network-header';
import TabBar, { Tab } from '../../shared/components/tabs/tab-bar';
import { useNetworkDashboard } from './network-dashboard-context';
import NetworkStaking from './network-staking/network-staking';
import NetworkSummary from './network-summary/network-summary';
import './network-dashboard-page.scss';

const NetworkDashboardPage: React.FC = () => {
    const navigate = useNavigate();
    const { network } = useNetworkDashboard();
    const { mainAssetMap } = useAsset();
    const { pageKey } = useParams();

    const nativeAsset = useMemo(() => mainAssetMap?.[network.chainId], [ mainAssetMap, network.chainId ]);

    const unavailable = useMemo(
        () => network?.type === 'RollApp' && network.status !== 'Active' && network.status !== 'Degraded',
        [ network?.status, network?.type ],
    );

    const inactiveTooltip = useMemo(
        () => !network.status || network.status === 'Active' || network.status === 'Degraded' ?
            'Available upon initial transfer of tokens from RollApp to Dymension Hub' :
            network.status === 'IRO' ? 'This token has not launched a rollapp yet.' :
                ROLLAPP_STATUS_INFO_MAP[network.status].infoText,
        [ network.status ],
    );

    const isPageKeyExists = useMemo(
        () => pageKey && [
            'dashboard',
            ...(network.type === 'Hub' ? [ 'token', 'proposal', 'staking', 'governance' ] :
                network.tokenless ? [ 'staking', 'governance' ] : !mainAssetMap || nativeAsset ? [ 'token', 'staking', 'governance' ] : []),
        ].includes(pageKey),
        [ mainAssetMap, nativeAsset, network.tokenless, network.type, pageKey ],
    );

    useEffect(() => {
        if (!isPageKeyExists) {
            const defaultPageKey = network.status === 'IRO' ? 'token' : 'dashboard';
            navigate(`${pageKey ? '../' : './'}${defaultPageKey}`, { relative: 'path', replace: true });
        }
    }, [ isPageKeyExists, nativeAsset, navigate, network.status, pageKey ]);

    if (!isPageKeyExists) {
        return null;
    }

    const renderTokenTabLabel = (): ReactElement => {
        return (
            <b className={classNames('asset-tab-label trade', { iro: network.status === 'IRO', disabled: !nativeAsset })}>
                <Icon className='trade-icon'><SwapIcon /></Icon>
                Trade{network.status === 'IRO' && <>&nbsp;&nbsp;<RollappStatusBadge status='IRO' size='small' /></>}
            </b>
        );
    };

    return (
        <div className={classNames('page network-dashboard', { wide: pageKey === 'dashboard' || pageKey === 'token' })}>
            <div className='dashboard-content'>
                <NetworkHeader className='dashboard-network-header' network={network} />

                <TabBar
                    className='dashboard-tab-bar'
                    activeTabKey={pageKey === 'proposal' ? 'governance' : pageKey}
                    onTabChange={(tabKey) => navigate('../' + tabKey, { relative: 'path' })}
                >
                    <Tab label='Dashboard' tabKey='dashboard' className='dashboard-tab'>
                        <NetworkSummary />
                    </Tab>
                    {!network.tokenless && (
                        <Tab
                            label={renderTokenTabLabel()}
                            tabKey='token'
                            className='dashboard-tab asset'
                            tooltip={!nativeAsset && inactiveTooltip}
                            disabled={!nativeAsset}
                        >
                            <NetworkAsset />
                        </Tab>
                    )}
                    <Tab
                        label='Stake'
                        tabKey='staking'
                        className='dashboard-tab'
                        tooltip={unavailable && inactiveTooltip}
                        disabled={unavailable}
                    >
                        <NetworkStaking />
                    </Tab>
                    <Tab
                        label='Vote'
                        tabKey='governance'
                        className='dashboard-tab'
                        tooltip={unavailable && inactiveTooltip}
                        disabled={unavailable}
                    >
                        <Governance network={network} />
                    </Tab>
                </TabBar>
            </div>
        </div>
    );
};

export default NetworkDashboardPage;
